import moment from "moment/moment";
import { routeFilters } from "../constants"
import store from "../../redux/store";

const stopLoader = () => {
    store.dispatch({ type: 'STOP_LOADER_FOR_LIST_ROUTES_PAGE' })
    store.dispatch({ type: 'STOP_LOADER_FOR_LIST_TENDERS_PAGE' })
}

const dateFilter = (arr, value) =>
    arr.filter((el) => {
        const date =
            el.routeparts[0].date1 !== '0000-00-00'
                ? new Date(el.routeparts[0].date1).setHours(0, 0, 0, 0)
                : new Date().setHours(0, 0, 0, 0)

        if (value.from && value.to) {
            if (
                moment(date).isSameOrAfter(value.from) &&
                moment(date).isSameOrBefore(value.to)
            )
                return el
        } else if (value.from) {
            if (moment(date).isSameOrAfter(value.from)) return el
        } else if (value.to) {
            if (moment(date).isSameOrBefore(value.to)) return el
        }
    })

export const filterRoutes = (data, filterArr) => {
    let result = []
    let dateFilterItem = null
    const updater = (obj) => {
        const isHave = result.find((el) => el.id === obj.id)
        if (isHave) {
            return
        } else {
            result.push(obj)
        }
    }
    ///


    const condition = (arr, value, key) => {

        if(key === routeFilters.typeAuto && arr[0]?.cartypeRelation) {
            arr.flatMap((el, index) =>
                      el?.cartypeRelation.map((obj) => ({ ...obj, i: index }))
                  ).map((obj) => {
                      if (obj?.carTypeTitle === value){
                          updater(arr[obj.i])
                          return obj
                      }
                      return obj
                  })
        }

        if(key === routeFilters.loadTowns){
            arr.map((el) => {
                if (el.routeparts[0].checkpoint_relation.town_relation.title_ru === value){
                    updater(el)
                }

            })
        }
        if(key === routeFilters.unloadTowns){
            arr.map((el) => {
                if (
                    el.routeparts[el.routeparts.length - 1].checkpoint_relation
                        .town_relation.title_ru === value
                ){
                    updater(el)
                }
            })
        }

        if(key === routeFilters.shippers){
            arr.map((el) => {
                if (el.client_relation.company_relation.title_ru === value){
                    updater(el)
                }
            })
        }
    }

    if (!result.length) {

        if (filterArr.length === 0) {
            result = data
        }
        stopLoader()
    }

    for(let i = 0; filterArr.length > i; i++){
        const filterItem = filterArr[i]
        if(filterItem.id === 'date'){
            dateFilterItem = filterItem
        }else {
            condition(data, filterItem.filter, filterItem.id)
        }

    }

    return dateFilterItem
        ? dateFilter(result.length ? result : data, dateFilterItem)
        : result

}