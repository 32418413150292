import React from 'react'
import { useDispatch } from 'react-redux'
import Calendar from '../../components/popUps/Calendar'

export default () => {
  const dispatch = useDispatch()
  const onChangeUserDate = (obj) => {
    dispatch({ type: 'SET_USER_DATE', payload: obj })
  }
  const onSaveChange = (newDate) => {
    onChangeUserDate(newDate)
    dispatch({ type: 'CLOSE_MODAL' })
  }
  const onCancelChange = () => {
    dispatch({ type: 'CLOSE_MODAL' })
  }
  return (
    <Calendar
      fnUpdateCheck={onChangeUserDate}
      fnSave={onSaveChange}
      fnCancel={onCancelChange}
    />
  )
}
