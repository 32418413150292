import React, { PureComponent } from 'react'
export default class BitrixChat extends PureComponent {
  bitrixCode = () => {
    const s = document.createElement('script')
    // s.type = 'text/javascript'
    s.async = true
    s.innerHTML = "document.write('This is output by document.write()!')"
    s.src =
      'https://cdn.bitrix24.ru/b937185/crm/site_button/loader_3_7dtw0y.js' +
      '?' +
      ((Date.now() / 60000) | 0)
    this.instance.appendChild(s)
    const h = document.getElementsByTagName('script')[0]
    h.parentNode.insertBefore(s, h)
  }

  runBitrix = () => {
    this.setState((state) => ({
      ...state,
      isOriginal: true,
    }))
  }

  componentDidMount() {
    const {
      props: { isOriginal },
      bitrixCode,
    } = this
    if (isOriginal) bitrixCode()
  }

  componentDidUpdate() {
    const {
      props: { isOriginal },
      bitrixCode,
    } = this
    if (isOriginal) bitrixCode()
  }

  render() {
    const {
      props: { isOriginal = false },
    } = this
    return isOriginal ? <div ref={(el) => (this.instance = el)} /> : null
  }
}
