import React, { useEffect, useCallback } from 'react'
import { ThemeProvider } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import MainStyles from './mainStyles'
import useRouter from './hooks/useRouter'
import Routing from './Routing'
import themes from './lib/themes'
import ScrollToTopBrn from './components/support/ScrollToTopBtn'
import MainMenu from './containers/menus/MainMenu'
import SmallLoader from './components/layouts/SmallLoader'
import ApiError from './components/layouts/ApiError'
import Modal from './components/layouts/Modal'
import ForChecklist from './containers/popups/ForChecklist'
import ForCalendar from './containers/popups/ForCalendar'
import ForTime from './containers/popups/ForTime'
import ForFilter from './containers/popups/forFilter/Index'
import ForTransportForm from './containers/popups/ForTransportForm'
import { modalChildren } from './lib/constants'
import BubbleMessage from './containers/popups/BubbleMessage'
import Bitrix from './bitrix/Bitrix'
import Sentry from './Sentry'
import { isIos } from './lib/helpers/forValidate'
import usePWAInstallBtn from './hooks/usePWAInstallBtn'
import { useUserDevice } from "./hooks/useUserDevice"
import { useForceAuthorization } from './hooks/useForceAuthorization'

const App = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const isMenu = useSelector((s) => s.mainMenu.isShow)
  const isLoader = useSelector((s) => s.general.appLoader)
  const isError = useSelector((s) => s.apiError.isShow)
  const isModal = useSelector((s) => s.general.isShowModal)
  const isModalChildren = useSelector((s) => s.general.modalChildren)
  const currentTheme = useSelector((s) => s.general.theme)
  const startPath = useSelector((s) => s.general.startPath)
  const redirectPath = useSelector((s) => s.redirectOutside.path)
  const [prompt, onInstallPWA] = usePWAInstallBtn()

  useUserDevice()
  useForceAuthorization()

  useEffect(() => {
    dispatch({ type: 'SAGA_GET_LIST_LANG' })
    dispatch({ type: 'SAGA_GET_DICTIONARY', comparison: true })
    dispatch({ type: 'DETECT_IOS', payload: isIos(window.navigator.userAgent) })
  }, []) //eslint-disable-line

  useEffect(() => {
    if (prompt) {
      dispatch({
        type: 'NECESSARY_INSTALL_PWA',
        payload: true,
        func: onInstallPWA,
      })
    }
  }, [prompt]) //eslint-disable-line

  useEffect(() => {
    const firstUrl = localStorage.getItem('sovLink')
    if (startPath === null && router.pathname !== '/authorization') {
      dispatch({ type: 'SET_START_PATH', payload: router.pathname })
      localStorage.setItem('sovLink', router.pathname)
    } else {
      if (firstUrl) {
        dispatch({ type: 'SET_START_PATH', payload: firstUrl })
      }
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    const isHaveTheme = localStorage.getItem('sovTheme')
    if (isHaveTheme) {
      dispatch({ type: 'SET_THEME', payload: isHaveTheme })
    }
    const isHaveToken = localStorage.getItem('sovToken')
    if (isHaveToken) {
      dispatch({
        type: 'SET_TOKEN',
        payload: JSON.parse(isHaveToken),
        history: router,
      })
    } else {
      dispatch({ type: 'FINISH_APP_LOADER' })
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    if (redirectPath) {
      router.push(redirectPath)
      dispatch({ type: 'REDIRECT_OUTSIDE', payload: null })
    }
  }, [redirectPath]) //eslint-disable-line


  const MARKUP = useCallback(() => {
    return (
      <>
        {isError && <ApiError />}
        {isMenu && <MainMenu />}
        {isLoader ? (
          <SmallLoader style={{ height: '94vh' }} size="5.6rem" />
        ) : (
          <Routing />
        )}
        <ScrollToTopBrn />
        <BubbleMessage />
        {/*<Bitrix />*/}
        {/* REQUIRED! add new children to constans>modalChildren */}
        {isModal && (
          <Modal>
            <>
              {isModalChildren === modalChildren.check && <ForChecklist />}
              {isModalChildren === modalChildren.date && <ForCalendar />}
              {isModalChildren === modalChildren.time && <ForTime />}
              {isModalChildren === modalChildren.filter && <ForFilter />}
              {isModalChildren === modalChildren.transportForm && <ForTransportForm />}
            </>
          </Modal>
        )}
      </>
    )
  }, [isError, isLoader, isMenu, isModal, isModalChildren])

  return (
    <ThemeProvider theme={themes[currentTheme]}>
      <MainStyles />
      {process.env.NODE_ENV === 'production' ? (
        <Sentry>{MARKUP()}</Sentry>
      ) : (
        MARKUP()
      )}
    </ThemeProvider>
  )
}

export default App
