import React from 'react'
import styled from 'styled-components'
import Icon from '../../components/Icon'

const Link = styled.a`
  text-decoration: none;
  padding-bottom: 0.2rem;
  font-size: ${(p) => p.theme.h4};
  font-weight: 600;
  transition: 0.2s ease;
  display: flex;
  ${(p) =>
    p.disabled &&
    `
  pointer-events: none;
  `};
  p {
    color: pink;
    border-bottom: 0.1rem solid ${(p) => p.theme.links};
  }
  &&& svg {
    margin-right: 0.7rem;
    fill: ${(p) => p.theme.links};
  }

  :link {
    p {
      color: ${(p) => p.theme.links};
      border-bottom: 0.1rem solid ${(p) => p.theme.links};
    }
  }
  :active {
    p {
      color: ${(p) => p.theme.regular_txt};
      border-bottom: 0.1rem solid ${(p) => p.theme.regular_txt};
    }
    &&& svg {
      fill: ${(p) => p.theme.regular_txt};
    }
  }
  :visited {
    p {
      color: ${(p) => p.theme.mint_Visited_Links};
      border-bottom: 0.1rem solid ${(p) => p.theme.mint_Visited_Links};
    }
    &&& svg {
      fill: ${(p) => p.theme.mint_Visited_Links};
    }
  }
`
const SpanWrp = styled.span`
  padding-bottom: 0.2rem;
  font-size: ${(p) => p.theme.h4};
  font-weight: 600;
  transition: 0.2s ease;
  display: flex;
  cursor: pointer;
  ${(p) =>
    p.disabled &&
    `
  pointer-events: none;
  `};
  p {
    color: ${(p) => p.theme.links};
    border-bottom: 0.1rem solid ${(p) => p.theme.links};
  }
  &&& svg {
    margin-right: 0.7rem;
    fill: ${(p) => p.theme.links};
  }
`

export default ({
  label = 'Link in new window',
  id = 'map',
  rout = null,
  disabled = false,
  style = {},
  onClick = () => {},
}) => {
  const icons = {
    map: <Icon name="map" style={{ marginTop: '0.4rem' }} />,
  }
  return rout ? (
    <Link href={rout} target="_blank" disabled={disabled} style={style}>
      {icons[id]}
      <p>{label}</p>
    </Link>
  ) : (
    <SpanWrp onClick={onClick} style={style}>
      {icons[id]}
      <p>{label}</p>
    </SpanWrp>
  )
}
