import React, { useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BaseLayout from '../components/layouts/BaseLayout'
import Tab1 from '../components/InterfaceForCarrier/forTenders/Tabs/TenderMenuTab1'
import Tab2 from '../components/InterfaceForCarrier/forTenders/Tabs/TenderMenuTab2'
import SmallLoader from '../components/layouts/SmallLoader'
import SeoMetaTegs from '../components/SeoMetaTegs'

export default () => {
  const dispatch = useDispatch()
  const [typeUpdate] = useState('GET_ALL_TENDERS')
  const [location] = useState('all')
  const userType = useSelector((s) => s.user.profile.usertype)
  const tendersList = useSelector((s) => s.forAllTenders.tendersList)
  const navId = useSelector((s) => s.user.currentNavRoute)
  const tabId = useSelector((s) => s.tender.isOpen[location])
  const navList = useSelector((s) => s.user.navlist)
  const [seoTabTitle, setSeoTabTitle] = useState('')
  const [seoPageTitle, setSeoPageTitle] = useState('')

  const clearStore = () => {
    dispatch({
      type: 'SET_TENDER_GROUP',
      payload: [],
    })
    dispatch({ type: 'SET_PAGE_COMPLETE_TENDERS', payload: 0 })
  }

  const Tab = (item) => ({
    1: (
      <Tab1
        key={item.id}
        obj={item}
        typeUpdate={typeUpdate}
        location={location}
      />
    ),
    2: (
      <Tab2
        key={item.id}
        obj={item}
        typeUpdate={typeUpdate}
        location={location}
      />
    ),
  })

  useEffect(() => {
    if(tendersList.length <= 0){
      dispatch({ type: 'SAGA_GET_DATA_FOR_ALL_TENDERS_PAGE' })
    }else {
      dispatch({ type: typeUpdate })
    }
  }, [])

  useEffect(() => {
    const result = navList.find((obj) => String(obj.id) === String(navId))
    if (result && result.title) {
      setSeoPageTitle(result.title)
    }
    setSeoTabTitle(
      tabId && tendersList.length
        ? tendersList.find((obj) => String(obj.id) === String(tabId)).title
        : ''
    )
  }, [tabId, navId, navList, tendersList])

  return useMemo(() => {
    return (
      <BaseLayout>
        <SeoMetaTegs
          title={`${seoPageTitle} ${seoTabTitle ? `> ${seoTabTitle}` : ''}`}
        />
        {tendersList.length > 0 && tendersList[0].content ? (
          tendersList.map((item) => {
            if (
              !item.content.length &&
              (Number(item.id) === 33 || Number(item.id) === 32)
            ) {
              return //eslint-disable-line
            } else {
              return Tab(item)[userType]
            }
          })
        ) : (
          <SmallLoader style={{ height: '50vh' }} size="5.6rem" />
        )}
      </BaseLayout>
    )
  }, [tendersList, typeUpdate, location, seoPageTitle, seoTabTitle, userType]) //eslint-disable-line
}
