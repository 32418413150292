import { takeLatest, call, put, select, delay, race } from 'redux-saga/effects'
import { getNotificationStatus } from '../../lib/requests/chatRequest'
import { getCurrentLang, getToken, auth, getDicionary } from '../selectors'
import { delays } from '../../lib/constants'

export function* worker() {
  while (yield select(auth)) {
    try {
      const currentLang = yield select(getCurrentLang)
      const token = yield select(getToken)
      const dic = yield select(getDicionary)

      const { result } = yield race({
        result: call(getNotificationStatus, token, currentLang),
        timeout: delay(delays.forCancelRequest),
      })

      if (result && result.status === 200) {
        yield put({
          type: 'SET_CHAT_MSG',
          payload: result.data.data.unreadMessages,
        })
        yield put({
          type: 'SET_INFO_MSG',
          payload: result.data.data.attentionRoutes,
        })
      } else {
        yield put({
          type: 'SHOW_ERROR_API',
          code: 6,
          message: dic.msg_server_err || 'internal server error',
        })
      }
    } catch (e) {
      if (yield select(auth)) {
        yield put({
          type: 'SHOW_ERROR_API',
          code: String(e.response.status).slice(0, 1),
          message: e.response ? e.response.data.message : e.message,
        })
      }

      if (e.response && e.response.data.relogin) {
        yield put({ type: 'SAGA_LOGOUT' })
      }
    }
    yield delay(delays.forNotificationQuantity)
  }
}

export default function* getNotificationQuantity() {
  yield takeLatest('GET_NOTIFICATION_STATUS', worker)
}
