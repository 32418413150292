import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  max-width: 80rem;
  height: 30rem;
  margin-top: ${(p) => p.marginTop};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const Title = styled.p`
  font-size: ${(p) => p.theme.h2};
`

export default ({ marginTop = 0 }) => {
  return (
    <Wrapper marginTop={marginTop}>
      <Title>Регистрация пользователя</Title>
    </Wrapper>
  )
}
