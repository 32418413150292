import { takeLatest, put } from 'redux-saga/effects'
export function* worker() {
  yield put({
    type: 'SET_AUTH',
    payload: false,
  })
  yield localStorage.removeItem('sovToken')
  yield localStorage.removeItem('sovLink')
  yield localStorage.removeItem('sovCurrentNavTab')
}

export default function* postSubscribeToExecution() {
  yield takeLatest('SAGA_LOGOUT', worker)
}
