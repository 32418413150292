import { takeEvery, put } from 'redux-saga/effects'

export function* worker({ requestType, parametr }) {
  const defaultParametrsForCreateRoute = {
    byCheckpoint: true,
  }
  switch (requestType) {
    case 'country':
      yield put({
        type: 'SAGA_GET_COUNTRIES',
        paramentrs: { ...defaultParametrsForCreateRoute },
      })
      break
    case 'town':
      yield put({
        type: 'SAGA_GET_TOWNS',
        paramentrs: { ...defaultParametrsForCreateRoute, byCountry: parametr },
      })
      break
    case 'townCustoms':
      yield put({
        type: 'SAGA_GET_TOWNS',
        paramentrs: {
          ...defaultParametrsForCreateRoute,
          byCountry: parametr,
          byCustoms: true,
        },
      })
      break

    case 'border':
      yield put({
        type: 'SAGA_GET_TOWNS',
        paramentrs: { byBorder: true }, //byCountry: parametr
      })
      break

    case 'company':
      yield put({
        type: 'SAGA_GET_COMPANIES',
        paramentrs: { ...defaultParametrsForCreateRoute, byTown: parametr },
      })
      break

    case 'customs':
      yield put({
        type: 'SAGA_GET_CHECKPOINTS',
        paramentrs: { byCustoms: true, byCheckpoint: true, byTown: parametr },
      })
      break

    case 'address':
      yield put({
        type: 'SAGA_GET_CHECKPOINTS',
        paramentrs: { ...parametr },
      })
      break

    case 'checkpointcontactdata':
      yield put({
        type: 'SAGA_GET_CHECKPOINT_CONTACT',
        paramentrs: { checkpoint: parametr },
      })
      break

    case 'cargo':
      yield put({
        type: 'SAGA_GET_CARGO',
        paramentrs: { q: parametr },
      })
      break

    case 'category':
      yield put({
        type: 'SAGA_GET_MEASURE_UNIT',
      })
      break

    default:
      break
  }
}

export default function* getCheckpointData() {
  yield takeEvery('SAGA_GET_CHECKPOINT_DATA', worker)
}
