import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";

const exception = [
    '/requestPasswordReset',
    '/resetPassword',
    '/new-password/',
    '/new-password',

]

const getToken = (hash) => {
    //get params
    const params = hash.split("?").pop();
    if(params.includes("token")){
    //get and return token value
        return params?.substring(params?.indexOf("=") + 1) || ""
    }
    // by default return falsy
    return ""
}

const removeToken = (hash) => {
    return hash.substring(0, hash.indexOf('?'));
}


export const useForceAuthorization = () => {
    const dispatch = useDispatch()
    const { pathname} = useLocation();
    const route = useRouteMatch();

  const hash = window?.location?.hash || ""

    useEffect(() => {

        console.log("pathname", pathname)
        if(!exception.includes(pathname)){

           const token =  getToken(hash)
            if(token){
                const forseAuth = async () => {
                    await localStorage.setItem('sovToken', JSON.stringify(token))
                    await dispatch({ type: 'SET_TOKEN', payload: token })
                     window.location.hash = removeToken(hash)
                }
                forseAuth()
            }

        }
    }, []);

    return null
};