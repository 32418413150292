import React from 'react'
import { useParams } from 'react-router-dom'
import BaseLayout from '../components/layouts/BaseLayout'

export default () => {
  const { id } = useParams()
  const styles = {
    width: '100%',
    height: '30rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  return (
    <BaseLayout>
      <div style={styles}>
        <p>{`report car ${id}`}</p>
      </div>
    </BaseLayout>
  )
}
