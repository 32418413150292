import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Time from '../../components/popUps/Time'

export default () => {
  const dispatch = useDispatch()
  const userTime = useSelector((s) => s.testSelect.userTime)
  const onChangeUserTime = (obj) => {
    dispatch({ type: 'SET_USER_TIME', payload: obj })
  }
  const onSaveChange = (newTime) => {
    onChangeUserTime(newTime)
    dispatch({ type: 'CLOSE_MODAL' })
  }
  const onCancelChange = () => {
    dispatch({ type: 'CLOSE_MODAL' })
  }
  return (
    <Time
      fnUpdateCheck={onChangeUserTime}
      fnSave={onSaveChange}
      fnCancel={onCancelChange}
      value={userTime ? userTime : null}
    />
  )
}
