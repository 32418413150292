// this page for testing new components and other fichs
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../containers/headers/BaseHeader'
import Checkbox from '../components/inputs/BaseCheckboxInput'
import BaseInput from '../components/inputs/BaseInput'
import RadioInput from '../components/inputs/BaseRadioInput'
import BaseCounter from '../containers/other/BaseCounter'
import BaseLink from '../components/links/BaseLink'
import DottedLink from '../components/links/DottedLink'
import DottedLightLink from '../components/links/DottedLightLink'
import BaseLinkNewWindow from '../components/links/BaseLinkNewWindow'
import BaseButton from '../components/buttons/BaseBtn'
import LoadMoreBtn from '../components/buttons/LoadMoreBtn'
import MainMenuHotKey from '../components/buttons/MainMenuHotKey'
import SelectAccBtn from '../components/buttons/SelectAccBtn'
import SelectDateBtn from '../components/buttons/SelectDateBtn'
import SelectInput from '../containers/selects/SelectInput'
import ExampleFormWithSmartSelect from '../containers/selects/ExampleFormWithSmartSelect'
import SelectTimeBtn from '../components/buttons/SelectTimeBtn'
import SelectTruckType from '../containers/selects/SelectTruckType'
import NavMenu from '../containers/menus/NavMenu'
import TabMenuWithIcons from '../containers/menus/TabMenuWithIcons'
import SmallLoader from '../components/layouts/SmallLoader'
import Icon from '../components/Icon'
import { iconsName, colors } from '../lib/constants'
import DevAccept from '../containers/forms/DevAccept'
import BtnLikeLink from '../components/buttons/BtnLikeLink'
import AddNewDriver from '../containers/forms/AddNewTS/AddNewDriver'
import AddNewCar from '../containers/forms/AddNewTS/AddNewCar'
import AddNewTrailer from '../containers/forms/AddNewTS/AddNewTrailer'

const Wrapper = styled.div`
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  margin-top: 6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${(p) => p.theme.elements_low};
`

const Screen = styled.div`
  margin-top: 2rem;
  width: 99%;
  max-width: 36rem;
  height: 70rem;
  background-color: ${(p) => p.theme.opposite};
  border-radius: 3.2rem;
`
const Top = styled.p`
  width: 100%;
  height: 3rem;
  padding: 0.5rem 0;
  border: 0.1rem dashed ${(p) => p.theme.stroke};
  border-radius: 5rem 5rem 0 0;
  background-color: ${(p) => p.theme.elements_low};
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: ${(p) => p.theme.regular_txt};
`
const Bottom = styled(Top)`
  border-radius: 0 0 5rem 5rem;
`
const Content = styled.div`
  min-width: 100%;
  height: 64rem;
  padding: 1rem 0.5rem 0;
  border-left: 0.1rem dashed ${(p) => p.theme.stroke};
  border-right: 0.1rem dashed ${(p) => p.theme.stroke};
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => (p.top ? 'flex-start' : 'space-evenly')};
  align-items: center;
  overflow: auto;
`
const IconRow = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item {
    padding: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
  }
  .left {
    margin-right: auto;
  }
`
export default () => {
  const dispatch = useDispatch()
  const [isAddTS, setIsAddTS] = useState(false)
  const [TSChildrenName, setAddTSChildrenName] = useState('')
  const [check, setCheck] = useState(false)
  const [radio, setRadio] = useState('phone')
  const filtredList = useSelector((s) => s.testSelect.filtredList)
  const searchList = useSelector((s) => s.myPark.serchList)
  const [userDate, setUserDate] = useState(new Date())
  const [userTime, setUserTime] = useState('15:15')
  const [approve, setApprove] = useState(false)
  const [counterQuantity, setCounterQuantity] = useState(0)
  const [carType, setCarType] = useState('tractor')
  const allFontWeight = [100, 200, 300, 400, 500, 600, 700, 800, 900]

  const onSetTypeCar = (val) => {
    setCarType(val)
  }

  const onAddNewTS = (nameForm) => {
    setAddTSChildrenName(nameForm)
    setIsAddTS(true)
  }
  const onCloseFormTS = () => {
    setAddTSChildrenName('')
    setIsAddTS(false)
  }
  const forAddNewTS = {
    driver: <AddNewDriver fnClose={onCloseFormTS} />,
    car: <AddNewCar fnClose={onCloseFormTS} />,
    trailer: <AddNewTrailer fnClose={onCloseFormTS} />,
    4: (
      <p
        style={{ padding: '3rem', backgroundColor: 'white' }}
        onClick={onCloseFormTS}
      >
        пока не реализовано
      </p>
    ),
  }

  const onUpdateCounterQuantity = (action) => {
    const selector = {
      plus: counterQuantity + 1,
      minus: counterQuantity - 1,
    }
    setCounterQuantity(selector[action])
  }

  const onChangeCheckbox = () => {
    setCheck(!check)
  }
  const onChangeRadio = (value) => {
    setRadio(value)
  }

  const forTestAcc = () => {
    dispatch({ type: 'OPEN_MODAL', payload: 'checklist' })
  }
  const forTestTime = (value) => {
    setUserTime(value)
  }
  const forTestCalendar = (value) => {
    setUserDate(value)
  }
  const [valueSearchSelect, setValueSearchSelect] = useState('')
  const onChangeSearchSelect = (e) => {
    dispatch({ type: 'FILTER_SEARCH_LIST', payload: e.target.value })
    setValueSearchSelect(e.target.value)
  }
  const listForTabMenuWithIcon = useSelector((s) => s.user.tablist)
  const currentRoutForTabMenuWithIcon = useSelector((s) => s.user.currentTab)
  const onChangeTabForTabMenuWithIcon = (id) => {
    dispatch({ type: 'TABMENU_SET_CURRENT_TAP', payload: String(id) })
  }

  const onShowBaseError = () => {
    setTimeout(
      () => {
        dispatch({
          type: 'SHOW_ERROR_API',
          code: 4,
          message: 'Не можем вас распознать, пожалуйста, авторизируйтесь',
        })
      },
      2000,
      true
    )
  }
  const onShowConnectError = () => {
    setTimeout(
      () => {
        dispatch({
          type: 'SHOW_ERROR_API',
          code: 6,
          message: 'Іnternal server error',
        })
      },
      2000,
      true
    )
  }
  const onShowServerError = () => {
    setTimeout(
      () => {
        dispatch({
          type: 'SHOW_ERROR_API',
          code: 5,
          message: 'Проблемы с подключением, попробуйте еще раз',
        })
      },
      2000,
      true
    )
  }
  const [for200Msg, setFor200Msg] = useState(false)
  const onShow200Message = () => {
    setTimeout(
      () => {
        dispatch({
          type: 'CLOSE_ERROR_API',
        })
        setFor200Msg(true)
      },
      2000,
      true
    )
  }
  const onShow200 = () => {
    setTimeout(
      () => {
        dispatch({
          type: 'CLOSE_ERROR_API',
        })
      },
      2000,
      true
    )
  }
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setApprove(true)
    }
  }, [])
  return !approve ? (
    <DevAccept onApprove={setApprove} />
  ) : (
    <>
      {isAddTS && forAddNewTS[TSChildrenName]}
      <Header />
      <Wrapper>
        {/* template */
        /* <Screen>
        <Top>Title</Top>
        <Content>
        new content
        </Content>
        <Bottom />
      </Screen> */}
        <Screen>
          <Top>icons</Top>
          <Content top>
            <IconRow>
              {Object.keys(colors).map((colorName, index) => (
                <p key={colorName} className="item">
                  {colorName}
                </p>
              ))}
            </IconRow>
            <IconRow>
              {Object.keys(colors).map((colorName, i) => (
                <div key={`${colorName}${i}`} className="item">
                  <Icon name="mack" type={colorName} />
                </div>
              ))}
            </IconRow>
            <div style={{ width: '30%' }}>
              {iconsName.map((name) => (
                <IconRow key={name}>
                  <p className="item left">{name}</p>
                  <div className="item bigText">
                    <Icon name={name} />
                  </div>
                </IconRow>
              ))}
            </div>
          </Content>
          <Bottom />
        </Screen>
        <Screen>
          <Top>Menus</Top>
          <Content top>
            <NavMenu />
            <TabMenuWithIcons
              list={listForTabMenuWithIcon}
              onChangeTab={onChangeTabForTabMenuWithIcon}
              currentRout={currentRoutForTabMenuWithIcon}
            />
            <LoadMoreBtn />
            <SmallLoader style={{ height: '20rem' }} />
            <SmallLoader style={{ height: '20rem' }} size="5.6rem" />
          </Content>
          <Bottom />
        </Screen>
        <Screen>
          <Top>Selects</Top>
          <Content>
            <BaseInput value="base input" />
            <SelectTruckType fnUpdate={onSetTypeCar} currentType={carType} />
            <SelectInput
              list={searchList}
              value={valueSearchSelect}
              fnChange={onChangeSearchSelect}
              fnSetValue={setValueSearchSelect}
            />
            <SelectTimeBtn value={userTime} fnClick={forTestTime} />
            <SelectDateBtn value={userDate} fnClick={forTestCalendar} />
            <SelectAccBtn content={filtredList} fnClick={forTestAcc} />
          </Content>
          <Bottom />
        </Screen>
        <Screen>
          <Top>Inputs</Top>
          <Content>
            <BaseCounter
              max={3}
              label="Кол-во до 3"
              value={counterQuantity}
              onUpdate={onUpdateCounterQuantity}
            />
            <div style={{ margin: '0 auto' }}>
              <Checkbox
                checked={check}
                label="my Check"
                fnChange={onChangeCheckbox}
              />
            </div>
            <RadioInput
              label="email"
              id="email"
              currentValue={radio === 'email'}
              fnChange={onChangeRadio}
            />
            <RadioInput
              label="phone"
              id="phone"
              currentValue={radio === 'phone'}
              fnChange={onChangeRadio}
            />
          </Content>
          <Bottom />
        </Screen>
        <Screen>
          <Top>links</Top>
          <Content>
            <DottedLink bold={1} />
            <BaseLink />
            <DottedLink />
            <DottedLightLink />
            <BaseLinkNewWindow />
          </Content>
          <Bottom />
        </Screen>
        <Screen>
          <Top>Buttons</Top>
          <Content>
            <BaseButton
              fullSize
              toUpperCase
              inContext="confirm"
              label="toUpperCase"
            />
            <BaseButton fullSize label="disabled" disabled />
            <MainMenuHotKey />
            <BaseButton iconPlus iconArrow pad="mid" label="with icons" />
            <BaseButton pad="mid" label="mid padding" />
            <BaseButton pad="small" label="small padding" />
            <BaseButton label="default" />
            <BaseButton inContext="confirm" label="confirm" />
            <BaseButton inContext="setup" label="setup" />
            <BaseButton inContext="popup" label="popup" />
          </Content>
          <Bottom />
        </Screen>
        <Screen>
          <Top>Font-weight example</Top>
          <Content>
            {allFontWeight.map((item, i) => {
              if (item === 300) {
                return (
                  <>
                    <p
                      key={`${item}${i}`}
                      style={{ fontWeight: item }}
                    >{`Test text with weigth ${item}`}</p>
                    <p style={{ fontWeit: item, fontStyle: 'italic' }}>
                      {`Test italic text with weigth ${item}`}
                    </p>
                  </>
                )
              } else {
                return (
                  <p
                    key={`${item}${i}`}
                    style={{ fontWeight: item }}
                  >{`Test text with weigth ${item}`}</p>
                )
              }
            })}
          </Content>
          <Bottom />
        </Screen>
        <Screen>
          <Top>Api Errors</Top>
          <Content>
            <BaseButton
              label="base error"
              fnClick={onShowBaseError}
              localId="1"
              forLoadFlow
            />

            <BaseButton
              label="connect error"
              fnClick={onShowConnectError}
              localId="2"
              forLoadFlow
            />
            <BaseButton
              label="server error"
              fnClick={onShowServerError}
              localId="3"
              forLoadFlow
            />
            {for200Msg ? (
              <p>next content</p>
            ) : (
              <BaseButton
                label="200 next content"
                fnClick={onShow200Message}
                localId="4"
                forLoadFlow
              />
            )}
            <BaseButton
              label="200"
              fnClick={onShow200}
              inContext="confirm"
              localId="5"
              forLoadFlow
            />
          </Content>
          <Bottom />
        </Screen>
        <Screen>
          <Top>Пример формы с умным селектом</Top>
          <Content>
            <ExampleFormWithSmartSelect />
          </Content>
          <Bottom />
        </Screen>
        <Screen>
          <Top>Пример форм добавить в парк: </Top>
          <Content>
            <BtnLikeLink
              label="добавить водителя"
              firstLetter
              onClick={() => onAddNewTS('driver')}
            />
            <BtnLikeLink
              label="добавить авто"
              firstLetter
              onClick={() => onAddNewTS('car')}
            />
            <BtnLikeLink
              label="добавить прицеп"
              firstLetter
              onClick={() => onAddNewTS('trailer')}
            />
            <BtnLikeLink
              label="добавить транспортное средство"
              firstLetter
              onClick={() => onAddNewTS(4)}
            />
          </Content>
          <Bottom />
        </Screen>
      </Wrapper>
    </>
  )
}
