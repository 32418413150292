import { takeEvery } from 'redux-saga/effects' //put, select, race, call, delay
// import { getRouteMessages } from '../../lib/requests/routesRequests'
// import { delays } from '../../lib/constants'
// import { getCurrentLang, getToken, getDicionary } from '../selectors'

export function* worker({ periodic, withoutLoader }) {
  // try {
  // const currentLang = yield select(getCurrentLang)
  // const token = yield select(getToken)
  // const dic = yield select(getDicionary)
  // if (!withoutLoader) {
  //   yield put({ type: 'SET_ROUTECHAT_LOADER', payload: true })
  // }
  // const { result } = yield race({
  //   result: call(getRouteMessages, token, currentLang, {
  //     route: periodic || '',
  //   }),
  //   timeout: delay(delays.forCancelRequest),
  // })
  // if (result && (result.status === 200 || result.status === 'success')) {
  //   yield put({ type: 'SET_ROUTECHAT_LOADER', payload: true })
  //   yield put({
  //     type: 'SET_ROUTECHAT_MSG',
  //     payload: [...result.data.data],
  //   })
  //   yield put({ type: 'SET_ROUTECHAT_LOADER', payload: false })
  // } else {
  //   yield put({ type: 'SET_ROUTECHAT_LOADER', payload: false })
  //   yield put({
  //     type: 'SHOW_ERROR_API',
  //     code: 6,
  //     message: dic.msg_server_err || 'internal server error',
  //   })
  // }
  // } catch (e) {
  //   yield put({ type: 'SET_ROUTECHAT_LOADER', payload: false })
  //   yield put({ type: 'SAGA_CATCH_ACTION', error: e })
  // }
}

export default function* singleRoute() {
  yield takeEvery('SAGA_GET_ROUTECHAT_MSG', worker)
}
