import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import shortid from 'shortid'
import styled, { ThemeContext } from 'styled-components'
import { ReactComponent as Checked } from '../../assets/svg/other/checked.svg'
import BaseInput from './BaseInput'
import { patterns } from '../../lib/constants'

const Wrapper = styled.div`
  width: 100%;
`

const Checkbox = styled.div`
  margin-bottom: ${(p) => (p.quantity ? '1.6rem' : '0')};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  transition: 0.2 ease;
`
const FlexWrp = styled.div`
  width: 100%;
  margin-left: 2.8rem;
  display: flex;
  justify-content: space-space-between;
  align-items: center;
`

const Square = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
  border: ${(p) => (p.isChecked ? 'none' : `0.2rem solid ${p.theme.stroke}`)};
  box-sizing: border-box;
  border-radius: 0.3rem;
`
const Text = styled.p`
  width: calc(100% - 2.8rem);
  font-size: ${(p) => p.theme.h4};
`

export default ({
  label = 'default checkbox',
  id = '',
  checked = false,
  data = {},
  fnChange = () => {},
  fnChangeQuantity = () => {},
  style = {},
}) => {
  const currentTheme = useContext(ThemeContext)
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const [inputErr, setInputErr] = useState(false)

  const setErr = () => {
    setInputErr(true)
    setTimeout(() => setInputErr(false), 1000)
  }
  const onChangeInput = (e) => {
    const VALUE = e.target.value
    if (VALUE === '' || VALUE === '-' || patterns.int.test(VALUE)) {
      fnChangeQuantity(id, VALUE)
      return
    }
    setErr()
  }
  return (
    <Wrapper style={style}>
      <Checkbox
        onClick={() => fnChange(id)}
        quantity={data.hasOwnProperty('quantity')}
      >
        {checked ? (
          <Checked
            style={{ marginRight: '1rem', fill: currentTheme.trigger }}
          />
        ) : (
          <Square />
        )}
        <Text>{label}</Text>
      </Checkbox>
      {data.hasOwnProperty('quantity') && (
        <FlexWrp>
          <BaseInput
            label=""
            name={shortid.generate()}
            value={data.quantity}
            disabled={!data.checked}
            style={{ width: '40%' }}
            error={inputErr}
            fnChange={onChangeInput}
            message={dic.form_err_onlyNumbrers}
          />
          <Text style={{ marginLeft: '1rem', opacity: data.checked ? 1 : 0.5 }}>
            {data.quantitySimbol || ''}
          </Text>
        </FlexWrp>
      )}
    </Wrapper>
  )
}
