import { takeLatest, put, call, select, race, delay } from 'redux-saga/effects' //select, call,
import { sendMessage } from '../../../lib/requests/chatRequest'
import { delays } from '../../../lib/constants'
import { getCurrentLang, getToken, getDicionary } from '../../selectors'

export function* worker({  periodic, recipient, message, }) {
  try {
    const currentLang = yield select(getCurrentLang)
    const token = yield select(getToken)
    const dic = yield select(getDicionary)

    const { result } = yield race({
      result: call(sendMessage, token, currentLang, periodic, recipient, message),
      timeout: delay(delays.forCancelRequest),
    })
    yield put({ type: 'SET_POST_PROCESS', payload: 0 })

    if (result && (result.status === 200 || result.status === 202)) {
      yield put({ type: 'GET_ROUTE_CHATS', periodic })
      yield put({ type: 'SET_CHAT_FETCH', payload: false })
      yield put({ type: 'SET_POST_PROCESS', payload: 1 })
    } else {
      yield put({ type: 'SET_POST_PROCESS', payload: 2 })
      yield put({
        type: 'SHOW_ERROR_API',
        code: 6,
        message: dic.msg_server_err || 'internal server error',
      })
    }
  } catch (e) {
    yield put({ type: 'SET_POST_PROCESS', payload: 2 })
    yield put({
      type: 'SHOW_ERROR_API',
      code: String(e.response.status).slice(0, 1),
      message: e.response ? e.response.data.message : e.message,
    })
    if (e.response && e.response.data.relogin) {
      yield put({ type: 'SAGA_LOGOUT' })
    }
  }
}

export default function* postSendMyBet() {
  yield takeLatest('SAGA_SEND_CHAT_MESSAGE', worker)
}
