import React from 'react'
import BaseLayout from '../components/layouts/BaseLayout'

export default () => {
  const styles = {
    width: '100%',
    height: '30rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  return (
    <BaseLayout withoutNav>
      <div style={styles}>
        <h3>settings page</h3>
      </div>
    </BaseLayout>
  )
}
