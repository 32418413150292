import { api, routs } from '../api'
//add new TS
export const addNewTS = (token, currentLang, body, parametr) => {
  return api.post(
    routs.addVehicle,
    {
      ...body,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
      params: {
        only: parametr,
      },
    }
  )
}
