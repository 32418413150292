import { takeEvery, call, put, select, race, delay } from 'redux-saga/effects'
import {
  getMyDrivers,
  getMyCars,
  getMyTrailers,
} from '../../lib/requests/auxRequests'
import { delays } from '../../lib/constants'
import { getCurrentLang, getToken, getDicionary } from '../selectors'

export function* worker({ requestType }) {
  const currentLang = yield select(getCurrentLang)
  const token = yield select(getToken)
  const dic = yield select(getDicionary)

  yield put({ type: 'SET_PARK_LOADER', payload: true })
  switch (requestType) {
    case 'driver':
      try {
        const { result } = yield race({
          result: call(getMyDrivers, token, currentLang),
          timeout: delay(delays.forCancelRequest),
        })
        if (result && (result.status === 200 || result.status === 'success')) {
          yield put({
            type: 'SET_PARK_LIST',
            payload: result.data.data.map((obj) => ({
              ...obj,
              union: `${obj.lastname} ${obj.firstname} ${
                obj.maincellphone
              }`,
            })),
          })
          yield put({ type: 'FILTER_SEARCH_LIST' })
          yield put({ type: 'SET_PARK_LOADER', payload: false })
        } else {
          yield put({
            type: 'SHOW_ERROR_API',
            code: 6,
            message: dic.msg_server_err || 'internal server error',
          })
        }
      } catch (e) {
        yield put({
          type: 'SHOW_ERROR_API',
          code: String(e.response.status).slice(0, 1),
          message: e.response ? e.response.data.message : e.message,
        })
        if (e.response && e.response.data.relogin) {
          yield put({ type: 'SAGA_LOGOUT' })
        }
        if (e.response && e.response.data.forwardTo) {
          yield put({
            type: 'REDIRECT_OUTSIDE',
            payload: e.response.data.forwardTo,
          })
        }
      }
      break
    case 'driver2':
      try {
        const { result } = yield race({
          result: call(getMyDrivers, token, currentLang),
          timeout: delay(delays.forCancelRequest),
        })
        if (result && result.status === 200) {
          yield put({
            type: 'SET_PARK_LIST',
            payload: result.data.data.map((obj) => ({
              ...obj,
              union: `${obj.lastname} ${obj.firstname} ${
                obj.maincellphone
              }`,
            })),
          })
          yield put({ type: 'FILTER_SEARCH_LIST' })
          yield put({ type: 'SET_PARK_LOADER', payload: false })
        } else {
          yield put({
            type: 'SHOW_ERROR_API',
            code: 6,
            message: dic.msg_server_err || 'internal server error',
          })
        }
      } catch (e) {
        yield put({
          type: 'SHOW_ERROR_API',
          code: String(e.response.status).slice(0, 1),
          message: e.response ? e.response.data.message : e.message,
        })
        if (e.response && e.response.data.relogin) {
          yield put({ type: 'SAGA_LOGOUT' })
        }
        if (e.response && e.response.data.forwardTo) {
          yield put({
            type: 'REDIRECT_OUTSIDE',
            payload: e.response.data.forwardTo,
          })
        }
      }
      break
    case 'car':
      try {
        const { result } = yield race({
          result: call(getMyCars, token, currentLang),
          timeout: delay(delays.forCancelRequest),
        })
        if (result && result.status === 200) {
          yield put({
            type: 'SET_PARK_LIST',
            payload: result.data.data.map((obj) => ({
              ...obj,
              union: `${obj.make.title_ru} ${String(
                obj.number
              )}`,
            })),
          })
          yield put({ type: 'FILTER_SEARCH_LIST' })
          yield put({ type: 'SET_PARK_LOADER', payload: false })
        } else {
          yield put({
            type: 'SHOW_ERROR_API',
            code: 6,
            message: dic.msg_server_err || 'internal server error',
          })
        }
      } catch (e) {
        yield put({
          type: 'SHOW_ERROR_API',
          code: String(e.response.status).slice(0, 1),
          message: e.response ? e.response.data.message : e.message,
        })
        if (e.response && e.response.data.relogin) {
          yield put({ type: 'SAGA_LOGOUT' })
        }
        if (e.response && e.response.data.forwardTo) {
          yield put({
            type: 'REDIRECT_OUTSIDE',
            payload: e.response.data.forwardTo,
          })
        }
      }
      break
    case 'trailer':
      try {
        const { result } = yield race({
          result: call(getMyTrailers, token, currentLang),
          timeout: delay(delays.forCancelRequest),
        })
        if (result && result.status === 200) {
          yield put({
            type: 'SET_PARK_LIST',
            payload: result.data.data.map((obj) => ({
              ...obj,
              union: `${obj.model} ${String(
                obj.number
              )}`,
            })),
          })
          yield put({ type: 'FILTER_SEARCH_LIST' })
          yield put({ type: 'SET_PARK_LOADER', payload: false })
        } else {
          yield put({
            type: 'SHOW_ERROR_API',
            code: 6,
            message: dic.msg_server_err || 'internal server error',
          })
        }
      } catch (e) {
        yield put({
          type: 'SHOW_ERROR_API',
          code: String(e.response.status).slice(0, 1),
          message: e.response ? e.response.data.message : e.message,
        })
        if (e.response && e.response.data.relogin) {
          yield put({ type: 'SAGA_LOGOUT' })
        }
        if (e.response && e.response.data.forwardTo) {
          yield put({
            type: 'REDIRECT_OUTSIDE',
            payload: e.response.data.forwardTo,
          })
        }
      }
      break
    default:
      break
  }
}

export default function* myAutoPark() {
  yield takeEvery('SAGA_GET_MY_PARK', worker)
}
