import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon'

const Wrapper = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: none;
  transition: 0.3s ease;
  cursor: pointer;
  :active {
    transform: scale(0.97);
  }
  :disabled {
    opacity: 0.4;
    cursor: default;
  }
  .thinBtnLabel {
    ${(p) =>
      p.firstLetter &&
      `
    :first-letter {
    text-transform: uppercase;
  }

  `};
    font-size: ${(p) => p.theme.h4};
    color: ${(p) => p.theme.regular_txt};
    font-weight: 500;
  }
`
export default ({
  style = {},
  disabled = false,
  onClick = () => {},
  type = '',
  label = 'thin Btn',
  firstLetter = false,
  id = 'thinBtn',
}) => {
  const icons = {
    delete: (
      <Icon
        name="basket"
        style={{ marginTop: '0.2rem', marginRight: '0.5rem' }}
      />
    ),
    edit: (
      <Icon
        name="pencil"
        style={{ marginTop: '0.2rem', marginRight: '0.5rem' }}
      />
    ),
    return: (
      <Icon
        name="magic"
        style={{ marginTop: '0.2rem', marginRight: '0.5rem' }}
      />
    ),
    back: (
      <Icon
        name="arrow"
        style={{ marginTop: '0.2rem', marginRight: '0.5rem' }}
      />
    ),
  }
  return (
    <Wrapper
      disabled={disabled}
      onClick={onClick}
      style={style}
      firstLetter={firstLetter}
      id={id}
    >
      {type && icons[type]}
      <p className="thinBtnLabel">{label}</p>
    </Wrapper>
  )
}
