import React from 'react'
import styled from 'styled-components'
import useRouter from '../../hooks/useRouter'

const Wrapper = styled.a`
  text-decoration: none;
  padding-bottom: 0.2rem;
  font-size: ${(p) => p.theme.h5};
  font-weight: 400;
  transition: 0.2s ease;

  :link {
    color: ${(p) => p.theme.trigger_txt};
    border-bottom: 0.1rem dotted ${(p) => p.theme.trigger_txt};
  }
  :active {
    color: ${(p) => p.theme.regular_txt};
    border-bottom: 0.1rem solid ${(p) => p.theme.regular_txt};
  }
  :visited {
    color: ${(p) => p.theme.mint_Visited_Links};
    border-bottom: 0.1rem solid ${(p) => p.theme.mint_Visited_Links};
  }
`
const NavLink = styled.div`
  padding-bottom: 0.2rem;
  font-size: ${(p) => p.theme.h5};
  font-weight: 400;
  transition: 0.2s ease;
  color: ${(p) => p.theme.trigger_txt};
  border-bottom: 0.1rem dotted ${(p) => p.theme.trigger_txt};
  :active {
    color: ${(p) => p.theme.regular_txt};
    border-bottom: 0.1rem solid ${(p) => p.theme.regular_txt};
  }
  :visited {
    color: ${(p) => p.theme.mint_Visited_Links};
    border-bottom: 0.1rem solid ${(p) => p.theme.mint_Visited_Links};
  }
`

export default ({
  title = 'Dotted light link',
  rout = 'https://sovtes.ua',
  path = '',
  style = null,
}) => {
  const router = useRouter()
  const redirect = () => {
    router.push(path)
  }
  return Boolean(path) ? (
    <NavLink onClick={redirect} style={style}>
      {title}
    </NavLink>
  ) : (
    <Wrapper href={rout} style={style}>
      {title}
    </Wrapper>
  )
}
