import React from 'react'
import styled from 'styled-components'
import Icon from '../../components/Icon'

const Wrapper = styled.div`
  width: 33%;
  :last-child {
    width: 34%;
  }
  height: 100%;
  border-right: 0.1rem solid ${(p) => p.theme.lines};
  background: ${(p) => (p.current ? p.theme.truckSelectGadient : null)};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
`
export default ({
  typeTruck = 'truck',
  currentType = 'truck',
  fnClick = () => {},
}) => {
  const icons = {
    truck: <Icon name="truck" type="icons" />,
    coupling: <Icon name="coupling" type="icons" />,
    tractor: <Icon name="tractor" type="icons" />,
  }
  return (
    <Wrapper
      current={currentType === typeTruck}
      onClick={() => fnClick(typeTruck)}
    >
      {icons[typeTruck]}
    </Wrapper>
  )
}
