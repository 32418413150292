import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(p) => p.theme.modalGradient};
  display: flex;
  justify-content: center;
  align-items: center;
`

export default ({ children }) => {
  // prop closeModal = () => {}
  const appRoot = document.getElementById('root')
  const myNode = (
    <Wrapper>
      <div>{children}</div>
    </Wrapper>
  )
  return ReactDOM.createPortal(myNode, appRoot)
}
