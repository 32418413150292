import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import Icon from '../Icon'
import BtnLoader from '../layouts/BtnLoader'

const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :active {
    transform: scale(0.97);
  }
  :disabled {
    opacity: 0.4;
    cursor: default;
  }
`

export default ({
  type = '',
  disabled = false,
  onClick = () => {},
  forLoadFlow = false, // required for buttonFlow
  localId = null, // required for buttonFlow
  style,
}) => {
  const dispatch = useDispatch()
  const isLoad = useSelector((s) => s.buttonFlow.isLoad)
  const currentId = useSelector((s) => s.buttonFlow.btnId)

  const localClick = () => {
    dispatch({ type: 'START_REQUEST', payload: localId })
    onClick()
  }
  const icons = {
    delete: (
      <Icon name="cancelBtn" type="links" style={{ marginTop: '0.2rem' }} />
    ),
    share: <Icon name="share" type="links" style={{ marginTop: '0.2rem' }} />,
  }
  return (
    <Wrapper
      onClick={forLoadFlow ? localClick : onClick}
      disabled={isLoad ? isLoad : disabled}
      style={style}
      id={localId}
    >
      {isLoad && currentId === localId ? <BtnLoader /> : icons[type]}
    </Wrapper>
  )
}
