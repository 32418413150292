import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import useRouter from '../../../../hooks/useRouter'
import {
  Wrapper,
  Title,
  Message,
  DriversInfo,
  Scale2RouteParts,
  PeriodicWrp,
} from '../../../smallPartsForInterface/SmallPartsWidgets'
import YellowMarker from '../../../YellowMarker'
import { strToUpperCase } from '../../../../lib/helpers/forStyling'

export default ({ obj, dic, memory = '' }) => {
  const router = useRouter()
  const myref = useRef(null)
  const dispatch = useDispatch()
  const [active, setActive] = useState(0)
  const [activePoint, setActivePoint] = useState({ workaction: 100 })
  const toDetermineTheHotPoint = (id) => {
    setActive(id)
  }
  const {
    periodic,
    routeresponse_relation,
    routeresponse,
    operator_relation,
    routeparts,
  } = obj

  const titles = {
    1: strToUpperCase(dic.msg_11),
    2: strToUpperCase(dic.msg_15),
  }

  const aboutDrivers = routeresponse_relation.find(
    (item) => item.id === routeresponse
  )
  useEffect(() => {
    if (active) {
      setActivePoint(routeparts.find((item) => item.id === active))
    }
  }, [active, routeparts])

  const widgetClick = () => {
    dispatch({ type: 'REMEMBER_ROUTE_WIDGET', payload: periodic })
    router.push(`/route/${periodic}`)
  }

  const executeScroll = () =>
    myref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })

  useEffect(() => {
    if (memory === periodic) {
      executeScroll()
    }
  }, [memory, periodic])
  return (
    obj &&
    dic && (
      <span ref={myref}>
        <Wrapper isMrgnBtm onClick={widgetClick}>
          <PeriodicWrp right>{`№ ${periodic}`}</PeriodicWrp>
          <div
            className="flexWrp"
            style={{ marginTop: '1rem', marginBottom: '0.5rem' }}
          >
            {operator_relation && (
              <Title isMessage={false}>
                {operator_relation.company_relation.title_ru}
              </Title>
            )}
            {obj.needAttention && <YellowMarker />}
          </div>
          {routeparts && (
            <Scale2RouteParts
              arr={routeparts}
              toDetermineTheHotPoint={toDetermineTheHotPoint}
              dic={dic}
              status="awaitaction"
            />
          )}
          {aboutDrivers && <DriversInfo obj={aboutDrivers} />}
          <div style={{ marginTop: '1rem' }}>
            {titles[activePoint.workaction] && (
              <Message>{titles[activePoint.workaction]}</Message>
            )}
          </div>
        </Wrapper>
      </span>
    )
  )
}
