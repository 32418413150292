import { takeEvery, call, put } from 'redux-saga/effects'
import { getListLang } from '../../lib/requests/authRequest'

export function* worker() {
  try {
    const result = yield call(getListLang)
    if (result.status === 200) {
      yield put({ type: 'SET_LIST_LANG', payload: result.data.data })
    }
  } catch (e) {}
}

export default function* getDumpLang() {
  yield takeEvery('SAGA_GET_LIST_LANG', worker)
}
