import React from 'react'
import FormLayout from '../components/layouts/FormLayout'
import RenevalForm from '../containers/forms/RenevalForm'

export default () => {
  return (
    <FormLayout isLogo>
      <RenevalForm marginTop="7.1rem" />
    </FormLayout>
  )
}
