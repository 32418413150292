import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import EmailLink from '../links/EmailLink'
import TelLink from '../links/TelLink'
import BaseLinkNewWindow from '../links/BaseLinkNewWindow'
import { Info, SeparateLine, Title } from './SmallPartsCard'
import { strToUpperCase } from '../../lib/helpers/forStyling'
import { linkWithHttp } from '../../lib/helpers/forValidate'

const Wrapper = styled.div`
  width: 100%;
  padding: 2.6rem 0 3.4rem;
`
const FlexWrp = styled.div`
  width: 100%;
  margin-bottom: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
const RightItem = styled.div`
  width: ${(p) => p.width || '60%'};
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`

const ThisInfo = styled(Info)`
  margin-top: 0.4rem;
`
const ThisTitle = styled(Title)`
  text-align: right;
`

export default ({ obj = {} }) => {
  const dic = useSelector((s) => s.defaultLang.serverDictionary)

  return (
    <Wrapper data-testid="companyCard">
      <Title big mrgnBtm="3.5rem">
        {obj.titleRu}
      </Title>
      <FlexWrp>
        <ThisInfo>{`${strToUpperCase(dic.full_title)}:`}</ThisInfo>
        <RightItem>
          <ThisTitle>{obj.titleFull || dic.info_3}</ThisTitle>
        </RightItem>
      </FlexWrp>
      <FlexWrp>
        <ThisInfo>{`${strToUpperCase(dic.tel)}:`}</ThisInfo>
        <RightItem>
          {obj.telnum ? (
            <TelLink cell={obj.telnum} />
          ) : (
            <ThisTitle>{dic.info_3}</ThisTitle>
          )}
        </RightItem>
      </FlexWrp>
      <FlexWrp>
        <ThisInfo>{`${strToUpperCase(dic.email)}:`}</ThisInfo>
        <RightItem width="80%">
          {obj.email ? (
            <EmailLink email={obj.email} style={{ maxWidth: '25rem' }} />
          ) : (
            <ThisTitle>{dic.info_3}</ThisTitle>
          )}
        </RightItem>
      </FlexWrp>
      <FlexWrp style={{ marginBottom: '1.7rem' }}>
        <ThisInfo>{`${strToUpperCase(dic.site)}:`}</ThisInfo>
        <RightItem width="80%">
          {obj.website ? (
            <BaseLinkNewWindow
              style={{ maxWidth: '25rem', fontWeight: '400' }}
              rout={linkWithHttp(obj.website)}
              label={obj.website}
              id=""
            />
          ) : (
            <ThisTitle>{dic.info_3}</ThisTitle>
          )}
        </RightItem>
      </FlexWrp>
      <SeparateLine style={{ marginBottom: '1.7rem' }} />
      <FlexWrp>
        <ThisInfo>{`${strToUpperCase(dic.country)}:`}</ThisInfo>
        <RightItem width="75%">
          <ThisTitle>{obj.country || dic.info_3}</ThisTitle>
        </RightItem>
      </FlexWrp>
      <FlexWrp>
        <ThisInfo>{`${strToUpperCase(dic.address_legal)}:`}</ThisInfo>
        <RightItem>
          <ThisTitle>{obj.legalAddress || dic.info_3}</ThisTitle>
        </RightItem>
      </FlexWrp>
      <FlexWrp style={{ marginBottom: '1.7rem' }}>
        <ThisInfo>{`${strToUpperCase(dic.address_mailing)}:`}</ThisInfo>
        <RightItem>
          <ThisTitle>{obj.factAddress || dic.info_3}</ThisTitle>
        </RightItem>
      </FlexWrp>
      <SeparateLine style={{ marginBottom: '1.7rem' }} />
      <FlexWrp>
        <ThisInfo>{`${strToUpperCase(dic.bank_account)}:`}</ThisInfo>
        <ThisTitle>{obj.bankAccount || dic.info_3}</ThisTitle>
      </FlexWrp>
      <FlexWrp>
        <ThisInfo>{`${strToUpperCase(dic.bank_name)}:`}</ThisInfo>
        <RightItem width="70%">
          <ThisTitle>{obj.bankname || dic.info_3}</ThisTitle>
        </RightItem>
      </FlexWrp>
      <FlexWrp>
        <ThisInfo
          style={{ textTransform: 'uppercase' }}
        >{`${dic.bank_mfo}:`}</ThisInfo>
        <ThisTitle>{obj.ifoid || dic.info_3}</ThisTitle>
      </FlexWrp>
      <FlexWrp>
        <ThisInfo
          style={{ textTransform: 'uppercase' }}
        >{`${dic.bank_egrpou}:`}</ThisInfo>
        <ThisTitle>{obj.natcomid || dic.info_3}</ThisTitle>
      </FlexWrp>

      <FlexWrp>
        <ThisInfo
          style={{ textTransform: 'uppercase' }}
        >{`${dic.inn}:`}</ThisInfo>
        <ThisTitle>{obj.taxid || dic.info_3}</ThisTitle>
      </FlexWrp>
      <FlexWrp style={{ marginBottom: '1.7rem' }}>
        <ThisInfo>{`${strToUpperCase(dic.nds_license)}:`}</ThisInfo>
        <ThisTitle>{obj.vatid || dic.info_3}</ThisTitle>
      </FlexWrp>
      <SeparateLine style={{ marginBottom: '1.7rem' }} />
      <FlexWrp>
        <ThisInfo>{`${strToUpperCase(dic.leader_position)}:`}</ThisInfo>
        <RightItem width="50%">
          <ThisTitle>{obj.signer || dic.info_3}</ThisTitle>
        </RightItem>
      </FlexWrp>
      <FlexWrp>
        <ThisInfo>{`${strToUpperCase(dic.leader_basis)}:`}</ThisInfo>
        <RightItem width="50%">
          <ThisTitle>{obj.actsonbaseof || dic.info_3}</ThisTitle>
        </RightItem>
      </FlexWrp>
      <FlexWrp>
        <ThisInfo>{`${strToUpperCase(dic.leader_secondName)}:`}</ThisInfo>
        <RightItem width="50%">
          <ThisTitle>{obj.director || dic.info_3}</ThisTitle>
        </RightItem>
      </FlexWrp>
      <FlexWrp>
        <ThisInfo>{`${strToUpperCase(dic.leader_firstName)}:`}</ThisInfo>
        <RightItem width="50%">
          <ThisTitle>{obj.directorFirstName || dic.info_3}</ThisTitle>
        </RightItem>
      </FlexWrp>
      <FlexWrp style={{ marginBottom: '1.7rem' }}>
        <ThisInfo>{`${strToUpperCase(dic.leader_patronymic)}:`}</ThisInfo>
        <RightItem width="50%">
          <ThisTitle>{obj.directorPatronymic || dic.info_3}</ThisTitle>
        </RightItem>
      </FlexWrp>
      <SeparateLine style={{ marginBottom: '1.7rem' }} />
    </Wrapper>
  )
}
