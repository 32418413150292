import Type from '../types'

const initialState = {
  isLoader: false,
  chronology: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_CHRONOLOGY_DATA:
      return {
        ...state,
        chronology: action.payload,
      }

    case Type.SET_CHRONOLOGY_LOADER:
      return {
        ...state,
        isLoader: action.payload,
      }

    default:
      return state
  }
}
