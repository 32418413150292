import { takeEvery, call, put, select, race, delay } from 'redux-saga/effects'
import { getMyTenderWidgetGroups } from '../../lib/requests/routesRequests'
import { delays } from '../../lib/constants'
import { getCurrentLang, getToken, getDicionary } from '../selectors'

export function* worker() {
  try {
    const currentLang = yield select(getCurrentLang)
    const token = yield select(getToken)
    const dic = yield select(getDicionary)

    const { result } = yield race({
      result: call(getMyTenderWidgetGroups, token, currentLang),
      timeout: delay(delays.forCancelRequest),
    })

    if (result && result.status === 200) {
      yield put({
        type: 'SET_ALL_TENDER_GROUP',
        payload: [
          ...result.data.data.map((obj) => ({ ...obj, isSort: false })),
        ],
      })
      yield put({ type: 'GET_ALL_TENDERS' })
    } else {
      yield put({
        type: 'SHOW_ERROR_API',
        code: 6,
        message: dic.msg_server_err || 'internal server error',
      })
    }
  } catch (e) {
    yield put({
      type: 'SHOW_ERROR_API',
      code: String(e.response.status).slice(0, 1),
      message: e.response ? e.response.data.message : e.message,
    })
    if (e.response && e.response.data.relogin) {
      yield put({ type: 'SAGA_LOGOUT' })
    }
  }
}

export default function* tenderWidgetGroups() {
  yield takeEvery('SAGA_GET_DATA_FOR_ALL_TENDERS_PAGE', worker)
}
