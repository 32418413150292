import React from 'react'
import FormLayout from '../components/layouts/FormLayout'
import NewPasswordFormForm from '../containers/forms/NewPasswordForm'

export default () => {
    return (
        <FormLayout>
            <NewPasswordFormForm marginTop="7.8rem"/>
        </FormLayout>
    )
}