export default {
  DETECT_IOS: 'DETECT_IOS',
  NECESSARY_INSTALL_PWA: 'NECESSARY_INSTALL_PWA',
  SET_WORDBOOK: 'SET_WORDBOOK',
  SET_LIST_LANG: 'SET_LIST_LANG',
  SET_DICTIONARY: 'SET_DICTIONARY',
  TOGGLE_LANG_SELECT: 'TOGGLE_LANG_SELECT',
  FINISH_APP_LOADER: 'FINISH_APP_LOADER',
  START_APP_LOADER: 'START_APP_LOADER',
  SET_TOKEN: 'SET_TOKEN',
  SET_AUTH: 'SET_AUTH',
  SET_START_PATH: 'SET_START_PATH',
  SET_AUTHORIZATION: 'SET_AUTHORIZATION',
  POST_RENEWAL:'POST_RENEWAL',
  SET_RENEWAL_PROCESS:'SET_RENEWAL_PROCESS',
  LOGOUT: 'LOGOUT',
  START_REQUEST: 'START_REQUEST',
  SHOW_ERROR_API: 'SHOW_ERROR_API',
  CLOSE_ERROR_API: 'CLOSE_ERROR_API',
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  SET_AUX_MENU: 'SET_AUX_MENU',
  SET_USER_MENU: 'SET_USER_MENU',
  SET_HOTKEYS: 'SET_HOTKEYS',
  REDIRECT_OUTSIDE: 'REDIRECT_OUTSIDE',
  SET_CHEKLIST: 'SET_CHEKLIST',
  CLEAR_CHEKLIST: 'CLEAR_CHEKLIST',
  SET_CHECKLIST_LOADER: 'SET_CHECKLIST_LOADER',

  // default settings
  UPDATE_NEWROUTE_SETTINGS: 'UPDATE_NEWROUTE_SETTINGS',

  //filter
  CHANGE_CURRENT_ID_FOR_FILTER: 'CHANGE_CURRENT_ID_FOR_FILTER',
  CLEAR_ARGS_FOR_FILTER: 'CLEAR_ARGS_FOR_FILTER',
  ADD_ARG_FOR_FILTER: 'ADD_ARG_FOR_FILTER',
  REMOVE_ARG_FOR_FILTER: 'REMOVE_ARG_FOR_FILTER',
  REWRITE_ARG_FOR_FILTER: 'REWRITE_ARG_FOR_FILTER',

  //routes
  GET_MY_ROUTES: 'GET_MY_ROUTES',
  GET_ALL_ROUTES: 'GET_ALL_ROUTES',
  SET_ALL_ROUTE_GROUP: 'SET_ALL_ROUTE_GROUP',
  SET_MY_ROUTE_GROUP: 'SET_MY_ROUTE_GROUP',
  SET_ALL_ROUTES: 'SET_ALL_ROUTES',
  SET_MY_ROUTES: 'SET_MY_ROUTES',
  SET_WIDGET_ROUTES: 'SET_WIDGET_ROUTES',
  SET_WIDGET_ROUTES_FOR_COMPLETE: 'SET_WIDGET_ROUTES_FOR_COMPLETE',
  SET_TOTAL_COMPLETE_ROUTES: 'SET_TOTAL_COMPLETE_ROUTES',
  SET_PAGE_COMPLETE_ROUTES: 'SET_PAGE_COMPLETE_ROUTES',
  CLEAR_WIDGET_ROUTES: 'CLEAR_WIDGET_ROUTES',
  SET_OPEN_WIDGET_ROUTES: 'SET_OPEN_WIDGET_ROUTES',
  SET_SINGLE_ROUTE: 'SET_SINGLE_ROUTE',
  UPDATE_ROUTES_FLAG_LIST: 'UPDATE_ROUTES_FLAG_LIST',
  REMEMBER_ROUTE_WIDGET: 'REMEMBER_ROUTE_WIDGET',
  STOP_LOADER_FOR_SINGLE_ROUTE_PAGE: 'STOP_LOADER_FOR_SINGLE_ROUTE_PAGE',
  CLEAR_FLAG_FOR_SINGLE_ROUTE_PAGE: 'CLEAR_FLAG_FOR_SINGLE_ROUTE_PAGE',
  STOP_LOADER_FOR_LIST_ROUTES_PAGE: 'STOP_LOADER_FOR_LIST_ROUTES_PAGE',
  CLEAR_FLAG_FOR_LIST_ROUTES_PAGE: 'CLEAR_FLAG_FOR_LIST_ROUTES_PAGE',
  SET_ROUTECHAT_MSG: 'SET_ROUTECHAT_MSG',
  SET_ROUTECHAT_LOADER: 'SET_ROUTECHAT_LOADER',
  //tenders
  GET_MY_TENDERS: 'GET_MY_TENDERS',
  GET_ALL_TENDERS: 'GET_ALL_TENDERS',
  SET_ALL_TENDERS: 'SET_ALL_TENDERS',
  SET_MY_TENDERS: 'SET_MY_TENDERS',
  SET_ALL_TENDER_GROUP: 'SET_ALL_TENDER_GROUP',
  SET_MY_TENDER_GROUP: 'SET_MY_TENDER_GROUP',
  SET_WIDGET_TENDERS: 'SET_WIDGET_TENDERS',
  SET_WIDGET_TENDERS_FOR_COMPLETE: 'SET_WIDGET_TENDERS_FOR_COMPLETE',
  SET_PAGE_COMPLETE_TENDERS: 'SET_PAGE_COMPLETE_TENDERS',
  SET_TOTAL_COMPLETE_TENDERS: 'SET_TOTAL_COMPLETE_TENDERS',
  CLEAR_WIDGET_TENDERS: 'CLEAR_WIDGET_TENDERS',
  SET_OPEN_WIDGET_TENDERS: 'SET_OPEN_WIDGET_TENDERS',
  SET_SINGLE_TENDER: 'SET_SINGLE_TENDER',
  SET_BETS: 'SET_BETS',
  SET_FLAG_FOR_BID_STEPS: 'SET_FLAG_FOR_BID_STEPS',
  SET_BID_STEPS: 'SET_BID_STEPS',
  CLOSE_BET_WINDOW: 'CLOSE_BET_WINDOW',
  REMEMBER_TENDER_WIDGET: 'REMEMBER_TENDER_WIDGET',
  SET_TENDER_CHILDREN: 'SET_TENDER_CHILDREN',
  SET_TENDER_CHILDREN_LOADER:'SET_TENDER_CHILDREN_LOADER',

  //documents
  SET_DOCUMENT_GENERAL_DATA: 'SET_DOCUMENT_GENERAL_DATA',
  SET_DOCUMENT_UPLOAD_DATA: 'SET_DOCUMENT_UPLOAD_DATA',
  SET_DOCUMENT_LOADER: 'SET_DOCUMENT_LOADER',
  //chronology
  SET_CHRONOLOGY_LOADER: 'SET_CHRONOLOGY_LOADER',
  SET_CHRONOLOGY_DATA: 'SET_CHRONOLOGY_DATA',
  // chats
  SET_DIALOGUES: 'SET_DIALOGUES',
  SET_CHAT_RECIPIENTS: 'SET_CHAT_RECIPIENTS',
  SET_DIALOG_MSG: 'SET_DIALOG_MSG',
  SET_CHAT_LOADER: 'SET_CHAT_LOADER',
  SET_CHAT_FETCH: 'SET_CHAT_FETCH',
  SET_POST_PROCESS: 'SET_POST_PROCESS',

  STOP_LOADER_FOR_SINGLE_TENDER_PAGE: 'STOP_LOADER_FOR_SINGLE_TENDER_PAGE',
  CLEAR_FLAG_FOR_SINGLE_TENDER_PAGE: 'CLEAR_FLAG_FOR_SINGLE_TENDER_PAGE',
  STOP_LOADER_FOR_LIST_TENDERS_PAGE: 'STOP_LOADER_FOR_LIST_TENDERS_PAGE',
  CLEAR_FLAG_FOR_LIST_TENDERS_PAGE: 'CLEAR_FLAG_FOR_LIST_TENDERS_PAGE',
  //
  SET_CHAT_MSG: 'SET_CHAT_MSG',
  SET_INFO_MSG: 'SET_INFO_MSG',
  OPEN_MAIN_MENU: 'OPEN_MAIN_MENU',
  CLOSE_MAIN_MENU: 'CLOSE_MAIN_MENU',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  SET_BUBBLE_MESSAGE: 'SET_BUBBLE_MESSAGE',
  CLOSE_BUBBLE_MESSAGE: 'CLOSE_BUBBLE_MESSAGE',
  NAVMENU_SET_CURRENT_TAP: 'NAVMENU_SET_CURRENT_TAP',
  TABMENU_SET_CURRENT_TAP: 'TABMENU_SET_CURRENT_TAP',
  COUNTER_PLUS: 'COUNTER_PLUS',
  COUNTER_MINUS: 'COUNTER_MINUS',
  SET_THEME: 'SET_THEME',
  SET_API_ERROR: 'SET_API_ERROR',
  SET_PARK_LIST: 'SET_PARK_LIST',
  SET_PARK_LOADER: 'SET_PARK_LOADER',
  CLEAR_SEARCH_LIST: 'CLEAR_SEARCH_LIST',
  SET_DRIVERS_FOR_SMART_LIST: 'SET_DRIVERS_FOR_SMART_LIST',
  SET_CARS_FOR_SMART_LIST: 'SET_CARS_FOR_SMART_LIST',
  SET_TRAILERS_FOR_SMART_LIST: 'SET_TRAILERS_FOR_SMART_LIST',
  CLEAR_SMART_LIST: 'CLEAR_SMART_LIST',
  FILTER_SMART_LIST: 'FILTER_SMART_LIST',
  FILTER_SEARCH_LIST: 'FILTER_SEARCH_LIST',

  SET_CHECKPOINT_LOADER: 'SET_CHECKPOINT_LOADER',
  SET_CHECKPOINT_DATA: 'SET_CHECKPOINT_DATA',
  FILTER_CHECKPOINT_LIST: 'FILTER_CHECKPOINT_LIST',
  CLEAR_CHECKPOINT_LIST: 'CLEAR_CHECKPOINT_LIST',
  // company
  SET_COMPANY_INFO: 'SET_COMPANY_INFO',
  SET_COMPANY_BADREQEST: 'SET_COMPANY_BADREQEST',

  //temporary for test
  CHANGE_CHECK_TEST_LIST: 'CHANGE_CHECK_TEST_LIST',
  FILTER_TEST_LIST: 'FILTER_TEST_LIST',
  SET_USER_DATE: 'SET_USER_DATE',
  SET_USER_TIME: 'SET_USER_TIME',

  //create new route
  SET_NEWROUTE_DEFAULT_DATA_FLAG: 'SET_NEWROUTE_DEFAULT_DATA_FLAG',
  UPDATE_NEWROUTE_CODEAPI: 'UPDATE_NEWROUTE_CODEAPI',
  UPDATE_NEWROUTE_PARTS: 'UPDATE_NEWROUTE_PARTS',
  UPDATE_NEWROUTE_PARTS_ERROR: 'UPDATE_NEWROUTE_PARTS_ERROR',
  UPDATE_NEWROUTE_ITEM: 'UPDATE_NEWROUTE_ITEM',
  UPDATE_NEWROUTE_QUANTITY_AUTO: 'UPDATE_NEWROUTE_QUANTITY_AUTO',
  UPDATE_NEWROUTE_CHARGETYPE: 'UPDATE_NEWROUTE_CHARGETYPE',
  UPDATE_NEWROUTE_CARTYPE: 'UPDATE_NEWROUTE_CARTYPE',
  UPDATE_NEWROUTE_FERRYPROPERTIES: 'UPDATE_NEWROUTE_FERRYPROPERTIES',
  UPDATE_NEWROUTE_COMMENT: 'UPDATE_NEWROUTE_COMMENT',
  UPDATE_NEWROUTE_TENDERTERMS: 'UPDATE_NEWROUTE_TENDERTERMS',
  UPDATE_NEWROUTE_ISTENDER: 'UPDATE_NEWROUTE_ISTENDER',
  UPDATE_NEWROUTE_NDS: 'UPDATE_NEWROUTE_NDS',
  UPDATE_NEWROUTE_TENDERISNOW: 'UPDATE_NEWROUTE_TENDERISNOW',
  UPDATE_NEWROUTE_TENDER_FINISH_TIME: 'UPDATE_NEWROUTE_TENDER_FINISH_TIME',
  UPDATE_NEWROUTE_TENDER_FINISH_DATE: 'UPDATE_NEWROUTE_TENDER_FINISH_DATE',
  UPDATE_NEWROUTE_TENDER_START_TIME: 'UPDATE_NEWROUTE_TENDER_START_TIME',
  UPDATE_NEWROUTE_TENDER_START_DATE: 'UPDATE_NEWROUTE_TENDER_START_DATE',
  UPDATE_NEWROUTE_TENDER_STEP: 'UPDATE_NEWROUTE_TENDER_STEP',
  UPDATE_NEWROUTE_TENDER_STARTPRICE: 'UPDATE_NEWROUTE_TENDER_STARTPRICE',
  UPDATE_NEWROUTE_TENDER_TOTALCOUNT: 'UPDATE_NEWROUTE_TENDER_TOTALCOUNT',
  UPDATE_NEWROUTE_CURRENCY: 'UPDATE_NEWROUTE_CURRENCY',
  UPDATE_NEWROUTE_PRICEFOR: 'UPDATE_NEWROUTE_PRICEFOR',
  UPDATE_NEWROUTE_PAYMENTTYPE: 'UPDATE_NEWROUTE_PAYMENTTYPE',
  UPDATE_NEWROUTE_PAYORDER_COMPANY: 'UPDATE_NEWROUTE_PAYORDER_COMPANY',
  UPDATE_NEWROUTE_TENDERTYPE: 'UPDATE_NEWROUTE_TENDERTYPE',
  UPDATE_NEWROUTE_TENDERISBLIND: 'UPDATE_NEWROUTE_TENDERISBLIND',
  UPDATE_NEWROUTE_TENDER_BASEPRICE: 'UPDATE_NEWROUTE_TENDER_BASEPRICE',
  UPDATE_NEWROUTE_TENDER_WINNERPRICE: 'UPDATE_NEWROUTE_TENDER_WINNERPRICE',
  UPDATE_NEWROUTE_TENDER_ROUTEPRICE: 'UPDATE_NEWROUTE_TENDER_ROUTEPRICE',
  UPDATE_NEWROUTE_OPERATORS: 'UPDATE_NEWROUTE_OPERATORS',
  SET_NEWROUTE_DEFAULT_DATA: 'SET_NEWROUTE_DEFAULT_DATA',
  UPDATE_NEWROUTE_FLAG_FOR_BEFOREUNLOAD:
    'UPDATE_NEWROUTE_FLAG_FOR_BEFOREUNLOAD',

  //add new TS
  NEW_TS_SET_DEFAULT_DATA: 'NEW_TS_SET_DEFAULT_DATA',
  NEW_TS_UPDATE_DRIVER: 'NEW_TS_UPDATE_DRIVER',
  NEW_TS_UPDATE_TRAILER: 'NEW_TS_UPDATE_TRAILER',
  NEW_TS_UPDATE_CAR: 'NEW_TS_UPDATE_CAR',
  NEW_TS_UPDATE_LIST_FERRY_PROPERTIES:  'NEW_TS_UPDATE_LIST_FERRY_PROPERTIES',
  NEW_TS_UPDATE_LIST_CHARGE_TYPE:  'NEW_TS_UPDATE_LIST_CHARGE_TYPE',
  NEW_TS_UPDATE_CHECKLIST: 'NEW_TS_UPDATE_CHECKLIST',

  //select list
  SET_SELECTLIST_LOADER: 'SET_SELECTLIST_LOADER',
  SET_SELECTLIST_DATA: 'SET_SELECTLIST_DATA',
  FILTER_SELECTLIST: 'FILTER_SELECTLIST',
  CLEAR_SELECTLIST: 'CLEAR_SELECTLIST',

  //for sagas
  //local
  SAGA_LOGOUT: 'SAGA_LOGOUT',
  //api
  SAGA_GET_LIST_LANG: 'SAGA_GET_LIST_LANG',
  SAGA_GET_DICTIONARY: 'SAGA_GET_DICTIONARY',
  SAGA_POST_NEW_WORDS: 'SAGA_POST_NEW_WORDS',
  SET_LANG: 'SET_LANG',
  SAGA_CHANGE_LANG: 'SAGA_CHANGE_LANG',
  GET_NOTIFICATION_STATUS: 'GET_NOTIFICATION_STATUS',
  GET_ROUTE_WIDGET_GROUP: 'GET_ROUTE_WIDGET_GROUP',
  SAGA_GET_ROUTES_DETAILS: 'SAGA_GET_ROUTE_DETAILS',
  SAGA_GET_COMPLETE_ROUTES: 'SAGA_GET_COMPLETE_ROUTES',
  SAGA_GET_COMPLETE_TENDERS: 'SAGA_GET_COMPLETE_TENDERS',
  SAGA_GET_SINGLE_ROUTE: 'SAGA_GET_SINGLE_ROUTE',
  SET_SENTRY_MESSAGE: 'SET_SENTRY_MESSAGE',
  SAGA_GET_MY_TENDER_WIDGET_GROUP: 'SAGA_GET_MY_TENDER_WIDGET_GROUP',
  SAGA_GET_TENDER_DETAILS: 'SAGA_GET_TENDER_DETAILS',
  SAGA_GET_SINGLE_TENDER: 'SAGA_GET_SINGLE_TENDER',
  SAGA_GET_NEWROUTE_DEFAULT_DATA: 'SAGA_GET_NEWROUTE_DEFAULT_DATA',
  SAGA_GET_ROUTE_DOCUMENTS: 'SAGA_GET_ROUTE_DOCUMENTS',
  SAGA_GET_ROUTE_CHRONOLOGY: 'SAGA_GET_ROUTE_CHRONOLOGY',
  SAGA_GET_COMPANY_INFO: 'SAGA_GET_COMPANY_INFO',
  //action
  SAGA_SUBSCRIBE_TO_EXECUTION: 'SAGA_SUBSCRIBE_TO_EXECUTION',
  SAGA_ARRIVED_TO: 'SAGA_ARRIVED_TO',
  SAGA_OFFER_TRANSPORT: 'SAGA_OFFER_TRANSPORT',
  SAGA_ADD_NEW_TS: 'SAGA_ADD_NEW_TS',
  SAGA_BOOK_ROUTE: 'SAGA_BOOK_ROUTE',
  SAGA_CANCEL_BOOK_ROUTE: 'SAGA_CANCEL_BOOK_ROUTE',
  SAGA_SEND_MY_BET: 'SAGA_SEND_MY_BET',
  SAGA_GET_PRICEQUTES: 'SAGA_GET_PRICEQUTES',
  //aux
  SAGA_GET_MY_PARK: 'SAGA_GET_MY_PARK',
  SAGA_GET_MY_PARK_FOR_SMART_SELECT: 'SAGA_GET_MY_PARK_FOR_SMART_SELECT',
  SAGA_GET_CAR_ALL_PROPERTIES: 'SAGA_GET_CAR_ALL_PROPERTIES',
}
