import React, { useRef } from 'react'
import styled from 'styled-components'
import useOutsideClick from '../../hooks/useOutsideClick'

const Wrapper = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(p) => p.theme.modalGradient};
  display: flex;
  justify-content: center;
  align-items: center;
`

export default ({ children }) => {
  const childrenRef = useRef(null)
  // const closeModal = () => dispatch({ type: 'CLOSE_MODAL' }) // bad work with filter route tab
  useOutsideClick(childrenRef, () => {})
  return (
    <Wrapper>
      <div ref={childrenRef}>{children}</div>
    </Wrapper>
  )
}
