import Type from '../types'

const initialState = {
  isLoader: false,
  uploadFiles: [],
  generalFiles: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_DOCUMENT_GENERAL_DATA:
      return {
        ...state,
        generalFiles: action.payload,
      }
    case Type.SET_DOCUMENT_UPLOAD_DATA:
      return {
        ...state,
        uploadFiles: action.payload,
      }
    case Type.SET_DOCUMENT_LOADER:
      return {
        ...state,
        isLoader: action.payload,
      }

    default:
      return state
  }
}
