import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.p`
  outline: none;
  text-decoration: none;
  color: ${(p) => p.theme.regular_txt};
  width: 25%;
  padding: 1rem;
  background-color: ${(p) => (p.active ? p.theme.selected : p.theme.opposite)};
  text-align: center;
  border-right: 0.1rem solid ${(p) => p.theme.lines};
  :last-child {
    border-right: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.theme.h4};
  cursor: pointer;
  transition: 0.2s ease;
`
const defaultObj = {
  title: 'nav tab',
  id: '0',
  path: '/',
}

export default ({ obj = defaultObj, activeTab, fnClick = () => {} }) => (
  <Wrapper
    active={activeTab === String(obj.path)}
    onClick={() => fnClick(obj.id, obj.path)}
  >
    {obj.title}
  </Wrapper>
)
