import axios from 'axios'

export const routs = {
  //get
  dumpLang: '/getLoginFormStrings',
  listLang: '/listLanguages',
  profile: '/getUserProfile',
  notificationStatus: '/getNotificationStatus',
  routeWidgetGroups: '/getRouteGroups',
  tenderWidgetGroups: '/getTenderGroups',
  myRoutes: '/getMyRoutes',
  myTenders: '/getMyTenders',
  allRoutes: '/getAllRoutes',
  allTenders: '/getAllTenders',
  getCompleteRoutes: '/getCompleteRoutes',
  getCompleteTenders: '/getCompleteTenders',
  routesForWidget: '/getBasicDetailsOfRoutes',
  singleRoute: '/singleRoute',
  finishRoute: '/finishRoute',
  getTenderChildren: 'getTenderChildren',
  route: '/route',
  singleCompany: '/showCompany',
  getPricequotes: '/getPricequotes/',
  getRouteMessages: '/getRouteMessages',
  getTenderSteps: 'getTenderSteps',
  routeChronology: '/routeChronology',
  getRouteMessageGroups: '/getRouteMessageGroups',
  getRouteDocuments:'/getRouteDocuments',
  //post
  addVehicle: '/addVehicle',
  auth: '/auth',
  renewal:'/requestPasswordReset',
  newPassword:'/resetPassword',
  bookRoute: '/bookRoute',
  cancelBookRoute: '/cancelBook',
  createRoute: '/createRoute',
  createTender: '/createTender',
  offerTransport: '/offerAuto',
  ratifyOperator: '/ratifyOperator',
  sendMyBet: '/pricequote',
  confirmRoute: '/confirmWorkOnRoute',
  arrivedAtCharge: '/arrivedAtCharge',
  confirmCharge: '/confirmCharge',
  arrivedAtUncharge: '/arrivedAtUncharge',
  confirmUncharge: '/confirmUncharge',
  arrivedAtCustoms: '/arrivedAtCustoms',
  arrivedAtBorder: '/arrivedAtBorder',
  passedBorder: '/borderComplete',
  passedCustom: '/customsComplete',
  addDocument: '/addDocument',
  sendMessage: '/sendMessage',
  getRecepients: '/getRouteMessageRecepients',

  //aux
  getCarMake: '/getCarMake',
  getVehicleClasses: '/getVehicleClasses',
  myDrivers: '/getMyDrivers',
  myCars: '/getMyCars',
  myTrailers: '/getMyTrailers',
  getDictionary: '/getDictionary',
  addMissingDictionary: '/addMissingDictionary',
  getCountries: '/getCountry',
  getTowns: '/getTown',
  getCompanies: '/getCompanies',
  getCheckpoints: '/getCheckpoint',
  getCheckpointContact: '/getCheckpointContactData',
  getCargo: '/getCargo',
  getMeasureUnits: '/getMeasureUnit',
  getCartype: '/getCartype',
  getChargetype: '/getChargetype',
  getFerryproperty: '/getFerryproperty',
  getPaymenttype: '/getPaymenttype',
  getCurrency: '/getCurrency',
  getPricefor: '/getPricefor',
  getPayor: '/getPayor',
  getPartners: '/getPartners',
  getSettings: '/getSettings',
}

export const beforeAuth = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: { Authorization: process.env.REACT_APP_STATIC_TOKEN },
})
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: { Extend: 'dictionary' },
})
