import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Checklist from '../../components/popUps/Checklist'

export default () => {
  const dispatch = useDispatch()
  const list = useSelector((s) => s.testSelect.list)
  const onChangeList = (id) => {
    dispatch({ type: 'CHANGE_CHECK_TEST_LIST', payload: id })
  }
  const onSaveChange = () => {
    dispatch({ type: 'FILTER_TEST_LIST' })
    dispatch({ type: 'CLOSE_MODAL' })
  }
  const onCancelChange = () => {
    dispatch({ type: 'FILTER_TEST_LIST' })
    dispatch({ type: 'CLOSE_MODAL' })
  }
  return (
    <Checklist
      list={list}
      fnUpdateCheck={onChangeList}
      fnSave={onSaveChange}
      fnCancel={onCancelChange}
    />
  )
}
