import {
  baseBG,
  extraBaseBG,
  baseColors,
  darkBG,
  extraDarkBG,
  darkColors,
  FS,
  ShdwBase,
  ShdwDark,
  other,
} from './variables'

const base = {
  ...baseBG,
  ...extraBaseBG,
  ...baseColors,
  ...FS,
  ...ShdwBase,
  ...other,
}
const dark = {
  ...darkBG,
  ...extraDarkBG,
  ...darkColors,
  ...FS,
  ...ShdwDark,
  ...other,
}

export default {
  base,
  dark,
}
