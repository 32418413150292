import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import shortid from 'shortid'
import SelectInput from './SelectInput'
import SmartInput from '../../components/inputs/SmartInput'
import BaseInput from '../../components/inputs/BaseInput'
import BtnLikeLink from '../../components/buttons/BtnLikeLink'
import { strToUpperCase } from '../../lib/helpers/forStyling'
import { patterns } from '../../lib/constants'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const FlexWrp = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(p) =>
    p.error &&
    `
    padding: 0.5rem;
    border: 0.1rem solid ${p.theme.error};
    border-radius:0.3rem;
  `}
`
const HalfBox = styled.div`
  width: ${(p) => p.width};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const ErrorText = styled.p`
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.4rem;
  color: ${(p) => p.theme.error};
`

export default ({
  index = 2,
  onUpdateItemRoutePart = () => {},
  prewData = {},
  errors = [],
  settings = {},
}) => {
  const dispatch = useDispatch()
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const list = useSelector((s) => s.checkpoint.searchList)
  const [isShowExtra, setIsShowExtra] = useState(false)
  const [data, setData] = useState({
    group: { union: '' },
    cargo: { union: '' },
    weight: { union: '' },
    volume: { union: '' },
    length: { union: '' },
    height: { union: '' },
    width: { union: '' },
    category: { union: '', quantity: '' },
  })
  const [inputErr, setInputErr] = useState({
    weight: { bool: 0, msg: '' },
    volume: { bool: 0, msg: '' },
    length: { bool: 0, msg: '' },
    width: { bool: 0, msg: '' },
    height: { bool: 0, msg: '' },
    quantity: { bool: 0, msg: '' },
  })
  const [dataForDispatch] = useState({
    type: 'SAGA_GET_CHECKPOINT_DATA',
    clear: 'CLEAR_CHECKPOINT_LIST',
    loader: 'checkpoint',
  })
  const toggler = () => {
    setIsShowExtra(!isShowExtra)
  }
  const defaultErr = {
    weight: { bool: 0, msg: '' },
    volume: { bool: 0, msg: '' },
    length: { bool: 0, msg: '' },
    width: { bool: 0, msg: '' },
    height: { bool: 0, msg: '' },
    quantity: { bool: 0, msg: '' },
  }

  const uniq = {
    group: shortid.generate(),
    cargo: shortid.generate(),
    weight: shortid.generate(),
    volume: shortid.generate(),
    Length: shortid.generate(),
    height: shortid.generate(),
    width: shortid.generate(),
    category: shortid.generate(),
    quantity: shortid.generate(),
  }

  const NAME_SELECTOR = {
    [uniq.group]: 'group',
    [uniq.cargo]: 'cargo',
    [uniq.weight]: 'weight',
    [uniq.volume]: 'volume',
    [uniq.Length]: 'length',
    [uniq.height]: 'height',
    [uniq.width]: 'width',
    [uniq.category]: 'category',
    [uniq.quantity]: 'quantity',
  }
  const removeError = (NAME) =>
    setTimeout(() => {
      setInputErr({ ...defaultErr, [NAME]: { bool: 0, msg: '' } })
    }, 1000)

  const setError = (NAME) => {
    setInputErr({ ...defaultErr, [NAME]: { bool: 1, msg: 'только цифры' } })
    removeError(NAME)
  }
  const fnChange = (e) => {
    const VALUE = e.target.value
    const NAME = NAME_SELECTOR[e.target.name]

    if (NAME === 'cargo') {
      dispatch({ type: 'FILTER_CHECKPOINT_LIST', payload: VALUE })
      dispatch({
        type: 'SAGA_GET_CHECKPOINT_DATA',
        requestType: 'cargo',
        parametr: VALUE,
      })
    }
    const result = { ...data[NAME], union: VALUE }
    setData({
      ...data,
      [NAME]: result,
    })
    onUpdateItemRoutePart(index, { [NAME]: result })
  }

  const fnChangeInputs = (e) => {
    const VALUE = e.target.value
    const NAME = NAME_SELECTOR[e.target.name]
    const test = VALUE === '' || VALUE === '-' || patterns.float.test(VALUE)
    if (!test) {
      setError(NAME)
      return
    }
    const result = { ...data[NAME], union: VALUE }
    setData({
      ...data,
      [NAME]: result,
    })
    onUpdateItemRoutePart(index, { [NAME]: result })
  }

  const onChangeCategoriesQuantity = (e) => {
    const VALUE = e.target.value
    const NAME = NAME_SELECTOR[e.target.name]
    const test = VALUE === '' || VALUE === '-' || patterns.float.test(VALUE)

    if (!test) {
      setError(NAME)
      return
    }
    const result = { ...data.category, quantity: VALUE }
    setData({
      ...data,
      category: result,
    })
    onUpdateItemRoutePart(index, { category: result })
  }

  const fnFormValue = (whereUpdate, obj) => {
    const selector = {
      group: {
        ...data,
        [NAME_SELECTOR[whereUpdate]]: obj,
      },
      cargo: {
        ...data,
        [NAME_SELECTOR[whereUpdate]]: obj,
      },
      weight: {
        ...data,
        [NAME_SELECTOR[whereUpdate]]: obj,
      },
      volume: {
        ...data,
        [NAME_SELECTOR[whereUpdate]]: obj,
      },
      length: { ...data, [NAME_SELECTOR[whereUpdate]]: obj },
      height: { ...data, [NAME_SELECTOR[whereUpdate]]: obj },
      width: { ...data, [NAME_SELECTOR[whereUpdate]]: obj },
      category: { ...data, [NAME_SELECTOR[whereUpdate]]: obj },
    }
    setData(selector[[NAME_SELECTOR[whereUpdate]]])

    onUpdateItemRoutePart(index, { [[NAME_SELECTOR[whereUpdate]]]: obj })
  }

  const updateDefaultState = () => {
    const { group, cargo, weight, volume, length, height, width, category } =
      prewData

    const result = {
      group: group && group.union.length ? group : { union: '' },
      cargo: cargo && cargo.union.length ? cargo : { union: '' },
      weight: weight && weight.union.length ? weight : { union: '' },
      volume: volume && volume.union.length ? volume : { union: '' },
      length: length && length.union.length ? length : { union: '' },
      height: height && height.union.length ? height : { union: '' },
      width: width && width.union.lenght ? width : { union: '' },
      category:
        category && category.union.length
          ? category
          : { union: '', quantity: '' },
    }

    setData(result)
  }

  useEffect(() => {
    if (prewData.cargo && prewData.cargo.union.length) {
      updateDefaultState()
    }
  }, []) //eslint-disable-line

  return (
    <Wrapper>
      {settings.category && (
        <SelectInput
          name={uniq.group}
          id={uniq.group}
          style={{ marginBottom: '2.4rem' }}
          label={
            errors[index] && errors[index].group
              ? `${dic.category || ''}*`
              : dic.category || ''
          }
          value={data.group.union}
          fnFormValue={fnFormValue}
          disabled
          // action={{ ...dataForDispatch, payload: 'cargo' }}
          // list={list}
          nothingToDisplay={dic.noMatches}
        />
      )}
      <SmartInput
        name={uniq.cargo}
        id={uniq.cargo}
        style={{ marginBottom: '2.4rem' }}
        label={
          errors[index] && errors[index].cargo
            ? `${dic.cargo || ''}*`
            : dic.cargo || ''
        }
        value={data.cargo.union}
        parametr={data.cargo.union}
        fnFormValue={fnFormValue}
        fnChange={fnChange}
        action={{ ...dataForDispatch, payload: 'cargo' }}
        list={list}
        error={
          errors[index] && errors[index].cargo ? errors[index].cargo.bool : null
        }
        message={
          errors[index] && errors[index].cargo ? errors[index].cargo.msg : null
        }
      />
      {Boolean(errors[index].weight.bool) && (
        <ErrorText>Укажите хотя бы 1 параметр</ErrorText>
      )}
      <FlexWrp error={errors[index].weight.bool}>
        <HalfBox width="50%">
          <BaseInput
            label={`${dic.weight}*`}
            style={{ width: '70%', marginRight: '1rem' }}
            name={uniq.weight}
            fnChange={fnChangeInputs}
            value={data.weight.union}
            error={inputErr.weight.bool}
            message={inputErr.weight.msg}
          />
          <p style={{ marginTop: '1.8rem' }}>{dic.t}</p>
        </HalfBox>
        <HalfBox width="50%">
          <BaseInput
            label={dic.volume}
            style={{ width: '70%', marginRight: '1rem' }}
            name={uniq.volume}
            fnChange={fnChangeInputs}
            value={data.volume.union}
            error={inputErr.volume.bool}
            message={inputErr.volume.msg}
          />
          <p style={{ marginTop: '1.8rem' }}>{dic.mcube}</p>
        </HalfBox>
      </FlexWrp>
      <BtnLikeLink
        label={`${isShowExtra ? '-' : '+'}${strToUpperCase(
          dic.cargo_extra_parameters
        )}`}
        style={{ marginRight: 'auto', marginBottom: '2.4rem' }}
        onClick={toggler}
      />
      {isShowExtra && (
        <>
          <FlexWrp style={{ marginBottom: '2.4rem' }}>
            <HalfBox width="33%">
              <BaseInput
                label={dic.length}
                style={{ width: '70%', marginRight: '1rem' }}
                name={uniq.Length}
                fnChange={fnChangeInputs}
                value={data.length.union}
                error={inputErr.length.bool}
                message={inputErr.length.msg}
              />
              <p style={{ marginTop: '1.8rem' }}>{dic.m}</p>
            </HalfBox>
            <HalfBox width="33%">
              <BaseInput
                label={dic.width}
                style={{ width: '70%', marginRight: '1rem' }}
                name={uniq.width}
                fnChange={fnChangeInputs}
                value={data.width.union}
                error={inputErr.width.bool}
                message={inputErr.width.msg}
              />
              <p style={{ marginTop: '1.8rem' }}>{dic.m}</p>
            </HalfBox>
            <HalfBox width="33%">
              <BaseInput
                label={dic.height}
                style={{ width: '70%', marginRight: '1rem' }}
                name={uniq.height}
                fnChange={fnChangeInputs}
                value={data.height.union}
                error={inputErr.height.bool}
                message={inputErr.height.msg}
              />
              <p style={{ marginTop: '1.8rem' }}>{dic.m}</p>
            </HalfBox>
          </FlexWrp>
          <FlexWrp style={{ marginBottom: '2.4rem' }}>
            <HalfBox width="66%">
              <SelectInput
                label={dic.unit_my}
                name={uniq.category}
                style={{ width: '100%', marginRight: '1rem' }}
                value={data.category.union}
                fnFormValue={fnFormValue}
                fnChange={fnChange}
                action={{ ...dataForDispatch, payload: 'category' }}
                list={list}
                nothingToDisplay={dic.info_1}
              />
            </HalfBox>
            <HalfBox width="33%">
              <BaseInput
                label={dic.quantity}
                style={{ width: '70%', marginRight: '1rem' }}
                name={uniq.quantity}
                value={data.category.quantity}
                fnChange={onChangeCategoriesQuantity}
                error={inputErr.quantity.bool}
                message={inputErr.quantity.msg}
              />
            </HalfBox>
          </FlexWrp>
        </>
      )}
    </Wrapper>
  )
}
