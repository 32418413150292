import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import Icon from '../Icon'
import {
  NumberWithoutNulls,
  parseUnitPrices,
} from '../../lib/helpers/forRoutes'
import ModalPortal from '../layouts/ModalPortal'
import HereRouteMap from '../../here/RouteMap'
import DottedLink from '../links/DottedLink'
import EmailLink from '../links/EmailLink'
import TelLink from '../links/TelLink'
import { currencyIcons } from '../../lib/constants'
import {
  // parseDateOfLocal,
  parseWholeDate,
  datesForScales,
} from '../../lib/helpers/forRoutes'
import { strToUpperCase } from '../../lib/helpers/forStyling'
import useDataStore from '../../hooks/useDataStore'

export const Wrapper = styled.div`
  position: relative;
`
const TitleWrp = styled.p`
  margin-bottom: ${(p) => p.mrgnBtm};
  font-size: ${(p) => (p.large ? p.theme.h3 : p.theme.h4)};
  line-height: 1.5;
  white-space: pre-wrap;
  ${(p) =>
    p.big &&
    `
  font-size: ${p.theme.h2}
  `};
  font-weight: ${(p) => (p.large ? '700' : '500')};
  color: ${(p) => p.theme.regular_txt};
  display: flex;
  justify-content: ${(p) => (p.right ? 'flex-end' : 'flex-start')};
  align-items: center;
`
const UnitWrp = styled.div`
  margin-bottom: ${(p) => p.mrgnBtm};
  font-size: ${(p) => (p.large ? p.theme.h3 : p.theme.h4)};
  line-height: 1.5;
  white-space: pre-wrap;
  color: ${(p) => p.theme.regular_txt};
`
const InfoWrp = styled.p`
  display: block;
  margin-bottom: ${(p) => p.mrgnBtm};
  font-size: ${(p) => p.theme[p.fs] || p.theme.h5};
  color: ${(p) => (p.isWarn ? p.theme.error : p.theme.additional)};
  text-align: ${(p) => (p.right ? 'right' : 'left')};
  ${(p) =>
    !p.lineWrp &&
    `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;`}
  ${(p) =>
    p.isTrigger &&
    `
  color: ${p.theme.trigger_txt};
  `}
`
export const SeparateLine = styled.div`
  width: calc(100% + 3.2rem);
  margin-left: -1.6rem;
  border-top: 0.1rem solid ${(p) => p.theme.lines};
`
const HalfBoxWrp = styled.div`
  width: 50%;
  padding-right: 1rem;
  margin-bottom: ${(p) => p.mrgnBtm};
`
const FlexBoxWrp = styled.div`
  margin-bottom: ${(p) => p.mrgnBtm};
  display: flex;
  justify-content: ${(p) => (p.center ? 'space-around' : 'space-between')};
  align-items: ${(p) => (p.alignCenter ? 'center' : 'flex-start')};
`
const InfoLinkWrp = styled.p`
  margin-bottom: ${(p) => p.mrgnBtm};
  font-size: ${(p) => p.theme[p.fs] || p.theme.h4};
  color: ${(p) => p.theme.links};
  font-weight: 500;
`
export const MapLogo = styled.img`
  margin-left: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
`
export const Action = styled.div`
  z-index: 3;
  position: fixed;
  bottom: 0rem;
  left: 0;
  width: 100%;
  padding: 1rem 1.6rem 2.6rem;
  background-color: ${(p) => p.theme.opposite};
  border-top: 0.1rem solid ${(p) => p.theme.lines};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const SuccessMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${(p) => p.theme.approver};
  .title {
    font-size: ${(p) => p.theme.h3};
  }
  .text {
    font-size: ${(p) => p.theme.h5};
  }
`
const AwaitMessage = styled.p`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${(p) => p.theme.approver};
  font-size: ${(p) => p.theme[p.fs] || p.theme.h5};
`
const MapWrap = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${(p) => p.theme.opposite};
  .closeWrp {
    padding: 0 1rem;
    height: 6vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${(p) => p.theme.h4};
  }
`

export const RouteMap = ({
  points = [],
  fnClose = () => {},
  title = 'Map',
}) => {
  return (
    <ModalPortal>
      <MapWrap>
        <div className="closeWrp">
          <p>{title}</p>
          <Icon name="close" onClick={fnClose} style={{ marginLeft: 'auto' }} />
        </div>
        <HereRouteMap points={points} height="94vh" />
      </MapWrap>
    </ModalPortal>
  )
}
export const AwaitApprovedAction = ({
  text = '[default message]',
  style = null,
  fs = '',
}) => (
  <AwaitMessage fs={fs} style={style}>
    {strToUpperCase(text)}
  </AwaitMessage>
)
export const ConfirmOfAction = ({
  title = '[title]',
  text = '[default message]',
}) => (
  <SuccessMessage>
    <Icon name="done" type="approver" style={{ marginRight: '1rem' }} />
    <div>
      <p className="title">{title}</p>
      <p className="text">{text}</p>
    </div>
  </SuccessMessage>
)

export const FlexBox = ({
  children,
  mrgnBtm = '0',
  alignCenter = false,
  style,
  center = false,
  onClick = () => {},
}) => (
  <FlexBoxWrp
    style={style}
    v
    center={center}
    alignCenter={alignCenter}
    mrgnBtm={mrgnBtm}
    onClick={onClick}
  >
    {children}
  </FlexBoxWrp>
)
export const HalfBox = ({ children, mrgnBtm = '0', style }) => (
  <HalfBoxWrp style={style} mrgnBtm={mrgnBtm}>
    {children}
  </HalfBoxWrp>
)
export const Title = ({
  children,
  large = false,
  right = false,
  mrgnBtm = '0',
  big = false,
  style = null,
  className = '',
}) => (
  <TitleWrp
    style={style}
    large={large}
    right={right}
    mrgnBtm={mrgnBtm}
    big={big}
    className={className}
  >
    {children}
  </TitleWrp>
)

export const InfoLink = ({ children, mrgnBtm = '0', fs = 'h4', style }) => (
  <InfoLinkWrp style={style} mrgnBtm={mrgnBtm} fs={fs}>
    {children}
  </InfoLinkWrp>
)
export const Info = ({
  children,
  className,
  isLate = false,
  isWarn = false,
  isTrigger = false,
  right = false,
  head = false,
  mrgnBtm = '0',
  fs = 'h5',
  lineWrp = false,
  style,
}) => (
  <InfoWrp
    right={right}
    head={head}
    isLate={isLate}
    isWarn={isWarn}
    isTrigger={isTrigger}
    mrgnBtm={mrgnBtm}
    className={className}
    fs={fs}
    lineWrp={lineWrp}
    style={style}
  >
    {children}
    {isLate && (
      <Icon
        name="atencion"
        type="error"
        style={{ marginLeft: '0.5rem', marginBottom: '1rem' }}
      />
    )}
  </InfoWrp>
)
const RoutePartsWrp = styled.div`
  margin-bottom: ${(p) => p.mrgnBtm};
  ${(p) => p.borderTop && `border-top: 0.1rem solid ${p.theme.lines}`};

  padding-left: 0.5rem;
`
const ItemWrp = styled.div`
  position: relative;
  padding-top: 0.8rem;
  margin-bottom: ${(p) => p.mrgnBtm};
  display: flex;
  justify-content: space-between;
  align-items: start;
`
const Scale = styled.div`
  position: absolute;
  top: ${(p) => (p.isFirst ? '2.3rem' : '0')};
  left: 0;
  transform: translateX(-50%);
  width: 0.1rem;
  height: ${(p) => (p.isLast ? '2.3rem' : '100%')};
  background-color: ${(p) => p.theme.flat_headers};
`
const DataWrp = styled.div`
  width: 100%;
`
const Content = styled.div`
  width: 100%;
  padding: 1rem 0.5rem 1.4rem 1.8rem;
  background-color: ${(p) =>
    p.isActive ? p.theme.basic_low : p.theme.oppsite};
  ${(p) =>
    p.isPassed &&
    `
  opacity:0.6;
  `};
`
const FlexWrp = styled.div`
  margin-bottom: ${(p) => p.mrgnBtm};
  width: 100%;
  display: flex;
  justify-content: ${(p) => (p.between ? 'space-between' : 'flex-start')};
  align-items: ${(p) => (p.start ? 'flex-statr' : 'center')};
  p {
    margin-right: 1rem;
  }
`
const Line = styled.div`
  width: 90%;
  margin-left: auto;
  height: 0.1rem;
  background-color: ${(p) => p.theme.lines};
`
const UnloadWrp = styled.div`
  z-index: 1;
  position: absolute;
  top: 2.3rem;
  left: -0.95rem;
  width: 1.8rem;
  height: 1.8rem;
  background-color: ${(p) => p.theme.opposite};
  border: 0.1rem solid ${(p) => p.theme.regular_txt};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.theme.h6};
  font-weight: bold;
  ${(p) =>
    p.isActive &&
    `
  background-color: ${p.theme.basic};
  border: 0.1rem solid ${p.theme.basic};
  `};
  ${(p) =>
    p.passed &&
    `
  background-color: ${p.theme.flat_headers};
  border: 0.1rem solid ${p.theme.flat_headers};
  color: ${p.theme.opposite};
  `};
  .content {
  }
`
const LoadWrp = styled(UnloadWrp)`
  border-radius: 50%;
`
const TriangleWrp = styled.div`
  z-index: 1;
  position: absolute;
  top: 2.3rem;
  left: -1.2rem;
  border-style: solid;
  border-width: 2rem 1.2rem 0 1.2rem;
  border-color: ${(p) => p.theme.regular_txt} transparent;
  border-radius: 0.1rem 0.1rem 0 0.1rem;
  .triangleBg {
    position: absolute;
    top: -1.9rem;
    left: -1rem;
    border-style: solid;
    border-width: 1.7rem 1rem 0 1rem;
    border-color: ${(p) => p.theme.opposite} transparent;
    ${(p) =>
      p.isActive &&
      `
  border-color:${p.theme.basic} transparent;
  `};
    ${(p) =>
      p.passed &&
      `
  border-color:${p.theme.flat_headers} transparent;
  `};
  }
  ${(p) =>
    p.isActive &&
    `
  border-color:${p.theme.basic} transparent;
  `};
  ${(p) =>
    p.passed &&
    `
  border-color:${p.theme.flat_headers} transparent;
  color: ${p.theme.opposite};
  `};
  .content {
    position: absolute;
    top: -1.8rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: ${(p) => p.theme.h6};
    font-weight: bold;
  }
`
const Rhombus = styled.div`
  z-index: 1;
  position: absolute;
  top: 2.3rem;
  left: -0.9rem;
  width: 1.8rem;
  height: 1.8rem;
  background-color: ${(p) => p.theme.opposite};
  border: 0.1rem solid ${(p) => p.theme.regular_txt};
  transform: rotate(45deg);
  display: flex;
  justify-items: center;
  align-items: center;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    font-size: ${(p) => p.theme.h6};
    font-weight: bold;
  }
  ${(p) =>
    p.isActive &&
    `
  background-color: ${p.theme.basic};
  border: 0.1rem solid ${p.theme.basic};
  `};
  ${(p) =>
    p.passed &&
    `
  background-color: ${p.theme.flat_headers};
  border: 0.1rem solid ${p.theme.flat_headers};
  color: ${p.theme.opposite};
  `};
`
export const Load = ({
  passed = false,
  isActive = false,
  content = null,
  className,
}) => (
  <LoadWrp passed={passed} isActive={isActive} className={className}>
    {content && <p className="content">{content}</p>}
  </LoadWrp>
)
export const Unload = ({
  passed = false,
  isActive = false,
  content = null,
  className,
}) => (
  <UnloadWrp passed={passed} isActive={isActive} className={className}>
    {content && content}
  </UnloadWrp>
)
export const CrossedBorder = ({
  passed = false,
  isActive = false,
  content = null,
  className,
}) => (
  <TriangleWrp passed={passed} isActive={isActive} className={className}>
    <div className="triangleBg">
      {content && <span className="content">{content}</span>}
    </div>
  </TriangleWrp>
)
export const Customs = ({
  passed = false,
  content = null,
  isActive = false,
  className,
}) => (
  <Rhombus passed={passed} isActive={isActive} className={className}>
    {content && <span className="content">{content}</span>}
  </Rhombus>
)
const pointComponent = (id, idx, active) => {
  const isPassed = idx < active
  const isActive = idx === active
  const content = idx + 1
  return {
    1: (
      <Load
        key={`${id}${idx}`}
        passed={isPassed}
        content={content}
        isActive={isActive}
      />
    ),
    2: (
      <Unload
        key={`${id}${idx}`}
        passed={isPassed}
        content={content}
        isActive={isActive}
      />
    ),
    3: (
      <CrossedBorder
        key={`${id}${idx}`}
        passed={isPassed}
        content={content}
        isActive={isActive}
      />
    ),
    4: (
      <Customs
        key={`${id}${idx}`}
        passed={isPassed}
        content={content}
        isActive={isActive}
      />
    ),
  }
}
export const EnRouteRouteParts = ({
  arr = [],
  lang,
  mrgnBtm = '0',
  borderTop = false,
  routestatus = null,
  setComunicatonStatus = () => {},
  setCurrentWorkAction = () => {},
  style,
  forTender = false,
  isFinishRoute = false,
  data
}) => {
  const [isShow, setIsShow] = useState(0)
  const [active, setActive] = useState('')
  const { MONTH_SHORT } = useDataStore()

  useEffect(() => {
    if (
      !forTender &&
      routestatus !== 2 &&
      routestatus !== 3 &&
      routestatus !== 4 &&
      arr &&
      arr.length > 0
    ) {
      if (routestatus === 7 || routestatus === 10) {
        const triggered = arr.filter((item) => item.triggered)

        setActive(triggered.length - 1)
        setComunicatonStatus({
          complete:
            triggered.length - 1 >= 0
              ? arr[triggered.length - 1].complete
              : null,
          triggered:
            triggered.length - 1 >= 0
              ? arr[triggered.length - 1].triggered
              : null,
          id: arr[triggered.length - 1].id,
        })
      } else {
        const completed = arr.filter((item) => item.complete)
        const statusResult = {
          complete:
            completed.length >= 0 && completed.length < arr.length
              ? arr[completed.length].complete
              : null,
          triggered:
            completed.length - 1 >= 0 && completed.length < arr.length
              ? arr[completed.length].triggered
              : null,
          id: completed.length < arr.length ? arr[completed.length].id : null,
        }
        setActive(completed.length)
        setComunicatonStatus(statusResult)
      }
    }
    setIsShow(true)
  }, [arr, routestatus, setComunicatonStatus, forTender])

  useEffect(() => {
    if (
      active >= 0 &&
      String(active) &&
      active <= arr.length &&
      !isFinishRoute
    ) {
      setCurrentWorkAction(arr[active].workaction)
    } else {
      if (routestatus === 4) {
        setCurrentWorkAction(444)
      }
      if (routestatus === 2) {
        setCurrentWorkAction(222)
      }
    }
  }, [arr, active, setCurrentWorkAction, routestatus, isFinishRoute, data])

  return (
    <>
      {borderTop && <SeparateLine />}
      <RoutePartsWrp style={style} mrgnBtm={mrgnBtm}>
        {arr &&
          arr.length > 0 &&
          isShow &&
          arr.map((item, idx) => (
            <div key={`${item.id}${item.route}`}>
              <ItemWrp>
                <Scale isFirst={idx === 0} isLast={arr.length - 1 === idx} />
                {
                  pointComponent(item.checkpoint.id, idx, active)[
                    item.workaction
                  ]
                }
                <DataWrp>
                  <Content isActive={active === idx} isPassed={idx < active}>
                    <FlexWrp between start={1}>
                      <div>
                        <Title large mrgnBtm="0.5rem">
                          {strToUpperCase(
                            lang[`workaction_${item.workaction}`]
                          )}
                          {idx < active && (
                            <Icon
                              name="done"
                              type="approver"
                              style={{ marginLeft: '1rem' }}
                            />
                          )}
                        </Title>
                        <InfoLink mrgnBtm="0.5rem">
                          {item.checkpoint.town.title_ru}
                        </InfoLink>
                        {/*new*/}
                      </div>
                      <InfoLink
                        right
                        style={{
                          marginTop: '0.5rem',
                          fontWeight: '300',
                          whiteSpace: 'pre-line',
                          textAlign: 'right',
                        }}
                      >
                        {datesForScales(
                          item.date1,
                          item.time1,
                          item.date2,
                          item.time2,
                          MONTH_SHORT,
                          lang.msg_6,
                          lang.roundTheClock
                        )}
                      </InfoLink>
                    </FlexWrp>
                    {(item?.checkpoint?.town?.region?.title_ru && !item?.bordercrossforoperator) && (
                      <Info style={{ paddingBottom: '0.5rem' }}>
                        {item?.checkpoint?.town?.district &&
                          `${item?.checkpoint?.town?.district || ""}, `}
                        {`${item?.checkpoint?.town?.region?.title_ru || ""}`}
                      </Info>
                    )}
                    <Info style={{ paddingBottom: '0.5rem' }}>
                      {item.checkpoint.address}
                    </Info>
                    {(item.checkpointcontacts && item.workaction < 3) && (
                      <Info mrgnBtm="1.5rem">
                        {`${item.checkpointcontacts.contactperson || ''} ${
                          (item.checkpointcontacts.cellnum &&
                            item.checkpointcontacts.cellnum) ||
                          ''
                        }`}
                      </Info>
                    )}
                    {item.cargo && (
                      <>
                        <FlexWrp style={{ paddingBottom: '0.5rem' }}>
                          <Icon
                            name="hook"
                            type="links"
                            style={{ marginRight: '0.5rem' }}
                          />
                          <InfoLink
                            style={{
                              fontWeight: '400',
                            }}
                          >
                            {`${item.cargo}`}
                          </InfoLink>
                        </FlexWrp>
                        <Info lineWrp style={{ paddingBottom: '1.5rem' }}>{`${
                          item.dimensions
                            ? parseUnitPrices(item.dimensions)
                            : ''
                        }`}</Info>
                      </>
                    )}
                  </Content>
                  <FlexWrp>
                    {item.hasOwnProperty('km') && <p>{`${lang.km}`}</p>}
                    {arr.length - 1 !== idx && <Line />}
                  </FlexWrp>
                </DataWrp>
              </ItemWrp>
            </div>
          ))}
      </RoutePartsWrp>
    </>
  )
}

const RouteClient = (obj, dic, withLogist = false) => (
  <>
    <Title big style={{ marginTop: '1rem', marginBottom: '2rem' }}>
      {strToUpperCase(dic.carrier)}
    </Title>
    {obj.operatordata && (
      <div style={{ marginBottom: '3.2rem' }}>
        <DottedLink
          title={obj.operatordata.title_ru}
          path={`/company/${obj.operatordata.hash}`}
          // disabled
          // withoutLine
        />
      </div>
    )}
    {withLogist && (
      <>
        <Info mrgnBtm="0.5rem" className="firstLetterUpperCase">
          {dic.responsible_logistician}
        </Info>
        <Title mrgnBtm="2rem">{obj.userclientdata.name}</Title>
        <FlexBox style={{ paddingBottom: '3rem' }}>
          <HalfBox>
            <Info className="firstLetterUpperCase">{dic.tel}</Info>
            <TelLink cell={obj.userclientdata.cellnum} />
          </HalfBox>
          <HalfBox>
            <Info className="firstLetterUpperCase">{dic.email}</Info>
            <EmailLink
              email={obj.userclientdata.email}
              title={`${dic.route} № ${obj.periodic}`}
            />
          </HalfBox>
        </FlexBox>
      </>
    )}
    <SeparateLine />
  </>
)

const RouteOperator = (obj, dic, withLogist = false) => (
  <>
    <Title big style={{ marginTop: '1rem', marginBottom: '2rem' }}>
      {strToUpperCase(dic.customer)}
    </Title>
    <div style={{ marginBottom: '3.2rem' }}>
      <DottedLink
        title={obj.companydata.title_ru}
        path={`/company/${obj.clientdata.hash}`}
      />
    </div>
    {withLogist && (
      <>
        <Info mrgnBtm="0.5rem" className="firstLetterUpperCase">
          {dic.responsible_logistician}
        </Info>
        <Title mrgnBtm="2rem">{obj.userclientdata.name}</Title>
        <FlexBox style={{ paddingBottom: '3rem' }}>
          <HalfBox>
            <Info className="firstLetterUpperCase">{dic.tel}</Info>
            <TelLink cell={obj.userclientdata.cellnum} />
          </HalfBox>
          <HalfBox>
            <Info className="firstLetterUpperCase">{dic.email}</Info>
            <EmailLink
              email={obj.userclientdata.email}
              title={`${dic.route} № ${obj.periodic}`}
            />
          </HalfBox>
        </FlexBox>
      </>
    )}

    <SeparateLine />
  </>
)

const RouteDrivers = (driversData, dic) => (
  <>
    <Title big mrgnBtm="2rem" style={{ paddingTop: '2rem' }}>
      {strToUpperCase(dic.car_Title)}
    </Title>
    <Info mrgnBtm="0.5rem" className="firstLetterUpperCase">
      {dic.driver}
    </Info>
    <Title mrgnBtm="2rem">{`${driversData.driver.firstname} ${driversData.driver.lastname}`}</Title>
    <FlexBox mrgnBtm="2rem">
      <HalfBox>
        <Info className="firstLetterUpperCase">{dic.driver_license}</Info>
        <Title>{`${driversData.driver.driverlicenseserial} ${driversData.driver.driverlicensenumber}`}</Title>
      </HalfBox>
      <HalfBox>
        <Info className="firstLetterUpperCase">{dic.tel}</Info>
        <TelLink cell={driversData.driver.maincellphone} />
      </HalfBox>
    </FlexBox>
    {driversData.driver2 && (
      <>
        <Info
          mrgnBtm="0.5rem"
          className="firstLetterUpperCase"
        >{`${dic.driver} 2`}</Info>
        <Title mrgnBtm="2rem">{`${driversData.driver2.firstname} ${driversData.driver2.lastname}`}</Title>
        <FlexBox mrgnBtm="2rem">
          <HalfBox>
            <Info className="firstLetterUpperCase">{dic.driver_license}</Info>
            <Title>{`${driversData.driver2.driverlicenseserial} ${driversData.driver2.driverlicensenumber}`}</Title>
          </HalfBox>
          <HalfBox>
            <Info className="firstLetterUpperCase">{dic.tel}</Info>
            <TelLink cell={driversData.driver2.maincellphone} />
          </HalfBox>
        </FlexBox>
      </>
    )}
    <FlexBox mrgnBtm="0.5rem">
      <HalfBox>
        <Info className="firstLetterUpperCase">{dic.car}</Info>
        <Title>{driversData.car.number}</Title>
      </HalfBox>
      {driversData.trailer && (
        <HalfBox>
          <Info className="firstLetterUpperCase">{dic.trailer}</Info>
          <Title>{driversData.trailer.number}</Title>
        </HalfBox>
      )}
    </FlexBox>
    <Title mrgnBtm="2.5rem" className="firstLetterUpperCase">
      {driversData.car.make.title_ru}
    </Title>
    <SeparateLine />
  </>
)
const RouteRequirementVehicle = (obj, dic) => (
  <>
    <Title big style={{ marginTop: '2rem', marginBottom: '2rem' }}>
      {strToUpperCase(dic.requirementVehicle)}
    </Title>
    <FlexBox mrgnBtm="2rem">
      <HalfBox>
        <Info className="firstLetterUpperCase">{dic.car_type}</Info>
        <Title>
          {obj.cartype && obj.cartype.length > 0 ? obj.cartype.join(', ') : '-'}
        </Title>
      </HalfBox>
      <HalfBox>
        <Info className="firstLetterUpperCase">{dic.charge_type}</Info>
        <Title>
          {obj.chargetype && obj.chargetype.length > 0
            ? obj.chargetype.join(', ')
            : '-'}
        </Title>
      </HalfBox>
    </FlexBox>
    <FlexBox mrgnBtm="3.2rem">
      <HalfBox>
        <Info className="firstLetterUpperCase">{dic.ferry_property}</Info>
        <Title>
          {obj.ferryproperty && obj.ferryproperty.length > 0
            ? obj.ferryproperty.join(', ')
            : '-'}
        </Title>
      </HalfBox>
      <HalfBox>
        <Info className="firstLetterUpperCase">{dic.cargo_total_weight}</Info>
        <Title>
          {obj.totalweight ? `${obj.totalweight} ${dic.t.toLowerCase()}` : '-'}
        </Title>
      </HalfBox>
    </FlexBox>
  </>
)

const RouteRemark = (remarkData = [],  dic, lineToTop = false) => (
  <>
    <SeparateLine />
    <Title big style={{ marginTop: '2rem', marginBottom: '2rem' }}>
      {strToUpperCase(dic.remark)}
      <Icon
        name="atencion"
        type="error"
        width="1.8rem"
        height="1.8rem"
        style={{ marginLeft: '0.8rem' }}
      />
    </Title>
    {remarkData?.length > 0 && (
        remarkData.map(({text, author})=>(
            <>
              <Info
                  style={{ marginBottom: '0.5rem' }}
                  className="firstLetterUpperCase"
                  key={text}
              >
                {author}
              </Info>
              <Title style={{ marginBottom: '3.2rem' }}>{text}</Title>
            </>
        )))}
  </>
)

const RoutePayment = (obj, dic, routestatus) => (
  <>
    <Title big style={{ marginTop: '2rem', marginBottom: '2rem' }}>
      {strToUpperCase(dic.payment)}
    </Title>
    <FlexBox mrgnBtm="2rem">
      {obj.budget && (
        <HalfBox>
          <Info className="firstLetterUpperCase">{dic.price}</Info>
          <Title>{`${NumberWithoutNulls(obj.budget)} ${
            obj.defaultcurrency
              ? currencyIcons[obj.defaultcurrency]
              : currencyIcons.uah
          }`}</Title>
        </HalfBox>
      )}
      {true && (
        <HalfBox>
          <UnitWrp style={{ marginTop: '1.65rem' }}>
            {parseUnitPrices(obj.unitPrices).map((str) => (
              <p key={str}>{str}</p>
            ))}
          </UnitWrp>
        </HalfBox>
      )}
    </FlexBox>
    <FlexBox mrgnBtm="3.2rem">
      <HalfBox>
        <Info className="firstLetterUpperCase">{dic.payment_type}</Info>
        <Title>{`${obj.paymenttype ? obj.paymenttype.title : null} ${
          obj.nds ? dic.nds_1 : dic.nds_2
        }`}</Title>
      </HalfBox>
      {obj.payorcompany && ( // дочерняя компания
        <HalfBox>
          <Info className="firstLetterUpperCase">{dic.payor}</Info>
          <Title>{obj.payorcompany.title || obj.companydata.title_ru}</Title>
        </HalfBox>
      )}
    </FlexBox>
    {(routestatus === 2 || routestatus === 3) && <SeparateLine />}
  </>
)

export const InfoBodyTop = ({
  routestatus = 0,
  driversData,
  dic,
  obj,
  userType,
}) => {

  const hasRemark = obj?.remark || driversData?.remark
  const remarkData = useMemo(()=>{
    let remarks = [];
    if(obj?.remark){
      remarks = [
        ...remarks,
        { text: obj.remark, author: obj?.companydata.title_ru }
      ]
    }
    if(driversData?.remark){
      remarks = [
        ...remarks,
        { text: driversData.remark, author: obj?.operatordata?.title_ru }
      ]
    }

    return remarks

  },[driversData, obj])

  return (
      <>
        {routestatus <= 4 && (
            <>
              {driversData && RouteDrivers(driversData, dic)}
              {(userType === 1 && driversData) ? RouteDrivers(driversData, dic) : RouteOperator(obj, dic, false)}
              {userType === 2 && RoutePayment(obj, dic, routestatus)}
              {hasRemark && RouteRemark(remarkData, dic)}
            </>
        )}
      </>
  )
}

export const InfoBodyBottom = ({
  routestatus = 0,
  driversData,
  dic,
  obj,
  userType,
}) => {
  const hasRemark = obj?.remark || driversData?.remark
  const remarkData = useMemo(()=>{
    let remarks = [];
    if(obj?.remark){
      remarks = [
          ...remarks,
        { text: obj.remark, author: obj?.companydata.title_ru }
      ]
    }
    if(driversData?.remark){
      remarks = [
        ...remarks,
        { text: driversData.remark, author: obj?.operatordata?.title_ru }
      ]
    }

    return remarks

  },[driversData, obj])

  return (
      <div style={{marginBottom: "7rem"}}>
        {routestatus <= 4 && userType === 1 && (
            <>
              {RoutePayment(obj, dic, routestatus)}
              {RouteRequirementVehicle(obj, dic)}
            </>
        )}
        {routestatus > 4 && (
            <>
              {driversData && RouteDrivers(driversData, dic)}
              {userType === 1 ? RouteClient(obj, dic) : RouteOperator(obj, dic)}
              {RoutePayment(obj, dic, routestatus)}
              {hasRemark && RouteRemark(remarkData, dic)}
              {/*<SeparateLine />*/}
            </>
        )}
      </div>
  )
}

export const ChartBody = ({ dic, obj, local = {} }) => {
  return (
    <>
      <Info mrgnBtm="0.5rem">{strToUpperCase(dic.customer)}</Info>
      <Title large mrgnBtm="2rem" style={{ fontWeight: '500' }}>
        {strToUpperCase(obj.companydata.title_ru)}
      </Title>
      <Info mrgnBtm="0.5rem" className="firstLetterUpperCase">
        {dic.responsible_logistician}
      </Info>
      <Title mrgnBtm="2rem">{obj.userclientdata.name}</Title>

      <Info mrgnBtm="0.5rem" className="firstLetterUpperCase">
        {dic.quantity_total}
      </Info>
      <Title mrgnBtm="2rem">{`${NumberWithoutNulls(obj.totalcount)} ${
        local[obj.pricefor]
      }`}</Title>

      <Info className="firstLetterUpperCase">{dic.price_start}</Info>
      <Title mrgnBtm="2rem">{`${NumberWithoutNulls(
        obj.maxquotewithcommission
      )} ${
        obj.defaultcurrency
          ? currencyIcons[obj.defaultcurrency]
          : currencyIcons.uah
      }`}</Title>

      <Info mrgnBtm="0.5rem" className="firstLetterUpperCase">
        {dic.date_time_start}
      </Info>
      <Title mrgnBtm="2rem">?????????</Title>
      <Info mrgnBtm="0.5rem" className="firstLetterUpperCase">
        {dic.date_time_finish}
      </Info>
      <Title mrgnBtm="2rem">???????</Title>
    </>
  )
}

const BidsItem = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  padding: 1.8rem 1.2rem 2rem 1rem;
  background-color: ${(p) => p.theme.substrate};
`

const BidsFlexWrp = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const BidsFlexItem = styled.p`
  width: 33.33%;
  text-align: ${(p) => p.align};
  ${(p) =>
    p.color &&
    `
  color:${p.color};
  `};
`
const BidsFlexValue = styled(BidsFlexItem)`
  font-size: ${(p) => p.theme.h3};
  color: ${(p) => p.theme.regular_txt};
  text-align: right;
  font-weight: 600;
`

export const ChartOffers = ({ data = {}, bids = [], dic = {}, local = {} }) => {
  return (
    <>
      <Title
        big
        mrgnBtm="2rem"
        style={{ marginTop: '3rem' }}
      >{`${strToUpperCase(dic.offers)}: ${bids.length}`}</Title>
      {bids.length > 0 &&
        bids.map((obj) => (
          <BidsItem key={obj.moment}>
            <Title large mrgnBtm="0.8rem" style={{ fontWeight: '500' }}>
              {strToUpperCase('?????????')}
            </Title>
            <BidsFlexWrp>
              <BidsFlexItem align="left" color="additional">
                {parseWholeDate(obj.moment, dic, true)}
              </BidsFlexItem>
              <BidsFlexItem align="center" fs="h5">{`${NumberWithoutNulls(
                obj.loadqoute
              )} ${local[obj.pricefor]}`}</BidsFlexItem>
              <BidsFlexValue>{`${NumberWithoutNulls(obj.pricequote)} ${
                data.defaultcurrency
                  ? currencyIcons[data.defaultcurrency]
                  : currencyIcons.uah
              }`}</BidsFlexValue>
            </BidsFlexWrp>
          </BidsItem>
        ))}
      <Title
        big
        mrgnBtm="2rem"
        style={{ marginTop: '3rem' }}
      >{`${strToUpperCase(dic.tender_result)}:`}</Title>
      <BidsItem>
        <Title large mrgnBtm="0.8rem" style={{ fontWeight: '500' }}>
          {strToUpperCase('?????????')}
        </Title>
        <BidsFlexWrp>
          <BidsFlexItem align="left" color="additional">
            {parseWholeDate(bids[0].moment, dic, true)}
          </BidsFlexItem>
          <BidsFlexItem align="center" fs="h5">{`${NumberWithoutNulls(
            bids[0].loadqoute
          )} ${local[bids[0].pricefor]}`}</BidsFlexItem>
          <BidsFlexValue>{`${NumberWithoutNulls(bids[0].pricequote)} ${
            data.defaultcurrency
              ? currencyIcons[bids[0].defaultcurrency]
              : currencyIcons.uah
          }`}</BidsFlexValue>
        </BidsFlexWrp>
      </BidsItem>
      <Title
        big
        mrgnBtm="0.5rem"
        style={{ marginTop: '3rem' }}
      >{`${strToUpperCase('директор')}:`}</Title>
      <Title large mrgnBtm="3rem" style={{ fontWeight: '500' }}>
        {strToUpperCase('?????????')}
      </Title>
    </>
  )
}
