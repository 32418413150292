import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import {
  ChartBody,
  ChartOffers,
  Title,
} from '../../smallPartsForInterface/SmallPartsCard'
import { strToUpperCase } from '../../../lib/helpers/forStyling'
import useDataStore from '../../../hooks/useDataStore'

const Wrapper = styled.div`
  width: 100%;
  padding-top: 1.5rem;
`

export default () => {
  // const profile = useSelector((s) => s.user.profile)
  const cardTender = useSelector((s) => s.tender.cardTender)
  const offers = useSelector((s) => s.tender.allBids)
  const { DIC } = useDataStore()

  const local = {
    mcube: DIC.mcube,
    tonn: DIC.t,
    km: DIC.km,
    ride: DIC.flightAccusative,
  }

  const isHaveData = () => {
    const result =
      cardTender && Object.keys(cardTender).length > 0 ? true : false
    return result
  }

  return (
      <Wrapper data-testid="tenderChartList">
        <Title big mrgnBtm="2.6rem" style={{ marginRight: 'auto' }}>
          {strToUpperCase(DIC.tender_result)}
        </Title>
        {isHaveData() && (
          <>
            <ChartBody obj={cardTender} dic={DIC} local={local} />
            <ChartOffers
              data={cardTender}
              bids={offers}
              dic={DIC}
              local={local}
            />
          </>
        )}
      </Wrapper>
  )
}
