import React from 'react'
import styled from 'styled-components'
import useRouter from '../../hooks/useRouter'

const Wrapper = styled.a`
  width: fit-content;
  text-decoration: none;
  padding-bottom: 0.2rem;
  font-size: ${(p) => (p.bold ? p.theme.h3 : p.theme.h4)};
  font-weight: ${(p) => (p.bold ? '600' : '400')};
  transition: 0.2s ease;
  cursor: pointer;
  :link {
    color: ${(p) => p.theme.links};
    ${(p) =>
      !p.withoutLine &&
      `
  border-bottom: 0.1rem dashed ${p.theme.links};
  `}
  }
  :active {
    color: ${(p) => p.theme.regular_txt};
    ${(p) =>
      !p.withoutLine &&
      `
  border-bottom: 0.1rem dashed ${p.theme.regular_txt};
  `}
  }
  :visited {
    color: ${(p) => p.theme.mint_Visited_Links};
    ${(p) =>
      !p.withoutLine &&
      `
  border-bottom: 0.1rem dashed ${p.theme.mint_Visited_Links};
  `}
  }
  :first-letter {
    text-transform: uppercase;
  }
`
const NavLink = styled.div`
  width: fit-content;
  padding-bottom: 0.2rem;
  font-size: ${(p) => (p.bold ? p.theme.h3 : p.theme.h4)};
  font-weight: ${(p) => (p.bold ? '600' : '400')};
  transition: 0.2s ease;
  color: ${(p) => p.theme.links};
  ${(p) =>
    !p.withoutLine &&
    `
  border-bottom: 0.1rem dashed ${p.theme.links};
  `}
  cursor: pointer;
  :active {
    color: ${(p) => p.theme.regular_txt};
    border-bottom: 0.1rem dashed ${(p) => p.theme.regular_txt};
  }
  :visited {
    color: ${(p) => p.theme.mint_Visited_Links};
    border-bottom: 0.1rem dashed ${(p) => p.theme.mint_Visited_Links};
  }
  :first-letter {
    text-transform: uppercase;
  }
`
export default ({
  title = 'Dotted link',
  rout = '/',
  path = null,
  bold = 0,
  style,
  disabled = false,
  withoutLine = false,
}) => {
  const router = useRouter()

  const redirect = () => {
    router.push(path)
  }
  return Boolean(path) ? (
    <NavLink
      onClick={disabled ? null : redirect}
      bold={bold}
      style={style}
      withoutLine={withoutLine}
    >
      {title}
    </NavLink>
  ) : (
    <Wrapper href={rout} bold={bold} style={style} withoutLine={withoutLine}>
      {title}
    </Wrapper>
  )
}
