import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import BaseBtn from '../../components/buttons/BaseBtn'
import SelectDateBtn from '../../components/buttons/SelectDateBtn'
import SelctTimeBtn from '../../components/buttons/SelectTimeBtn'

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 2.5rem;
  background-color: inherit;
`
const Title = styled.p`
  margin-bottom: 0.5rem;
  color: ${(p) => p.theme.regular_txt};
  font-size: ${(p) => p.theme.h5};
  opacity: 0.8;
  :first-letter {
    text-transform: uppercase;
  }
`
const InputWrp = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export default ({
  titleBtn = 'Send my step',
  periodic = null,
  idRoutePart = 'undefined',
  date = '',
  time = '',
  style = null,
}) => {
  const dispatch = useDispatch()
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const [newDate, setNewDate] = useState(
    date === '0000-00-00' ? new Date() : new Date(date)
  )
  const [newTime, setNewTime] = useState(time)

  const saveNewDate = (value) => {
    setNewDate(value)
  }
  const saveNewTime = (value) => {
    setNewTime(value)
  }
  const onSubmitForm = () => {
    const data = {
      route: periodic,
      routepartsDates: {
        [idRoutePart]: moment(newDate).format('YYYY-MM-DD'),
      },
      routepartsTimes: {
        [idRoutePart]: newTime,
      },
    }
    dispatch({ type: 'START_REQUEST', payload: `${periodic}top` })
    dispatch({ type: 'SAGA_SUBSCRIBE_TO_EXECUTION', data })
  }
  return (
    <Wrapper style={style}>
      <Title>{dic.msg_18}</Title>
      <InputWrp>
        <SelectDateBtn
          label=""
          value={newDate}
          fnClick={saveNewDate}
          width="48%"
          disabledPeriod="past"
        />
        <SelctTimeBtn
          label=""
          value={newTime}
          fnClick={saveNewTime}
          width="48%"
        />
      </InputWrp>
      <BaseBtn
        fullSize
        label={titleBtn}
        fnClick={onSubmitForm}
        localId={`${periodic}top`}
      />
    </Wrapper>
  )
}
