import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import Icon from '../Icon'
import useOutsideClick from '../../hooks/useOutsideClick'

const Wrapper = styled.div`
  position: relative;
  margin-top: 0.5rem;
  margin-left: 1rem;
`
const Message = styled.p`
  z-index: 10;
  position: absolute;
  top: 106%;
  left: -4rem;
  width: 25rem;
  padding: 1.6rem 1.6rem 2.6rem;
  background-color: ${(p) => p.theme.opposite};
  border: 0.1rem solid ${(p) => p.theme.lines};
  border-radius: ${(p) => p.theme.PopUpRadius};
  box-shadow: ${(p) => p.theme.listShadow};
  line-height: 135%;
  /* text-align: justify; */
`

export default ({ msg = '' }) => {
  const myRef = useRef()
  const [isMsg, setIsMsg] = useState(false)
  const closeList = () => {
    setIsMsg(false)
  }
  const toogler = () => {
    setIsMsg(!isMsg)
  }
  useOutsideClick(myRef, closeList)
  return (
    <Wrapper onClick={toogler} ref={myRef}>
      <Icon
        name="prompt"
        type="trigger"
        width="1.6rem"
        height="1.6rem"
        // style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
      />
      {isMsg && <Message>{msg}</Message>}
    </Wrapper>
  )
}
