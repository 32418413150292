import { takeLatest, put, select, call, race, delay } from 'redux-saga/effects' //select, call,
import { ratifyOperator } from '../../../lib/requests/clientRouteAction'
import { delays } from '../../../lib/constants'
import {
  getCurrentLang,
  getToken,
  routeFlagList,
  getDicionary,
} from '../../selectors'

export function* worker({ route }) {
  try {
    const currentLang = yield select(getCurrentLang)
    const token = yield select(getToken)
    const flagList = yield select(routeFlagList)
    const dic = yield select(getDicionary)

    const { result } = yield race({
      result: call(ratifyOperator, token, currentLang, route),
      timeout: delay(delays.forCancelRequest),
    })

    if (result && result.status === 200) {
      yield put({ type: 'SAGA_GET_SINGLE_ROUTE', periodic: route })
      yield put({
        type: 'UPDATE_ROUTES_FLAG_LIST',
        payload: { ...flagList, top: true },
      })

      yield put({ type: 'CLOSE_ERROR_API' })
    } else {
      yield put({
        type: 'SHOW_ERROR_API',
        code: 6,
        message: dic.msg_server_err || 'internal server error',
      })
    }
  } catch (e) {
    yield put({
      type: 'SHOW_ERROR_API',
      code: String(e.response.status).slice(0, 1),
      message: e.response ? e.response.data.message : e.message,
    })
    if (e.response && e.response.data.relogin) {
      yield put({ type: 'SAGA_LOGOUT' })
    }
  }
}

export default function* postSubscribeToExecution() {
  yield takeLatest('SAGA_RATIFY_OPERATOR', worker)
}
