export const baseBG = {
  opposite: '#FFFFFF',
  opposite2: '#FFFFFF',
  basic: '#F6E924', //#FFF44F
  basic_low: '#FFFBBB',
  system_button: '#F2F2F2',
  selected: '#F1F4FB',
  substrate: '#F9F9FA',
  flat_headers: '#19213C',
  trigger: '#0067D6',
}
export const baseColors = {
  regular_txt: '#19213C',
  basic_txt: '#F6E924', //#FFF44F
  opposite_txt: '#FFFFFF',
  links: '#3E5190',
  trigger_txt: '#0067D6',
  additional: '#707DA4',
  additional2: '#A6AEC6',
}
export const extraBaseBG = {
  error: '#F20149',
  lightError: '#FEE3EB',
  focus: '#FFC700',
  approver: '#00C880',
  mint_Visited_Links: '#623CD0',
  elements: '#495375',
  icons: '#3E5190',
  elements_low: '#5C6B9D',
  inputBorder: '#959FD1',
  stroke: '#C2CCE3',
  lines: '#E9EDFA',
  routeNumb: '#8D9BCA',
  currentPoint: '#FEFDE9',
  filtred: '#DAE6F6',
}
export const ShdwBase = {
  boxShadow: '0px 5px 10px 0px rgba(163,186,217,0.25)',
  listShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
  hotKeyShadow: 'inset 0px 4px 46px rgba(0, 0, 0, 0.08)',
  activeBtnStyle: 'inset 0px 12px 40px rgba(25, 33, 60, 0.06)',
  popupShadow: '0px 10px 13px 8px rgba(0, 0, 0, 0.1)',
  bubbleShadow: '0px 4px 12px 8px rgba(0, 0, 0, 0.12)',
  partShadow:
    '-2px -1px 4px rgba(0, 0, 0, 0.02), 1px 1px 16px 6px rgba(0, 0, 0, 0.04)',
  formGradient:
    'radial-gradient(798.21% 198.71% at 50.73% -2.59%, #FFFFFF 0%, #FFFFFF 20.3%, #EEF8FE 45.11%, #DAF3FF 80.73%, #CEEAF8 100%)',
  modalGradient: 'rgba(0, 0, 0, 0.56)',
  truckSelectGadient:
    'linear-gradient(180deg, #A4EB91 0%, #B2F4A0 23.44%, #B4F5A3 67.71%, #AAEF98 89.58%)',
}
export const darkBG = {
  opposite: '#000000',
  opposite2: '#15181C',
  basic: '#F6E924',
  basic_low: '#373C4A',
  system_button: '#5D5D5D',
  selected: '#424254',
  substrate: '#17181B',
  flat_headers: '#FFFFFF',
  trigger: '#47B2FF',
}
export const darkColors = {
  regular_txt: '#FFFFFF',
  basic_txt: '#F6E924',
  opposite_txt: '#000000',
  links: '#577EFF',
  trigger_txt: '#47B2FF',
  additional: '#96ABEA',
  additional2: '#A6AEC6',
}
export const extraDarkBG = {
  error: '#F20149',
  lightError: '#FEE3EB',
  focus: '#FFC700',
  approver: '#00C880',
  mint_Visited_Links: '#623CD0',
  elements: '#495375',
  icons: '#3E5190',
  elements_low: '#5C6B9D',
  inputBorder: '#959FD1',
  stroke: '#C2CCE3',
  lines: '#E9EDFA',
  routeNumb: '#8D9BCA',
  currentPoint: '#FEFDE9',
  filtred: '#DAE6F6',
}
export const ShdwDark = {
  boxShadow: '0px 5px 10px 0px rgba(163,186,217,0.25)',
  listShadow: '',
  hotKeyShadow: 'inset 0px 12px 40px rgba(25, 33, 60, 0.06)',
  activeBtnStyle: 'inset 0px 19px 26px 13px rgba(0, 0, 0, 0.25)',
  popupShadow: '',
  bubbleShadow: '0px 4px 9px 9px rgba(255, 255, 255, 0.12)',
  partShadow:
    '-2px -1px 4px rgba(0, 0, 0, 0.02), 1px 1px 16px 6px rgba(0, 0, 0, 0.04)',
  formGradient:
    'linear-gradient(180deg, rgba(55, 60, 74, 0) 0%, #7E859A 0.01%, #1B2038 25.52%, #18162E 100%)',
  modalGradient: 'rgba(0, 0, 0, 0.56)',
  truckSelectGadient:
    'linear-gradient(180deg, #A4EB91 0%, #B2F4A0 23.44%, #B4F5A3 67.71%, #AAEF98 89.58%)',
}

export const FS = {
  h1: '3.2rem',
  h2: '2.5rem',
  headerTitle: '2rem',
  h3: '1.8rem',
  h4: '1.6rem',
  h5: '1.4rem',
  h6: '1.2rem',
  h7: '1rem',
  h8: '0.8rem',
}
export const other = {
  PopUpRadius: '1rem',
  formSidePad: '1rem',
  basePad: '1.6rem',
  chatMsgRadius: '1.4rem',
  widgetRadius: '1.6rem',
  itemRadius: '1.4rem',
}

export default {
  baseBG,
  extraBaseBG,
  baseColors,
  darkBG,
  extraDarkBG,
  darkColors,
  FS,
  ShdwBase,
  ShdwDark,
  other,
}
