import Type from '../types'

const initialState = {
  isLoad: false,
  btnId: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.START_REQUEST:
      return {
        ...state,
        isLoad: true,
        btnId: action.payload,
      }
    case Type.CLOSE_ERROR_API:
      return {
        ...state,
        isLoad: false,
        btnId: null,
      }
    default:
      return state
  }
}
