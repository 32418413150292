import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import Icon from '../../../components/Icon'
import DoNotShow from '../../../components/InterfaceForCarrier/forRoutes/forKebab/DoNotShow'
import AwaitApproval from '../../../components/InterfaceForCarrier/forRoutes/forKebab/AwaitApproval'
import EnRoute from '../../../components/InterfaceForCarrier/forRoutes/forKebab/EnRoute'
const Wrapper = styled.div`
  z-index: 20;
  position: absolute;
  top: 7.2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  min-height: 10rem;
  padding: 2.4rem 2.4rem 1rem;
  border: 0.1rem ${(p) => p.theme.system_button};
  border-radius: ${(p) => p.theme.PopUpRadius};
  background-color: ${(p) => p.theme.opposite2};
  box-shadow: ${(p) => p.theme.bubbleShadow};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export default ({ routestatus = 2, onClose = () => {}, dic }) => {
  const [isClick, setIsClick] = useState(false)
  const onAction = (action, data) => {
    //TODO switch for value to server
    setIsClick(true)
    setTimeout(
      () => {
        onClose()
      },
      2000,
      true
    )
  }
  const component = {
    2: (
      <DoNotShow
        onAction={onAction}
        title={dic.badConditionTitle}
        afterActionText={dic.successTitle}
        isClick={isClick}
        dic={dic}
      />
    ),
    3: (
      <AwaitApproval
        onAction={onAction}
        list={[
          { title: dic.refuseRoute, action: 'refuseRoute' },
          { title: dic.changeDates, action: 'changeDates' },
          { title: dic.changeTransport, action: 'changeTransport' },
        ]}
        afterActionText={dic.successTitle}
        isClick={isClick}
        dic={dic}
      />
    ),
    4: (
      <AwaitApproval
        onAction={onAction}
        list={[
          { title: dic.refuseRoute, action: 'refuseRoute' },
          { title: dic.changeDates, action: 'changeDates' },
          { title: dic.changeTransport, action: 'changeTransport' },
        ]}
        afterActionText={dic.successTitle}
        dic={dic}
        isClick={isClick}
      />
    ),
    5: (
      <EnRoute
        onAction={onAction}
        list={[
          { title: dic.deleteRoute, action: 'deleteRoute' },
          { title: dic.changeRoute, action: 'changeRoute' },
          { title: dic.changeTransport, action: 'changeTransport' },
        ]}
        afterActionText={dic.successTitle}
        dic={dic}
        isClick={isClick}
      />
    ),
    6: (
      <EnRoute
        onAction={onAction}
        list={[
          { title: dic.deleteRoute, action: 'deleteRoute' },
          { title: dic.changeRoute, action: 'changeRoute' },
          { title: dic.changeTransport, action: 'changeTransport' },
        ]}
        afterActionText={dic.successTitle}
        dic={dic}
        isClick={isClick}
      />
    ),
    7: (
      <EnRoute
        onAction={onAction}
        list={[
          { title: dic.deleteRoute, action: 'deleteRoute' },
          { title: dic.changeRoute, action: 'changeRoute' },
          { title: dic.changeTransport, action: 'changeTransport' },
        ]}
        afterActionText={dic.successTitle}
        dic={dic}
        isClick={isClick}
      />
    ),
    8: (
      <EnRoute
        onAction={onAction}
        list={[
          { title: dic.deleteRoute, action: 'deleteRoute' },
          { title: dic.changeRoute, action: 'changeRoute' },
          { title: dic.changeTransport, action: 'changeTransport' },
        ]}
        dic={dic}
        afterActionText={dic.successTitle}
        isClick={isClick}
      />
    ),
    9: (
      <EnRoute
        onAction={onAction}
        list={[
          { title: dic.deleteRoute, action: 'deleteRoute' },
          { title: dic.changeRoute, action: 'changeRoute' },
          { title: dic.changeTransport, action: 'changeTransport' },
        ]}
        afterActionText={dic.successTitle}
        dic={dic}
        isClick={isClick}
      />
    ),
    10: (
      <EnRoute
        onAction={onAction}
        list={[
          { title: dic.deleteRoute, action: 'deleteRoute' },
          { title: dic.changeRoute, action: 'changeRoute' },
          { title: dic.changeTransport, action: 'changeTransport' },
        ]}
        dic={dic}
        afterActionText={dic.successTitle}
        isClick={isClick}
      />
    ),
    11: (
      <EnRoute
        onAction={onAction}
        list={[
          { title: dic.deleteRoute, action: 'deleteRoute' },
          { title: dic.changeRoute, action: 'changeRoute' },
          { title: dic.changeTransport, action: 'changeTransport' },
        ]}
        afterActionText={dic.successTitle}
        dic={dic}
        isClick={isClick}
      />
    ),
    12: (
      <EnRoute
        onAction={onAction}
        list={[
          { title: dic.deleteRoute, action: 'deleteRoute' },
          { title: dic.changeRoute, action: 'changeRoute' },
          { title: dic.changeTransport, action: 'changeTransport' },
        ]}
        afterActionText={dic.successTitle}
        dic={dic}
        isClick={isClick}
      />
    ),
    13: (
      <EnRoute
        onAction={onAction}
        list={[
          { title: dic.deleteRoute, action: 'deleteRoute' },
          { title: dic.changeRoute, action: 'changeRoute' },
          { title: dic.changeTransport, action: 'changeTransport' },
        ]}
        afterActionText={dic.successTitle}
        dic={dic}
        isClick={isClick}
      />
    ),
    15: (
      <EnRoute
        onAction={onAction}
        list={[
          { title: dic.deleteRoute, action: 'deleteRoute' },
          { title: dic.changeRoute, action: 'changeRoute' },
          { title: dic.changeTransport, action: 'changeTransport' },
        ]}
        afterActionText={dic.successTitle}
        dic={dic}
        isClick={isClick}
      />
    ),
    16: (
      <EnRoute
        onAction={onAction}
        list={[
          { title: dic.deleteRoute, action: 'deleteRoute' },
          { title: dic.changeRoute, action: 'changeRoute' },
          { title: dic.changeTransport, action: 'changeTransport' },
        ]}
        afterActionText={dic.successTitle}
        dic={dic}
        isClick={isClick}
      />
    ),
    17: (
      <EnRoute
        onAction={onAction}
        list={[
          { title: dic.deleteRoute, action: 'deleteRoute' },
          { title: dic.changeRoute, action: 'changeRoute' },
          { title: dic.changeTransport, action: 'changeTransport' },
        ]}
        afterActionText={dic.successTitle}
        dic={dic}
        isClick={isClick}
      />
    ),
  }
  return (
    <Wrapper>
      <Icon
        name="close"
        type="regularText"
        style={{ marginLeft: 'auto' }}
        onClick={onClose}
      />
      {component[routestatus]}
    </Wrapper>
  )
}
