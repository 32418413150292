import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  top: ${(p) => p.top};
  ${(p) => p.side};
  min-width: 1.6rem;
  min-height: 1.6rem;
  padding: 0.2rem 0.3rem;
  border-radius: 2rem;
  background-color: ${(p) => p.theme.basic};
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-weight: 800;
    font-size: ${(p) => p.theme.h8};
    color: ${(p) => p.theme.regular_txt};
  }
`

export default ({
  total = 1,
  side = 'right',
  top = '0',
  id = 'popupQuantityId',
}) => {
  const sidePosition = { right: 'right: -1.2rem;', left: 'left: -1rem;' }
  return (
    <Wrapper side={sidePosition[side]} top={top} id={id}>
      <p>{total}</p>
    </Wrapper>
  )
}
