import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import shortId from 'shortid'
import useDataStore from '../../../hooks/useDataStore'
import ThinBtn from '../../../components/buttons/ThinBtn'
import BaseInput from '../../../components/inputs/BaseInput'
import BaseBtn from '../../../components/buttons/BaseBtn'
import SelectInput from '../../selects/SelectInput'
import BaseRadioInput from '../../../components/inputs/BaseRadioInput'
import ModalPortal from '../../../components/layouts/ModalPortal'
import Checklist from '../../../components/popUps/Checklist'
import HiddenTextarea from '../../../components/inputs/HiddenTextarea'
import BtnLikeLink from '../../../components/buttons/BtnLikeLink'
import DateSelect from '../../../components/buttons/SelectDateBtn'
import TimeSelect from '../../../components/buttons/SelectTimeBtn'
import InfoMsgBubble from '../../../components/support/InfoMsgBubble'
import {
  CargoRoutePartView,
  TextRoutePartView,
  FlexWrp,
  TitleRoutePartEdit,
  HalfBox,
  Container,
} from './SmallComponentsForNewRouteForm'
import { strToUpperCase } from '../../../lib/helpers/forStyling'
import { parseDateOfLocal } from '../../../lib/helpers/forRoutes'

export default ({ inEditor = '', currentStep = 0, onEditPart = () => {} }) => {
  const dispatch = useDispatch()
  const list = useSelector((s) => s.selectList.searchList)
  const [dataForDispatch] = useState({
    type: 'SAGA_GET_NEWROUTE_PAYMENT_DATA',
    clear: 'CLEAR_SELECTLIST',
    loader: 'selectList',
  })
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const settings = useSelector((s) => s.newRoute.settings)

  const { MONTH_SHORT } = useDataStore()

  const tenderIsNow = useSelector((s) => s.newRoute.tenderIsNow)
  const tenderFinishDate = useSelector(
    (s) => s.newRoute.tenderavailableuntilmoment
  )
  const tenderFinishTime = useSelector(
    (s) => s.newRoute.tenderavailableuntilmoment_time
  )

  const tenderStartDate = useSelector((s) => s.newRoute.plannedTenderDate)
  const tenderStartTime = useSelector((s) => s.newRoute.plannedTenderTime)
  const list2 = useSelector((s) => s.checkList.list)
  const currency = useSelector((s) => s.newRoute.currency)
  const nds = useSelector((s) => s.newRoute.nds)
  const codeApi = useSelector((s) => s.newRoute.codeApi)
  const currentNds = useSelector((s) => s.newRoute.currentNds)
  const totalcount = useSelector((s) => s.newRoute.totalcount)
  const priceFor = useSelector((s) => s.newRoute.priceFor)
  const paymentType = useSelector((s) => s.newRoute.paymentType)
  const payorcompany = useSelector((s) => s.newRoute.payorcompany)
  const routePrice = useSelector((s) => s.newRoute.routePrice)
  const tenderStartPrice = useSelector((s) => s.newRoute.tenderStartPrice)
  const tenderBasePrice = useSelector((s) => s.newRoute.tenderBasePrice)
  const tenderWinnerPrice = useSelector((s) => s.newRoute.tenderWinnerPrice)
  const operators = useSelector((s) => s.newRoute.operators)

  const tenderStep = useSelector((s) => s.newRoute.tenderStep)
  const tenderterms = useSelector((s) => s.newRoute.tenderterms)
  const comment = useSelector((s) => s.newRoute.remark)
  const isTender = useSelector((s) => s.newRoute.istender)
  const tenderIsBlind = useSelector((s) => s.newRoute.tenderIsBlind)
  const currentTenderType = useSelector((s) => s.newRoute.tendertype)

  const [isModal, setIsModal] = useState(false)
  const [isShowComment, setIsShowComment] = useState(false)
  const [isShowTenderterms, setIsShowTenderterms] = useState(false)
  const [notNumErr, setNotNumErr] = useState({
    price: 0,
    tenderStep: 0,
    routePrice: 0,
    tenderStartPrice: 0,
    tenderBasePrice: 0,
    tenderWinnerPrice: 0,
    totalcount: 0,
  })

  const uniq = {
    codeApi: shortId.generate(),
    price: shortId.generate(),
    totalcount: shortId.generate(),
    routePrice: shortId.generate(),
    tenderStartPrice: shortId.generate(),
    tenderBasePrice: shortId.generate(),
    tenderWinnerPrice: shortId.generate(),
    tenderStep: shortId.generate(),
    tenderterms: shortId.generate(),
    comment: shortId.generate(),
    nds: shortId.generate(),
    currency: shortId.generate(),
    priceFor: shortId.generate(),
    paymentType: shortId.generate(),
    payorcompany: shortId.generate(),
  }
  const UNIQNAME = {
    [uniq.codeApi]: 'codeApi',
    [uniq.price]: 'price',
    [uniq.totalcount]: 'totalcount',
    [uniq.routePrice]: 'routePrice',
    [uniq.tenderStartPrice]: 'tenderStartPrice',
    [uniq.tenderBasePrice]: 'tenderBasePrice',
    [uniq.tenderWinnerPrice]: 'tenderWinnerPrice',
    [uniq.tenderStep]: 'tenderStep',
    [uniq.tenderterms]: 'tenderterms',
    [uniq.comment]: 'comment',
    [uniq.nds]: 'nds',
    [uniq.currency]: 'currency',
    [uniq.priceFor]: 'priceFor',
    [uniq.paymentType]: 'paymentType',
    [uniq.payorcompany]: 'payorcompany',
  }

  const onChangeListOperators = (id) => {
    let action = 'minus'
    dispatch({
      type: 'SET_CHEKLIST',
      payload: list2.map((obj) => {
        if (obj.id === id) {
          action = obj.checked ? 'minus' : 'plus'
          return { ...obj, checked: !obj.checked }
        }
        return obj
      }),
    })
    dispatch({
      type: 'UPDATE_NEWROUTE_OPERATORS',
      payload:
        action === 'plus'
          ? [...operators, id]
          : [...operators.filter((str) => str !== id)],
    })
  }

  const togglerModal = () => {
    setIsModal(!isModal)
  }

  const togglerTenderType = (val) => {
    dispatch({ type: 'UPDATE_NEWROUTE_TENDERTYPE', payload: val })
  }

  const togglerTenderStartDate = () => {
    dispatch({ type: 'UPDATE_NEWROUTE_TENDERISNOW', payload: !tenderIsNow })
  }

  const togglerTenderIsBlind = (id, obj) => {
    dispatch({
      type: 'UPDATE_NEWROUTE_TENDERISBLIND',
      payload: Boolean(obj.id),
    })
  }

  const togglerComponent = () => {
    dispatch({ type: 'UPDATE_NEWROUTE_ISTENDER', payload: !isTender })
  }
  const togglerTenderterms = () => {
    setIsShowTenderterms(!isShowTenderterms)
  }

  const togglerComment = () => {
    setIsShowComment(!isShowComment)
  }
  const setNotnumErrorFn = (name) => {
    const defaultVal = {
      price: 0,
      tenderStep: 0,
      routePrice: 0,
      tenderStartPrice: 0,
      tenderBasePrice: 0,
      tenderWinnerPrice: 0,
      totalcount: 0,
    }
    setNotNumErr({ ...defaultVal, [name]: 1 })
    setTimeout(() => setNotNumErr({ ...defaultVal, [name]: 0 }), 1000)
  }

  const onChangeInputs = (e) => {
    const ID = UNIQNAME[e.target.name]
    const VALUE = e.target.value

    const actions = {
      comment: 'UPDATE_NEWROUTE_COMMENT',
      tenderterms: 'UPDATE_NEWROUTE_TENDERTERMS',
      tenderStep: 'UPDATE_NEWROUTE_TENDER_STEP',
      routePrice: 'UPDATE_NEWROUTE_TENDER_ROUTEPRICE',
      tenderStartPrice: 'UPDATE_NEWROUTE_TENDER_STARTPRICE',
      tenderBasePrice: 'UPDATE_NEWROUTE_TENDER_BASEPRICE',
      tenderWinnerPrice: 'UPDATE_NEWROUTE_TENDER_WINNERPRICE',
      totalcount: 'UPDATE_NEWROUTE_TENDER_TOTALCOUNT',
      codeApi: 'UPDATE_NEWROUTE_CODEAPI',
    }

    if (
      ID !== 'price' &&
      ID !== 'tenderStep' &&
      ID !== 'routePrice' &&
      ID !== 'tenderStartPrice' &&
      ID !== 'tenderBasePrice' &&
      ID !== 'tenderWinnerPrice' &&
      ID !== 'totalcount'
    ) {
      return dispatch({ type: actions[ID], payload: VALUE })
    } else {
      if (!Number(VALUE) && VALUE.length > 0) {
        return setNotnumErrorFn(ID)
      }
      return dispatch({ type: actions[ID], payload: VALUE })
    }
  }

  const fnFormValue = (whereUpdate, obj) => {
    const ID = UNIQNAME[whereUpdate]

    const actions = {
      nds: 'UPDATE_NEWROUTE_NDS',
      currency: 'UPDATE_NEWROUTE_CURRENCY',
      priceFor: 'UPDATE_NEWROUTE_PRICEFOR',
      paymentType: 'UPDATE_NEWROUTE_PAYMENTTYPE',
      payorcompany: 'UPDATE_NEWROUTE_PAYORDER_COMPANY',
    }
    dispatch({ type: actions[ID], payload: obj })
    dispatch({ type: 'CLEAR_SELECTLIST' })
  }

  const onUpdateDates = (value, id) => {
    let parseVal = ''
    if (typeof value === 'object') {
      parseVal = value.toISOString()
    } else {
      parseVal = value
    }

    const actions = {
      startDate: 'UPDATE_NEWROUTE_TENDER_START_DATE',
      startTime: 'UPDATE_NEWROUTE_TENDER_START_TIME',
      finishDate: 'UPDATE_NEWROUTE_TENDER_FINISH_DATE',
      finishTime: 'UPDATE_NEWROUTE_TENDER_FINISH_TIME',
    }
    dispatch({ type: actions[id], payload: parseVal })
  }

  const onGetPartners = () => {
    dispatch({ type: 'SAGA_GET_PARTNERS', prewData: [...list2] })
    togglerModal()
  }

  const component = {
    false: (
      <>
        <FlexWrp style={{ marginBottom: '1.4rem' }}>
          <BaseInput
            style={{ width: '60%' }}
            label={true ? `${dic.sum || ''}*` : dic.sum || ''}
            fnChange={onChangeInputs}
            name={uniq.routePrice}
            value={routePrice}
            error={notNumErr.routePrice}
            message={dic.form_err_onlyNumbrers}
          />
          <SelectInput
            name={uniq.currency}
            id={uniq.currency}
            style={{ width: '35%' }}
            label={dic.currency}
            value={currency.union}
            fnFormValue={fnFormValue}
            action={{ ...dataForDispatch, payload: 'currency' }}
            list={list}
            nothingToDisplay={dic.info_1_1}
          />
        </FlexWrp>
        <SelectInput
          style={{ width: '50%', marginBottom: '4.8rem' }}
          label={dic.per}
          name={uniq.priceFor}
          id={uniq.priceFor}
          value={priceFor.union}
          fnFormValue={fnFormValue}
          action={{ ...dataForDispatch, payload: 'priceFor' }}
          list={list}
          nothingToDisplay={dic.info_1_1}
        />
      </>
    ),
    true: (
      <>
        <FlexWrp style={{ marginBottom: '2.4rem' }}>
          <HalfBox width="50%">
            <BaseRadioInput
              label={dic.spend_now}
              currentValue={tenderIsNow}
              fnChange={togglerTenderStartDate}
              likeTab
            />
          </HalfBox>
          <HalfBox width="50%">
            <BaseRadioInput
              label={dic.schedule}
              currentValue={!tenderIsNow}
              fnChange={togglerTenderStartDate}
              likeTab
            />
          </HalfBox>
        </FlexWrp>
        {!tenderIsNow && (
          <>
            <CargoRoutePartView style={{ marginBottom: '4.4rem' }}>
              {strToUpperCase(dic.msg_37)}
            </CargoRoutePartView>
            <FlexWrp style={{ marginBottom: '2.4rem' }}>
              <HalfBox width="50%">
                <DateSelect
                  // style={{ width: '100%' }}
                  label={
                    true ? `${dic.offers_from || ''}*` : dic.offers_from || ''
                  }
                  value={tenderStartDate}
                  fnClick={onUpdateDates}
                  index="startDate"
                  disabledPeriod="past"
                  // error={
                  //   errors[index] && errors[index].date
                  //     ? errors[index].date.bool
                  //     : null
                  // }
                />
              </HalfBox>
              <HalfBox width="50%">
                <TimeSelect
                  // style={{ width: time.mode === 2 ? '80%' : '95%' }}
                  label=""
                  // disabled={time.mode === 3}
                  value={tenderStartTime}
                  index="startTime"
                  fnClick={onUpdateDates}
                />
              </HalfBox>
            </FlexWrp>
          </>
        )}
        <FlexWrp style={{ marginBottom: '2.4rem' }}>
          <HalfBox width="40%">
            <BaseRadioInput
              label={dic.open}
              currentValue={currentTenderType === 0}
              fnChange={() => togglerTenderType(0)}
            />
          </HalfBox>
          {currentTenderType === 0 && (
            <HalfBox width="60%">
              <CargoRoutePartView>
                {strToUpperCase(dic.msg_36)}
              </CargoRoutePartView>
            </HalfBox>
          )}
        </FlexWrp>
        <FlexWrp style={{ marginBottom: '4.4rem' }}>
          <HalfBox width="40%">
            <BaseRadioInput
              label={dic.close}
              currentValue={currentTenderType === 1}
              fnChange={() => togglerTenderType(1)}
            />
          </HalfBox>
          {currentTenderType === 1 && (
            <HalfBox width="60%">
              <CargoRoutePartView>
                {strToUpperCase(dic.msg_35)}
              </CargoRoutePartView>
            </HalfBox>
          )}
        </FlexWrp>
        <FlexWrp style={{ marginBottom: '2.4rem' }}>
          <HalfBox width="50%">
            <DateSelect
              // style={{ width: '100%' }}
              label={
                true ? `${dic.offers_up_to || ''}*` : dic.offers_up_to || ''
              }
              // disabled={date.mode === 3}
              value={tenderFinishDate}
              fnClick={onUpdateDates}
              index="finishDate"
              disabledPeriod="past"
              checkDate={tenderStartDate}
              // error={
              //   errors[index] && errors[index].date
              //     ? errors[index].date.bool
              //     : null
              // }
            />
          </HalfBox>
          <HalfBox width="50%">
            <TimeSelect
              style={{ width: '100%' }}
              label=""
              // disabled={time.mode === 3}
              value={tenderFinishTime}
              index="finishTime"
              fnClick={onUpdateDates}
            />
          </HalfBox>
        </FlexWrp>
        <div style={{ height: '1.8rem' }} />
        {settings.blindTender && (
          <>
            <FlexWrp>
              <p style={{ marginRight: '0.5rem' }}>
                {strToUpperCase(dic.type_participation)}
              </p>
              <InfoMsgBubble msg={dic.msg_38} />
              <HalfBox width="50%">
                <SelectInput
                  name="type_participation"
                  id="type_participation"
                  label=""
                  value={tenderIsBlind ? dic.blind : dic.regular}
                  fnFormValue={togglerTenderIsBlind}
                  list={[
                    { union: dic.regular, id: false },
                    { union: dic.blind, id: true },
                  ]}
                  nothingToDisplay={dic.info_1_1}
                />
              </HalfBox>
            </FlexWrp>
            <div style={{ height: '1.8rem' }} />
          </>
        )}
        <FlexWrp>
          <p style={{ marginRight: '0.5rem' }}>
            {strToUpperCase(dic.offers_per)}
          </p>
          <HalfBox width="50%">
            <SelectInput
              name={uniq.priceFor}
              id={uniq.priceFor}
              label=""
              value={priceFor.union}
              fnFormValue={fnFormValue}
              action={{ ...dataForDispatch, payload: 'priceFor' }}
              list={list}
              nothingToDisplay={dic.info_1_1}
            />
          </HalfBox>
        </FlexWrp>
        <div style={{ height: '1.8rem' }} />
        <FlexWrp>
          <p style={{ marginRight: '0.5rem' }}>
            {strToUpperCase(dic.wont_to_transport)}
          </p>
          <HalfBox width="50%">
            <BaseInput
              style={{ width: '60%', marginBottom: '1.8 rem' }}
              name={uniq.totalcount}
              label=""
              value={totalcount}
              fnChange={onChangeInputs}
              error={notNumErr.totalcount}
              message={dic.form_err_onlyNumbrers}
            />
            <p style={{ marginLeft: '0.5rem' }}>{priceFor.union}</p>
          </HalfBox>
        </FlexWrp>
        <div style={{ height: '1.8rem' }} />
        <FlexWrp>
          <p style={{ marginRight: '0.5rem' }}>
            {strToUpperCase(dic.tender_currency)}
          </p>
          <HalfBox width="50%">
            <SelectInput
              name={uniq.currency}
              id={uniq.currency}
              label=""
              value={currency.union}
              fnFormValue={fnFormValue}
              action={{ ...dataForDispatch, payload: 'currency' }}
              list={list}
              nothingToDisplay={dic.info_1_1}
            />
          </HalfBox>
        </FlexWrp>
        <div style={{ height: '1.8rem' }} />
        {settings.baseprice && (
          <>
            <FlexWrp>
              <p style={{ marginRight: '0.5rem' }}>
                {strToUpperCase(dic.price_base)}
              </p>
              <HalfBox width="50%">
                <BaseInput
                  style={{ width: '60%', marginBottom: '1.8 rem' }}
                  name={uniq.tenderBasePrice}
                  label=""
                  value={tenderBasePrice}
                  fnChange={onChangeInputs}
                  error={notNumErr.tenderBasePrice}
                  message={dic.form_err_onlyNumbrers}
                />
                <p style={{ marginLeft: '0.5rem' }}>{currency.union}</p>
              </HalfBox>
            </FlexWrp>
            <div style={{ height: '1.8rem' }} />
          </>
        )}
        {!tenderIsBlind && (
          <>
            <FlexWrp>
              <p style={{ marginRight: '0.5rem' }}>
                {strToUpperCase(dic.price_start)}
              </p>
              <HalfBox width="50%">
                <BaseInput
                  style={{ width: '60%', marginBottom: '1.8 rem' }}
                  name={uniq.tenderStartPrice}
                  label=""
                  value={tenderStartPrice}
                  fnChange={onChangeInputs}
                  error={notNumErr.tenderStartPrice}
                  message={dic.form_err_onlyNumbrers}
                />
                <p style={{ marginLeft: '0.5rem' }}>{currency.union}</p>
              </HalfBox>
            </FlexWrp>
            <div style={{ height: '1.8rem' }} />

            {settings.winnerprice && (
              <>
                <FlexWrp>
                  <p style={{ marginRight: '0.5rem' }}>
                    {strToUpperCase(dic.price_winner)}
                  </p>
                  <HalfBox width="50%">
                    <BaseInput
                      style={{ width: '60%', marginBottom: '1.8 rem' }}
                      name={uniq.tenderWinnerPrice}
                      label=""
                      value={tenderWinnerPrice}
                      fnChange={onChangeInputs}
                      error={notNumErr.tenderWinnerPrice}
                      message={dic.form_err_onlyNumbrers}
                    />
                    <p style={{ marginLeft: '0.5rem' }}>{currency.union}</p>
                  </HalfBox>
                </FlexWrp>
                <div style={{ height: '1.8rem' }} />
              </>
            )}
            <FlexWrp>
              <p style={{ marginRight: '0.5rem' }}>
                {strToUpperCase(dic.step)}
              </p>
              <HalfBox width="50%">
                <BaseInput
                  style={{ width: '60%', marginBottom: '1.8 rem' }}
                  name={uniq.tenderStep}
                  label=""
                  value={tenderStep}
                  fnChange={onChangeInputs}
                  error={notNumErr.tenderStep}
                  message={dic.form_err_onlyNumbrers}
                />
                <p style={{ marginLeft: '0.5rem' }}>{currency.union}</p>
              </HalfBox>
            </FlexWrp>
          </>
        )}
        <div style={{ height: '2.4rem' }} />
        <HiddenTextarea
          labelLikeLink={`${dic.tender_terms}...`}
          fnToggler={togglerTenderterms}
          isShow={isShowTenderterms}
          fnChange={onChangeInputs}
          value={tenderterms}
          name={uniq.tenderterms}
          label=""
          style={{ marginBottom: '2.4rem' }}
        />
      </>
    ),
  }

  return (
    <Container>
      {isModal && (
        <ModalPortal>
          <Checklist
            list={list2}
            fnUpdateCheck={onChangeListOperators}
            // fnUpdateQuantity={onChangeListItemQuantity}
            fnCancel={togglerModal}
            fnSave={togglerModal}
            title={dic.carriers}
            id="wer"
          />
        </ModalPortal>
      )}
      <FlexWrp>
        <TitleRoutePartEdit style={{ paddingRight: '1rem' }}>
          {strToUpperCase(dic.payment_type_title)}
        </TitleRoutePartEdit>
        {currentStep === 4 && (
          <ThinBtn type="edit" label="" onClick={() => onEditPart('payment')} />
        )}
      </FlexWrp>
      {inEditor === 'payment' || currentStep === 3 ? (
        <>
          <SelectInput
            name={uniq.paymentType}
            id={uniq.paymentType}
            style={{ marginBottom: '2.4rem' }}
            label={dic.payment_type}
            value={paymentType.union}
            fnFormValue={fnFormValue}
            action={{ ...dataForDispatch, payload: 'paymentType' }}
            list={list}
            nothingToDisplay={dic.info_1_1}
          />
          <SelectInput
            style={{ marginBottom: '2.4rem', width: '50%' }}
            name={uniq.nds}
            id={uniq.nds}
            label={dic.nds_title_1}
            value={dic[`nds_${currentNds.id}`]}
            fnFormValue={fnFormValue}
            list={nds.map((el) => ({ id: el, union: dic[`nds_${el}`] }))}
            nothingToDisplay={dic.info_1_1}
          />
          {settings.showPayor && (
            <SelectInput
              style={{ marginBottom: '2.4rem' }}
              name={uniq.payorcompany}
              id={uniq.payorcompany}
              label={dic.payor}
              value={payorcompany.union}
              fnFormValue={fnFormValue}
              action={{ ...dataForDispatch, payload: 'payorcompany' }}
              list={list}
              nothingToDisplay={dic.info_1_1}
            />
          )}
          {settings.codeApi && (
            <BaseInput
              name={uniq.codeApi}
              label={`${dic.api_code}*`}
              value={codeApi}
              fnChange={onChangeInputs}
              error={notNumErr.tenderStartPrice}
              message={dic.form_err_onlyNumbrers}
            />
          )}
          <div style={{ marginBottom: '3.2rem' }} />
          <FlexWrp style={{ marginBottom: '2.4rem' }}>
            <HalfBox width="50%">
              <BaseRadioInput
                label={dic.price_info_1}
                id="putPrice"
                currentValue={!isTender}
                fnChange={togglerComponent}
              />
            </HalfBox>
            <HalfBox width="50%">
              <BaseRadioInput
                label={dic.tender_hold}
                iconName="hammer"
                id="announceTender"
                currentValue={isTender}
                fnChange={togglerComponent}
              />
            </HalfBox>
          </FlexWrp>
          {component[isTender]}
          <HiddenTextarea
            labelLikeLink={`${dic.route_note}...`}
            fnToggler={togglerComment}
            isShow={isShowComment}
            fnChange={onChangeInputs}
            value={comment}
            name={uniq.comment}
            label=""
            style={{
              marginBottom: settings.operatorselect ? '2.4rem' : '4rem',
            }}
          />
          {settings.operatorselect && (
            <BtnLikeLink
              firstLetter
              label={dic.carriers_choise}
              onClick={onGetPartners}
              // id = 'BtnLikeLink',
              style={{ marginBottom: '4rem' }}
            />
          )}
          {currentStep === 4 && (
            <BaseBtn
              fullSize
              label={dic.btn_aply}
              fnClick={() => onEditPart('')}
            />
          )}
        </>
      ) : (
        <>
          <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
            <CargoRoutePartView>
              {strToUpperCase(dic.payment_type)}
            </CargoRoutePartView>
            <TextRoutePartView>{paymentType.union || '-'}</TextRoutePartView>
          </FlexWrp>
          <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
            <CargoRoutePartView>
              {/* {strToUpperCase(dic.nds_title_1)} */}
            </CargoRoutePartView>
            <TextRoutePartView>
              {dic[`nds_${currentNds.id}`] || '-'}
            </TextRoutePartView>
          </FlexWrp>

          {settings.showPayor && (
            <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
              <CargoRoutePartView>
                {strToUpperCase(dic.payor)}
              </CargoRoutePartView>
              <TextRoutePartView>{payorcompany.union || '-'}</TextRoutePartView>
            </FlexWrp>
          )}
          {settings.codeApi && (
            <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
              <CargoRoutePartView>
                {strToUpperCase(dic.api_code)}
              </CargoRoutePartView>
              <TextRoutePartView>{codeApi || '-'}</TextRoutePartView>
            </FlexWrp>
          )}

          {isTender ? (
            <>
              {!tenderIsNow && (
                <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
                  <CargoRoutePartView>
                    {strToUpperCase(dic.tender_post)}
                  </CargoRoutePartView>
                  <TextRoutePartView>
                    {tenderStartDate
                      ? parseDateOfLocal(
                          tenderStartDate,
                          tenderStartTime,
                          MONTH_SHORT,
                          true
                        )
                      : '-'}
                  </TextRoutePartView>
                </FlexWrp>
              )}
              <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
                <CargoRoutePartView>
                  {strToUpperCase(dic.tender_type)}
                </CargoRoutePartView>
                <TextRoutePartView>
                  {currentTenderType ? dic.close : dic.open}
                </TextRoutePartView>
              </FlexWrp>
              {settings.blindTender && (
                <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
                  <CargoRoutePartView>
                    {strToUpperCase(dic.type_participation)}
                  </CargoRoutePartView>
                  <TextRoutePartView>
                    {tenderIsBlind ? dic.blind : dic.regular}
                  </TextRoutePartView>
                </FlexWrp>
              )}

              <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
                <CargoRoutePartView>
                  {strToUpperCase(dic.tender_currency)}
                </CargoRoutePartView>
                <TextRoutePartView>{currency.union || '-'}</TextRoutePartView>
              </FlexWrp>
              <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
                <CargoRoutePartView>
                  {strToUpperCase(dic.offers_per)}
                </CargoRoutePartView>
                <TextRoutePartView>{priceFor.union || '-'}</TextRoutePartView>
              </FlexWrp>
              <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
                <CargoRoutePartView>
                  {strToUpperCase(dic.wont_to_transport)}
                </CargoRoutePartView>
                <TextRoutePartView>
                  {totalcount ? `${totalcount} ${priceFor.union}` : '-'}
                </TextRoutePartView>
              </FlexWrp>
              <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
                <CargoRoutePartView>
                  {strToUpperCase(dic.offers_up_to)}
                </CargoRoutePartView>
                <TextRoutePartView>
                  {tenderFinishDate
                    ? parseDateOfLocal(
                        tenderFinishDate,
                        tenderFinishTime,
                        MONTH_SHORT,
                        true
                      )
                    : '-'}
                </TextRoutePartView>
              </FlexWrp>

              {settings.baseprice && (
                <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
                  <CargoRoutePartView>
                    {strToUpperCase(dic.price_base)}
                  </CargoRoutePartView>
                  <TextRoutePartView>
                    {tenderBasePrice
                      ? `${tenderBasePrice} ${currency.symbol}`
                      : '-'}
                  </TextRoutePartView>
                </FlexWrp>
              )}
              {!tenderIsBlind && (
                <>
                  <FlexWrp
                    style={{ marginBottom: '3rem', paddingRight: '1rem' }}
                  >
                    <CargoRoutePartView>
                      {strToUpperCase(dic.price_start)}
                    </CargoRoutePartView>
                    <TextRoutePartView>
                      {tenderStartPrice
                        ? `${tenderStartPrice} ${currency.symbol}`
                        : '-'}
                    </TextRoutePartView>
                  </FlexWrp>
                  {settings.winnerprice && (
                    <FlexWrp
                      style={{ marginBottom: '3rem', paddingRight: '1rem' }}
                    >
                      <CargoRoutePartView>
                        {strToUpperCase(dic.price_winner)}
                      </CargoRoutePartView>
                      <TextRoutePartView>
                        {tenderWinnerPrice
                          ? `${tenderWinnerPrice} ${currency.symbol}`
                          : '-'}
                      </TextRoutePartView>
                    </FlexWrp>
                  )}
                </>
              )}
              {!tenderIsBlind && (
                <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
                  <CargoRoutePartView>
                    {strToUpperCase(dic.step)}
                  </CargoRoutePartView>
                  <TextRoutePartView>
                    {tenderStep ? `${tenderStep} ${currency.symbol}` : '-'}
                  </TextRoutePartView>
                </FlexWrp>
              )}
              <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
                <CargoRoutePartView>
                  {strToUpperCase(dic.tender_terms)}
                </CargoRoutePartView>
                <TextRoutePartView>
                  {tenderterms ? tenderterms : '-'}
                </TextRoutePartView>
              </FlexWrp>
              <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
                <CargoRoutePartView>
                  {strToUpperCase(dic.remark)}
                </CargoRoutePartView>
                <TextRoutePartView>{comment ? comment : '-'}</TextRoutePartView>
              </FlexWrp>
            </>
          ) : (
            <>
              <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
                <CargoRoutePartView>
                  {strToUpperCase(dic.sum)}
                </CargoRoutePartView>
                <TextRoutePartView>
                  {routePrice ? `${routePrice} ${currency.symbol}` : '-'}
                </TextRoutePartView>
              </FlexWrp>
              <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
                <CargoRoutePartView>
                  {strToUpperCase(dic.remark)}
                </CargoRoutePartView>
                <TextRoutePartView>{comment ? comment : '-'}</TextRoutePartView>
              </FlexWrp>
            </>
          )}
        </>
      )}
    </Container>
  )
}
