import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Wrapper,
  Title,
  Info,
  FlexBox,
  HalfBox,
  InfoLink,
  Action,
  EnRouteRouteParts,
  RouteMap,
  SeparateLine,
} from '../../../smallPartsForInterface/SmallPartsCard'
import BaseLinkNewWindow from '../../../links/BaseLinkNewWindow'
import DottedLink from '../../../links/DottedLink'
import BaseBtn from '../../../buttons/BaseBtn'
import BlindTenderControls from '../BlindTenderControls'
import {
  NumberWithoutNulls,
  KmWithoutNulls,
} from '../../../../lib/helpers/forRoutes'
import { currencyIcons } from '../../../../lib/constants'
import { strToUpperCase } from '../../../../lib/helpers/forStyling'
import BidsBase from '../Bids/BidsBase'
import BidsBlind from '../Bids/BidsBlind'
import SendBet from '../../../../containers/forms/SendBet'
import Icon from '../../../Icon'

export default ({ data, isFinish, isWinnerStatus }) => {
  const dispatch = useDispatch()
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const [isShowMap, setIsShowMap] = useState(false)
  const [isMyBet, setIsMyBet] = useState(null)
  const [isSendBet, setIsSendBet] = useState(false)

  const local = {
    mcube: dic.mcube,
    tonn: dic.t,
    km: dic.km,
    ride: dic.flightAccusative,
  }

  const cancelBet = () => {
    dispatch({
      type: 'SAGA_SEND_MY_BET',
      data: {
        route: periodic,
        cancelled: 1,
      },
    })
  }
  const returnBet = () => {
    dispatch({
      type: 'SAGA_SEND_MY_BET',
      data: {
        route: periodic,
        cancelled: 0,
      },
    })
  }

  const togglerMap = () => {
    setIsShowMap(!isShowMap)
  }
  const {
    companydata,
    clientdata,
    defaultcurrency,
    distance,
    maxquotewithcommission,
    pricefor,
    totalweight,
    routeparts,
    routestatus,
    paymenttype,
    cartype,
    chargetype,
    ferryproperty,
    periodic,
    totalcount,
    step,
    fastwinprice,
  } = data
  return (
    <Wrapper>
      <>
        {isSendBet && (
          <SendBet
            titleBet={`${dic.bid}`}
            titleQuantity={`${dic.willCarry} ${local[data.pricefor]}`}
            titleComment={`${dic.comment_add}...`}
            totalcount={NumberWithoutNulls(totalcount)}
            outOf={`${dic.of} ${NumberWithoutNulls(totalcount)}`}
            periodic={periodic}
            currency={
              defaultcurrency
                ? currencyIcons[defaultcurrency]
                : currencyIcons.uah
            }
            fnClose={setIsSendBet}
            value={isMyBet}
          />
        )}
        <Title big style={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
          {strToUpperCase(dic.customer)}
        </Title>
        <div style={{ marginBottom: '3.2rem' }}>
          <DottedLink
            title={companydata.title_ru}
            path={`/company/${clientdata.hash}`}
          />
        </div>
        <SeparateLine />
        <Title big style={{ marginTop: '2.5rem', marginBottom: '2rem' }}>
          {strToUpperCase(dic.payment)}
        </Title>
        <FlexBox mrgnBtm="2rem">
          {true && (
            <HalfBox>
              <Info className="firstLetterUpperCase">{dic.price_start}</Info>
              {maxquotewithcommission ? (
                <Title>{`${NumberWithoutNulls(maxquotewithcommission)} ${
                  defaultcurrency
                    ? currencyIcons[defaultcurrency]
                    : currencyIcons.uah
                }`}</Title>
              ) : (
                <Title>-</Title>
              )}
            </HalfBox>
          )}
          {true && (
            <HalfBox>
              <Info className="firstLetterUpperCase">{dic.offer}</Info>
              <Title>{`${NumberWithoutNulls(totalcount)} ${
                local[pricefor]
              }`}</Title>
            </HalfBox>
          )}
        </FlexBox>
        <FlexBox mrgnBtm="2rem">
          <HalfBox>
            <Info className="firstLetterUpperCase">{dic.payment_type}</Info>
            <Title>{`${paymenttype ? paymenttype.title : null} ${
              data.nds ? dic.nds_1 : dic.nds_2
            }`}</Title>
          </HalfBox>
          {NumberWithoutNulls(step) > 0 && (
            <HalfBox>
              <Info className="firstLetterUpperCase">{dic.step}</Info>
              <Title>{`${NumberWithoutNulls(step)} ${
                defaultcurrency
                  ? currencyIcons[defaultcurrency]
                  : currencyIcons.uah
              }`}</Title>
            </HalfBox>
          )}
        </FlexBox>
        {!isFinish && NumberWithoutNulls(fastwinprice) > 0 ? (
          <FlexBox
            mrgnBtm="3.2rem"
            alignCenter
            style={{
              minHeight: '5rem',
              width: '100%',
            }}
          >
            <HalfBox>
              <Info className="firstLetterUpperCase">{dic.price_winner}</Info>
            </HalfBox>
            <HalfBox>
              {/* <BaseBtn
                pad="mid"
                fullSize
                label={`${dic.willCarryFor} ${NumberWithoutNulls(
                  fastwinprice
                )}`}
              /> */}
            </HalfBox>
          </FlexBox>
        ) : (
          <SeparateLine />
        )}
        <Title big style={{ marginTop: '2rem', marginBottom: '2rem' }}>
          {strToUpperCase(dic.requirementVehicle)}
        </Title>
        <FlexBox mrgnBtm="2rem">
          {cartype && (
            <HalfBox>
              <Info className="firstLetterUpperCase">{dic.car_type}</Info>
              <Title>{cartype.join(', ')}</Title>
            </HalfBox>
          )}
          {chargetype && (
            <HalfBox>
              <Info className="firstLetterUpperCase">{dic.charge_type}</Info>
              <Title>{chargetype.join(', ')}</Title>
            </HalfBox>
          )}
        </FlexBox>
        <FlexBox style={{ paddingBottom: '3rem' }}>
          {ferryproperty && (
            <HalfBox>
              <Info className="firstLetterUpperCase">{dic.ferry_property}</Info>
              <Title>{ferryproperty.join(', ')}</Title>
            </HalfBox>
          )}
          <HalfBox>
            <Info className="firstLetterUpperCase">
              {dic.cargo_total_weight}
            </Info>
            <Title>{`${totalweight} ${dic.t}`}</Title>
          </HalfBox>
        </FlexBox>
      </>
      <SeparateLine />
      {data.remark && ( // коментарий заказчика
        <>
          <Title big style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            {strToUpperCase(dic.remark)}
            <Icon
              name="atencion"
              type="error"
              width="1.8rem"
              height="1.8rem"
              style={{ marginLeft: '0.8rem' }}
            />
          </Title>
          <Info
            style={{ marginBottom: '0.5rem' }}
            className="firstLetterUpperCase"
          >
            {data.companydata.title_ru}
          </Info>
          <Title style={{ marginBottom: '3.2rem' }}>{data.remark}</Title>
        </>
      )}
      {data.tenderterms && ( //условия тендера
        <>
          <Title big style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            {strToUpperCase(dic.tender_terms)}
            <Icon
              name="atencion"
              type="error"
              width="1.8rem"
              height="1.8rem"
              style={{ marginLeft: '0.8rem' }}
            />
          </Title>
          <Title style={{ marginBottom: '3.2rem' }}>{data.tenderterms}</Title>
        </>
      )}
      {data.blindtender ? (
        <>
          <BidsBlind
            dic={dic}
            local={local}
            currency={defaultcurrency}
            periodic={periodic}
            isFinish={isFinish}
            isWinnerStatus={isWinnerStatus}
            setIsMyBet={setIsMyBet}
            isOpenBidForm={isSendBet}
          />
          {routestatus > 1 && (
            <BlindTenderControls
              className="flex"
              style={{
                width: '100%',
                marginBottom: '4rem',
                justifyContent:
                  isMyBet && isMyBet.cancelled ? 'center' : 'space-between',
              }}
              dic={dic}
              isMyBet={isMyBet}
              fnCancel={cancelBet}
              fnEdit={() => setIsSendBet(true)}
              fnReturn={returnBet}
            />
          )}
        </>
      ) : (
        <BidsBase
          dic={dic}
          local={local}
          currency={defaultcurrency}
          periodic={periodic}
          isFinish={isFinish}
          isWinnerStatus={isWinnerStatus}
          setIsMyBet={setIsMyBet}
          isOpenBidForm={isSendBet}
        />
      )}

      {/* {data.blindtender && (
        
      )} */}
      <EnRouteRouteParts
        arr={routeparts}
        lang={dic}
        routestatus={routestatus}
        mrgnBtm="4rem"
        borderTop
        forTender
      />
      <FlexBox mrgnBtm={!isFinish ? '18rem' : '8rem'} onClick={togglerMap}>
        <BaseLinkNewWindow label={dic.show_map} disabled />
        <InfoLink style={{ textTransform: 'lowercase' }}>
          {distance && `${KmWithoutNulls(distance)} ${dic.km}`}
        </InfoLink>
      </FlexBox>
      {isShowMap && (
        <RouteMap
          points={routeparts}
          title={`${dic.route_map} № ${periodic}`}
          fnClose={togglerMap}
        />
      )}
      {(data.blindtender ? !isMyBet : true) && !isFinish && (
        <Action>
          <BaseBtn
            fullSize
            // label={isMyBet ? dic.bid_my_last : `${dic.bid_send}...`}
            // fnClick={() => setIsSendBet(true)}
            // disabled={isMyBet}
          />
        </Action>
      )}
    </Wrapper>
  )
}
