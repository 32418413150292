import { takeLatest, put, call } from 'redux-saga/effects' //select, call,
import { addMissingDictionary } from '../../../lib/requests/auxRequests'

export function* worker({ data }) {
  try {
    const result = yield call(addMissingDictionary, data)
    if (result.status === 200 || result.status === 202) {
    }
  } catch (e) {
    yield put({
      type: 'SHOW_ERROR_API',
      code: String(e.response.status).slice(0, 1),
      message: e.response ? e.response.data.message : e.message,
    })
    if (e.response && e.response.data.relogin) {
      yield put({ type: 'SAGA_LOGOUT' })
    }
  }
}

export default function* postNewWords() {
  yield takeLatest('SAGA_POST_NEW_WORDS', worker)
}
