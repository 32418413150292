import React from 'react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { sampleRate } from './lib/sentry'
import { useSelector } from 'react-redux'

export default ({ children }) => {
  const userProfile = useSelector((s) => s.user.profile)
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: sampleRate,
  })

  Sentry.configureScope((scope) => {
    scope.setUser({
      id: userProfile && userProfile.id ? userProfile.id : null,
      name: userProfile && userProfile.name ? userProfile.name : null,
      email: userProfile && userProfile.email ? userProfile.email : null,
      language:
        userProfile && userProfile.language ? userProfile.language : null,
      company: userProfile && userProfile.company ? userProfile.company : null,
    })

    scope.setTag('type', 'react-app')
  })

  return (
    <Sentry.ErrorBoundary fallback={'An error has occurred'}>
      {children}
    </Sentry.ErrorBoundary>
  )
}
