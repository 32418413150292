import { beforeAuth, api, routs } from '../api'

export const getMyDrivers = (token, currentLang) => {
  return api.get(routs.myDrivers, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}
export const getMyCars = (token, currentLang) => {
  return api.get(routs.myCars, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

export const getMyTrailers = (token, currentLang) => {
  return api.get(routs.myTrailers, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

// Get dictionary
export const getDictionary = (language) => {
  return beforeAuth.get(routs.getDictionary, {
    params: { language },
  })
}

// add Missing Dictionary
export const addMissingDictionary = (data) => {
  return beforeAuth.post(routs.addMissingDictionary, {
    data,
  })
}

// Get Country
export const getCountries = (token, currentLang, params) => {
  return api.get(routs.getCountries, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: { ...params },
  })
}

// Get Town
export const getTowns = (token, currentLang, params) => {
  return api.get(routs.getTowns, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: { ...params },
  })
}
// Get Companies
export const getCompanies = (token, currentLang, params) => {
  return api.get(routs.getCompanies, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: { ...params },
  })
}

// Get Checkpoint
export const getCheckpoints = (token, currentLang, params) => {
  return api.get(routs.getCheckpoints, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: { ...params },
  })
}
// Get Checkpoint Contactdata
export const getCheckpointContact = (token, currentLang, params) => {
  return api.get(routs.getCheckpointContact, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: { ...params },
  })
}

// Get Cargo
export const getCargo = (token, currentLang, params) => {
  return api.get(routs.getCargo, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: { ...params },
  })
}

// Get Measure Unit
export const getMeasureUnits = (token, currentLang) => {
  return api.get(routs.getMeasureUnits, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

//Get car type

export const getCartype = (token, currentLang) => {
  return api.get(routs.getCartype, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

//Get charge type

export const getChargetype = (token, currentLang) => {
  return api.get(routs.getChargetype, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

// get ferryproperty
export const getFerryproperty = (token, currentLang) => {
  return api.get(routs.getFerryproperty, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

// get Payment type
export const getPaymenttype = (token, currentLang) => {
  return api.get(routs.getPaymenttype, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}
// get Currency
export const getCurrency = (token, currentLang) => {
  return api.get(routs.getCurrency, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

// get Price for
export const getPricefor = (token, currentLang) => {
  return api.get(routs.getPricefor, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}
// get Payor
export const getPayor = (token, currentLang) => {
  return api.get(routs.getPayor, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}
// get Partners
export const getPartners = (token, currentLang) => {
  return api.get(routs.getPartners, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

// get car brands
export const getCarMake = (token, currentLang) => {
  return api.get(routs.getCarMake, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

// get vehicle classes
export const getVehicleClasses = (token, currentLang) => {
  return api.get(routs.getVehicleClasses, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}
