import React, {useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BaseLink from '../../links/BaseLink'
import BtnLikeLink from "../../buttons/BtnLikeLink"
import { Info } from '../../smallPartsForInterface/SmallPartsCard'
import SmallLoader from '../../layouts/SmallLoader'

export default ({
                    data={},
                }) => {
    const dispatch = useDispatch()
    const lang = useSelector((s) => s.defaultLang.serverDictionary)
    const children = useSelector((s) => s.tender.children)
    const loader = useSelector((s) => s.tender.childrenLoader)
    const { periodic } = data
    const [onShowList, setOnShowList] = useState(false)

    const onToggleList = () => {
        return setOnShowList(!onShowList)
    }

    const {total, remain } = useMemo(()=>{
        if(children?.length > 0 ){
            return {
                total:children.length,
                remain: children.filter((route)=>route?.routestatus < 4)?.length,

            }
        }

        return {
            total:0,
            remain: 0,
        }


    },[children])

    useEffect(()=>{
        dispatch({ type: 'SAGA_GET_TENDER_CHILDREN', periodic: periodic })
    },[])

    return (
        loader ? <SmallLoader/> : (
        <>
            <div className="flex" style={{ marginBottom: '1rem' }}>
                <div>
                    <p>{`${lang.routes_created}: ${total}`}</p>
                </div>
                <BtnLikeLink
                    onClick={onToggleList}
                    label={`${lang.seeAll} →`}
                    lowercase={1}
                />
            </div>
            <div className="flex mrgBottom">
                <Info>{`${lang.left}: ${remain} ${lang.of} ${total} ${lang.routeAccusative}`}</Info>
            </div>
            {onShowList && (
                <div
                    style={{
                        flexDirection: "column",
                        alignItems:"flex-start",
                    }}
                    className="flex" >
                    {children.map((route)=>{
                        return (
                            <BaseLink
                                key={route.periodic}
                                style={{marginBottom:"1.6rem"}}
                                nav
                                rout={`/route/${route.periodic}`}
                                label={`${lang.route} ${route.periodic}`}
                                lowercase={1}
                            />
                        )
                    })}

                </div>
            )}
        </>
        )
    )
}
