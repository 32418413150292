import React from 'react'
import FormLayout from '../components/layouts/FormLayout'
import RegistrationForm from '../containers/forms/RegistrationForm'

export default () => {
  return (
    <FormLayout>
      <RegistrationForm marginTop="7.8rem" />
    </FormLayout>
  )
}
