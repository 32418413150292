import Type from '../types'
import { dic, langObj } from '../../lib/dataForTests'

const initialState = {
  langObj: { ...langObj },
  //CHANGE_LANG
  baseDictionary: dic,
  serverDictionary: {},
  currentLang: 'ua',
  isOpen: 0,
  list: [
    { title: 'українська', id: 'ua' },
    { title: 'русский', id: 'ru' },
    { title: 'english', id: 'en' },
  ],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_WORDBOOK:
      return {
        ...state,
        langObj: action.payload,
      }
    case Type.SET_LANG:
      return {
        ...state,
        currentLang: action.payload,
      }
    case Type.TOGGLE_LANG_SELECT:
      return {
        ...state,
        isOpen: action.payload,
      }
    case Type.SET_LIST_LANG:
      return {
        ...state,
        list: [...action.payload],
      }
    case Type.SET_DICTIONARY:
      return {
        ...state,
        serverDictionary: { ...action.payload },
      }

    default:
      return state
  }
}
