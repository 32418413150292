import { useMemo, useState } from 'react'
import { TRAILER_FIELDS } from '../../../lib/dataTemplateForForms'
import { dataPreValidate } from "../../../lib/helpers/addNewTrailer"
import {useSelector} from "react-redux";

const renderRequiredInputChain = [
    { name:"number" },
    {
        name:"cartype",
        action: {
            type: 'SAGA_GET_CARTYPES',
            loader: 'selectList',
        },
        styles: { marginBottom: '1rem' },// marginBottom: "3.5rem",
    },
    {
        name:"trailerbodytype",
        action: {
            type: 'SAGA_GET_CAR_BODY_TYPES', // getCartype
            loader: 'selectList',
        },
    },
    {
        name:"make",
        action: {
            type: 'SAGA_GET_CAR_BRANDS',
            loader: 'selectList',
        },
    },
    { name:"model" },
    {name: "loadcapacity"},
    { name:"chargetype",
        action: {
            type: 'SAGA_GET_CHARGE_TYPES',
        },
    },
    { name:"ferryproperty",
        action: {
            type: 'SAGA_GET_FERRY_PROPERTIES',
        },
    },
    { name:"year_of_manufact" },
    { name:"width" },
    { name:"height" },
    { name:"length" },
    { name: 'weight' },
    { name:"total_weight" },
    { name:"vin" },
];

const renderAdditionalInputChain = [

    { name: 'carbase' },
    { name: 'cellnum' },
    {
        name: 'axes',
        styles: {
            marginBottom: "3.5rem"
        }
    },
];

export const useAddNewTrailer = () => {

    //error-item example  - number: { bool: 0, msg: dic.form_err_required }
    const [ errors, setErrors ] = useState({})
    const dic = useSelector((s) => s.defaultLang.serverDictionary)

    const requiredFields = useMemo(()=>{
       return renderRequiredInputChain.reduce((acc, render)=>{
            const {name} = render
            const data = TRAILER_FIELDS[name]
            if(data){
                return [...acc, { ...render, ...data }]
            }
            return acc
        }, [])
    },[])


    const additionalFields = useMemo(()=>{

        return renderAdditionalInputChain.reduce((acc, render)=>{
            const {name} = render
            const data = TRAILER_FIELDS[name]

            if(data){
                return [...acc, { ...render, ...data }]
            }
            return acc
        }, [])
    },[])

    const onSetError = (NAME, msg = dic.form_err_required ) => {
        setErrors({
            ...errors,
            [NAME]: {
                ...errors[NAME],
                bool: 1,
                msg,
            }
        })

        const copy = { ...errors}
         delete copy[NAME]

        setTimeout(
            () => setErrors(copy),
            3000
        )
    }

    const onSubmitForm = (data, actionSubmit) => {
       return dataPreValidate(
            {
                onSetError,
                dataModel : TRAILER_FIELDS,
            },
            data,
            actionSubmit
        )
    }

    return {
        requiredFields,
        additionalFields,
        errors,
        onSubmitForm,
    }

}