import React from 'react'
import styled from 'styled-components'
import unavailablePage from '../../assets/img/unavailablePage.png'

const Wrapper = styled.span`
  width: 100vw;
  height: 100vh;
  padding: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Picture = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${(p) => `url(${unavailablePage})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  }
`
export default ({ style, size = '2.5rem', id = 'badRequest' }) => {
  return (
    <Wrapper style={style} id={id} data-testid={id}>
            <Picture size='100' />
    </Wrapper>
  )
}
