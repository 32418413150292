import { takeLatest, put } from 'redux-saga/effects'
export function* worker({ codeId, msg }) {
  yield put({
    type: 'SHOW_ERROR_API',
    code: codeId,
    message: msg || 'internal server error',
  })
}

export default function* postSubscribeToExecution() {
  yield takeLatest('SAGA_SHOW_ERROR', worker)
}
