import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import Icon from '../Icon'
import BtnLoader from '../layouts/BtnLoader'
import { strToUpperCase } from '../../lib/helpers/forStyling'

const Wrapper = styled.button`
  ${(p) => (p.fullSize ? `width:100%;` : null)};
  ${(p) => p.inContext === 'popup' && `width:50%`};
  max-width: 100%;
  padding: ${(p) => p.padding};
  background-color: ${(p) => p.bg};
  font-size: ${(p) => p.theme.h4};
  font-weight: ${(p) => (p.inContext === 'confirm' ? 500 : 500)};
  text-transform: ${(p) => (p.toUpperCase ? 'uppercase' : 'none')};
  color: ${(p) => p.color};
  cursor: pointer;
  transition: 0.3s ease;
  .sizeWrp {
    opacity: ${(p) => (p.isLoad ? 0 : 1)};
    height: ${(p) => (p.isLoad ? 0 : 'auto')};
    display: flex;
    justify-content: ${(p) => (p.flexLeft ? 'flex-start' : 'center')};
    align-items: center;
  }
  ${(p) =>
    p.inContext === 'popup' &&
    `border-right: 0.1rem solid ${p.theme.system_button}; :last-child{ border-right:unset};`};
  :active {
    transform: scale(0.97);
    ${(p) =>
      p.inContext === 'confirm'
        ? `background-color: ${p.theme.basic}; color : ${p.theme.regular_txt};`
        : `box-shadow: ${p.theme.activeBtnStyle}`};
    ${(p) => p.inContext === 'popup' && `color : ${p.theme.basic_txt};`};
  }
  :disabled {
    opacity: 0.4;
    cursor: default;
  }
`
const closeStyles = {
  marginRight: '0.7rem',
  transform: 'rotate(45deg) scale(0.6)',
}

export default ({
  type = 'button',
  label = 'button',
  disabled = false,
  fnClick = () => {},
  forLoadFlow = false, // required for buttonFlow
  inContext = 'interface',
  fullSize = false,
  pad = 'big',
  toUpperCase = false,
  iconPlus = false,
  iconArrow = false,
  nameIcon = 0,
  flexLeft = false,
  localId = null, // required for buttonFlow
  style,
}) => {
  const dispatch = useDispatch()
  const isLoad = useSelector((s) => s.buttonFlow.isLoad)
  const currentId = useSelector((s) => s.buttonFlow.btnId)
  const currentTheme = useContext(ThemeContext)
  const bgStyle = {
    confirm: currentTheme.flat_headers,
    interface: currentTheme.basic,
    setup: currentTheme.system_button,
    popup: 'none',
  }
  const colorStyle = {
    confirm: currentTheme.opposite_txt,
    interface: currentTheme.regular_txt,
    setup: currentTheme.regular_txt,
    popup: currentTheme.regular_txt,
  }
  const padStyle = {
    big: '1.3rem 4.2rem',
    mid: '1.3rem 1.6rem',
    small: '0.6rem 1.2rem',
    forTwo: '1.3rem 0.5rem',
  }
  const actionIcons = {
    1: 'arrowDown',
    2: 'arrowUp',
    3: 'customs',
    4: 'border',
  }

  const localClick = () => {
    dispatch({ type: 'START_REQUEST', payload: localId })
    fnClick()
  }

  return (
    <Wrapper
      type={type}
      onClick={forLoadFlow ? localClick : fnClick}
      disabled={isLoad ? isLoad : disabled}
      inContext={inContext}
      bg={bgStyle[inContext]}
      color={colorStyle[inContext]}
      padding={padStyle[pad]}
      fullSize={fullSize}
      toUpperCase={toUpperCase}
      isLoad={isLoad && currentId === localId}
      style={style}
      flexLeft={flexLeft}
      id={localId}
    >
      {isLoad && currentId === localId && (
        <BtnLoader forDark={inContext === 'confirm'} />
      )}
      <div className="sizeWrp">
        {iconPlus && (
          <Icon name="close" type="regularText" style={closeStyles} />
        )}
        {strToUpperCase(label)}
        {Boolean(nameIcon) && (
          <Icon
            name={actionIcons[nameIcon]}
            type="regularText"
            style={{ marginTop: '0.7rem', marginLeft: '2rem' }}
          />
        )}
        {iconArrow && (
          <Icon
            name="arrowShevron"
            type="regularText"
            style={{ marginLeft: '5rem' }}
          />
        )}
      </div>
    </Wrapper>
  )
}
