import dumpFileIcon from './dumpFileIcon.png'
import docIcon from './docIcon.png'
import pdfIcon from './pdfIcon.png'
import pptIcon from './pptIcon.png'
import zipIcon from './xlsIcon.png'
import svgIcon from './svgIcon.png'
import jpgIcon from './jpgIcon.png'
import pngIcon from './pngIcon.png'
import xlsIcon from './xlsIcon.png'

export default {
  all: dumpFileIcon,
  doc: docIcon,
  docx: docIcon,
  pdf: pdfIcon,
  ppt: pptIcon,
  zip: zipIcon,
  svg: svgIcon,
  jpg: jpgIcon,
  jpeg: jpgIcon,
  png: pngIcon,
  xls: xlsIcon,
  xlsx: xlsIcon,
}
