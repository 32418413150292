import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import useRouter from '../../hooks/useRouter'
import Icon from '../../components/Icon'
import HotKey from '../../components/buttons/MainMenuHotKey'
import HorizontalTab from '../../components/tabs/HorizontalTab'
import AuxMenuLink from '../../components/links/MenuLink'
import useClickoutside from '../../hooks/useOutsideClick'
import test from '../../assets/img/test.jpg'

const Wrapper = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(p) => p.theme.modalGradient};
  display: flex;
`
const Content = styled.div`
  width: 80%;
  max-width: 50rem;
  height: 100%;
  background-color: ${(p) => p.theme.opposite2};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .owerflowAuto {
    width: 100%;
    overflow: auto;
  }
`
const Header = styled.div`
  width: 100%;
  padding: 1.8rem 1.8rem 2.4rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Title = styled.p`
  font-family: 'FM Bolyar Sans Pro';
  color: ${(p) => p.theme.regular_txt};
  font-weight: 900;
  text-transform: uppercase;
  font-size: ${(p) => p.theme.headerTitle};
  letter-spacing: -0.5%;
`
const UserTitle = styled.div`
  width: 100%;
  padding: 0 0 2.4rem 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`
const Name = styled.h3`
  font-size: ${(p) => p.theme.h3};
  font-weight: 600;
  color: ${(p) => p.theme.regular_txt};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const Company = styled.p`
  color: ${(p) => p.theme.additional};
  font-size: ${(p) => p.theme.h6};
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const Img = styled.div`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  margin-top: 0.1rem;
  border-radius: 50%;
  background-image: ${(p) =>
    p.userLogo ? `url(${p.userLogo})` : `url(${test})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`
const HotKeyWrp = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
const NavMenuWrp = styled.div`
  width: 100%;
  min-height: 20vh;
  border-top: 0.1rem solid ${(p) => p.theme.lines};
  ::last-child {
    border-bottom: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
const AuxMenuWrp = styled.div`
  width: 100%;
  min-height: 20vh;
  padding: 1.5rem 1.8rem 0 2rem;
  /* border-top: 0.1rem solid ${(p) => p.theme.lines}; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export default () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const profile = useSelector((s) => s.user.profile)
  const menuList = useSelector((s) => s.user.hotKeys)
  const navList = useSelector((s) => s.user.navlist)
  const auxList = useSelector((s) => s.user.auxMenu)
  const isShow = useSelector((s) => s.mainMenu.isShowPWAInsallBTn)
  const dic = useSelector((s) => s.defaultLang.serverDictionary)

  const changeTap = (id, route) => {
    dispatch({ type: 'NAVMENU_SET_CURRENT_TAP', payload: String(id) })
    dispatch({ type: 'CLOSE_MAIN_MENU' })
    router.push(route)
  }

  const closeMenu = () => dispatch({ type: 'CLOSE_MAIN_MENU' })
  const menuRef = useRef(null)
  useClickoutside(menuRef, closeMenu)

  return (
    <Wrapper>
      <Content ref={menuRef}>
        <Header>
          <Title>sovtes</Title>
          <span
            style={{ marginLeft: 'auto', cursor: 'pointer' }}
            id="closeMainMenu"
          >
            <Icon name="close" type="regularText" onClick={closeMenu} />
          </span>
        </Header>
        <UserTitle>
          {profile && profile.avatar ? (
            <Img userLogo={profile.avatar} />
          ) : (
            <Icon
              name="avatarSingle"
              type="regularText"
              style={{ marginRight: '1rem', marginTop: '0.1rem' }}
              width="2rem"
              height="2rem"
            />
          )}
          {profile && (
            <div style={{ maxWidth: '90%' }}>
              {profile.name && <Name>{profile.name}</Name>}
              {profile.company && <Company>{profile.company}</Company>}
            </div>
          )}
        </UserTitle>
        <span className="owerflowAuto">
          {menuList && menuList.length > 0 && (
            <HotKeyWrp>
              {menuList.map((item) => (
                <HotKey key={item.id} obj={item} />
              ))}
            </HotKeyWrp>
          )}
          <NavMenuWrp>
            {navList &&
              navList.length > 0 &&
              navList.map((item) => (
                <HorizontalTab
                  withSidePads
                  key={item.id}
                  obj={item}
                  fnClick={() => changeTap(item.id, item.path)}
                  id={item.id}
                />
              ))}
          </NavMenuWrp>
          <AuxMenuWrp>
            {auxList &&
              auxList.length > 0 &&
              auxList.map((item) => (
                <AuxMenuLink
                  key={item.id}
                  iconType={item.iconType && item.iconType}
                  iconColor={item.iconColor && item.iconColor}
                  title={item.title && item.title}
                  path={item.path && item.path}
                  externalLink={item.externalLink && item.externalLink}
                  action={item.action && item.action}
                />
              ))}
            {isShow && (
              <AuxMenuLink title={dic.pwa_btn_title} action="pwa" upperCase />
            )}
          </AuxMenuWrp>
        </span>
      </Content>
    </Wrapper>
  )
}
