import Type from '../types'

const initialState = {
  currentId: '',
  selectedArgs: [],
  where: { typeUpdate: '', location: '' },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.CHANGE_CURRENT_ID_FOR_FILTER:
      return {
        ...state,
        currentId: action.payload,
        where: action.where,
      }
    case Type.ADD_ARG_FOR_FILTER:
      return {
        ...state,
        selectedArgs: [...state.selectedArgs, action.payload],
      }
    case Type.REMOVE_ARG_FOR_FILTER:
      return {
        ...state,
        selectedArgs: state.selectedArgs.filter(
          (el) => el.filterId !== action.payload.filterId
        ),
      }
    case Type.REWRITE_ARG_FOR_FILTER:
      const isHaveData = state.selectedArgs.find(
        (el) => el.id === action.payload.id
      )
      return {
        ...state,
        selectedArgs: isHaveData
          ? state.selectedArgs.map((el) =>
              el.id === action.payload.id ? action.payload : el
            )
          : [...state.selectedArgs, action.payload],
      }

    case Type.CLEAR_ARGS_FOR_FILTER:
      return {
        ...state,
        selectedArgs: [],
      }

    default:
      return state
  }
}
