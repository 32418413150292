import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ThinBtn from '../../../components/buttons/ThinBtn'
import BaseBtn from '../../../components/buttons/BaseBtn'
import SelectAccBtn from '../../../components/buttons/SelectAccBtn'
import BaseCounter from '../../other/BaseCounter'
import ModalPortal from '../../../components/layouts/ModalPortal'
import Checklist from '../../../components/popUps/Checklist'
import {
  CargoRoutePartView,
  FlexWrp,
  TitleRoutePartEdit,
  TextRoutePartView,
  Container,
} from './SmallComponentsForNewRouteForm'
import { strToUpperCase } from '../../../lib/helpers/forStyling'

export default ({ inEditor = '', currentStep = 0, onEditPart = () => {} }) => {
  const dispatch = useDispatch()
  const list = useSelector((s) => s.checkList.list)
  const autoQuantity = useSelector((s) => s.newRoute.quantity_auto)
  const typeAutoList = useSelector((s) => s.newRoute.cartype)
  const typeLoadList = useSelector((s) => s.newRoute.chargetype)
  const ferryPropertyList = useSelector((s) => s.newRoute.ferryproperty)
  const dic = useSelector((s) => s.defaultLang.serverDictionary)

  const [isModal, setIsModal] = useState(false)
  const [selector, setSelector] = useState('')
  const [localTitle, setLocalTitle] = useState('')

  const onUpdateAutoQuantity = (action) => {
    const selector = {
      plus: autoQuantity + 1,
      minus: autoQuantity - 1,
    }
    dispatch({
      type: 'UPDATE_NEWROUTE_QUANTITY_AUTO',
      payload: selector[action],
    })
  }
  const onChangeList = (id, where) => {
    const dataSelector = {
      typeAuto: typeAutoList,
      typeLoad: typeLoadList,
      ferryProperty: ferryPropertyList,
    }
    const actions = {
      typeAuto: 'UPDATE_NEWROUTE_CARTYPE',
      typeLoad: 'UPDATE_NEWROUTE_CHARGETYPE',
      ferryProperty: 'UPDATE_NEWROUTE_FERRYPROPERTIES',
    }
    let result = []
    if (dataSelector[where].find((el) => el.id === id)) {
      result = dataSelector[where].filter((el) => el.id !== id)
    } else {
      const item = list.find((el) => el.id === id)
      result = [...dataSelector[where], { ...item, checked: true }]
    }
    dispatch({ type: actions[where], payload: result })

    dispatch({
      type: 'SET_CHEKLIST',
      payload: list.map((el) => {
        if (el.id === id) {
          return { ...el, checked: !el.checked }
        } else {
          return el
        }
      }),
    })
  }
  const onChangeListItemQuantity = (where, id, val) => {
    const dataSelector = {
      typeAuto: typeAutoList,
      typeLoad: typeLoadList,
      ferryProperty: ferryPropertyList,
    }
    const actions = {
      typeAuto: 'UPDATE_NEWROUTE_CARTYPE',
      typeLoad: 'UPDATE_NEWROUTE_CHARGETYPE',
      ferryProperty: 'UPDATE_NEWROUTE_FERRYPROPERTIES',
    }

    let result = []

    result = dataSelector[where].map((el) =>
      el.id === id ? { ...el, quantity: val } : el
    )

    dispatch({ type: actions[where], payload: result })

    dispatch({
      type: 'SET_CHEKLIST',
      payload: list.map((el) => {
        if (el.id === id) {
          return { ...el, quantity: val }
        } else {
          return el
        }
      }),
    })
  }
  const togglerModal = (localTitle, currentSelector) => {
    if (!isModal) {
      const data = {
        typeAuto: typeAutoList,
        typeLoad: typeLoadList,
        ferryProperty: ferryPropertyList,
      }
      dispatch({ type: 'CLEAR_CHEKLIST' })
      dispatch({
        type: 'SAGA_GET_CAR_ALL_PROPERTIES',
        selector: currentSelector,
        prewData: data[currentSelector].length ? data[currentSelector] : [],
      })
    }
    setSelector(currentSelector)
    setLocalTitle(localTitle)
    setIsModal(!isModal)
  }

  const onCloseModal = (bool) => {
    if (bool) {
      //clear filtred list
    }
    setIsModal(false)
  }
  return (
    <>
      {isModal && (
        <ModalPortal>
          <Checklist
            list={list}
            fnUpdateCheck={onChangeList}
            fnUpdateQuantity={onChangeListItemQuantity}
            fnCancel={() => onCloseModal(true)}
            fnSave={onCloseModal}
            title={localTitle}
            id={selector}
          />
        </ModalPortal>
      )}
      <Container>
        <FlexWrp>
          <TitleRoutePartEdit>
            {strToUpperCase(dic.requirementVehicle)}
          </TitleRoutePartEdit>
          {currentStep === 4 && (
            <ThinBtn
              type="edit"
              label=""
              onClick={() => onEditPart('vehicle')}
            />
          )}
        </FlexWrp>
        {inEditor === 'vehicle' || currentStep === 2 ? (
          <>
            <SelectAccBtn
              content={typeAutoList}
              fnClick={() =>
                togglerModal(strToUpperCase(dic.car_type), 'typeAuto')
              }
              ferryProperty
              typeLoad
              label={dic.car_type}
              style={{ marginBottom: '3.4rem' }}
            />
            <SelectAccBtn
              content={typeLoadList}
              fnClick={() =>
                togglerModal(strToUpperCase(dic.charge_type), 'typeLoad')
              }
              label={dic.charge_type}
              style={{ marginBottom: '3.4rem' }}
            />
            <SelectAccBtn
              content={ferryPropertyList}
              fnClick={() =>
                togglerModal(
                  strToUpperCase(dic.ferry_property),
                  'ferryProperty'
                )
              }
              label={dic.ferry_property}
              style={{ marginBottom: '1.4rem' }}
            />
            <BaseCounter
              label={strToUpperCase(dic.car_quantity)}
              value={autoQuantity}
              onUpdate={onUpdateAutoQuantity}
              min={1}
              max={10}
              style={currentStep === 4 ? { marginBottom: '2rem' } : {}}
            />
            {currentStep === 4 && (
              <BaseBtn
                fullSize
                label={dic.btn_aply}
                fnClick={() => onEditPart('')}
              />
            )}
          </>
        ) : (
          <>
            <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
              <CargoRoutePartView>
                {strToUpperCase(dic.car_type)}
              </CargoRoutePartView>
              <TextRoutePartView>
                {typeAutoList.length
                  ? typeAutoList.map((el) => el.label).join(', ')
                  : '-'}
              </TextRoutePartView>
            </FlexWrp>
            <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
              <CargoRoutePartView>
                {strToUpperCase(dic.charge_type)}
              </CargoRoutePartView>
              <TextRoutePartView>
                {typeLoadList.length
                  ? typeLoadList.map((el) => el.label).join(', ')
                  : '-'}
              </TextRoutePartView>
            </FlexWrp>
            <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
              <CargoRoutePartView>
                {strToUpperCase(dic.ferry_property)}
              </CargoRoutePartView>
              <TextRoutePartView>
                {ferryPropertyList.length
                  ? ferryPropertyList
                      .map((el) =>
                        el.quantity ? `${el.label} (${el.quantity})` : el.label
                      )
                      .join(', ')
                  : '-'}
              </TextRoutePartView>
            </FlexWrp>
            <FlexWrp style={{ marginBottom: '3rem', paddingRight: '1rem' }}>
              <CargoRoutePartView>
                {strToUpperCase(dic.car_quantity)}
              </CargoRoutePartView>
              <TextRoutePartView>
                {autoQuantity ? autoQuantity : '-'}
              </TextRoutePartView>
            </FlexWrp>
          </>
        )}
      </Container>
    </>
  )
}
