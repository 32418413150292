import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import DateSelect from '../../components/buttons/SelectDateBtn'
import TimeSelect from '../../components/buttons/SelectTimeBtn'
import BaseRadioInput from '../../components/inputs/BaseRadioInput'
import { strToUpperCase } from '../../lib/helpers/forStyling'
import { executeScroll } from '../../lib/helpers/forValidate'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const FlexWrp = styled.div`
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const HalfBox = styled.div`
  width: ${(p) => p.width};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export default ({
  index = 2,
  onUpdateItemRoutePart = () => {},
  prewData = {},
  errors = [],
}) => {
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  // exactly 1
  // period 2
  // nearest 3

  const [date, setDate] = useState({
    from: null,
    to: null,
    exactly: null,
    mode: 1,
  })
  const [time, setTime] = useState({
    from: null,
    to: null,
    exactly: null,
    mode: 2,
  })
  const dateRef = useRef(null)

  const updateReducer = (data) => {
    onUpdateItemRoutePart(index, data ? data : { date, time })
  }
  const updateDate = (value, mode) => {
    const selector = {
      exactly: { ...date, exactly: value, from: null, to: null, id: true },
      from: { ...date, from: value, exactly: null, id: date.to ? true : false },
      to: { ...date, to: value, exactly: null, id: date.from ? true : false },
    }
    const result = selector[mode]
    setDate(result)
    updateReducer({ time, date: result })
  }
  const updateTime = (value, mode) => {
    const selector = {
      exactly: { ...time, exactly: value, from: null, to: null },
      from: { ...time, from: value, exactly: null },
      to: { ...time, to: value, exactly: null },
    }
    const result = selector[mode]
    setTime(result)
    updateReducer({ date, time: result })
  }

  const toggler = (action) => {
    switch (action) {
      case 'exactlyDate':
        const resultexactlyDate = { ...date, mode: 1 }
        setDate(resultexactlyDate)
        updateReducer({ time, date: resultexactlyDate })
        break
      case 'exactlyTime':
        const resultexactlyTime = { ...time, mode: 1 }
        setTime(resultexactlyTime)
        updateReducer({ date, time: resultexactlyTime })
        break
      case 'periodDate':
        const resultperiodDate = { ...date, mode: 2, id: false }
        setDate(resultperiodDate)
        updateReducer({ time, date: resultperiodDate })
        break
      case 'periodTime':
        const resultperiodTime = { ...time, mode: 2 }
        setTime(resultperiodTime)
        updateReducer({ date, time: resultperiodTime })
        break
      case 'nearestDate':
        const resultnearestDate = {
          from: null,
          to: null,
          mode: 3,
          exactly: null,
          id: true,
        }
        setDate(resultnearestDate)
        updateReducer({ time, date: resultnearestDate })
        break
      case 'nearestTime':
        const resultnearestTime = {
          from: null,
          to: null,
          mode: 3,
          exactly: null,
          id: true,
        }
        setTime(resultnearestTime)
        updateReducer({ date, time: resultnearestTime })
        break
      default:
        break
    }
  }

  useEffect(() => {
    setDate({ ...prewData.date })
    setTime({ ...prewData.time })
  }, []) // eslint-disable-line
  useEffect(() => {
    if (errors[index] && errors[index].date.bool) {
      executeScroll({ date: dateRef }, 'date')
    }
  }, [errors, index])

  return (
    <Wrapper>
      <FlexWrp
        style={{
          marginBottom: date.mode === 2 || time.mode === 2 ? '2.4rem' : 0,
        }}
      >
        <HalfBox width="49%" style={{ marginRight: '2%' }}>
          {date.mode === 2 && (
            <p style={{ marginRight: '1.8rem' }}>{dic.from}</p>
          )}
          <div ref={dateRef} style={{ width: date.mode === 2 ? '80%' : '95%' }}>
            <DateSelect
              style={{ width: '100%' }}
              label={
                errors[index] && errors[index].date
                  ? `${strToUpperCase(dic.date) || ''}*`
                  : strToUpperCase(dic.date) || ''
              }
              disabled={date.mode === 3}
              value={date.mode === 2 ? date.from || '' : date.exactly || ''}
              fnClick={updateDate}
              index={date.mode === 2 ? 'from' : 'exactly'}
              error={
                errors[index] && errors[index].date
                  ? errors[index].date.bool
                  : null
              }
            />
          </div>
        </HalfBox>
        <HalfBox width="49%">
          {time.mode === 2 && (
            <p style={{ marginRight: '1.8rem' }}>{dic.from}</p>
          )}
          <TimeSelect
            style={{ width: time.mode === 2 ? '80%' : '100%' }}
            label={strToUpperCase(dic.time)}
            disabled={time.mode === 3}
            value={time.mode === 2 ? time.from || '' : time.exactly || ''}
            index={time.mode === 2 ? 'from' : 'exactly'}
            fnClick={updateTime}
          />
        </HalfBox>
      </FlexWrp>
      <FlexWrp>
        <HalfBox width="49%" style={{ marginRight: '2%' }}>
          {date.mode === 2 && (
            <>
              <p style={{ marginRight: '1rem' }}>{dic.to}</p>
              <DateSelect
                style={{ width: '80%' }}
                label=""
                index="to"
                value={date.to || ''}
                fnClick={updateDate}
                error={
                  errors[index] && errors[index].date
                    ? errors[index].date.bool
                    : null
                }
              />
            </>
          )}
        </HalfBox>
        <HalfBox width="49%">
          {time.mode === 2 && (
            <>
              <p style={{ marginRight: '1rem' }}>{dic.to}</p>
              <TimeSelect
                style={{ width: '80%' }}
                label=""
                index="to"
                value={time.to || ''}
                fnClick={updateTime}
              />
            </>
          )}
        </HalfBox>
      </FlexWrp>
      <FlexWrp
        style={{
          marginBottom: '2.4rem',
          marginTop: '2.4rem',
          padding: '0 0.6rem',
        }}
      >
        <HalfBox width="52%" style={{ paddingRight: '1rem' }}>
          <BaseRadioInput
            label={dic.date_exactly}
            id="exactlyDate"
            currentValue={date.mode === 1}
            fnChange={toggler}
          />
        </HalfBox>
        <HalfBox width="48%">
          <BaseRadioInput
            label={dic.time_exactly}
            id="exactlyTime"
            currentValue={time.mode === 1}
            fnChange={toggler}
          />
        </HalfBox>
      </FlexWrp>
      <FlexWrp style={{ marginBottom: '2.4rem', padding: '0 0.6rem' }}>
        <HalfBox width="52%" style={{ paddingRight: '1rem' }}>
          <BaseRadioInput
            label={dic.period}
            id="periodDate"
            currentValue={date.mode === 2}
            fnChange={toggler}
          />
        </HalfBox>
        <HalfBox width="48%">
          <BaseRadioInput
            label={dic.period}
            id="periodTime"
            currentValue={time.mode === 2}
            fnChange={toggler}
          />
        </HalfBox>
      </FlexWrp>
      <FlexWrp style={{ marginBottom: '4rem', padding: '0 0.6rem' }}>
        <HalfBox width="52%" style={{ paddingRight: '1rem' }}>
          <BaseRadioInput
            label={dic.date_nearest}
            id="nearestDate"
            currentValue={date.mode === 3}
            fnChange={toggler}
          />
        </HalfBox>
        <HalfBox width="48%">
          <BaseRadioInput
            label={dic.roundTheClock}
            id="nearestTime"
            currentValue={time.mode === 3}
            fnChange={toggler}
          />
        </HalfBox>
      </FlexWrp>
    </Wrapper>
  )
}
