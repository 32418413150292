import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Wrapper,
  FlexBox,
  InfoLink,
  Action,
  ConfirmOfAction,
  AwaitApprovedAction,
  EnRouteRouteParts,
  RouteMap,
  InfoBodyTop,
  InfoBodyBottom,
} from '../../../smallPartsForInterface/SmallPartsCard'
import { FlexWrp } from '../../../../baseStylesComponents'
import ThinBtn from '../../../buttons/ThinBtn'
import BaseLinkNewWindow from '../../../links/BaseLinkNewWindow'
import BaseBtn from '../../../buttons/BaseBtn'
import { KmWithoutNulls } from '../../../../lib/helpers/forRoutes'
import { strToUpperCase } from '../../../../lib/helpers/forStyling'

export default ({ data }) => {
  const dispatch = useDispatch()
  const flagList = useSelector((s) => s.route.flagList)
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const [isBtn, setIsBtn] = useState(true)
  const [comunicatonStatus, setComunicatonStatus] = useState({ triggered: 0 })
  const [isMsg, setIsMsg] = useState(false)
  const [currentWorkAction, setCurrentWorkAction] = useState('100')
  const [isShowMap, setIsShowMap] = useState(false)

  const togglerMap = () => {
    setIsShowMap(!isShowMap)
  }

  const routeAction = (id) => {
    if (id === 11 || id === 22) {
      dispatch({
        type: 'SAGA_CONFIRM_AT',
        actionType: id,
        data: { routepart: comunicatonStatus.id },
        routeId: data.periodic,
        actionForCard: true,
      })
      return
    }
    if (id === 'ratifyOperator') {
      dispatch({ type: 'SAGA_RATIFY_OPERATOR', route: data.periodic })
      return
    }
    if (id === 'rejectOperator') {
      dispatch({ type: 'SAGA_REJECT_OPERATOR', route: data.periodic })
      return
    }
  }
  const { distance, routeparts, routestatus } = data

  const driversData = data.response ? data.response[data.routeresponse] : null

  const messageAction = {
    1: <AwaitApprovedAction text={strToUpperCase(dic.msg_11_1)} />,
    2: <AwaitApprovedAction text={strToUpperCase(dic.msg_15_1)} />,
    3: (
      <AwaitApprovedAction
        text={strToUpperCase(
          comunicatonStatus.triggered ? dic.msg_11_33 : dic.msg_11_3
        )}
      />
    ),
    4: (
      <AwaitApprovedAction
        text={strToUpperCase(
          comunicatonStatus.triggered ? dic.msg_11_44 : dic.msg_11_4
        )}
      />
    ),
  }

  const btnAction = {
    1: (
      <BaseBtn
        fullSize
        label={dic.btn_action_11}
        nameIcon={1}
        fnClick={() => routeAction(11)}
        forLoadFlow
        localId={data.periodic}
      />
    ),
    2: (
      <BaseBtn
        fullSize
        label={dic.btn_action_22}
        nameIcon={2}
        fnClick={() => routeAction(22)}
        forLoadFlow
        localId={data.periodic}
      />
    ),
    100: (
      <FlexWrp>
        <ThinBtn
          type=""
          firstLetter
          label={dic.btn_reject}
          onClick={() => routeAction('rejectOperator')}
          style={{ marginRight: '2rem' }}
        />
        <BaseBtn
          fullSize
          label={dic.msg_34}
          pad="forTwo"
          fnClick={() => routeAction('ratifyOperator')}
          forLoadFlow
          localId={data.periodic}
        />
      </FlexWrp>
    ),
  }

  useEffect(() => {
    const result =
      routestatus > 2 && routestatus <= 4
        ? false
        : routestatus > 4 && routestatus <= 11
        ? comunicatonStatus.triggered
          ? false
          : true
        : true
    setIsMsg(result)
  }, [comunicatonStatus, routestatus, currentWorkAction])

  useEffect(() => {
    if (flagList.bottom) {
      setIsBtn(false)
    }
  }, [flagList]) // eslint-disable-line
  return (
    <Wrapper>
      <InfoBodyTop
        routestatus={Number(routestatus)}
        driversData={driversData}
        dic={dic}
        obj={data}
        userType={1}
      />

      <EnRouteRouteParts
        borderTop={routestatus < 4 ? true : false}
        arr={routeparts}
        lang={dic}
        routestatus={routestatus}
        isFinishRoute={data.complete}
        mrgnBtm="4rem"
        setComunicatonStatus={setComunicatonStatus}
        setCurrentWorkAction={setCurrentWorkAction}
      />
      <FlexBox mrgnBtm={routestatus < 4 ? '8rem' : '3rem'}>
        <BaseLinkNewWindow label={dic.show_map} onClick={togglerMap} />
        <InfoLink style={{ textTransform: 'lowercase' }}>
          {distance && `${KmWithoutNulls(distance)} ${dic.km}`}
        </InfoLink>
      </FlexBox>
      {isShowMap && (
        <RouteMap
          points={routeparts}
          title={`${dic.route_map} № ${data.periodic}`}
          fnClose={togglerMap}
        />
      )}
      <InfoBodyBottom
        routestatus={routestatus}
        driversData={driversData}
        dic={dic}
        obj={data}
        userType={1}
      />

      {/* TODO: refactoring this */}
      {(routestatus !== 3 || routestatus !== 4) && (
        <Action id="action">
          {isBtn ? (
            isMsg ? (
              messageAction[currentWorkAction]
            ) : (
              btnAction[currentWorkAction]
            )
          ) : (
            <ConfirmOfAction
              title={`${strToUpperCase(dic.msg_1)}!`}
              text={strToUpperCase(dic.msg_2)}
            />
          )}
        </Action>
      )}
    </Wrapper>
  )
}
