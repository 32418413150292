import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import NavTab from '../../components/tabs/NavTab'
import useRouter from '../../hooks/useRouter'

const Wrapper = styled.nav`
  width: 100%;
  max-width: 100vw;
  padding: 1.5rem 0.8rem 2rem;
  background-color: ${(p) => p.theme.opposite};
  border-bottom: 0.1rem solid ${(p) => p.theme.lines};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`
export default ({ style = {} }) => {
  const navList = useSelector((s) => s.user.navlist)
  const router = useRouter()
  const dispatch = useDispatch()
  //TODO maybe need save in redux current path  - not id
  const changeTap = (id, route) => {
    router.push(route)
    dispatch({ type: 'NAVMENU_SET_CURRENT_TAP', payload: String(id) })
  }
  return (
    <Wrapper style={style}>
      {navList && navList.length > 0 ? (
        navList.map((item) => (
          <NavTab
            key={item.id}
            obj={item}
            fnClick={changeTap}
            activeTab={router.pathname}
          />
        ))
      ) : (
        <p>Нет доступных сервисов</p>
      )}
    </Wrapper>
  )
}
