import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Wrapper,
  FlexBox,
  InfoLink,
  Action,
  ConfirmOfAction,
  AwaitApprovedAction,
  EnRouteRouteParts,
  RouteMap,
  InfoBodyTop,
  InfoBodyBottom,
} from '../../../smallPartsForInterface/SmallPartsCard'
import BaseLinkNewWindow from '../../../links/BaseLinkNewWindow'
import BaseBtn from '../../../buttons/BaseBtn'
import { KmWithoutNulls } from '../../../../lib/helpers/forRoutes'
import { strToUpperCase } from '../../../../lib/helpers/forStyling'
import OfferTransportForm from '../../../../containers/forms/OfferTransportForm'

export default ({ data }) => {
  const dispatch = useDispatch()
  const flagList = useSelector((s) => s.route.flagList)
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const [isBtn, setIsBtn] = useState(true)
  const [isShowOfferTransport, setIsShowOfferTransport] = useState(false)
  const [comunicatonStatus, setComunicatonStatus] = useState({ triggered: 0 })
  const [isMsg, setIsMsg] = useState(false)
  const [currentWorkAction, setCurrentWorkAction] = useState('100')
  const [isShowMap, setIsShowMap] = useState(false)

  const togglerMap = () => {
    setIsShowMap(!isShowMap)
  }
  const togglerOfferForm = (bool) => {
    if (bool) {
      setIsShowOfferTransport(false)
      setIsBtn(false)
    } else {
      setIsShowOfferTransport(false)
    }
  }

  const routeAction = (id) => {
    if ((id >= 1 && id <= 4) || id === 33 || id === 44) {
      dispatch({
        type: 'SAGA_ARRIVED_TO',
        actionType: id,
        data: { routepart: comunicatonStatus.id },
        routeId: data.periodic,
        actionForCard: true,
      })
      return
    }
    if (id === 'offerCar') {
      setIsShowOfferTransport(true)
    }
  }
  const { distance, routeparts, routestatus, periodic } = data

  const driversData = data.response ? data.response[data.routeresponse] : null

  const messageAction = {
    1: <AwaitApprovedAction text={strToUpperCase(dic.msg_11)} />,
    2: <AwaitApprovedAction text={strToUpperCase(dic.msg_15)} />,
    3: <AwaitApprovedAction text={strToUpperCase(dic.msg_11)} />,
    4: <AwaitApprovedAction text={strToUpperCase(dic.msg_15)} />,
  }

  const btnAction = {
    1: (
      <BaseBtn
        fullSize
        label={dic.btn_action_1}
        nameIcon={1}
        fnClick={() => routeAction(1)}
        forLoadFlow
        localId={data.periodic}
      />
    ),
    2: (
      <BaseBtn
        fullSize
        label={dic.btn_action_2}
        nameIcon={2}
        fnClick={() => routeAction(2)}
        forLoadFlow
        localId={data.periodic}
      />
    ),
    3: (
      <BaseBtn
        fullSize
        label={
          comunicatonStatus.triggered ? dic.btn_action_33 : dic.btn_action_3
        }
        nameIcon={3}
        fnClick={() => routeAction(comunicatonStatus.triggered ? 33 : 3)}
        forLoadFlow
        localId={data.periodic}
      />
    ),
    4: (
      <BaseBtn
        fullSize
        label={
          comunicatonStatus.triggered ? dic.btn_action_44 : dic.btn_action_4
        }
        nameIcon={4}
        fnClick={() => routeAction(comunicatonStatus.triggered ? 44 : 4)}
        forLoadFlow
        localId={data.periodic}
      />
    ),

    222: (
      <BaseBtn
        fullSize
        label={`${dic.msg_17}...`}
        fnClick={() => routeAction('offerCar')}
      />
    ),
  }
  useEffect(() => {
    const result = Boolean(
      (routestatus === 15
        ? comunicatonStatus.triggered
          ? false
          : true
        : false) ||
        (routestatus === 16
          ? comunicatonStatus.triggered
            ? false
            : true
          : false) ||
        (routestatus === 17
          ? comunicatonStatus.triggered
            ? true
            : false
          : false) ||
        (comunicatonStatus.triggered
          ? routestatus === 15 || routestatus === 16
            ? false
            : true
          : false)
    )
    setIsMsg(result)
  }, [comunicatonStatus, routestatus, currentWorkAction])
  useEffect(() => {
    if (flagList.bottom) {
      setIsBtn(false)
    }
  }, [flagList]) // eslint-disable-line
  return (
    <Wrapper>
      {isShowOfferTransport && (
        <OfferTransportForm
          periodic={periodic}
          fnClose={togglerOfferForm}
          flagList={flagList}
          routeparts={routeparts.map((item) => {
            return {
              address: `${item.checkpoint.town.title_ru} ${item.checkpoint.address}`,
              title: item.workaction_data?.title_ua || item.workaction_data?.title_ru,
              date1: item.date1,
              time1: item.time1,
              id: item.id,
              header: item.checkpoint.town.title_ru,
              workaction: item.workaction,
              bordercrossforoperator: item?.bordercrossforoperator
            }
          })}
        />
      )}
      <InfoBodyTop
        routestatus={Number(routestatus)}
        driversData={driversData}
        dic={dic}
        obj={data}
        userType={2}
      />

      <EnRouteRouteParts
        borderTop={routestatus < 4 ? true : false}
        arr={routeparts}
        lang={dic}
        routestatus={routestatus}
        isFinishRoute={data.complete}
        mrgnBtm="4rem"
        setComunicatonStatus={setComunicatonStatus}
        setCurrentWorkAction={setCurrentWorkAction}
        data={data}
      />
      <FlexBox mrgnBtm={routestatus < 4 ? '8rem' : '3rem'}>
        <BaseLinkNewWindow label={dic.show_map} onClick={togglerMap} />
        <InfoLink style={{ textTransform: 'lowercase' }}>
          {distance && `${KmWithoutNulls(distance)} ${dic.km}`}
        </InfoLink>
      </FlexBox>
      {isShowMap && (
        <RouteMap
          points={routeparts}
          title={`${dic.route_map} № ${data.periodic}`}
          fnClose={togglerMap}
        />
      )}
      <InfoBodyBottom
        routestatus={routestatus}
        driversData={driversData}
        dic={dic}
        obj={data}
        userType={2}
      />

      {/* TODO: refactoring this */}
      {(routestatus !== 3 || routestatus !== 4) && (
        <Action id="action">
          {isBtn ? (
            isMsg ? (
              messageAction[currentWorkAction]
            ) : (
              btnAction[currentWorkAction]
            )
          ) : (
            <ConfirmOfAction
              title={`${strToUpperCase(dic.msg_1)}!`}
              text={strToUpperCase(dic.msg_2)}
            />
          )}
        </Action>
      )}
    </Wrapper>
  )
}
