import { combineReducers } from 'redux'
import general from './general'
import apiError from './apiError'
import defaultLang from './defaultLang'
import buttonFlow from './buttonFlow'
import user from './user'
import messages from './messages'
import mainMenu from './mainMenu'
import counter from './counter'
import route from './route'
import newRoute from './newRoute'
import forAllRoutes from './forAllRoutes'
import forMyRoutes from './forMyRoutes'
import tender from './tender'
import forAllTenders from './forAllTenders'
import forMyTenders from './forMyTenders'
import myPark from './myPark'
import checkpoint from './checkpoint'
import redirectOutside from './redirectOutside'
import filterForTab from './filterForTab'
import checkList from './checkList'
import selectList from './selectList'
import routeDocuments from './routeDocuments'
import routeChronology from './routeChronology'
import company from './company'
import chats from "./chats"
import newTS from './newTS'
//for test
import testSelect from './testSelect'

export default combineReducers({
  general,
  apiError,
  defaultLang,
  user,
  messages,
  mainMenu,
  buttonFlow,
  counter,
  route,
  newRoute,
  forAllRoutes,
  forMyRoutes,
  tender,
  forAllTenders,
  forMyTenders,
  myPark,
  checkpoint,
  redirectOutside,
  filterForTab,
  checkList,
  selectList,
  routeDocuments,
  routeChronology,
  company,
  chats,
  newTS,

  // for test
  testSelect,
})
