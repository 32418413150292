import React from 'react'
import styled from 'styled-components'
import Icon from '../../components/Icon'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 10rem;
`
const Btn = styled.button`
  width: 100%;
  padding: 1.3rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => p.theme.selected};
  border: 0.1rem solid ${(p) => p.theme.selected};
  :active {
    border: 0.1rem solid ${(p) => p.theme.inputBorder};
  }
`
const Label = styled.p`
  position: absolute;
  top: -2rem;
  left: 0.4rem;
  color: ${(p) => p.theme.regular_txt};
  font-size: ${(p) => p.theme.h5};
  opacity: 0.8;
`
const Quantity = styled.p`
  padding-right: 0.5rem;
  font-size: ${(p) => p.theme.h4};
`

const Content = styled(Quantity)`
  padding-right: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default ({
  label = 'Accumulator',
  content = [],
  fnClick = () => {},
  style,
}) => {
  return (
    <Wrapper style={style}>
      <Label>{label}</Label>
      <Btn type="button" onClick={fnClick} id={label}>
        {content.length > 0 && <Quantity>{`(${content.length})`}</Quantity>}
        <Content>
          {content
            .map((obj) =>
              obj.quantity ? `${obj.label} (${obj.quantity})` : obj.label
            )
            .join(', ')}
        </Content>
        <Icon
          name="arrowShevron"
          type="regularText"
          style={{ marginLeft: 'auto', transform: 'rotate(90deg)' }}
        />
      </Btn>
    </Wrapper>
  )
}
