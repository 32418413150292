import React from 'react'
import {
  Wrapper,
  Title,
  CargoTitle,
  Info,
  Price,
  ScaleRouteParts,
  PeriodicWrp,
} from '../../../smallPartsForInterface/SmallPartsWidgets'
import Icon from '../../../Icon'
import {
  NumberWithoutNulls,
  parseCargoInfo,
  KmWithoutNulls,
  parseExtraCarOption,
} from '../../../../lib/helpers/forRoutes'
import YellowMarker from '../../../YellowMarker'
import { currencyIcons } from '../../../../lib/constants'
import useRouter from '../../../../hooks/useRouter'
import { strToUpperCase } from '../../../../lib/helpers/forStyling'

const defaultState =  {
  defaultcurrency: null,
  budget: null,
  distance: null,
  routeparts: null,
  periodic: null,
  client_relation: null,
}

export default ({ obj, dic }) => {
  const router = useRouter()
  const data = obj? obj : defaultState;
  const {
    defaultcurrency,
    budget,
    distance,
    routeparts,
    periodic,
    client_relation,
  } = data

  return (
    obj &&
    dic && (
      <Wrapper isMrgnBtm onClick={() => router.push(`/route/${periodic}`)}>
        <div className="flexWrp" style={{ marginBottom: '1.5rem' }}>
          <Price>{`${strToUpperCase(dic.msg_10)}`}</Price>
        </div>
        <div className="flexWrp" style={{ marginBottom: '1.8rem' }}>
          {routeparts && (
            <Info head>{`${dic.points} ${routeparts.length}`}</Info>
          )}

          {Boolean(obj.budget) && obj.budget > 1 && (
            <Info head>{`${
              defaultcurrency
                ? currencyIcons[defaultcurrency]
                : currencyIcons.uah
            } ${NumberWithoutNulls(budget)}`}</Info>
          )}
          {Boolean(distance) && distance > 1 && (
            <Info head>{`${KmWithoutNulls(distance)} ${dic.km}`}</Info>
          )}
        </div>
        <div style={{ marginBottom: '0.5rem' }}>
          <div className="flexWrp">
            {client_relation && (
              <Title isMessage={false}>
                {client_relation.company_relation.title_ru}
              </Title>
            )}
            {obj.needAttention && <YellowMarker />}
          </div>
          <Info className="flexWrp">
            <Icon name="hook" type="focused" style={{ marginTop: '0.4rem' }} />
            <CargoTitle style={{ textTransform: 'lowercase' }}>
              {parseCargoInfo(routeparts)}
            </CargoTitle>
          </Info>
          <Info style={{ paddingLeft: '1.35rem' }}>
            {parseExtraCarOption(obj)}
          </Info>
        </div>
        <ScaleRouteParts arr={routeparts} dic={dic} status="finish" />
        <PeriodicWrp right>{`№ ${periodic}`}</PeriodicWrp>
      </Wrapper>
    )
  )
}
