import { takeEvery, put } from 'redux-saga/effects'

export function* worker({ data }) {
  try {
    const forNewRoute = {
      codeApi: data.userroutenumberrequired.values[0] || false,
      category: data.usercategoryrequired.values[0] || false,
      operatorselect: data.operatorselect.values[0] || false,
      baseprice: data.baseprice.values[0] || false,
      winnerprice: data.winnerpriceallow.values[0] || false,
      blindTender: data.blindTenderAvailable.values[0] || false,
      showPayor: data.showPayor.values[0] || false,
    }
    yield put({
      type: 'UPDATE_NEWROUTE_SETTINGS',
      payload: { ...forNewRoute },
    })
    yield put({
      type: 'UPDATE_NEWROUTE_NDS',
      payload: { id: Number(data.nds[0]) || 0 },
    })
    yield put({
      type: 'UPDATE_NEWROUTE_TENDERISBLIND',
      payload: data.blindTenderDefault[0] || false,
    })
  } catch (e) {
    yield put({
      type: 'SHOW_ERROR_API',
      code: e.response ? String(e.response.status).slice(0, 1) : 4,
      message: e.response ? e.response.data.message : e.message,
    })
  }
}

export default function* getUserSettings() {
  yield takeEvery('SAGA_UPDATE_NEWROUTE_SETTINGS', worker)
}
