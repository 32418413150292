import { replaceChartSymbols } from './forStyling'
import { convertTime, convertDate } from './forRoutes'

export const comparisonObj = (currentObj, serverObj) => {
  const data = Object.keys(serverObj)
  return Object.keys(currentObj).reduce((total, item) => {
    if (!data.find((el) => el === item)) {
      return [...total, { [item]: replaceChartSymbols(currentObj[item]) }]
    } else {
      return total
    }
  }, [])
}

export const replaceObj = (serverObj) => {
  const data = Object.entries(serverObj)
  return data.reduce((obj, item) => {
    if (item[0] === 'form_err_required') {
    }
    return { ...obj, [item[0]]: replaceChartSymbols(item[1]) }
  }, {})
}

export const concatinationRouteObj_NewRoute = (newData) => {
  return {
    route_apicode: newData.codeApi || null, //string(40)
    budget: newData.routePrice, //decimal(12, 2)
    draft: false, //черновик
    currency: newData.currency.currencycode, //string(3)
    nds: Boolean(newData.currentNds.id),
    paymenttype: newData.paymentType.id, //	int (1) { id: '3', localTitle: 'Наличные', union: 'Наличные' }
    payorcode: newData.payorcompany.id, //string (30){ id: '', localTitle: '', union: '' },
    chargetype: newData.chargetype.map((obj) => obj.id),
    cartype: newData.cartype.map((obj) => obj.id),
    ferryproperty: newData.ferryproperty.map((obj) => obj.id),
    ferryproperty_details: newData.ferryproperty.reduce((acc, obj) => {
      if (obj.quantity) {
        acc = { ...acc, [obj.id]: obj.quantity }
      }
      return acc
    }, {}),
    //возможно
    quantity: newData.quantity_auto,
    remark: newData.remark,
    priceFor: newData.priceFor.code || '',
    tender: newData.istender,
    tender_terms: newData.tenderterms,
    total_count: newData.totalcount,
    step: newData.tenderStep,
    // partners
    forallpartners: newData.operators.length > 0 ? 0 : 1, // если я хочу выбрать партненров  = значение в false
    operators: newData.operators,
    max_quote: newData.tenderStartPrice || '0',
    publish_datetime: `${convertDate(newData.plannedTenderDate)} ${convertTime(
      newData.plannedTenderTime
    )}`,
    tender_deadline: `${convertDate(
      newData.tenderavailableuntilmoment
    )} ${convertTime(newData.tenderavailableuntilmoment_time)}`,
    freeforall: newData.tendertype,
    blindtender: newData.tenderIsBlind,
    baseprice: newData.tenderBasePrice,
    winnerprice: newData.tenderWinnerPrice,
  }
}

const prepareMeasureunit = (obj) => {
  if (!obj) {
    return { measureunit: null }
  }
  if (obj.union && obj.quantity) {
    return { measureunit: { id: obj.id, quantity: obj.quantity } }
  }

  return { measureunit: null }
}

export const concatinationRoutePartsArr_NewRoute = (newData) => {
  const template = (item) => {
    switch (item.workaction) {
      case 1 || '1':
        return {
          workaction: item.workaction,
          apicode: `${item.country.isoNum}${item.town.title}${item.address.id}`,
          category: item.group.id || null, //group: { union: '', id: 12345 },
          cargo: item.cargo.union,
          weight: item.weight ? item.weight.union : null,
          volume: item.volume ? item.volume.union : null,
          height: item.height ? item.height.union : null,
          length: item.length ? item.length.union : null,
          width: item.width ? item.width.union : null,
          ...convertDate(item.date),
          ...convertTime(item.time),
          ...prepareMeasureunit(item.category || null),
          company_title: item.company.title,
          company_natcomid: item.company.natcomid,
          contact_name: item.checkpointcontactdata.contactperson,
          contact_email: item.checkpointcontactdata.email,
          contact_cellnum: item.checkpointcontactdata.cellnum,
          contact_telnum: item.checkpointcontactdata.telnum
            ? item.checkpointcontactdata.telnum
            : null,
          geo_country_iso_num: item.country.isoNum,
          geo_city: item.town.title,
          geo_region: item.town.region.title || null,
          geo_address: item.address.address,
          geo_district: item.town.district,
        }

      case 2 || '2':
        return {
          workaction: item.workaction,
          apicode: `${item.country.isoNum}${item.town.title}${item.address.id}`,
          ...convertDate(item.date),
          ...convertTime(item.time),
          company_title: item.company.title,
          company_natcomid: item.company.natcomid,
          contact_name: item.checkpointcontactdata.contactperson,
          contact_email: item.checkpointcontactdata.email,
          contact_cellnum: item.checkpointcontactdata.cellnum,
          geo_country_iso_num: item.country.isoNum,
          geo_city: item.town.title,
          geo_region: item.town.region.title || null,
          geo_address: item.address.address,
          geo_district: item.town.district,
        }

      case 3 || '3':
        return {
          workaction: item.workaction,
          geo_apicode: item.company.id,
          ...convertDate(item.date),
          ...convertTime(item.time),
        }

      case 4 || '4':
        return {
          workaction: item.workaction,
          border_operator_select: item.indicatesСarrier,
          geo_town_id: item.town ? item.town.id : null,
        }

      default:
        return {}
    }
  }

  return newData.map((obj) => {
    return template(obj)
  })
}
