import Type from '../types'

const initialState = {
  tenders: [],
  tendersList: [],
  tendersForWidget: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_ALL_TENDER_GROUP:
      return {
        ...state,
        tendersList: action.payload,
      }
    case Type.SET_ALL_TENDERS:
      return {
        ...state,
        tenders: action.payload,
        tendersList: state.tendersList.map((obj) => {
          const computation = obj.stati.flatMap((type) =>
            action.payload.filter((el) => el.contextstatus === type)
          )
          return {
            ...obj,
            isSort: true,
            content: computation,
            needAttention: computation.filter((el) => el.needAttention).length,
          }
        }),
      }
    default:
      return state
  }
}
