export const testReduxStore = {
  messages: { info: 2, chat: 5 },
  route: { widgetList: [] },
  user: {
    profile: {
      id: 17,
      name: 'Mike Tyson',
      avatar:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Mike_Tyson_2019_by_Glenn_Francis.jpg/1200px-Mike_Tyson_2019_by_Glenn_Francis.jpg',
      company: 'ТОВ Игорек',
      usertype: 2,
      language: 'en',
      country: 'USA',
      client: 65613,
    },
    hotKeys: [
      {
        title: 'test hot key',
        id: '0',
        path: '/',
      },
    ],
    navList: [
      { id: 1, title: 'nav title 1', path: '/nav_title_1' },
      { id: 2, title: 'nav title 2', path: '/nav_title_2' },
    ],
    auxMenu: [
      { id: 205, title: 'Вийти', iconType: 'logout', action: 'logout' },
      {
        id: 207,
        title: 'Повна версія',
        iconType: 'desctop',
        externalLink: 'https://sovtes.ua/a/',
      },
    ],
  },
}
export const dic = {
  add_driver_2: 'добавить второго водителя',
  address: 'адрес',
  address_legal: 'юридический адрес',
  address_mailing: 'почтовый адрес',
  api_code: 'код АПИ',
  axes: 'ось',
  axes_quantity: 'кол-во осей',
  bank_name: 'название банка',
  bank_account: 'расчетный счет',
  bank_mfo: 'мфо',
  bank_egrpou: 'егрпоу',
  belowMine: 'ниже вашей',
  bid: 'cтавка',
  bid_current: 'текущая ставка',
  bid_current_short: 'текущая',
  bid_edit: 'редактировать ставку',
  bid_deleted: 'вы удалили свою ставку в этом тендере',
  bid_dont_send: 'вы еще не делали ставку в этом тендере',
  bid_my_last: 'ваша ставка последняя',
  bid_my_last_long:
    'Ваше предложение на данный момент минимальное; Вы сможете предложить новую цену только после предложения другого перевозчика.',
  bid_send: 'сделать ставку',
  bid_your: 'ваша',
  bids: 'cтавки',
  bids_not: 'ставок еще нет',
  bids_total: 'cтавок всего',
  blind: 'слепой',
  btn_action_1: 'прибыл на загрузку',
  btn_action_11: 'подтвердить загрузку',
  btn_action_2: 'прибыл на разгрузку',
  btn_action_22: 'подтвердить разгрузку',
  btn_action_3: 'прибыл на таможню',
  btn_action_4: 'прибыл на границу',
  btn_action_33: 'прошел таможню',
  btn_action_44: 'прошел границу',
  btn_back: 'назад',
  btn_cancel: 'отмена',
  btn_clear_all: 'очистить',
  btn_delete: 'удалить',
  btn_loadMore: 'загрузить еще',
  btn_add: 'добавить',
  btn_aply: 'применить',
  btn_ok: 'ок',
  btn_next: 'дальше',
  btn_post: 'опубликовать',
  btn_save: 'сохранить',
  btn_show: 'показать',
  btn_showMore: 'показать ещё',
  btn_reject: 'отклонить',
  btn_yes: 'да',
  btn_no: 'нет',
  brand: 'бренд',
  board: 'борт',
  budget: 'стоимость',
  budget_min_route: 'минимальная стоимость перевозки',
  budget_max_route: 'максимальная стоимость перевозки',
  category: 'категория',
  car: 'авто',
  car_new: 'новое авто',
  car_Title: 'транспортное средство',
  car_location: 'место дислокации авто',
  car_class: 'класс автомобиля',
  car_insurance_term: 'срок страховки',
  carrier: 'перевозчик',
  carrier_indicates: 'указывает перевозчик',
  carriers: 'перевозчики',
  carriers_choise: 'выбрать перевозчиков',
  cargo: 'груз',
  cargo_extra_parameters: 'дополнительные параметры груза',
  cargo_total_weight: 'общий вес груза',
  car_type: 'тип авто',
  car_quantity: 'количество авто',
  carbodytype:'тип кузова за свидетельством',
  charge_type: 'тип загрузки',
  chronology: 'хронология',
  // chat_title: 'чат',
  chassis: 'шасси',
  choice: 'выбрать',
  close: 'закрытый',
  comment: 'коментарий',
  comment_add: 'добавить коментарий',
  contact_person: 'контактное лицо',
  consignee: 'грузополучатель',
  copy: "копировать",
  copy_link: 'ссылка скопирована',
  company_docs: 'документы компании',
  country: 'страна',
  custom_name: 'название таможни',
  customer: 'заказчик',
  currency: 'валюта',
  date: 'дата',
  date_exactly: 'точная дата',
  date_nearest: 'ближайшая дата',
  date_time_start: 'дата и время начала торгов',
  date_time_finish: 'дата и время окончания торгов',
  delete: 'удалить',
  driver: 'водитель',
  driver_birthday: 'дата рождения',
  driver_new: 'новый водитель',
  driver_name: 'имя',
  driver_lastName: 'Фамилия',
  driver_patronymic: 'отчество',
  driver_tel: 'моб. номер',
  driver_license: 'права',
  documents: 'документы',
  driver_license_series: 'серия водительского удостоверения',
  driver_license_number: 'номер водительского удостоверения',
  driver_license_when: 'когда выдано',
  driver_license_by: 'кем выдано',
  driver_id_allParams: 'серия и номер паспорта',
  driver_id_serial:'серия паспорта',
  driver_id_number:'номер паспорта',
  email: 'e-mail',
  err_try_more: 'ошибка ! попробуйте еще раз',
  extra: 'дополнительно',
  mileage_laden: 'груженый пробег',
  mileage_empty: 'пустой пробег',
  ferry_property: 'атрибут кузова',
  ferry_type: 'тип кузова',
  filters: 'фильтры',
  flight: 'рейс',
  flightAccusative: 'рейсов',
  for: 'для',
  form_err_required: 'обязательное поле',
  form_err_onlyNumbrers: 'только цифры',
  form_err_dateIsNotValid: 'неверно выбрана дата',
  form_err_matchesDrivers: 'совпадает с водителем 1',
  form_err_valueIsGreater: 'значение больше допустимого',
  form_err_valueCannotBeShorterThan: 'не может быть короче',
  form_err_mustBeTheSame: 'должны быть одинаковыми',
  form_err_international_phone_number:'номер международного формата +...',
  from: 'с',
  full_title: 'полное название',
  height: 'высота',
  indicate: 'укажите',
  inn: 'инн',
  info_1: 'не найдено в парке',
  info_1_1: 'нет доступных значений',
  info_2:
    'в слепом тендере перевозчики не видят ставок друг-друга. Вы делаете одну ставку, которую можете редактировать. Все изменения отображаются в хронологии.',
  info_3: 'не указано',
  info_4: 'не доступно в мобильной версии',
  kind: 'вид',
  info_5: 'pазмер файлов до',
  info_55: 'размер файла должен быть до 8 МБ',
  info_6: 'допустимые расширения',
  info_7: 'пока нет загруженных документов',
  info_8: 'вы точно хототе удалить файл',
  info_9: 'невозможно загрузить файл c типом',
  km: 'км',
  kg:'кг',
  leader_position: 'должность руководителя',
  leader_basis: 'действует на основании',
  leader_firstName: 'имя руководителя',
  leader_secondName: 'фамилия руководителя',
  leader_patronymic: 'отчество руководителя',
  left: 'осталось ещё',
  length: 'длина',
  load_more: 'показать ещё',
  loadcapacity: 'грузоподъемность',
  load_file: 'загрузить файл',
  m: 'м',
  min: 'мин',
  mcube: 'м³',
  manufacturer: 'производитель',
  model: 'модель',
  month_name_1: 'январь',
  month_name_2: 'февраль',
  month_name_3: 'март',
  month_name_4: 'апрель',
  month_name_5: 'май',
  month_name_6: 'июнь',
  month_name_7: 'июль',
  month_name_8: 'август',
  month_name_9: 'сентябрь',
  month_name_10: 'октябрь',
  month_name_11: 'ноябрь',
  month_name_12: 'декабрь',
  month_short_name_1: 'янв',
  month_short_name_2: 'фев',
  month_short_name_3: 'мар',
  month_short_name_4: 'апр',
  month_short_name_5: 'май',
  month_short_name_6: 'июн',
  month_short_name_7: 'июл',
  month_short_name_8: 'авг',
  month_short_name_9: 'сен',
  month_short_name_10: 'окт',
  month_short_name_11: 'ноя',
  month_short_name_12: 'дек',
  more2Days: 'более 2 суток',
  msg_1: 'успешно сохранено',
  msg_2: 'изменения отобразятся после обновления страницы',
  msg_3: 'отметить как неинтересный',
  msg_4: 'забронирован вами на',
  msg_4_1: 'Забронирован вами',
  msg_5: 'могу выполнить маршрут',
  msg_6: 'на ближайшее время',
  msg_6_6: 'ближайшее',
  msg_7: 'запросить изменения в маршруте',
  msg_8: 'заменить транспортное средство',
  msg_9: 'запрос на удаление маршрута',
  msg_10: 'маршрут завершен',
  msg_11: 'ожидается подтверждение зaгрузки от заказчика',
  msg_11_1: 'ожидается прибытие на зaгрузку',
  msg_11_3: 'ожидается прибытие на таможню',
  msg_11_33: 'ожидается подтверждение таможенного оформления',
  msg_11_4: 'ожидается прибытие на границу',
  msg_11_44: 'ожидается подтверждение пересечения границы',
  msg_12: 'вы победитель',
  msg_13: 'ожидается выбор победителя',
  msg_14: 'заказчик выбрал другое предложение',
  msg_15: 'ожидается подтверждение разгрузки от заказчика',
  msg_15_1: 'ожидается прибытие на разгрузку',
  msg_16: 'ожидается утверждение транспорта от заказчика',
  msg_17: 'предложить транспорт',
  msg_18: 'запланированные дата и время загрузки',
  msg_19: 'отказаться от выполнения маршрута',
  msg_20: 'подписатся на выполнение',
  msg_21: 'тендер завершен',
  msg_22: 'ожидается подтверждение разгрузки от заказчика',
  msg_23: 'по дате загрузки',
  msg_24: 'по дате разгрузки',
  msg_25: 'пункт отправки',
  msg_26: 'пункт прибытия',
  msg_33: 'ожидается утверждение транспорта от заказчика',
  msg_333: 'ожидается утверждение маршрута от перевозчика',
  msg_34: 'утвердить перевозчика',
  msg_35: 'только для моих партнеров',
  msg_36: 'для всех перевозчиков SOVTES',
  msg_37:
    'Тендер начнется автоматически в указанную вами дату и время. Посмотрать запланированные: Мои тендеры → Запланированные.',
  msg_38:
    'В слепом тендере перевозчики не видят ставок друг-друга, лиш кол-во сделанных ставок. Принимаются одинаковые ставки от разных перевозчиков.',
  msg_39:
    'вы уверены, что покинуть страницу создания маршрута? Данные не сохраняться.',
  msg_server_err: 'Нарушено соединение с сервером, попробуйте еще раз',
  nds_0: 'без НДС',
  nds_1: 'с НДС',
  nds_2: 'без НДС',
  nds_title_1: 'наличие НДС',
  nds_license: '№ свидетельства НДС',
  np_key: 'ключ Новой Почты',
  notLess: 'не менее',
  noMatches: 'нет совпадений',
  number: 'номер',
  of: 'из',
  offer: 'заказ',
  offers: 'предложения',
  offers_from: 'принимать предложения c',
  offers_up_to: 'принимать предложения до',
  offers_per: 'принимать предложения за',
  open: 'открытый',
  payment: 'оплата',
  payment_type: 'метод оплаты',
  payment_method: 'способ оплаты',
  payment_type_title: 'стоимость и условия оплаты',
  payor: 'плательщик',
  period: 'период',
  per: 'за',
  pieces: 'шт',
  point_add: 'добавить пункт',
  point: 'пункт',
  point_border: 'пункт пересечения границы',
  pointAccusative: 'точек',
  points: 'точек',
  price: 'цена',
  price_base: 'базовая цена',
  price_indicate: 'укажите цену',
  price_start: 'стартовая цена',
  price_start_short: 'стартовая',
  price_winner: 'цена победителя',
  price_info_1: 'указать цену самостоятельно',
  pwa_btn_title: 'установить на рабочий стол',
  quantity: 'кол-во',
  quantity_total: 'общее количество',
  reason: 'причина',
  remark: 'примечание',
  remark_add: 'добавить примечание',
  return: 'восстановить',
  regular: 'обычный',
  requirementVehicle: 'требования к ТС',
  reserveOn: 'забронировать на',
  responsible_logistician: 'ответственный логист',
  roundTheClock: 'Круглосуточно',
  route: 'маршрут',
  route_new: 'новый маршрут',
  route_create: 'создать новый маршут',
  route_err_msg_1: 'маршрут должен заканчиваться разгрузкой',
  route_err_msg_2: 'маршрут должен содержать минимум 2 пунта',
  route_enter_full_route_number: "введите полный номер маршрута",
  routeAccusative: 'рейсов',
  route_map: 'карта маршрута',
  route_note: 'примечание к маршруту',
  routes_created: 'создано маршрутов',
  seeAll: 'смотреть все',
  show_map: 'открыть карту',
  simbolAccusative: 'символов',
  site: 'сайт',
  share: 'поделиться',
  shipper: 'грузоотправитель',
  schedule: 'запланировать',
  spend_now: 'провести сейчас',
  step: 'шаг',
  sum: 'сумма',
  t: 'т',
  tel: 'телефон',
  tender: 'тендер',
  tender_post: 'опубликовать тендер',
  tender_type: 'тип тендера',
  tender_hold: 'провести тендер',
  tender_terms: 'условия тендера',
  tender_result: 'результаты торгов',
  tender_currency: 'валюта тендера',
  tender_indicate_start_date: 'укажите дату начала торгов',
  tender_indicate_start_time: 'укажите время начала торгов',
  tender_indicate_finish_date: 'укажите дату окончания тендера',
  tender_indicate_finish_time: 'укажите время окончания тендера',
  time: 'время',
  time_exactly: 'точное время',
  to: 'до',
  total_weight: 'общий вес',
  town: 'город',
  town_dispatch: 'город отправки',
  town_unload: 'город разгрузки',
  trailer: 'прицеп',
  trailer_new: 'новый прицеп',
  trailer_type: 'тип прицепа',
  type_participation: 'тип участия',
  unit_my: 'мои единицы измерения',
  vehicle: 'транспортное средство',
  vehicleInfo: 'полное досье транспортного средства',
  volume: 'объем',
  week_day_short_1: 'вс',
  week_day_short_2: 'пн',
  week_day_short_3: 'вт',
  week_day_short_4: 'ср',
  week_day_short_5: 'чт',
  week_day_short_6: 'пт',
  week_day_short_7: 'сб',
  weight: 'вес',
  weight_without_cargo: "вес без груза",
  width: 'ширина',
  willCarry: 'перевезу',
  willCarryFor: 'перевезу за',
  won: 'выигранный',
  wont_to_transport: 'требуется перевезти',
  workaction_1: 'загрузка',
  workaction_2: 'разгрузка',
  workaction_3: 'таможня',
  workaction_4: 'граница',
  year_sort:"г",
  year_of_manufact:'год производства',
  your: 'ваша',

}

export const langObj = {
  autorization: {
    title: 'Вхiд',
    input_login: 'Логiн або Email',
    input_password: 'Пароль',
    link_registration: 'зареєструватися',
    link_renewal: 'забули пароль?',
    button_confirm: 'увійти',
  },
  renewal: {
    title: 'забули пароль',
    input_email: 'E-mail',
    input_tel: 'телефон',
    link_back: 'згадали пароль',
    link_resend: 'відправити знову.',
    button_confirm: 'далі',
    button_next: 'ввести код підтвердження',
    msgBlock_one: {
      first: 'силка для відновлення паролю повторно відправлена на E-mail',
    },
    msgBlock_two: {
      first: 'на ваш E-mail',
      second: 'відправлена силка для відновлення паролю.',
    },
    msgBlock_three: {
      first: 'перевірте папку СПАМ.',
      second:
        'якщо лист зі силкою для відновлення паролю не надійшло в продовж 5 хвилин, можливо',
    },
  },
  newPassword: {
    title: 'Відновлення пароля',
    input_password1: 'Новий пароль',
    input_password2: 'Повторіть пароль',
    input_code: 'код підверження',

    button_confirm: 'Зберегти новий пароль',
    success_msg: 'пароль успішно змінений'
  },
  loader: {
    inProgress: 'Загрузка...',
    done: 'Виконано!',
    error: 'Помилка:(',
  },
  notFound: {
    title: 'На жаль ця сторінка не існує :(',
    text: ' Для уточнення деталей, звернуться у службу підтримки',
    link: 'Замовити зворотній виклик',
  },
}
export const routeObjForTest = {
  routeparts: [
    {
      checkpoint: { town: { title_ru: 'london', region: { title_ru: '' } } },
      id: 'l',
      date1: '2020-10-11',
      date2: '0000-00-00',
      time1: '12:00:00',
      time2: '00:00:00',
    },
    {
      checkpoint: { town: { title_ru: 'mexico', region: { title_ru: '' } } },
      id: 'm',
      date1: '2020-10-11',
      date2: '0000-00-00',
      time1: '11:54:00',
      time2: '00:00:00',
    },
  ],
  periodic: '123',
  bookedtill: '2020-11-10 12:45:22',
  companydata: { title_ru: 'test_company_name' },
  clientdata: { hash: '123wer123' },
  userclientdata: { name: 'name' },
  unitPrices: [
    { value: '5.00', title: 'грн/тонна' },
    { value: '3.00', title: 'грн/мкуб' },
    { value: '0.00', title: 'грн/км' },
  ],
}
export const routeWidgetAprroved = {
  blindtender: false,
  bookedbyme: false,
  bookedsince: null,
  bookedtill: null,
  booktime: 10,
  budget: 8000,
  client: 179,
  client_relation: { company_relation: { title_ru: 'Енотик, ЧЛ' } },
  completemoment: null,
  context: { title: null, iconTitle: null },
  defaultcurrency: null,
  distance: 500,
  id: 132424,
  istender: false,
  kmprice: 0,
  maxquotewithcommission: '0.00',
  mcubeprice: 0,
  minquote: null,
  needAttention: true,
  newMessages: 0,
  operator: 65613,
  operator_relation: {
    company: 832649,
    company_relation: { title_ru: 'ТОВ Игорек' },
    id: 65613,
  },
  periodic: '456-03-21',
  routeparts: [
    {
      cargo: 'название груза',
      checkpoint_relation: {
        id: 14294,
        town_relation: { title_ru: 'Дніпропетровськ', district: '' },
      },
      complete: false,
      date1: '2021-03-04',
      date2: '0000-00-00',
      id: 111453201,
      route: 132424,
      time1: '18:00:00',
      time2: '00:00:00',
      triggered: false,
      workaction: 1,
    },
    {
      cargo: null,
      checkpoint_relation: {
        id: 10598,
        town_relation: { title_ru: 'Київ', district: 'місто Київ' },
      },
      complete: false,
      date1: '2021-03-04',
      date2: '0000-00-00',
      id: 111453202,
      route: 132424,
      time1: '20:00:00',
      time2: '00:00:00',
      triggered: false,
      workaction: 2,
    },
  ],
  routeresponse: 87887,
  routeresponse_relation: [
    {
      cancelled: 0,
      car: 37755,
      car_relation: {
        id: 37755,
        number: 'BI3217BO',
        model: 'Bronco',
        make: 14,
        make_relation: { id: 14, title_ru: 'Ford' },
      },
      driver: 36447,
      driver2: null,
      driver2_relation: null,
      driver_relation: {
        id: 36447,
        firstname: 'Василий',
        lastname: 'Горох',
        patronymic: 'Петрович',
        maincellphone: '+380931232233',
      },
      id: 87887,
      moment: '2021-03-04 10:38:09',
      pricequotewithcommission: null,
      route: 132424,
      trailer: 36858,
      trailer_relation: { id: 36858, number: 'CA2345TT', model: 'VseChetko' },
    },
  ],
  routestatus: 4,
  routetender: null,
  stepwithcommission: '0.00',
  tenderavailableuntilmoment: '0000-00-00 00:00:00',
  tenderuntil: { date: '-0001-11-30', time: '00:00:00' },
  tonnprice: 0,
  unitPrices: [
    { value: null, title: 'грн/тонна' },
    { value: null, title: 'грн/мкуб' },
    { value: null, title: 'грн/км' },
  ],
}
export const routeWidgetEnRoute = {
  id: 127054,
  routestatus: 6,
  defaultcurrency: null,
  budget: 0,
  distance: 100,
  periodic: '388-11-20',
  routeresponse: 85343,
  client: 5748,
  operator: 65613,
  tenderavailableuntilmoment: '0000-00-00 00:00:00',
  istender: false,
  blindtender: false,
  maxquotewithcommission: '0.00',
  stepwithcommission: '0.00',
  completemoment: null,
  bookedsince: null,
  bookedtill: null,
  client_relation: {
    company_relation: {
      title_ru: 'ИНТЕРПАЙП УКРАИНА, ООО',
    },
  },
  operatordata: { title_ru: 'test_shipper_company_name', hash: '123wer123' },
  operator_relation: {
    id: 65613,
    company: 832649,
    company_relation: {
      title_ru: 'ТОВ Игорек',
    },
  },
  routeparts: [
    {
      route: 127054,
      id: 111438820,
      date1: '2020-11-11',
      date2: '0000-00-00',
      time1: '00:00:00',
      time2: '00:00:00',
      workaction: 1,
      triggered: true,
      complete: false,
      cargo: 'алюмини',
      checkpoint: {
        id: 4,
        town: {
          region: {},
          title_ru: 'Олександрія',
        },
      },
      checkpoint_relation: {
        id: 3048,
        town_relation: {
          title_ru: 'Олександрія',
          district: '',
        },
      },
    },
    {
      route: 127054,
      id: 111438821,
      date1: '2020-11-14',
      date2: '0000-00-00',
      time1: '00:00:00',
      time2: '00:00:00',
      workaction: 1,
      triggered: false,
      complete: false,
      cargo: 'Чугун',
      checkpoint: {
        id: 3,
        town: {
          region: {},
          title_ru: 'Горлівка',
        },
      },
      checkpoint_relation: {
        id: 1944,
        town_relation: {
          title_ru: 'Горлівка',
          district: '',
        },
      },
    },
    {
      route: 127054,
      id: 111438822,
      date1: '2020-11-15',
      date2: '0000-00-00',
      time1: '00:00:00',
      time2: '00:00:00',
      workaction: 2,
      triggered: false,
      complete: false,
      cargo: null,
      checkpoint: {
        id: 2,
        town: {
          region: {},
          title_ru: 'Олександрія',
        },
      },
      checkpoint_relation: {
        id: 3048,
        town_relation: {
          title_ru: 'Олександрія',
          district: '',
        },
      },
    },
    {
      route: 127054,
      id: 111438823,
      date1: '2020-11-15',
      date2: '0000-00-00',
      time1: '00:00:00',
      time2: '00:00:00',
      workaction: 2,
      triggered: false,
      complete: false,
      cargo: null,
      checkpoint: {
        id: 1,
        town: {
          region: {},
          title_ru: 'Олексіївка',
        },
      },
      checkpoint_relation: {
        id: 4038,
        town_relation: {
          title_ru: 'Олексіївка',
          district: 'Краснокутський район',
        },
      },
    },
  ],
  routeresponse_relation: [
    {
      id: 85343,
      route: 127054,
      driver: 36206,
      driver2: null,
      car: 37451,
      trailer: null,
      moment: '2020-11-10 12:45:04',
      pricequotewithcommission: null,
      cancelled: 0,
      driver_relation: {
        id: 36206,
        firstname: 'Tamara',
        lastname: 'Витвицкая',
        patronymic: '',
        maincellphone: '380974901991',
      },
      driver2_relation: null,
      car_relation: {
        id: 37451,
        number: '23АВА44',
        model: 'к',
        make: 6,
        make_relation: {
          id: 6,
          title_ru: 'Citroen',
        },
      },
      trailer_relation: null,
    },
  ],
  routetender: null,
  needAttention: true,
  minquote: null,
  tenderuntil: {
    date: '-0001-11-30',
    time: '00:00:00',
  },
  booktime: 10,
  bookedbyme: false,
  unitPrices: [
    {
      value: null,
      title: 'грн/тонна',
    },
    {
      value: null,
      title: 'грн/мкуб',
    },
    {
      value: null,
      title: 'грн/км',
    },
  ],
  newMessages: 0,
  context: {
    title: null,
    iconTitle: null,
  },
  kmprice: 0,
  mcubeprice: 0,
  tonnprice: 0,
}
export const tenderWidgetActiveWithStartPrice = {
  id: 132425,
  routestatus: 2,
  defaultcurrency: null,
  budget: 8000,
  distance: 500,
  periodic: '457-03-21',
  routeresponse: null,
  client: 179,
  operator: null,
  tenderavailableuntilmoment: '2021-03-06 17:00:00',
  istender: true,
  blindtender: false,
  maxquotewithcommission: '5000.00',
  stepwithcommission: '0.00',
  completemoment: null,
  bookedsince: null,
  bookedtill: null,
  client_relation: {
    company_relation: {
      title_ru: 'Енотик, ЧЛ',
    },
  },
  operator_relation: null,
  routeparts: [
    {
      route: 132425,
      id: 111453205,
      date1: '2021-03-04',
      date2: '0000-00-00',
      time1: '00:00:00',
      time2: '00:00:00',
      workaction: 1,
      triggered: false,
      complete: false,
      cargo: 'название груза',
      checkpoint_relation: {
        id: 14294,
        town_relation: {
          title_ru: 'Дніпропетровськ',
          district: '',
        },
      },
    },
    {
      route: 132425,
      id: 111453206,
      date1: '2021-03-04',
      date2: '0000-00-00',
      time1: '00:00:00',
      time2: '00:00:00',
      workaction: 2,
      triggered: false,
      complete: false,
      cargo: null,
      checkpoint_relation: {
        id: 10598,
        town_relation: {
          title_ru: 'Київ',
          district: 'місто Київ',
        },
      },
    },
  ],
  routeresponse_relation: [],
  routetender: {
    id: 8260,
    route: 132425,
    title_ru: 'Днепропетровск → Киев от 4 Марта, 2021 10:35',
    totalcount: '1.00',
    moment: '2021-03-04 10:35:53',
    terms: '',
    winnerclient: null,
    complete: 0,
    winnerpricewithcommission: null,
    currentminpricewithcommission: '0.00',
  },
  needAttention: true,
  minquote: null,
  tenderuntil: {
    date: '2021-03-06',
    time: '17:00:00',
  },
  booktime: 10,
  bookedbyme: false,
  unitPrices: [
    {
      value: null,
      title: 'грн/тонна',
    },
    {
      value: null,
      title: 'грн/мкуб',
    },
    {
      value: null,
      title: 'грн/км',
    },
  ],
  newMessages: 0,
  context: {
    title: null,
    iconTitle: null,
  },
  kmprice: 0,
  mcubeprice: 0,
  tonnprice: 0,
}
export const tenderWidgetActiveWithMyBid = {
  defaultcurrency: 'usd',
  distance: 2538.77,
  tenderavailableuntilmoment: '2021-03-13 13:00:00',
  minquote: {
    mine: true,
    pricequotewithcommission: 5000,
  },
  tenderuntil: {
    date: '2021-03-13',
    time: '13:00:00',
  },
}
export const tenderWidgetActiveWithNotMyBid = {
  defaultcurrency: 'usd',
  distance: 2538.77,
  tenderavailableuntilmoment: '2021-03-13 13:00:00',
  minquote: {
    mine: false,
    pricequotewithcommission: 4500,
  },
  tenderuntil: {
    date: '2021-03-13',
    time: '13:00:00',
  },
}

export const tenderWidgetCompletedYouWin = {
  operator: 65613,
  defaultcurrency: null,
  minquote: {
    mine: true,
    pricequotewithcommission: 140,
  },
}
