import facebook from './Fb.png'
import telegram from './telegram.png'
import viber from './viber.png'
import gmail from './gmail.png'
import link from './link.png'

export default {
  facebook,
  telegram,
  viber,
  gmail,
  link,
}
