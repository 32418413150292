import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import smallLoaderBase from '../../assets/img/loaders/loaderLightmode.png'
import smallLoaderDark from '../../assets/img/loaders/loaderDarkmode.png'

const Wrapper = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Circle = styled.div`
  width: ${(p) => p.size};
  height: ${(p) => p.size};
  background-image: ${(p) =>
    p.isBase ? `url(${smallLoaderBase})` : `url(${smallLoaderDark})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: spin 3s infinite linear;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
export default ({ style, size = '2.5rem', id = 'defaultLoader' }) => {
  const theme = useSelector((s) => s.general.theme)
  return (
    <Wrapper style={style} id={id} data-testid={id}>
      <Circle isBase={theme === 'base'} size={size} />
    </Wrapper>
  )
}
