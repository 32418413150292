import React, { useMemo } from 'react'
import styled from 'styled-components'
import { colors } from '../lib/constants'
import { ReactComponent as Api } from '../assets/svg/icons/api.svg'
import { ReactComponent as Arrow } from '../assets/svg/icons/arrow.svg'
import { ReactComponent as Share } from '../assets/svg/icons/share.svg'
import { ReactComponent as ArrowShevron } from '../assets/svg/icons/arrowShevron.svg'
import { ReactComponent as AvatarMulti } from '../assets/svg/icons/avatarMulti.svg'
import { ReactComponent as AvatarSingle } from '../assets/svg/icons/avatarSingle.svg'
import { ReactComponent as Bill } from '../assets/svg/icons/bill.svg'
import { ReactComponent as Burger } from '../assets/svg/icons/burger.svg'
import { ReactComponent as Calendar } from '../assets/svg/icons/calendar.svg'
import { ReactComponent as Chat } from '../assets/svg/icons/chat.svg'
import { ReactComponent as Chronology } from '../assets/svg/icons/chronology.svg'
import { ReactComponent as Clock } from '../assets/svg/icons/clock.svg'
import { ReactComponent as Close } from '../assets/svg/icons/close.svg'
import { ReactComponent as CancelBtn } from '../assets/svg/icons/cancelBtn.svg'
import { ReactComponent as Coupling } from '../assets/svg/icons/coupling.svg'
import { ReactComponent as Desctop } from '../assets/svg/icons/desktop.svg'
import { ReactComponent as Documents } from '../assets/svg/icons/documents.svg'
import { ReactComponent as Driver } from '../assets/svg/icons/driver.svg'
import { ReactComponent as Eye } from '../assets/svg/icons/eye.svg'
import { ReactComponent as CrossedEye } from '../assets/svg/icons/crossedEye.svg'
import { ReactComponent as Filter } from '../assets/svg/icons/filter.svg'
import { ReactComponent as Hammer } from '../assets/svg/icons/hammer.svg'
import { ReactComponent as Hook } from '../assets/svg/icons/hook.svg'
import { ReactComponent as HookBig } from '../assets/svg/icons/hookBig.svg'
import { ReactComponent as Info } from '../assets/svg/icons/info.svg'
import { ReactComponent as Location } from '../assets/svg/icons/location.svg'
import { ReactComponent as Logout } from '../assets/svg/icons/logout.svg'
import { ReactComponent as Mack } from '../assets/svg/icons/mack.svg'
import { ReactComponent as Car } from '../assets/svg/icons/car.svg'
import { ReactComponent as Mail } from '../assets/svg/icons/mail.svg'
import { ReactComponent as MapIcon } from '../assets/svg/icons/map.svg'
import { ReactComponent as NumberIcon } from '../assets/svg/icons/number.svg'
import { ReactComponent as ScrollToTop } from '../assets/svg/icons/scrollToTop.svg'
import { ReactComponent as Search } from '../assets/svg/icons/search.svg'
import { ReactComponent as Settings } from '../assets/svg/icons/settings.svg'
import { ReactComponent as Support } from '../assets/svg/icons/support.svg'
import { ReactComponent as Tractor } from '../assets/svg/icons/tractor.svg'
import { ReactComponent as Truck } from '../assets/svg/icons/truck.svg'
import { ReactComponent as User } from '../assets/svg/icons/user.svg'
import { ReactComponent as Border } from '../assets/svg/icons/border.svg'
import { ReactComponent as Customs } from '../assets/svg/icons/customs.svg'
import { ReactComponent as Atencion } from '../assets/svg/icons/atencion.svg'
import { ReactComponent as ArrowDown } from '../assets/svg/icons/arrowDown.svg'
import { ReactComponent as ArrowUp } from '../assets/svg/icons/arrowUp.svg'
import { ReactComponent as Done } from '../assets/svg/icons/done.svg'
import { ReactComponent as Theme } from '../assets/svg/icons/theme.svg'
import { ReactComponent as Star } from '../assets/svg/icons/star.svg'
import { ReactComponent as Sad } from '../assets/svg/icons/sad.svg'
import { ReactComponent as Winner } from '../assets/svg/icons/winner.svg'
import { ReactComponent as WinnerNegative } from '../assets/svg/icons/winnerNegative.svg'
import { ReactComponent as Basket } from '../assets/svg/icons/basket.svg'
import { ReactComponent as Pencil } from '../assets/svg/icons/pencil.svg'
import { ReactComponent as Magic } from '../assets/svg/icons/magic.svg'
import { ReactComponent as Trailer } from '../assets/svg/icons/trailer.svg'
import { ReactComponent as Timer } from '../assets/svg/icons/timer.svg'
import { ReactComponent as Prompt } from '../assets/svg/icons/prompt.svg'
import { ReactComponent as SendMsgArrow } from '../assets/svg/other/send-msg-arrow.svg'
import { ReactComponent as ForwardArrow2 } from '../assets/svg/icons/forward-arrow2.svg'

//REQUIRED! add new icon to constans>iconsName
//HAVE TO USE: <Icon name=[icon name] type=[fill color] style=[obj extra styles] />

const icons = {
  api: <Api />,
  arrow: <Arrow />,
  share: <Share />,
  arrowShevron: <ArrowShevron />,
  avatarMulti: <AvatarMulti />,
  avatarSingle: <AvatarSingle />,
  bill: <Bill />,
  burger: <Burger />,
  calendar: <Calendar />,
  chat: <Chat />,
  chronology: <Chronology />,
  clock: <Clock />,
  timer: <Timer />,
  close: <Close />,
  cancelBtn: <CancelBtn />,
  coupling: <Coupling />,
  desctop: <Desctop />,
  documents: <Documents />,
  border: <Border />,
  customs: <Customs />,
  arrowDown: <ArrowDown />,
  arrowUp: <ArrowUp />,
  atencion: <Atencion />,
  prompt: <Prompt />,
  star: <Star />,
  sad: <Sad />,
  done: <Done />,
  driver: <Driver />,
  basket: <Basket />,
  pencil: <Pencil />,
  magic: <Magic />,
  eye: <Eye />,
  crossedEye: <CrossedEye />,
  theme: <Theme />,
  filter: <Filter />,
  hammer: <Hammer />,
  winner: <Winner />,
  winnerNegative: <WinnerNegative />,
  hook: <Hook />,
  hookBig: <HookBig />,
  info: <Info />,
  location: <Location />,
  logout: <Logout />,
  trailer: <Trailer />,
  mack: <Mack />,
  car: <Car />,
  mail: <Mail />,
  map: <MapIcon />,
  number: <NumberIcon />,
  scrollToTop: <ScrollToTop />,
  search: <Search />,
  settings: <Settings />,
  support: <Support />,
  tractor: <Tractor />,
  truck: <Truck />,
  user: <User />,
  sendMsgArrow: <SendMsgArrow />,
  forwardArrow2: <ForwardArrow2 />,
}

const Wrapper = styled.span`
  svg {
    fill: ${(p) => p.theme[colors[p.type]]};
    ${(p) => p.width && `width:${p.width}`};
    ${(p) => p.height && `height:${p.height}`};
  }
`

export default ({
  name = '',
  type = 'icons',
  width = null,
  height = null,
  style = null,
  id = '',
  onClick = () => {},
}) => {
  return useMemo(
    () =>
      icons.hasOwnProperty(name) && (
        <Wrapper
          width={width}
          height={height}
          style={style}
          name={name}
          type={type}
          id={id}
          onClick={onClick}
        >
          {icons[name]}
        </Wrapper>
      ),
    [width, height, style, onClick, name, type, id]
  )
}
