import { takeEvery, call, put, select, race, delay } from 'redux-saga/effects'
import { getMessagesByChat } from '../../lib/requests/chatRequest'
import { delays } from '../../lib/constants'
import {getCurrentLang, getToken, getDicionary } from '../selectors'

export function* worker({ periodic, companyHash }) {
  try {
    const currentLang = yield select(getCurrentLang)
    const token = yield select(getToken)
    const dic = yield select(getDicionary)

    const { result } = yield race({
      result: call(getMessagesByChat, token, currentLang, periodic, companyHash),
      timeout: delay(delays.forCancelRequest),
    })


    if (result && result.status === 200) {
      yield put({
        type: 'SET_DIALOG_MSG',
        payload: {
          data: Object.values(result.data.data),
          key: companyHash
        }
      })
    } else {
      yield put({
        type: 'SHOW_ERROR_API',
        code: 6,
        message: dic.msg_server_err || 'internal server error',
      })
    }
  } catch (e) {
    yield put({
      type: 'SHOW_ERROR_API',
      code: String(e.response.status).slice(0, 1),
      message: e.response ? e.response.data.message : e.message,
    })
    if (e.response && e.response.data.relogin) {
      yield put({ type: 'SAGA_LOGOUT' })
    }
    if (e.response && e.response.data.forwardTo) {
      yield put({
        type: 'REDIRECT_OUTSIDE',
        payload: e.response.data.forwardTo,
      })
    }
  }
}

export default function* allRoutes() {
  yield takeEvery('GET_CHAT_MESSAGES', worker)
}
