import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Checklist from '../../components/popUps/Checklist'

const actionName =  {
  "chargetype": "NEW_TS_UPDATE_LIST_CHARGE_TYPE",
  "ferryproperty": "NEW_TS_UPDATE_LIST_FERRY_PROPERTIES",
  "car": "NEW_TS_UPDATE_CAR",
  "trailer":"NEW_TS_UPDATE_TRAILER",

}

export default () => {
  const dispatch = useDispatch()
  const formData = useSelector((s) => s.newTS)
  const dic = useSelector((s) => s.defaultLang.serverDictionary)

  const titles = {
    "ferryproperty": `${dic.choice} ${dic.ferry_property}`,
    "chargetype":`${dic.choice} ${dic.charge_type}`,
  }

  const [list, setList] =  useState([]);

  const key = formData?.checklistType;
  const dataKey = `${key}Data`;
  const title = titles[key] || "";

  const fnUpdateCheck = (id) => {
    setList(list.map((item)=>{
      if(item.id === id){
        return {
          ...item,
          checked: !item.checked
        }
      }

      return item;
    }))
  }

  const fnUpdateQuantity = ( firstId, id, value) => {
    setList(list.map((item)=>{
      if(item.id === id){
        return {
          ...item,
          quantity: value
        }
      }

      return item;
    }))
  }
  const onSaveChange = () => {

    const value = list.reduce((acc, item)=>{
      const { checked, title, id, quantity = null,  } = item
          if(checked){
            return {
              ...acc,
              label: [ ...acc.label, title],
              data: { ...acc.data, [id]: { quantity }}
            }
          }

      return acc

    },{ label: [], data: {}})

    dispatch({
      type: actionName[formData?.checklistFor],
      payload: {
        ...formData[formData?.checklistFor],
        [key]: value.label.join(", "),
        [dataKey]: value.data,
      }
    })

    dispatch({
      type: actionName[formData?.checklistType],
      payload: { name : "", formName: "" },
    })
    dispatch({ type: 'CLOSE_MODAL' })
  }
  const onCancelChange = () => {
    dispatch({
      type: actionName[formData?.checklistType],
      payload: { name : "", formName: "" },
    })
    dispatch({ type: 'CLOSE_MODAL' })
  }

  useEffect(()=>{
    if(formData?.checklist?.length > 0 && list?.length === 0 ){
      const beforeValues = formData[formData?.checklistFor][dataKey]
      if(beforeValues){
        const result = formData.checklist.reduce((acc, item)=>{
         let _item = null
          const { id } = item
          const findItem = beforeValues[id]

          if(findItem){
            const quantity = findItem?.quantity
            _item = {
              ...item,
              checked: true
            }
              if(quantity) {
                _item = {
                  ..._item,
                  quantity
                }
              }

          }

          return _item ? [ ...acc,_item ] : [...acc, item]

        },[])

        return setList(result)
      }

      return setList(formData.checklist)
    }
  },[formData])

  useEffect(() => {
    return () => dispatch({
      type:"NEW_TS_UPDATE_CHECKLIST",
      payload:[],
    })
  }, [])



  return (
    <Checklist
      title={title}
      list={list}
      fnUpdateCheck={fnUpdateCheck}
      fnUpdateQuantity={fnUpdateQuantity}
      fnSave={onSaveChange}
      fnCancel={onCancelChange}
    />
  )
}
