import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  Wrapper,
  Price,
  Info,
  CargoTitle,
  ScaleRouteParts,
  PeriodicWrp,
} from '../../../smallPartsForInterface/SmallPartsWidgets'
import Icon from '../../../Icon'
import {
  NumberWithoutNulls,
  parseCargoInfo,
  parseUnitPrices,
  KmWithoutNulls,
  parseExtraCarOption,
} from '../../../../lib/helpers/forRoutes'
import { currencyIcons } from '../../../../lib/constants'
import { haveManyMinAreLeft } from '../../../../lib/helpers/forRoutes'
import useRouter from '../../../../hooks/useRouter'
import useTimer from '../../../../hooks/useTimer'

const Context = styled.p`
  max-width: 50%;
  font-size: ${(p) => p.theme.h5};
  color: ${(p) => p.theme.trigger};
  font-weight: 500;
  text-align: right;
`
export default ({ obj, dic, memory = '' }) => {
  const router = useRouter()
  const myref = useRef(null)
  const dispatch = useDispatch()
  const isIos = useSelector((s) => s.general.isIos)
  const [timerValue, haveTimerValue] = useTimer(
    {
      date: obj.bookedtill ? obj.bookedtill.split(' ')[0] : '',
      time: obj.bookedtill ? moment(obj.bookedtill).format('HH:mm:ss') : '',
    },
    0,
    dic.more2Days
  )

  const {
    defaultcurrency,
    budget,
    distance,
    routeparts,
    periodic,
    unitPrices,
  } = obj

  const widgetClick = () => {
    dispatch({ type: 'REMEMBER_ROUTE_WIDGET', payload: periodic })
    router.push(`/route/${periodic}`)
  }

  const executeScroll = () =>
    myref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })

  useEffect(() => {
    if (memory === periodic) {
      executeScroll()
    }
  }, [memory, periodic])

  return (
    obj &&
    dic && (
      <span ref={myref}>
        <Wrapper isMrgnBtm onClick={widgetClick}>
          <div className="flexWrp" style={{ marginBottom: '0.7rem' }}>
            {Boolean(obj.budget) && obj.budget > 1 && (
              <Price>{`${
                defaultcurrency
                  ? currencyIcons[defaultcurrency]
                  : currencyIcons.uah
              } ${NumberWithoutNulls(budget)}`}</Price>
            )}
            {obj.bookedbyme && !obj.context.title ? (
              (haveTimerValue || isIos) && (
                <p className="timer">
                  {`${dic.msg_4}: ${
                    isIos ? haveManyMinAreLeft(obj.bookedtill) : timerValue
                  }`}
                </p>
              )
            ) : (
              <Context>
                {`${obj.context.title ? obj.context.title : ''}`}
                {obj.context.iconTitle && (
                  <Icon
                    name={obj.context.iconTitle}
                    type="trigger"
                    style={{ marginLeft: '0.4rem' }}
                  />
                )}
              </Context>
            )}
          </div>
          <div className="flexWrp" style={{ marginBottom: '1.8rem' }}>
            {Boolean(distance) && distance > 1 && (
              <Info>{`${KmWithoutNulls(distance)} ${dic.km}`}</Info>
            )}
            {unitPrices && (
              <Info short={Boolean(distance)}>
                {parseUnitPrices(unitPrices).join(', ')}
              </Info>
            )}
            {routeparts && <Info>{`${dic.points} ${routeparts.length}`}</Info>}
          </div>
          <div style={{ marginBottom: '0.5rem' }}>
            <Info className="flexWrp">
              <Icon
                name="hook"
                type="focused"
                style={{ marginTop: '0.4rem' }}
              />
              {routeparts && (
                <CargoTitle style={{ textTransform: 'lowercase' }}>
                  {parseCargoInfo(routeparts)}
                </CargoTitle>
              )}
            </Info>
            <Info style={{ paddingLeft: '1.35rem' }}>
              {parseExtraCarOption(obj)}
            </Info>
          </div>
          {routeparts && (
            <ScaleRouteParts arr={routeparts} dic={dic} status="free" />
          )}
          <PeriodicWrp right>{`№ ${periodic}`}</PeriodicWrp>
        </Wrapper>
      </span>
    )
  )
}
