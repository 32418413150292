import React from 'react'
import styled from 'styled-components'
import Icon from '../../components/Icon'

const Wrapper = styled.div`
  position: relative;
  flex-grow: 1;
  padding: 0.5rem;
  background-color: ${(p) => (p.active ? p.theme.selected : p.theme.opposite)};
  text-align: center;
  border-right: 0.1rem solid ${(p) => p.theme.lines};
  :last-child {
    border-right: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.theme.h4};
  cursor: pointer;
  transition: 0.2s ease;
`
const Message = styled.p`
  position: absolute;
  top: -2%;
  right: 2%;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${(p) => p.theme.basic};
  font-size: ${(p) => p.theme.h8};
  display: flex;
  justify-content: center;
  align-items: center;
`
const defaultObj = {
  typeIcon: 'chat',
  id: '0',
}

export default ({
  obj = defaultObj,
  currentTab,
  fnClick = () => {},
  id = 'TabWithIcon',
}) => {
  const icons = {
    info: <Icon name="info" type="links" />,
    bill: <Icon name="documents" type="links" />,
    chat: <Icon name="chat" type="links" />,
    time: <Icon name="chronology" type="links" />,
    winner: <Icon name="winnerNegative" type="links" />,
  }
  return (
    <Wrapper active={currentTab === obj.id} onClick={fnClick} id={id}>
      {obj.message && <Message>{obj.message}</Message>}
      {icons[obj.typeIcon]}
    </Wrapper>
  )
}
