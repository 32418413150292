import moment from 'moment'
import store from '../../redux/store'

export const convertDate = (data) => {
  // data =
  // period mode {exactly: null,from: <JsDate>,id: true,mode: 2,to: <JsDate>,}
  // exactly Mode{ from: null, to: null, mode: 1, exactly: <JsDate>, id: true },
  // not matter{ from: null, to: null, mode: 3, exactly: null, id: true },
  if (!data) {
    return ''
  }
  if (data && !data.mode) {
    return moment(data).format('YYYY-MM-DD')
  }
  if (data.mode === 1) {
    return { date1: moment(data.exactly).format('YYYY-MM-DD') }
  }
  if (data.mode === 2) {
    return {
      date1: moment(data.from).format('YYYY-MM-DD'),
      date2: moment(data.to).format('YYYY-MM-DD'),
    }
  }
  if (data.mode === 3) {
    return { date1: '0000-00-00' }
  }
}

export const convertTime = (data) => {
  // data =
  // period mode{ from: '06:30', to: '19:30', mode: 2, exactly: null },
  // exactly Mode{ from: null, to: null, mode: 1, exactly: '10:30' },
  // not matter{ from: null, to: null, mode: 3, exactly: null, id: true },
  if (!data) {
    return ''
  }
  if (data && !data.mode) {
    return `${data}:00`
  }
  if (data.mode === 1) {
    return { time1: data.exactly }
  }
  if (data.mode === 2) {
    return {
      time1: data.from,
      time2: data.to,
    }
  }
  if (data.mode === 3) {
    return { time1: '00:00' }
  }
}

export const NumberWithoutNulls = (value) => {
  if (value && value < 1) {
    return ''
  }
  if (value) {
    if (Number.isInteger(value)) {
      return value
    } else {
      const data = String(value).split('.')[1]
      const isNull = data === '00' || data === '000'
      return isNull ? Number(value).toFixed() : value
    }
  } else {
    return 0
  }
}

export const KmWithoutNulls = (value) => {
  if (value) {
    return String(value).split('.')[0]
  } else {
    return ''
  }
}

export const parseCargoInfo = (arr) => {
  //TODO
  const result = arr
    .map((item) => {
      if (item.workaction === '1' || item.workaction === 1) {
        return item.cargo ? item.cargo : null
      } else {
        return null
      }
    })
    .filter((item) => item)
    .join(', ')
  return result
}
export const parseDriverInfo = (obj) => {
  const fio = []
  const tel = []
  if (obj.lastname) fio.push(obj.lastname)
  if (obj.firstname) fio.push(`${obj.firstname.slice(0, 1).toUpperCase()}.`)
  if (obj.maincellphone) tel.push(obj.maincellphone)
  const result = `${fio.join(' ')}${tel.length ? ` ${tel[0]}` : ''}`
  return result
}
export const parseCarInfo = (obj) => {
  const car = []
  const trailer = []

  if (obj.car_relation.make_relation)
    car.push(obj.car_relation.make_relation.title_ru)
  if (obj.car_relation.number) car.push(obj.car_relation.number)
  if (obj.trailer_relation) trailer.push(obj.trailer_relation.number)
  const result = `${car.join(' ')}${trailer.length ? `, ${trailer[0]}` : ''}`
  return result
}
export const parseUnitPrices = (arr) => {
  //TODO rewirite this to reduce
  const result = arr
    .filter((item) => Number(item.value) > 0)
    .map(
      (item) =>
        ` ${NumberWithoutNulls(item.value)} ${
          item.title.includes('м<sup>3</sup>')
            ? `м³ ${item.title.substr(13)}`
            : item.title
        }`
    )
  return result
}
export const isMyOffer = (arr, id) => {
  if (arr.length === 0) {
    return false
  } else {
    return arr.find((obj) => obj.relation === id) ? true : false
  }
}
export const isHaveOffer = (arr, id) => {
  if (arr.length === 0) {
    return false
  } else {
    if (arr[0].relation === id) {
      return false
    } else {
      return true
    }
  }
}

export const isOverdueTime = (date) => {
  if (date === '0000-00-00') {
    return false
  } else {
    const now = new Date()
    const result = Date.parse(date) - Date.parse(now) > 0 ? false : true
    return result
  }
}
export const parseWholeDate = (date, dic, ms) => {
  //date  = "2021-04-06 08:39:24"
  const day = moment(date).format('D')
  const month = dic[`month_short_name_${moment(date).format('M')}`]
  const timeRule = ms ? 'HH:mm:ss' : 'HH:mm'
  const time = moment(date).format(timeRule)
  return `${day || ''} ${month || ''} ${time || ''}`
}

export const parseDateOfLocal = (date, time, localMonthShortName, ms) => {
  //date  = "2021-04-06"
  // time = "08:39:24"

  const day = moment(date).format('D')
  const month = moment(date).format('M')
  // const year = moment(date).format('YY')

  const result = time
    ? `${day} ${localMonthShortName[month - 1]} ${
        ms ? time : time.slice(0, -3)
      }`
    : `${day} ${localMonthShortName[month - 1]}`

  return result
}

export const datesForScales = (
  date1,
  time1,
  date2,
  time2,
  localMonthShortName,
  nearestDate,
  roundTheClock
) => {
  //date  = "2021-04-06"
  // time = "08:39:24"

  let dateVal = date1 === '0000-00-00' ? nearestDate : ''
  let timeVal = (time1 === '00:00:00' || time1 === '00:01:00') ? roundTheClock : ''

  if (date1 === '0000-00-00' && time1 === '00:00:00') {
    return `${dateVal}`
  }

  if (time1 !== '00:00:00' && time2 === '00:00:00') {
    timeVal = `${time1.slice(0, -3)}`
  }

  if (
      (time1 !== '00:00:00' && time2 !== '00:00:00')
      &&  (time1 !== '00:01:00' && time2 !== '23:59:00')
  ) {

    timeVal = `${time1.slice(0, -3)} - ${time2.slice(0, -3)}`
  }

  if (date1 !== '0000-00-00' && date2 && date2 === '0000-00-00') {
    const day1 = moment(date1).format('D')
    const month1 = moment(date1).format('M')
    dateVal = `${day1} ${localMonthShortName[month1 - 1]}`
  }

  if (date1 !== '0000-00-00' && date2 && date2 !== '0000-00-00') {
    const day1 = moment(date1).format('D')
    const month1 = moment(date1).format('M')
    const day2 = moment(date2).format('D')
    const month2 = moment(date2).format('M')
    dateVal = `${day1} ${localMonthShortName[month1 - 1]} - ${day2} ${
      localMonthShortName[month2 - 1]
    }`
  }

  return `${dateVal}\n ${timeVal}`
}

export const haveManyMinAreLeft = (finishDate) => {
  const result = moment(finishDate) - moment()
  return result ? moment(result).format('mm:ss') : ''
}

export const parseExtraCarOption = (obj) => {
  let result = []
  if (obj.hasOwnProperty('cartype')) {
    result = [...obj.cartype]
  }
  if (obj.hasOwnProperty('chargetype')) {
    result = [...result, ...obj.chargetype]
  }
  if (obj.hasOwnProperty('ferryproperty')) {
    result = [...result, ...obj.ferryproperty]
  }

  return result.join(', ')
}
