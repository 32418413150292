import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'

// import HomePage from './pages/Home'
import MyRoutes from './pages/MyRoutes'
import AllRoutes from './pages/AllRoutes'
import MyTenders from './pages/MyTenders'
import AllTenders from './pages/AllTenders'
import Autorization from './pages/Authorization'
import Registration from './pages/Registration'
import Renewal from './pages/Renewal'
import Settings from './pages/Settings'
import NotFoundPage from './pages/NotFound'
import Develop from './pages/DevelopTest'
import SingleRoute from './pages/SingleRoute'
import SingleTender from './pages/SingleTender'
import Company from './pages/Company'
import Car from './pages/Car'
import Chat from './pages/Chat'

import CreateRoute from './pages/CreateRoute'
import NewPassword from "./pages/NewPassword";

export default () => {
  const auth = useSelector((s) => s.general.isAuth)
  return (
    <Switch>
      <Route
        path="/"
        exact
        render={() =>
          auth ? <Redirect to="/my_routes" /> : <Redirect to="/authorization" />
        }
      />
      <Route
        path="/home"
        exact
        render={() => (auth ? <MyRoutes /> : <Redirect to="/authorization" />)}
      />
      <Route
        path="/my_routes"
        exact
        render={() => (auth ? <MyRoutes /> : <Redirect to="/authorization" />)} //MyRoutes
      />
      <Route
        path="/all_routes"
        exact
        render={() => (auth ? <AllRoutes /> : <Redirect to="/authorization" />)}
      />
      <Route
        path="/my_tenders"
        exact
        render={() => (auth ? <MyTenders /> : <Redirect to="/authorization" />)}
      />
      <Route
        path="/all_tenders"
        exact
        render={() =>
          auth ? <AllTenders /> : <Redirect to="/authorization" />
        }
      />
      <Route
        path="/create_route"
        exact
        render={() =>
          auth ? <CreateRoute /> : <Redirect to="/authorization" />
        }
      />

      <Route
        path="/route/:periodic"
        exact
        render={() =>
          auth ? <SingleRoute /> : <Redirect to="/authorization" />
        }
      />
      <Route
        path="/tender/:periodic"
        exact
        render={() =>
          auth ? <SingleTender /> : <Redirect to="/authorization" />
        }
      />
      <Route
        path="/company/:hash"
        exact
        render={() => (auth ? <Company /> : <Redirect to="/authorization" />)}
      />
      <Route
        path="/car/:id"
        exact
        render={() => (auth ? <Car /> : <Redirect to="/authorization" />)}
      />

      <Route
        path="/authorization"
        exact
        render={() => (!auth ? <Autorization /> : <Redirect to="/home" />)}
      />
      <Route
        path="/registration"
        exact
        render={() => (!auth ? <Registration /> : <Redirect to="/home" />)}
      />
      <Route
        path="/settings"
        exact
        render={() => (auth ? <Settings /> : <Redirect to="/authorization" />)}
      />
      <Route
        path="/chat"
        exact
        render={() => (auth ? <Chat /> : <Redirect to="/authorization" />)}
      />

      <Route
        path="/renewal"
        exact
        render={() => (auth ? <Redirect to="/home" /> : <Renewal />)}
      />
      <Route
        path="/new-password"
        exact
        render={() => (auth ? <Redirect to="/home" /> : <NewPassword />)}
      />

      <Route
        path="/dev"
        exact
        render={() => (auth ? <Develop /> : <Redirect to="/authorization" />)}
      />

      <Route component={NotFoundPage} />
    </Switch>
  )
}
