import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import useTimer from '../../../../hooks/useTimer'
import BaseButton from '../../../buttons/BaseBtn'
import Icon from '../../../Icon'
import TabMenuWithIcon from '../../../../containers/menus/TabMenuWithIcons'
// import PopupBtn from '../../buttons/PopupBtn'
import RoundBtn from '../../../buttons/RoundBtn'
import RouteCardInfo from './RouteCardInfo2'
import RouteCardChat from '../RouteCardChat'
import RouteCardDocs from '../RouteCardDocs'
import RouteCardChronology from '../RouteCardChronology'
import KebabContent from '../../../../containers/InterfaceForCarrier/forRoutes/KebabContent'
import {
  Info,
  AwaitApprovedAction,
  SeparateLine,
} from '../../../smallPartsForInterface/SmallPartsCard'
import AgreeForExecution from '../../../../containers/forms/AgreeForExecutionForm'
import { haveManyMinAreLeft } from '../../../../lib/helpers/forRoutes'

const Wrapper = styled.div`
  width: 100%;
  margin-top: 6rem;
  padding: 2.6rem 1.6rem;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mrgBottom {
    margin-bottom: 3rem;
  }
  .padBottom {
    padding-bottom: 2.5rem;
  }
  .bottomLine {
    border-bottom: 0.1rem solid ${(p) => p.theme.lines};
  }
  .timer {
    width: 100%;
    padding-top: 2rem;
    color: ${(p) => p.theme.error};
    font-size: ${(p) => p.theme.h3};
    font-weight: 500;
  }
  .timer:first-letter {
    text-transform: uppercase;
  }
`
const Header = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`
const HeaderCity = styled.p`
  font-size: ${(p) => p.theme.h2};
  color: ${(p) => p.theme.regular_txt};
  font-weight: 600;
  :after {
    content: '→';
    margin: 0 0.5rem;
  }
  :last-child {
    :after {
      display: none;
    }
  }
`
export default ({
  obj,
  list = [],
  onChangeTab = () => {},
  currentTab = '',
}) => {
  const dispatch = useDispatch()
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const isIos = useSelector((s) => s.general.isIos)
  const [isPopup, setIsPopup] = useState(false)
  const flagList = useSelector((s) => s.route.flagList)
  const { routeparts, periodic } = obj
  const element = {
    11: <RouteCardInfo data={obj} />,
    22: <RouteCardDocs periodic={periodic} />,
    33: <RouteCardChat periodic={periodic} />,
    44: <RouteCardChronology periodic={periodic} />,
  }
  const onCancelBook = () =>
    dispatch({ type: 'SAGA_CANCEL_BOOK_ROUTE', periodic })
  const onShowPopup = () => setIsPopup(!isPopup)
  const [timerValue, haveTimerValue, isShowCancelBtnForTimer] = useTimer(
    {
      date: obj.bookedtill.split(' ')[0],
      time: moment(obj.bookedtill).format('HH:mm:ss'),
    },
    0,
    dic.more2Days
  )
  return (
    <Wrapper>
      {isPopup && (
        <KebabContent
          routestatus={obj.routestatus}
          onClose={onShowPopup}
          dic={dic}
        />
      )}
      <Header>
        {routeparts.map(({ checkpoint, id }) => (
          <HeaderCity key={id}>{checkpoint.town.title_ru}</HeaderCity>
        ))}
      </Header>
      {obj.routestatus === 11 && obj.complete && (
        <AwaitApprovedAction
          text={dic.msg_10}
          style={{ marginBottom: '2.6rem' }}
        />
      )}

      <div className="flex padBottom">
        <Info>{`${dic.route} № ${periodic}`}</Info>
        {/* {obj.routestatus !== 1 && obj.routestatus !== 14 && (
          <PopupBtn fnClick={onShowPopup} />
        )} */}
      </div>
      {obj.routestatus === 2 && (
        <span className="flex padBottom">
          {obj.bookedbyme && !obj.context.title ? (
            haveTimerValue || isIos ? (
              <>
                <p className="timer">
                  {`${dic.msg_4}: ${
                    isIos ? haveManyMinAreLeft(obj.bookedtill) : timerValue
                  }`}
                </p>
                {isShowCancelBtnForTimer && (
                  <RoundBtn
                    type="delete"
                    onClick={onCancelBook}
                    forLoadFlow
                    localId="cancelRoute"
                    style={{ marginTop: '2rem' }}
                  />
                )}
              </>
            ) : null
          ) : obj.context.title ? (
            <Info isTrigger fs="h3">
              {obj.context.title}
              {obj.context.iconTitle && (
                <Icon
                  name={obj.context.iconTitle}
                  type="trigger"
                  style={{ marginLeft: '0.5rem' }}
                />
              )}
            </Info>
          ) : (
            !flagList.top && (
              <BaseButton
                fullSize
                label={`${dic.reserveOn} ${obj?.booktime || 10} ${dic.min}`}
                fnClick={() =>
                  dispatch({
                    type: 'SAGA_BOOK_ROUTE',
                    periodic: obj.periodic,
                  })
                }
                forLoadFlow
                localId={`${obj.periodic}top`}
              />
            )
          )}
        </span>
      )}
      {obj.routestatus === 3 && (
        <span>
          <AwaitApprovedAction
            text={dic.msg_16}
            fs="h3"
            style={{ marginBottom: obj.autosubscribe ? '2.6rem' : '0' }}
          />
          {!obj.autosubscribe && (
            <AgreeForExecution
              titleBtn={dic.msg_20}
              date={obj.routeparts[0].plannedarrivaldate}
              time={obj.routeparts[0].plannedarrivaltime.slice(0, 5)}
              idRoutePart={obj.routeparts[0].id}
              periodic={obj.periodic}
              style={{ marginTop: '2rem' }}
              dic={dic}
            />
          )}
        </span>
      )}
      {obj.routestatus === 4 && !obj.autosubscribe && (
        <span>
          <AgreeForExecution
            titleBtn={dic.msg_20}
            date={obj.routeparts[0].plannedarrivaldate}
            time={obj.routeparts[0].plannedarrivaltime.slice(0, 5)}
            idRoutePart={obj.routeparts[0].id}
            periodic={obj.periodic}
            dic={dic}
          />
        </span>
      )}
      <SeparateLine />
      <TabMenuWithIcon
        onChangeTab={onChangeTab}
        // list={[list[0]]}
        list={
          obj.newMessages > 0
            ? list.map((item) => {
                if (item.typeIcon === 'chat') {
                  return { ...item, message: obj.newMessages }
                } else {
                  return item
                }
              })
            : list
        }
        currentRout={currentTab}
        style={{ paddingRight: 0, paddingLeft: 0 }}
      />
      {element[currentTab]}
    </Wrapper>
  )
}
