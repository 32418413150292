import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import BaseButton from '../../../buttons/BaseBtn'
import SelectInput from '../../../../containers/selects/SelectInput'
import {
  parseNewDriversData,
  parseDriversObj,
} from '../../../../lib/helpers/forForms'

const Wrapper = styled.div`
  width: 100%;
`
const Title = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  font-size: ${(p) => p.theme.h4};
`
const BtnWrap = styled.div`
  width: 100%;
  min-height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default ({
  content = { title: 'default title', action: 'test action' },
  onAction = () => {},
  onCancel = () => {},
  dic,
}) => {
  const routeresponse = useSelector((s) => s.route.cardRoute.routeresponse)
  const response = useSelector((s) => s.route.cardRoute.response[routeresponse])
  const searchList = useSelector((s) => s.myPark.serchList)
  const dispatch = useDispatch()
  const [drivers, setDrivers] = useState(parseDriversObj(response))

  const submit = () => {
    onAction(content.action, { newdrivers: parseNewDriversData(drivers) })
  }

  const saveNewDriver = (typeAction, obj) => {
    switch (typeAction) {
      case 'driver':
        setDrivers({ ...drivers, driver: obj })
        break
      case 'driver2':
        setDrivers({ ...drivers, driver2: obj })
        break
      case 'car':
        setDrivers({ ...drivers, car: obj })
        break
      case 'trailer':
        setDrivers({ ...drivers, trailer: obj })
        break
      default:
        break
    }
  }
  const onChangeInputDriver = (e) => {
    dispatch({ type: 'FILTER_SEARCH_LIST', payload: e.target.value })
    setDrivers({
      ...drivers,
      [e.target.name]: { ...[e.target.name], union: e.target.value },
    })
  }
  //CHANGE_LANG
  return (
    <Wrapper>
      <Title>{content.title}</Title>
      <SelectInput
        label={dic.driver}
        value={drivers.driver.union}
        name="driver"
        list={searchList}
        fnFormValue={saveNewDriver}
        fnChange={onChangeInputDriver}
        style={{ marginBottom: '2rem' }}
      />
      <SelectInput
        label={dic.car}
        value={drivers.car.union}
        name="car"
        list={searchList}
        fnFormValue={saveNewDriver}
        fnChange={onChangeInputDriver}
        style={{ marginBottom: '2rem' }}
      />
      <SelectInput
        label={dic.trailer}
        value={drivers.trailer.union}
        name="trailer"
        fnFormValue={saveNewDriver}
        list={searchList}
        fnChange={onChangeInputDriver}
        style={{ marginBottom: '2rem' }}
      />
      <SelectInput
        label={`${dic.driver} 2`}
        value={drivers.driver2.union}
        name="driver2"
        list={searchList}
        fnFormValue={saveNewDriver}
        fnChange={onChangeInputDriver}
        style={{ marginBottom: '2rem' }}
      />
      <BtnWrap>
        <BaseButton inContext="popup" label="Cancel" fnClick={onCancel} />
        <BaseButton inContext="popup" label="Save" fnClick={submit} />
      </BtnWrap>
    </Wrapper>
  )
}
