import { all } from 'redux-saga/effects'
import logout from './local/logout'
import showErrors from './local/showErrors'
import setNavTabToLocalstorage from './local/setNavTabToLocalstorage'
import catchAction from './local/catchAction'
import getNewRouteDefaultData from './getNewRouteDefaultData'
// import getDumpLang from './getDumpLang'
import getListLang from './getListLang'
import getBaseDictionary from './getDictionary'
import postNewWords from './postAction/postNewWords'
import changeDumpLang from './changeDumpLang'
import setAuthorization from './setAuthorization'
import postRenewal from './postRenewal'
import postNewPassword from "./postNewPassword"
import getProfile from './getProfile'
import getSettings from './getSettings'
import setNewRouteSettings from './local/setNewRouteSettings'
import getNotificationQuantity from './getNotificationStatus'
import allRouteWidgetGroups from './getAllRouteWidgetGroups'
import myRouteWidgetGroups from './getMyRouteWidgetGroups'
import allTenderWidgetGroups from './getAllTenderWidgetGroups'
import myTenderWidgetGroups from './getMyTenderWidgetGroups'
import myRoutes from './getMyRoutes'
import allRoutes from './getAllRoutes'
import myTenders from './getMyTenders'
import allTenders from './getAllTenders'
import routeForWidget from './getRouteForWidget'
import completeRouteForWidget from './getCompleteRouteForWidget'
import completeTenderForWidget from './getCompleteTenderForWidget'
import tenderForWidget from './getTenderForWidget'
import singleRoute from './getSingleRoute'
import singleTender from './getSingleTender'
import tenderChildren from './getTenderChildren'
import getTenderBets from './getTenderBets'
import getTenderSteps from './getTenderSteps'
import postSentryMessage from './postSentryMessage'
import getRouteDocuments from './getRouteDocuments'
import getRouteChronology from './getRouteChronology'
import getRouteChatMessages from './getRouteChatMessages'
import getCompanyData from './getCompanyData'
import getChatRecipients from './getChatRecipients'
import getRouteChats from './getRouteChats'
import getChatMessages from './getChatMessages'

//action
import postSubscribeToExecution from './postAction/postSubscribeToExecution'
import arrivedTo from './postAction/arrivedTo'
import confirmAt from './postAction/confirmAt'
import postOfferTransport from './postAction/postOfferTransport'
import postRatifyOperator from './postAction/postRatifyOperator'
import postRejectOperator from './postAction/postRejectOperator'
import postBookRoute from './postAction/postBookRoute'
import postCreateRoute from './postAction/postCreateRoute'
import postCancelBookRoute from './postAction/postCancelBookRoute'
import postSendMyBet from './postAction/postSendMyBet'
import postAddNewTS from './postAction/postAddNewTS'
import postSendChatMessage from './postAction/postSendChatMessage'
//aux
import myAutoPark from './getMyAutoPark'
import myParkForSmartSelect from './getMyParkForSmartSelect'
import getCheckpointData from './getCheckpointData'
import getNewRouteProperties from './getNewRouteProperties'
import getNewRoutePaymentData from './getNewRoutePaymentData'

import getCountries from './auxFolder/getCountries'
import getTowns from './auxFolder/getTowns'
import getCompanies from './auxFolder/getCompanies'
import getCheckpoints from './auxFolder/getCheckpoints'
import getCheckpointContact from './auxFolder/getCheckpointContact'
import getMeasureUnits from './auxFolder/getMeasureUnits'
import getCargo from './auxFolder/getCargo'
import getCarType from './auxFolder/getCarType'
import getCarTypes from './auxFolder/getCarTypes'
import getCarBodyTypes from './auxFolder/getCarBodyTypes'
import getCarTypeSingle from './auxFolder/getCarTypeSingle'
import getCarBrands from './auxFolder/getCarBrands'
import getVehicleClasses from './auxFolder/getVehicleClasses'
import getChargeType from './auxFolder/getChargeType'
import getChargeTypes from './auxFolder/getChargeTypes'
import getFerryProperty from './auxFolder/getFerryProperty'
import getFerryProperties from './auxFolder/getFerryProperties'
import getPaymentType from './auxFolder/getPaymentType'
import getCurrency from './auxFolder/getCurrency'
import getPriceFor from './auxFolder/getPriceFor'
import getPayor from './auxFolder/getPayor'
import getPartners from './auxFolder/getPartners'
import getBorders from './auxFolder/getBorders'

import testSaga from './testSaga'

export default function* rootSaga() {
  yield all([
    testSaga(),
    logout(),
    catchAction(),
    showErrors(),
    // getDumpLang(),
    getListLang(),
    getBaseDictionary(),
    postNewWords(),
    changeDumpLang(),
    getProfile(),
    getSettings(),
    setNewRouteSettings(),
    getNotificationQuantity(),
    allRouteWidgetGroups(),
    myRouteWidgetGroups(),
    allTenderWidgetGroups(),
    myTenderWidgetGroups(),
    setAuthorization(),
    postRenewal(),
    postNewPassword(),
    myRoutes(),
    allRoutes(),
    myTenders(),
    allTenders(),
    routeForWidget(),
    completeRouteForWidget(),
    completeTenderForWidget(),
    tenderForWidget(),
    singleRoute(),
    singleTender(),
    tenderChildren(),
    setNavTabToLocalstorage(),
    postSentryMessage(),
    postSubscribeToExecution(),
    arrivedTo(),
    confirmAt(),
    postBookRoute(),
    postCreateRoute(),
    postCancelBookRoute(),
    postOfferTransport(),
    postRatifyOperator(),
    postRejectOperator(),
    postAddNewTS(),
    postSendChatMessage(),
    myAutoPark(),
    getCarBrands(),
    getVehicleClasses(),
    myParkForSmartSelect(),
    getNewRouteDefaultData(),
    getCheckpointData(),
    getNewRouteProperties(),
    getNewRoutePaymentData(),
    postSendMyBet(),
    getTenderSteps(),
    getTenderBets(),
    getRouteDocuments(),
    getRouteChronology(),
    getRouteChatMessages(),
    getCompanyData(),
    getChatRecipients(),
    getRouteChats(),
    getChatMessages(),
    //aux
    getCountries(),
    getTowns(),
    getCompanies(),
    getCheckpoints(),
    getCheckpointContact(),
    getCargo(),
    getMeasureUnits(),
    getCarType(),
    getCarTypes(),
    getCarBodyTypes(),
    getCarTypeSingle(),
    getChargeType(),
    getChargeTypes(),
    getFerryProperty(),
    getFerryProperties(),
    getPaymentType(),
    getCurrency(),
    getPriceFor(),
    getPayor(),
    getPartners(),
    getBorders(),
  ])
}
