import React, { PureComponent } from 'react'
export default class BitrixChat extends PureComponent {
  componentDidMount() {
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.src = `https://cdn.bitrix24.ru/b937185/crm/form/loader_12.js?${
      (Date.now() / 180000) | 0
    }`
    s.dataset.b24Form = 'inline/12/dym9mm'
    s.dataset.skipMoving = true
    this.instance.appendChild(s)
  }
  componentWillUnmount() {
    const elem = document.querySelector('script')
    elem.remove()
  }

  render() {
    return <div ref={(el) => (this.instance = el)} />
  }
}
