import React, { useLayoutEffect, useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { defaultParams } from '../lib/hereApi'
import loadImg from '../assets/img/markers/loading.png'
import unloadImg from '../assets/img/markers/unloading.png'
import borderImg from '../assets/img/markers/border.png'
import customImg from '../assets/img/markers/customs.png'
import Loader from '../components/layouts/SmallLoader'

const Wrapper = styled.div`
  opacity: ${(p) => p.opacity};
`

const LoaderWrp = styled.div`
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`

export default ({ points = [], height = '80vh' }) => {
  const mapRef = useRef(null)
  const [start, setStart] = useState('')
  const [finish, setFinish] = useState('')
  const [body, setBody] = useState([])
  const [bodyAction, setBodyAction] = useState([])
  const [isData, setIsData] = useState(false)
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    if (points && points.length > 1) {
      const data = points.map(
        (item) => `${item.checkpoint.latit},${item.checkpoint.longit}`
      )

      setStart(data.shift())
      setFinish(data.pop())
      if (data.length > 0) {
        const data2 = points.map((item) => item.workaction)
        data2.shift()
        data2.pop()
        setBody(data)
        setBodyAction(data2)
      }
      setIsData(true)
    }
  }, []) //eslint-disable-line

  useLayoutEffect(() => {
    if (!mapRef.current) return
    if (isData) {
      const H = window.H
      const platform = new H.service.Platform({
        apikey: process.env.REACT_APP_HERE_API_KEY,
      })
      const defaultLayers = platform.createDefaultLayers({ lg: 'ru' })
      const hMap = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
        center: { lat: 50, lng: 30 },
        zoom: 6,
        pixelRatio: window.devicePixelRatio || 1,
      })
      /*eslint-disable*/
      const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hMap))

      const ui = H.ui.UI.createDefault(hMap, defaultLayers)
      const routingParameters = {
        // The start point of the route:
        ...defaultParams,
        origin: start,
        via: new H.service.Url.MultiValueQueryParameter([...body]),
        // The end point of the route:
        destination: finish,
        // Include the route shape in the response
        return: 'polyline',
      }
      const onResult = function (result) {
        let i = 0
        // ensure that at least one route was found
        if (result.routes.length) {
          result.routes[0].sections.forEach((section) => {
            // Create a linestring to use as a point source for the route line
            let linestring = H.geo.LineString.fromFlexiblePolyline(
              section.polyline
            )

            // Create a polyline to display the route:
            let routeLine = new H.map.Polyline(linestring, {
              style: { strokeColor: '#1775ba', lineWidth: 3 },
            })

            const icons = {
              1: new H.map.Icon(loadImg),
              2: new H.map.Icon(unloadImg),
              3: new H.map.Icon(customImg),
              4: new H.map.Icon(borderImg),
            }
            // Create a marker for the start point:
            let startMarker = new H.map.Marker(
              section.departure.place.location,
              { icon: icons[1] }
            )

            // Create a marker for the end point:
            const calc = i > bodyAction.length - 1 ? '2' : bodyAction[i]
            let endMarker = new H.map.Marker(section.arrival.place.location, {
              icon: icons[calc],
            })

            i += 1

            // Add the route polyline and the two markers to the map:
            hMap.addObjects([routeLine, startMarker, endMarker])

            // Set the map's viewport to make the whole route visible:
            hMap
              .getViewModel()
              .setLookAtData({ bounds: routeLine.getBoundingBox() })
          })
          if (i > bodyAction.length - 1) {
            //TODO fecescode
            setTimeout(() => setOpacity(1), 300, true)
          }
        }
      }
      const router = platform.getRoutingService(null, 8)
      router.calculateRoute(routingParameters, onResult, function (error) {
        alert(error.message)
      })

      /*eslint-enable*/
      return () => {
        hMap.dispose()
      }
    }
  }, [mapRef, isData]) //eslint-disable-line

  return (
    <>
      <Wrapper
        opacity={opacity}
        className="map"
        ref={mapRef}
        style={{ height: height }}
      />
      {!opacity && (
        <LoaderWrp>
          <Loader size="5.6rem" />
        </LoaderWrp>
      )}
    </>
  )
}
