import styled from 'styled-components'

export const Line = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${(p) => p.theme.lines};
`
export const FlexWrp = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const LikeLabel = styled.p`
  width: 100%;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  opacity: 0.8;
  font-size: ${(p) => p.theme.h5};
  color: ${(p) => p.theme.additional};
  white-space: nowrap;
`
