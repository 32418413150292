import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Transition } from 'react-transition-group'
// import alertImg from '../../assets/img/alert.png'

const Wrapper = styled.div`
  z-index: 1000;
  position: fixed;
  top: 1.9rem;
  right: 2rem;
  max-width: 20rem;
  padding: 1rem;
  background-color: ${(p) => p.theme.opposite};
  color: ${(p) => p.theme.regular_txt};
  border: 0.3rem ridge ${(p) => p.theme[p.typeMsg]};
  font-size: ${(p) => p.theme.h6};
  border-radius: 1rem;
  box-shadow: ${(p) => p.theme.bubbleShadow};
`
// const Image = styled.div`
//   width: 1.5rem;
//   height: 1.5rem;
//   margin: 0 auto 0.7rem;
//   background-image: url(${alertImg});
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
// `
export default () => {
  const dispatch = useDispatch()
  const message = useSelector((s) => s.general.bubbleMsg)
  const type = useSelector((s) => s.general.bubbleMsgType)
  const [state, setState] = useState('disabled')
  const duration = 300

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    display: 'none',
  }
  const transitionStyles = {
    show: { display: 'inline-block' },
    disabled: { display: 'none' },
  }

  const closeMessage = () => dispatch({ type: 'CLOSE_BUBBLE_MESSAGE' })
  useEffect(() => {
    const timeout = () =>
      setTimeout(() => dispatch({ type: 'CLOSE_BUBBLE_MESSAGE' }), 5000, true)
    if (message) {
      setState('show')
      timeout()
    } else {
      setState('disabled')
    }
  }, [message]) //eslint-disable-line
  const color = {
    2: 'approver',
    3: 'focus',
    4: 'error',
  }

  return (
    <Transition in={Boolean(message)} timeout={duration}>
      <Wrapper
        style={{ ...defaultStyle, ...transitionStyles[state] }}
        onClick={closeMessage}
        typeMsg={color[type] || color[2]}
      >
        {/* <Image /> */}
        <p>{message}</p>
      </Wrapper>
    </Transition>
  )
}
