import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import BaseBtn from '../../components/buttons/BaseBtn'
import BaseInput from '../../components/inputs/BaseInput'
import useRouter from '../../hooks/useRouter'
import {
  invalidLength,
  equalsZero,
  validateAthorizationForm,
} from '../../lib/helpers/forValidate'
import {registrationLink} from "../../lib/constants";
import BaseLinkNewWindow from "../../components/links/BaseLinkNewWindow";

const Wrapper = styled.div`
  width: 100%;
  max-width: 80rem;
  min-height: 30rem;
  margin-top: ${(p) => p.marginTop};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const InputWrp = styled.div`
  margin-top: 4rem;
  width: 100%;
  height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
// const Title = styled.p`
//   margin-bottom: 2rem;
//   font-size: ${(p) => p.theme.h2};
// `

export default ({ marginTop = 0 }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const [loginValue, setLoginValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')
  const [loginError, setLoginError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [loginMessage, setLoginMessage] = useState(dic.form_err_required)
  const [passwordMessage, setPasswordMessage] = useState(dic.form_err_required)
  const lang = useSelector((s) => s.defaultLang.langObj.autorization)

  const onChangeInput = (e) => {
    if (e.target.name === 'login') {
      setLoginValue(e.target.value)
    } else {
      setPasswordValue(e.target.value)
    }
  }

  const onStartRequest = () => {
    dispatch({ type: 'START_REQUEST', payload: 'authBtn' })
    dispatch({
      type: 'SET_AUTHORIZATION',
      login: loginValue,
      password: passwordValue,
      history: router,
    })
  }

  const onSubmitForm = () => {
    validateAthorizationForm(
      { equalsZero, dic, invalidLength },
      { value: loginValue, setLoginError, setLoginMessage },
      { value: passwordValue, setPasswordError, setPasswordMessage },
      onStartRequest
    )
  }

  return (
    <Wrapper marginTop={marginTop}>
      {/* <Title>{lang.title}</Title> */}
      <InputWrp>
        <BaseInput
          name="login"
          label={lang.input_login}
          error={loginError}
          message={loginMessage}
          value={loginValue}
          fnChange={onChangeInput}
          style={{ marginBottom: '1rem' }}
        />
        <BaseInput
          type="password"
          name="password"
          label={lang.input_password}
          error={passwordError}
          message={passwordMessage}
          value={passwordValue}
          fnChange={onChangeInput}
          style={{ marginBottom: '2rem' }}
        />
      </InputWrp>
      {/*<BaseLinkNewWindow*/}
      {/*    label={`${lang.link_registration} ...`}*/}
      {/*    id=""*/}
      {/*    rout={registrationLink}*/}
      {/*    style={{*/}
      {/*      textTransform: 'uppercase',*/}
      {/*      fontWeight: '500',*/}
      {/*      marginTop: '2.6rem',*/}
      {/*      marginBottom: '3.2rem',*/}
      {/*    }}*/}
      {/*/>*/}
      <BaseBtn
        label={lang.button_confirm}
        inContext="confirm"
        toUpperCase
        fullSize
        fnClick={onSubmitForm}
        localId="authBtn"
      />
    </Wrapper>
  )
}
