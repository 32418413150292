import Type from '../types'

const initialState = {
  isShow: false,
  isShowPWAInsallBTn: false,
  onInstallPWA: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.OPEN_MAIN_MENU:
      return {
        ...state,
        isShow: true,
      }
    case Type.CLOSE_MAIN_MENU:
      return {
        ...state,
        isShow: false,
      }
    case Type.NECESSARY_INSTALL_PWA:
      return {
        ...state,
        isShowPWAInsallBTn: action.payload,
        onInstallPWA: action.func,
      }

    default:
      return state
  }
}
