import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import shortid from 'shortid'
import { Line } from '../../../baseStylesComponents'
import SingleWrp from './SingleWrp'
import BaseInput from '../../../components/inputs/BaseInput'
import BaseTextarea from '../../../components/inputs/BaseTextarea'
import SelectDateBtn from '../../../components/buttons/SelectDateBtn'
import BtnLikeLink from '../../../components/buttons/BtnLikeLink'
import { strToUpperCase } from '../../../lib/helpers/forStyling'
import { validateSubmitAddNewDriver } from '../../../lib/helpers/forValidate'
import { patterns } from "../../../lib/constants"

const validateTel = (value) => {

  if(!value){
    return true
  }
  const testLength = value.length === 13

 return (patterns.mainCellNumber.test(value) && testLength)
}

export default ({
  fnClose = () => {},
  fnUpdateState = () => {},
  periodic = '',
  withoutPortal = false,
}) => {
  const dispatch = useDispatch()
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const data = useSelector((s) => s.newTS.driver)

  const [errors, setError] = useState({
    firstname: { bool: 0, msg: dic.form_err_required },
    lastname: { bool: 0, msg: dic.form_err_required },
    maincellphone: { bool: 0, msg: dic.form_err_required },
    driverlicenseserial: { bool: 0, msg: dic.form_err_required },
    driverlicensenumber: { bool: 0, msg: dic.form_err_required },
    driverlicenseissued: { bool: 0, msg: dic.form_err_required },
    driverlicenseissuedby: { bool: 0, msg: dic.form_err_required },
  })
  const [isExtraParams, setIsExtraParams] = useState(false)
  const [isComment, setIsComment] = useState(false)
  const [refs, setRefs] = useState({})
  const [uniq, setUniq] = useState({})
  const [parser, setParser] = useState({})
  const [isShow, setIsShow] = useState(false)
  const [isHaveData, setIsHaveData] = useState(false)

  const executeScroll = (refName) => {
    if(refName && refs[refName]?.current){
      refs[refName].current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  const onValidateTel = (e) => {
    const VALUE = e?.target?.value
    const NAME = "maincellphone";
    if (!validateTel(VALUE)) {
     return  setError({
        ...errors,
        [NAME]: {
          ...errors[NAME],
          bool: 1,
          msg: dic.form_err_international_phone_number
        }
      })
    }
    return  setError({
      ...errors,
      [NAME]: {
        ...errors[NAME],
        bool: 0,
        msg: dic.form_err_required,
      }
    })
  }

  const onChangeInputs = (e) => {
    let VALUE = e.target.value
    const NAME = parser[e.target.name]

    if(NAME === "passportserial" ||  NAME === "driverlicenseserial"){
      VALUE = VALUE.toUpperCase()
    }

    dispatch({
      type: 'NEW_TS_UPDATE_DRIVER',
      payload: { ...data, [NAME]: VALUE },
    })
  }

  const onDateUpdate = (VALUE, NAME) => {
    dispatch({
      type: 'NEW_TS_UPDATE_DRIVER',
      payload: { ...data, [NAME]: VALUE },
    })
  }

  const togglerExtraParams = () => {
    setIsExtraParams(!isExtraParams)
  }
  const togglerComment = () => {
    setIsComment(!isComment)
  }

  const onSetErrors = (NAME, TEXT) => {
    setError({ ...errors, [NAME]: { ...errors[NAME], bool: 1, msg: TEXT || errors[NAME].msg } })
    setTimeout(
      () => setError({ ...errors, [NAME]: { ...errors[NAME], bool: 0 } }),
      3000
    )
    executeScroll(NAME)
  }

  const onSaveNewData = () => {
    dispatch({ type: 'START_REQUEST', payload: `newDriver` })
    dispatch({
      type: 'SAGA_ADD_NEW_TS',
      data,
      action: 'driver',
      fnClose,
      fnUpdateState,
    })
  }

  const onSubmitForm = () => {

    validateSubmitAddNewDriver(
        {
          onSetErrors,
          validateTel,
          dic,
        },
        data,
        errors,
        onSaveNewData)
  }

  const newRefs = Object.keys(data).reduce((result, item) => {
    return { ...result, [item]: useRef(null) }
  }, {})
  useEffect(() => {
    if (isHaveData) {
      const uniqNames = Object.keys(data).reduce((result, item) => {
        return { ...result, [item]: shortid.generate() }
      }, {})

      setRefs(newRefs)
      setUniq(uniqNames)
      setParser(
        Object.keys(uniqNames).reduce((result, item) => {
          return { ...result, [uniqNames[item]]: item }
        }, {})
      )
    }
  }, [isHaveData]) //eslint-disable-line
  useEffect(() => {
    if (data.hasOwnProperty('firstname')) {
      setIsHaveData(true)
    }
  }, [data])
  useEffect(() => {
    if (refs.hasOwnProperty('firstname')) {
      setIsShow(true)
    }
  }, [refs])

  useEffect(() => {
    return () => dispatch({ type: 'NEW_TS_SET_DEFAULT_DATA' })
  }, []) //eslint-disable-line

  return (
    isShow && (
      <SingleWrp
        fnSubmit={onSubmitForm}
        fnClose={fnClose}
        periodic={periodic}
        type="driver"
        localId="newDriver"
        withoutPortal={withoutPortal}
      >
        <span ref={refs.lastname}>
          <BaseInput
            style={{ marginTop: '2.2rem', marginBottom: '1rem' }}
            fnChange={onChangeInputs}
            name={uniq.lastname}
            id="lastname"
            label={
              errors.lastname
                ? `${dic.driver_lastName || ''}*`
                : dic.driver_lastName || ''
            }
            value={data.lastname}
            error={errors.lastname ? errors.lastname.bool : null}
            message={errors.lastname ? errors.lastname.msg : null}
          />
        </span>
        <span ref={refs.firstname}>
          <BaseInput
            style={{ marginBottom: '1rem' }}
            fnChange={onChangeInputs}
            name={uniq.firstname}
            id="firstname"
            label={
              errors.firstname
                ? `${dic.driver_name || ''}*`
                : dic.driver_name || ''
            }
            value={data.firstname}
            error={errors.firstname ? errors.firstname.bool : null}
            message={errors.firstname ? errors.firstname.msg : null}
          />
        </span>
        <span ref={refs.patronymic}>
          <BaseInput
            style={{ marginBottom: '1rem' }}
            fnChange={onChangeInputs}
            name={uniq.patronymic}
            id="patronymic"
            label={
              errors.patronymic
                ? `${dic.driver_patronymic || ''}*`
                : dic.driver_patronymic || ''
            }
            value={data.patronymic}
            error={errors.patronymic ? errors.patronymic.bool : null}
            message={errors.patronymic ? errors.patronymic.msg : null}
          />
        </span>
        <span ref={refs.tel}>
          <BaseInput
            style={{ marginBottom: '4rem' }}
            fnChange={onChangeInputs}
            onBlur={onValidateTel}
            name={uniq.maincellphone}
            id="maincellphone"
            type="tel"
            label={
              errors.maincellphone
                ? `${dic.driver_tel || ''}*`
                : dic.driver_tel || ''
            }
            value={data.maincellphone}
            error={errors.maincellphone ? errors.maincellphone.bool : null}
            message={errors.maincellphone ? errors.maincellphone.msg : null}
          />
        </span>
        <Line style={{ marginBottom: '3.2rem' }} />
        <span ref={refs.driverlicenseserial}>
          <BaseInput
            style={{ marginBottom: '1rem' }}
            fnChange={onChangeInputs}
            name={uniq.driverlicenseserial}
            id="driverlicenseserial"
            label={
              errors.driverlicenseserial
                ? `${dic.driver_license_series || ''}*`
                : dic.driver_license_series || ''
            }
            value={data.driverlicenseserial}
            error={
              errors.driverlicenseserial
                ? errors.driverlicenseserial.bool
                : null
            }
            message={
              errors.driverlicenseserial ? errors.driverlicenseserial.msg : null
            }
          />
        </span>
        <span ref={refs.driverlicensenumber}>
          <BaseInput
            style={{ marginBottom: '3rem' }}
            fnChange={onChangeInputs}
            name={uniq.driverlicensenumber}
            id="driverlicensenumber"
            label={
              errors.driverlicensenumber
                ? `${dic.driver_license_number || ''}*`
                : dic.driver_license_number || ''
            }
            value={data.driverlicensenumber}
            error={
              errors.driverlicensenumber
                ? errors.driverlicensenumber.bool
                : null
            }
            message={
              errors.driverlicensenumber ? errors.driverlicensenumber.msg : null
            }
          />
        </span>
        <span ref={refs.driverlicenseissued}>
          <SelectDateBtn
            style={{ marginBottom: '1rem' }}
            disabledPeriod="after"
            label={
              errors.driverlicenseissued
                ? `${strToUpperCase(dic.driver_license_when) || ''}*`
                : strToUpperCase(dic.driver_license_when) || ''
            }
            fnClick={onDateUpdate}
            index="driverlicenseissued"
            id="driverlicenseissued"
            value={data.driverlicenseissued && new Date(data.driverlicenseissued)}
            error={
              errors.driverlicenseissued
                ? errors.driverlicenseissued.bool
                : null
            }
            message={
              errors.driverlicenseissued ? errors.driverlicenseissued.msg : null
            }
          />
        </span>
        <span ref={refs.driverlicenseissuedby}>
          <BaseInput
            style={{ marginBottom: '2.6rem' }}
            fnChange={onChangeInputs}
            name={uniq.driverlicenseissuedby}
            id="driverlicenseissuedby"
            label={
              errors.driverlicenseissuedby
                ? `${dic.driver_license_by || ''}*`
                : dic.driver_license_by || ''
            }
            value={data.driverlicenseissuedby}
            error={
              errors.driverlicenseissuedby
                ? errors.driverlicenseissuedby.bool
                : null
            }
            message={
              errors.driverlicenseissuedby
                ? errors.driverlicenseissuedby.msg
                : null
            }
          />
        </span>
        <BtnLikeLink
          label={`${dic.extra}...`}
          firstLetter
          style={{ marginBottom: '3.2rem' }}
          onClick={togglerExtraParams}
        />
        {isExtraParams && (
          <>
            <BaseInput
              style={{ marginBottom: '1rem' }}
              fnChange={onChangeInputs}
              name={uniq.passportserial}
              id="passportserial"
              label={
                errors.passportserial
                  ? `${dic.driver_id_serial || ''}*`
                  : dic.driver_id_serial || ''
              }
              value={data.passportserial}
              error={errors.passportserial ? errors.passportserial.bool : null}
              message={errors.passportserial ? errors.passportserial.msg : null}
            />
            <BaseInput
                style={{ marginBottom: '3rem' }}
                fnChange={onChangeInputs}
                name={uniq.passportnumber}
                id="passportnumber"
                label={
                  errors.passportnumber
                      ? `${dic.driver_id_number || ''}*`
                      : dic.driver_id_number || ''
                }
                value={data.passportnumber}
                error={errors.passportnumber ? errors.passportnumber.bool : null}
                message={errors.passportnumber ? errors.passportnumber.msg : null}
            />
            <SelectDateBtn
              style={{ marginBottom: '1rem' }}
              disabledPeriod="after"
              label={
                errors.passportissued
                  ? `${strToUpperCase(dic.driver_license_when) || ''}*`
                  : strToUpperCase(dic.driver_license_when) || ''
              }
              fnClick={onDateUpdate}
              index="passportissued"
              value={data.passportissued && new Date(data.passportissued)}
              error={errors.passportissued ? errors.passportissued.bool : null}
              message={errors.passportissued ? errors.passportissued.msg : null}
            />
            <BaseInput
              style={{ marginBottom: '1rem' }}
              fnChange={onChangeInputs}
              name={uniq.passportissuedby}
              id="passportissuedby"
              label={
                errors.passportissuedby
                  ? `${dic.driver_license_by || ''}*`
                  : dic.driver_license_by || ''
              }
              value={data.passportissuedby}
              error={
                errors.passportissuedby ? errors.passportissuedby.bool : null
              }
              message={
                errors.passportissuedby ? errors.passportissuedby.msg : null
              }
            />
            <BaseInput
              style={{ marginBottom: '3rem', textTransform: 'uppercase' }}
              fnChange={onChangeInputs}
              name={uniq.taxid}
              id="taxid"
              label={errors.taxid ? `${dic.inn || ''}*` : dic.inn || ''}
              value={data.taxid}
              error={errors.taxid ? errors.taxid.bool : null}
              message={errors.taxid ? errors.taxid.msg : null}
            />
            <SelectDateBtn
              style={{ marginBottom: '3rem' }}
              disabledPeriod="after"
              label={
                errors.birthdate
                  ? `${strToUpperCase(dic.driver_birthday) || ''}*`
                  : strToUpperCase(dic.driver_birthday) || ''
              }
              fnClick={onDateUpdate}
              index="birthdate"
              value={data.birthdate && new Date(data.birthdate)}
              error={errors.birthdate ? errors.birthdate.bool : null}
              message={errors.birthdate ? errors.birthdate.msg : null}
            />
          </>
        )}
        <BtnLikeLink
          label={`${dic.remark_add}...`}
          firstLetter
          style={{ marginBottom: isComment ? '3rem' : '4rem' }}
          onClick={togglerComment}
        />
        {isComment && (
          <BaseTextarea
            label={dic.remark}
            style={{ marginBottom: '4rem' }}
            fnChange={onChangeInputs}
            name={uniq.remark}
            value={data.remark}
            error={errors.remark ? errors.remark.bool : null}
            message={errors.remark ? errors.remark.msg : null}
          />
        )}
      </SingleWrp>
    )
  )
}
