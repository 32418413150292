import { takeLatest, put, call, select, race, delay } from 'redux-saga/effects' //select, call,
import { cancelBookRoute } from '../../../lib/requests/operatorRouteAction'
import { delays } from '../../../lib/constants'
import { getCurrentLang, getToken, getDicionary } from '../../selectors'

export function* worker({ periodic }) {
  try {
    const currentLang = yield select(getCurrentLang)
    const token = yield select(getToken)
    const dic = yield select(getDicionary)

    const { result } = yield race({
      result: call(cancelBookRoute, token, currentLang, periodic),
      timeout: delay(delays.forCancelRequest),
    })
    if (result && (result.status === 200 || result.status === 202)) {
      yield put({ type: 'CLOSE_ERROR_API' })
      yield put({ type: 'SAGA_GET_SINGLE_ROUTE', periodic })
    } else {
      yield put({
        type: 'SHOW_ERROR_API',
        code: 6,
        message: dic.msg_server_err || 'internal server error',
      })
    }
  } catch (e) {
    yield put({
      type: 'SHOW_ERROR_API',
      code: String(e.response.status).slice(0, 1),
      message: e.response ? e.response.data.message : e.message,
    })
    if (e.response && e.response.data.relogin) {
      yield put({ type: 'SAGA_LOGOUT' })
    }
  }
}

export default function* postBookRoute() {
  yield takeLatest('SAGA_CANCEL_BOOK_ROUTE', worker)
}
