import { takeEvery, put } from 'redux-saga/effects'

export function* worker({ requestType, parametr }) {
  yield put({ type: 'SET_SELECTLIST_LOADER', payload: true })
  switch (requestType) {
    case 'currency':
      yield put({ type: 'SAGA_GET_CURRENCY' })
      break
    case 'priceFor':
      yield put({ type: 'SAGA_GET_PRICEFOR' })
      break
    case 'payorcompany':
      yield put({ type: 'SAGA_GET_PAYOR' })
      break
    case 'paymentType':
      yield put({ type: 'SAGA_GET_PAYMENTTYPE' })
      break
    default:
      break
  }
}

export default function* getNewRoutePaymentData() {
  yield takeEvery('SAGA_GET_NEWROUTE_PAYMENT_DATA', worker)
}
