import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import BaseTextarea from '../../../inputs/BaseTextarea'
import BaseButton from '../../../buttons/BaseBtn'

const Wrapper = styled.div`
  width: 100%;
`
const Title = styled.p`
  margin-bottom: 2rem;
  font-size: ${(p) => p.theme.h4};
`
const BtnWrap = styled.div`
  width: 100%;
  min-height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default ({
  content = { title: 'default title', action: 'test action' },
  onAction = () => {},
  onCancel = () => {},
  reasonTitle = 'Why',
}) => {
  const [message, setMessage] = useState('')

  const onChangeTextArea = (e) => {
    setMessage(e.target.value)
  }
  //CHANGE_LANG
  return (
    <Wrapper>
      <Title>{content.title}</Title>
      <BaseTextarea
        fnChange={onChangeTextArea}
        value={message}
        label={reasonTitle}
      />
      <BtnWrap>
        <BaseButton inContext="popup" label="Cancel" fnClick={onCancel} />
        <BaseButton
          inContext="popup"
          label="Save"
          fnClick={() => onAction(content.action, message)}
        />
      </BtnWrap>
    </Wrapper>
  )
}
