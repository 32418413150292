import { api, routs } from '../api'

// Book route
export const bookRoute = (token, currentLang, periodic) => {
  return api.post(
    routs.bookRoute,
    {
      route: periodic,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

// cancel book route
export const cancelBookRoute = (token, currentLang, periodic) => {
  return api.post(
    routs.cancelBookRoute,
    {
      route: periodic,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

export const sendMyBet = (token, currentLang, data) => {
  return api.post(
    routs.sendMyBet,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

// Offer Auto

export const offerTransport = (token, currentLang, data) => {
  return api.post(
    routs.offerTransport,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

// Confirm Route

export const approveForExecution = (token, currentLang, data) => {
  return api.post(
    routs.confirmRoute,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

//Arrived At Charge

export const arrivedAtCharge = (token, currentLang, routepartId, periodic) => {
  return api.post(
    routs.arrivedAtCharge,
    { route: periodic, routepart: routepartId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

//Arrived At Uncharge

export const arrivedAtUncharge = (
  token,
  currentLang,
  routepartId,
  periodic
) => {
  return api.post(
    routs.arrivedAtUncharge,
    { route: periodic, routepart: routepartId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

// Arrived At Customs

export const arrivedAtCustoms = (token, currentLang, routepartId, periodic) => {
  return api.post(
    routs.arrivedAtCustoms,
    { route: periodic, routepart: routepartId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

// Arrived At Border

export const arrivedAtBorder = (token, currentLang, routepartId, periodic) => {
  return api.post(
    routs.arrivedAtBorder,
    { route: periodic, routepart: routepartId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

// Customs Complete
export const passedCustom = (token, currentLang, routepartId, periodic) => {
  return api.post(
    routs.passedCustom,
    { route: periodic, routepart: routepartId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}
// Border Complete
export const passedBorder = (token, currentLang, routepartId, periodic) => {
  return api.post(
    routs.passedBorder,
    { route: periodic, routepart: routepartId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}
