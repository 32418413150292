import Type from '../types'

const initialState = {
  list: [],
  isLoader: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_CHEKLIST:
      return {
        ...state,
        list: action.payload,
      }
    case Type.CLEAR_CHEKLIST:
      return {
        ...state,
        list: [],
      }
    case Type.SET_CHECKLIST_LOADER:
      return {
        ...state,
        isLoader: action.payload,
      }

    default:
      return state
  }
}
