import {useMemo} from "react";

export const parseDriversObj = (obj) => {
  const { car, trailer, driver, driver2 } = obj
  return {
    ...{},
    car: car
      ? { ...car, union: `${car.number} ${car.make.title_ru}` }
      : { union: '' },
    trailer: trailer
      ? { ...trailer, union: `${trailer.number} ${trailer.model}` }
      : { union: '' },
    driver: driver
      ? {
          ...driver,
          union: `${driver.lastname} ${driver.firstname} ${driver.maincellphone}`,
        }
      : { union: '' },
    driver2: driver2
      ? {
          ...driver2,
          union: `${driver2.lastname} ${driver2.firstname} ${driver2.maincellphone}`,
        }
      : { union: '' },
  }
}
export const parseNewDriversData = (obj) => {
  const { car, trailer, driver, driver2 } = obj
  return {
    car: car.id ? car.id : null,
    trailer: trailer.id ? trailer.id : null,
    driver: driver.id ? driver.id : null,
    driver2: driver2.id ? driver2.id : null,
  }
}


export const addKeysToRouteParts = (parts = [])=>{
   return  parts.map((item)=>{
        const {
            workaction,
            date1
        } = item
        if(workaction === 4){
            return {
                ...item,
                isRequired: item?.bordercrossforoperator,
                requiredId: null
            }
        }

        if(
            date1 === '0000-00-00'
            || date1 === ''
            || date1 === null
        ){
            return {
                ...item,
                isRequired: true
            }
        }

        return item

    })
}
