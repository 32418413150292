import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Wrapper = styled.a`
  text-transform: ${(p) => (p.lowercase ? 'lowercase' : 'uppercase')};
  text-decoration: none;
  padding-bottom: 0.2rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.links};
  font-size: ${(p) => p.theme.h4};
  transition: 0.2s ease;

  :link {
    color: ${(p) => p.theme.links};
    border-bottom: 0.1rem solid ${(p) => p.theme.links};
  }
  :active {
    color: ${(p) => p.theme.regular_txt};
    border-bottom: 0.1rem solid ${(p) => p.theme.regular_txt};
  }
  :visited {
    color: ${(p) => p.theme.mint_Visited_Links};
    border-bottom: 0.1rem solid ${(p) => p.theme.mint_Visited_Links};
  }
  ${(p) =>
    p.arrowback &&
    `
  :before {
    content: '←';
    margin-right: 0.4rem;
  }
  `};
`
const NavWrapper = styled(Link)`
  text-transform: ${(p) => (p.lowercase ? 'lowercase' : 'uppercase')};
  text-decoration: none;
  padding-bottom: 0.2rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.links};
  font-size: ${(p) => p.theme.h4};
  transition: 0.2s ease;

  :link {
    color: ${(p) => p.theme.links};
    border-bottom: 0.1rem solid ${(p) => p.theme.links};
  }
  :active {
    color: ${(p) => p.theme.regular_txt};
    border-bottom: 0.1rem solid ${(p) => p.theme.regular_txt};
  }
  :visited {
    color: ${(p) => p.theme.mint_Visited_Links};
    border-bottom: 0.1rem solid ${(p) => p.theme.mint_Visited_Links};
  }
  ${(p) =>
    p.arrowback &&
    `
  :before {
    content: '←';
    margin-right: 0.4rem;
  }
  `};
`
// use props.nav for navigation link (/home, /registration)
//exsample = <BaseLink label="to home page" rout="/home" nav />
export default ({
  label = 'link',
  rout = '/',
  isInNewWindow = false,
  nav = false,
  download = false,
  arrowback = 0,
  lowercase = 0,
  style = null,
}) => {
  return nav ? (
    <NavWrapper
      to={rout}
      lowercase={lowercase}
      style={style}
      arrowback={arrowback}
    >
      {label}
    </NavWrapper>
  ) : (
    <Wrapper
      href={rout}
      target={isInNewWindow ? '_blank' : '_self'}
      lowercase={lowercase}
      style={style}
      arrowback={arrowback}
      download={download}
    >
      {label}
    </Wrapper>
  )
}
