import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon'

const TabBtn = styled.div`
  width: 100%;
  height: 4.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(p) =>
    p.isActive ? p.theme.truckSelectGadient : p.theme.opposite};
  border: 0.1rem solid ${(p) => p.theme.selected};
  cursor: pointer;
  transition: 0.2 ease;
  p {
    ::first-letter {
      text-transform: uppercase;
    }
  }
`

const RadioBtn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`
const Round = styled.div`
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1rem;
  border: 0.2rem solid ${(p) => (p.isActive ? p.theme.trigger : p.theme.stroke)};
  border-radius: 50%;
  background: none;
  transition: 0.2 ease;
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background-color: ${(p) => p.theme.trigger};
  }
`
const Text = styled.p`
  width: ${(p) => (p.iconName ? 'calc(100% - 4.9rem)' : 'calc(100% - 3.4rem)')};
  font-size: ${(p) => p.theme.h4};
  ::first-letter {
    text-transform: uppercase;
  }
`

export default ({
  label = 'default radio',
  currentValue = false,
  id = '',
  iconName = '',
  fnChange = () => {},
  likeTab = false,
  style = {},
}) => {
  return likeTab ? (
    <TabBtn onClick={() => fnChange(id)} isActive={currentValue} style={style}>
      <p>{label}</p>
    </TabBtn>
  ) : (
    <RadioBtn onClick={() => fnChange(id)} style={style}>
      <Round isActive={currentValue}>
        {currentValue && <div className="center" />}
      </Round>
      {iconName && (
        <Icon
          name={iconName}
          type="regularText"
          style={{ marginRight: '0.5rem' }}
        />
      )}
      <Text iconName={iconName}>{label}</Text>
    </RadioBtn>
  )
}
