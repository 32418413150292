import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import useRouter from '../../hooks/useRouter'
import BaseBtn from '../../components/buttons/BaseBtn'
import BaseInput from '../../components/inputs/BaseInput'
import ModalPortal from '../../components/layouts/ModalPortal'

const Wrapper = styled.div`
  width: 95%;
  max-width: 40rem;
  padding: 1rem;
  background-color: ${(p) => p.theme.opposite};
  border-radius: ${(p) => p.theme.PopUpRadius};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default ({ onApprove = () => {}, key = '123wer123' }) => {
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [count, setCount] = useState(0)
  const router = useRouter()
  const onChangeInput = (e) => {
    setPassword(e.target.value)
  }

  const onSubmitForm = () => {
    if (key === password) {
      onApprove(true)
    }
    setPassword('')
    setCount(count + 1)
  }
  useEffect(() => {
    if (count === 3) {
      dispatch({
        type: 'SET_SENTRY_MESSAGE',
        message: '3 раза пытался войти на страницу dev',
      })
      router.push('/')
    }
  })
  return (
    <ModalPortal>
      <Wrapper>
        <BaseInput
          label={''}
          name="password"
          value={password}
          fnChange={onChangeInput}
        />
        <BaseBtn inContext="popup" fnClick={onSubmitForm} label="enter" />
      </Wrapper>
    </ModalPortal>
  )
}
