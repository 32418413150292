import { TRAILER_FIELDS } from '../dataTemplateForForms'

const byType = {
    "string": (v) => String(v),
    "number": (v) => Number(v),
    "oneOfType":(v) => v, // work with object return id
    "unionType":(v) => v, // work with object return union <string>
}

const putValueByType = (types, value) => {
    if(types.length  === 1){
        try{
            return byType[types[0]](value)
        }catch(e){
            console.log("Error: putValueByType", e)
        }
    }

    if(types[0] === 'oneOfType' && types[1]){
        return byType[types[1]](value?.id)
    }

    if(types[0] === 'unionType' && types[1]){
        return byType[types[1]](value?.union)
    }

    return value

}

const putObjValue = (object) => {
    const { id = null} = object
    if(!id){
        return null
    }
    return Number(id)
}
const mapAccordingByType = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {

        const types = TRAILER_FIELDS[key]?.expectedValue || [];

        if(types[0] === "manyTypes"){
            const properties = data[`${key}Data`] || {}
            const queries = Object.keys(properties).reduce((obj, id )=>{
                return {
                    ...obj,
                    [`${key}-${id}`]: properties[id]?.quantity || 1,
                }
            },{})

            return {
                ...queries,
                ...acc,
            }
        }

        return {
            ...acc,
            [key]: putValueByType(types, value)
        }

    }, {})
}

export const dataParse = (obj) => {

   return {
        ...mapAccordingByType(obj),
        'make-new-form': obj.make.id,
    }
}

export const dataPreValidate = (helpers, data, request) => {
    const { onSetError, dataModel } = helpers
    let result = 0

    for(const key in data){

        const expectedValue = dataModel[key]?.expectedValue || [];
        const isRequired = dataModel[key]?.isRequired || false;

        const value = data[key]

        const _value = putValueByType(expectedValue,value)

        //case empty required field
        if(isRequired && (!_value)){

            if(key === 'model' && !data?.make?.id){
                onSetError('make', "empty required field!")
            }else{
                onSetError( key, "empty required field!")
            }

            result +=1
            break
        }

        //case wrong type
        const _type = typeof(value) === 'object'
            ? expectedValue?.length === 0 ? 'object' : expectedValue[1]
            : expectedValue[0] === "manyTypes" ?  expectedValue[1] : expectedValue[0]
        if( typeof(_value) !== _type){
            onSetError(key, `value must be a ${_type}`)
            result +=1
            break
        }
    }

    if(result){
        return
    }

   return request()
}