import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  Title,
  Info,
  FlexBox,
} from '../../../smallPartsForInterface/SmallPartsCard'
import LoadMoreBtn from '../../../buttons/LoadMoreBtn'
import { currencyIcons } from '../../../../lib/constants'
import { strToUpperCase } from '../../../../lib/helpers/forStyling'
import { parseWholeDate } from '../../../../lib/helpers/forRoutes'
import { delays } from '../../../../lib/constants'

import Icon from '../../../Icon'

const Wrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 3.2rem;
`
const LastBid = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  padding: 2.5rem 1rem;
  background-color: ${(p) => (p.isMine ? p.theme.trigger : p.theme.basic_low)};
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .remark {
    margin-top: 1rem;
    color: ${(p) => (p.isMine ? p.theme.opposite : p.theme.additional)};
    font-size: ${(p) => p.theme.h5};
    font-weight: 300;
  }
`
const Bid = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  padding: 2.5rem 1rem;
  background-color: ${(p) => (p.isMine ? p.theme.trigger : p.theme.substrate)};
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .remark {
    margin-top: 1rem;
    color: ${(p) => (p.isMine ? p.theme.opposite : p.theme.additional)};
    font-size: ${(p) => p.theme.h5};
    font-weight: 300;
  }
`
const StartWrp = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .text {
    margin-right: 0.5rem;
    color: ${(p) => (p.errorColor ? p.theme.error : p.theme.opposite_txt)};
    font-size: ${(p) => p.theme.h6};
  }
`
const Date = styled.p`
  width: calc(50% - 2rem);
  font-size: ${(p) => p.theme.h6};
  color: ${(p) => (p.isMine ? p.theme.opposite : p.theme.additional)};
`
const Quantity = styled.p`
  margin-right: auto;
  font-size: ${(p) => p.theme.h5};
  color: ${(p) => (p.isMine ? p.theme.opposite : p.theme.regular_txt)};
`

const Value = styled.p`
  font-size: ${(p) => (p.isLastBet ? p.theme.h2 : p.theme.h3)};
  color: ${(p) => (p.isMine ? p.theme.opposite : p.theme.regular_txt)};
  text-align: right;
  font-weight: 600;
  ${(p) =>
    p.isHaveMyBet &&
    `
  color: ${p.isMine ? p.theme.opposite : p.theme.error};
  `};
`

const DumpBlock = styled.p`
  width: 100%;
  height: 8.6rem;
  background-color: ${(p) => p.theme.substrate};
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => p.theme.additional};
  display: flex;
  justify-content: center;
  align-items: center;
`

export default ({
  dic,
  local,
  currency = null,
  periodic = '123',
  isFinish = false,
  isWinnerStatus = 1,
  setIsMyBet = () => {},
  isOpenBidForm = false,
}) => {
  const dispatch = useDispatch()
  const result = useSelector((s) => s.tender.currentBets)
  const [isHaveData, setIsHaveData] = useState(false)
  const [lengthIdx, setLengthIdx] = useState(2)
  const [data, setData] = useState([])
  const [lastBid, setLastBid] = useState(null)
  const [body, setBody] = useState([])
  const [quantity, setQuantity] = useState(0)
  const [isHaveMyBet, setIsHaveMyBet] = useState(false)

  const loadMoreData = () => {
    const result = data.splice(0, 3)
    setBody([...body, ...result])
    setLengthIdx(lengthIdx + 2)
  }

  const updateDatas = () => {
    setIsHaveData(false)
    dispatch({ type: 'SAGA_GET_PRICEQUTES', route: periodic })
  }
  useEffect(() => {
    if (isHaveData) {
      setLastBid(data.shift())
      setBody([...data.splice(0, lengthIdx)])
      setIsHaveData(false)
    }
  }, [isHaveData]) //eslint-disable-line
  useEffect(() => {
    dispatch({ type: 'SAGA_GET_PRICEQUTES', route: periodic })
  }, []) //eslint-disable-line

  useEffect(() => {
    if (result.length > 0) {
      setIsHaveMyBet(result.find((obj) => obj && obj.isMine))
      setData(result)
      setIsHaveData(true)
      setQuantity(result.length)
    }
  }, [result])

  useEffect(() => {
    if (!isFinish) {
      const interval = setInterval(() => {
        if (!isOpenBidForm) updateDatas()
      }, delays.forTenderBids)
      return () => clearInterval(interval)
    }
  }, [isFinish, isOpenBidForm]) //eslint-disable-line

  useEffect(() => {
    if (lastBid) {
      setIsMyBet(
        lastBid.isMine
          ? {
              price: lastBid.pricequote,
              quantity: lastBid.loadqoute,
              cancelled: lastBid.cancelled,
              isMine: lastBid.isMine,
            }
          : null
      )
    }
  }, [lastBid]) //eslint-disable-line

  return (
    isWinnerStatus === 1 && (
      <Wrapper>
        <FlexBox alignCenter mrgnBtm="2rem">
          <Title big>{dic.bid_current}</Title>
          <Info>{`${dic.bids_total}: ${quantity}`}</Info>
        </FlexBox>
        {quantity <= 0 && <DumpBlock>{dic.bids_not}</DumpBlock>}
        {lastBid && !lastBid.cancelled && (
          <LastBid isMine={lastBid.isMine || false}>
            {lastBid && isHaveMyBet && (
              <StartWrp errorColor={!lastBid.isMine}>
                <p className="text">
                  {lastBid.isMine
                    ? strToUpperCase(dic.bid_your)
                    : strToUpperCase(dic.belowMine)}
                </p>
                <Icon
                  name={lastBid.isMine ? 'star' : 'atencion'}
                  type={lastBid.isMine ? 'opposite' : 'error'}
                  // style={{ marginTop: '0.5rem', marginLeft: '2rem' }}
                />
              </StartWrp>
            )}
            <div className="content">
              <Date isMine={lastBid.isMine || false}>
                {parseWholeDate(lastBid.moment, dic, true)}
              </Date>
              <Quantity isMine={lastBid.isMine}>
                {`${lastBid.loadqoute} ${local[lastBid.pricefor]}`}
              </Quantity>
              <Value
                isMine={lastBid.isMine || false}
                isHaveMyBet={Boolean(isHaveMyBet)}
                isLastBet
              >{`${lastBid.pricequote}${
                currency ? currencyIcons[currency] : currencyIcons.uah
              }`}</Value>
            </div>
            {lastBid.remark && (
              <p
                isMine={lastBid.isMine}
                className="remark"
                style={{ fontStyle: 'italic' }}
              >{`"${lastBid.remark}"`}</p>
            )}
          </LastBid>
        )}
        {body.length > 0 &&
          body.map((obj) => (
            <Bid key={obj.moment} isMine={obj.isMine}>
              {obj.isMine && (
                <StartWrp>
                  <p className="text">
                    {obj.isMine ? strToUpperCase(dic.bid_your) : ''}
                  </p>
                </StartWrp>
              )}
              <div className="content">
                <Date isMine={obj.isMine}>
                  {parseWholeDate(obj.moment, dic, true)}
                </Date>
                <Quantity isMine={obj.isMine}>{`${obj.loadqoute} ${
                  local[obj.pricefor]
                }`}</Quantity>
                <Value isMine={obj.isMine}>{`${obj.pricequote}${
                  currency ? currencyIcons[currency] : currencyIcons.uah
                }`}</Value>
              </div>
              {obj.remark && (
                <p
                  isMine={obj.isMine}
                  className="remark"
                  style={{ fontStyle: 'italic' }}
                >
                  {`"${obj.remark}"`}
                </p>
              )}
            </Bid>
          ))}
        {data.length > 0 && (
          <LoadMoreBtn
            title={dic.load_more}
            onClick={loadMoreData}
            style={{ marginTop: '3.2rem', marginBottom: '4rem' }}
          />
        )}
      </Wrapper>
    )
  )
}
