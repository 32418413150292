import React from 'react'
import styled from 'styled-components'
import TabWithIcon from '../../components/tabs/TabWithIcon'

const Wrapper = styled.div`
  width: 100%;
  padding: 1.5rem 0.8rem 2rem;
  /* border-top: 0.1rem solid ${(p) => p.theme.lines}; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
export default ({
  onChangeTab = () => {},
  list = [],
  currentRout = '',
  style,
}) => {
  return (
    <Wrapper style={style}>
      {list && list.length > 0 ? (
        list.map((item) => (
          <TabWithIcon
            key={item.id}
            obj={item}
            fnClick={() => onChangeTab(item.id)}
            currentTab={currentRout}
          />
        ))
      ) : (
        <p>Нет Доступных сервисов</p>
      )}
    </Wrapper>
  )
}
