import { takeEvery } from 'redux-saga/effects'
import * as Sentry from '@sentry/react'

export function* worker({ message }) {
  yield Sentry.captureException(message)
}

export default function* postSentryMessage() {
  yield takeEvery('SET_SENTRY_MESSAGE', worker)
}
