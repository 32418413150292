import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Icon from '../../../Icon'
import TabMenuWithIcon from '../../../../containers/menus/TabMenuWithIcons'
import RouteCardInfo from './RouteCardInfo1'
import RouteCardChat from '../RouteCardChat'
import RouteCardDocs from '../RouteCardDocs'
import RouteCardChronology from '../RouteCardChronology'
import KebabContent from '../../../../containers/InterfaceForCarrier/forRoutes/KebabContent'
import {
  Info,
  AwaitApprovedAction,
  SeparateLine,
} from '../../../smallPartsForInterface/SmallPartsCard'

const Wrapper = styled.div`
  width: 100%;
  margin-top: 6rem;
  padding: 2.6rem 1.6rem;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mrgBottom {
    margin-bottom: 3rem;
  }
  .padBottom {
    padding-bottom: 2.5rem;
  }
  .bottomLine {
    border-bottom: 0.1rem solid ${(p) => p.theme.lines};
  }
  .timer {
    width: 100%;
    padding-top: 2rem;
    color: ${(p) => p.theme.error};
    font-size: ${(p) => p.theme.h3};
    font-weight: 500;
  }
`
const Header = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`
const HeaderCity = styled.p`
  font-size: ${(p) => p.theme.h1};
  color: ${(p) => p.theme.regular_txt};
  font-weight: 600;
  :after {
    content: '→';
    margin: 0 0.5rem;
  }
  :last-child {
    :after {
      display: none;
    }
  }
`
export default ({
  obj,
  list = [],
  onChangeTab = () => {},
  currentTab = '',
}) => {
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const [isPopup, setIsPopup] = useState(false)
  const { routeparts, periodic } = obj
  const element = {
    11: <RouteCardInfo data={obj} />,
    22: <RouteCardDocs periodic={periodic} />,
    33: <RouteCardChat periodic={periodic} />,
    44: <RouteCardChronology periodic={periodic} />,
  }

  const onShowPopup = () => setIsPopup(!isPopup)

  return (
    <Wrapper>
      {isPopup && (
        <KebabContent
          routestatus={obj.routestatus}
          onClose={onShowPopup}
          dic={dic}
        />
      )}
      <Header>
        {routeparts.map(({ checkpoint, id }) => (
          <HeaderCity key={id}>{checkpoint.town.title_ru}</HeaderCity>
        ))}
      </Header>
      {obj.routestatus === 11 && obj.complete && (
        <AwaitApprovedAction
          text={dic.msg_10}
          style={{ marginBottom: '2.6rem' }}
        />
      )}

      <div className="flex padBottom">
        <Info>{`${dic.route} № ${periodic}`}</Info>
      </div>
      {obj.routestatus === 2 && obj.context.title && (
        <span className="flex padBottom">
          <Info isTrigger>
            {obj.context.title}
            {obj.context.iconTitle && (
              <Icon
                name={obj.context.iconTitle}
                type="trigger"
                style={{ marginLeft: '0.5rem' }}
              />
            )}
          </Info>
        </span>
      )}
      {obj.routestatus === 4 && (
        <span>
          <AwaitApprovedAction
            style={{ paddingBottom: '2rem' }}
            text={dic.msg_333}
          />
        </span>
      )}
      <SeparateLine />
      <TabMenuWithIcon
        onChangeTab={onChangeTab}
        // list={[list[0]]}
        list={
          obj.newMessages > 0
            ? list.map((item) => {
                if (item.typeIcon === 'chat') {
                  return { ...item, message: obj.newMessages }
                } else {
                  return item
                }
              })
            : list
        }
        currentRout={currentTab}
        style={{ paddingRight: 0, paddingLeft: 0 }}
      />
      {element[currentTab]}
    </Wrapper>
  )
}
