import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import SelectInput from './SmartSelectInput'
import AddNewDriver from '../forms/AddNewTS/AddNewDriver'
import AddNewCar from '../forms/AddNewTS/AddNewCar'
import AddNewTrailer from '../forms/AddNewTS/AddNewTrailer'
import BtnLikeLink from '../../components/buttons/BtnLikeLink'
import { strToUpperCase } from '../../lib/helpers/forStyling'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const Box = styled.div`
  position: relative;
  width: 100%;
`

const newParkItemStyle = {
  position: 'absolute',
  right: '1rem',
  top: '-0.5rem',
  zIndex: '4',
}

export default ({
  dataList = [],
  style = {},
  data = {},
  errors = {},
  fnFormValue = () => {},
  fnChange = () => {},
  typeUpdate = '',
}) => {
  const dispatch = useDispatch()
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const myDrivers = useSelector((s) => s.myPark.driver)
  const myCars = useSelector((s) => s.myPark.car)
  const myTrailers = useSelector((s) => s.myPark.trailer)
  const [TSChildrenName, setAddTSChildrenName] = useState('')
  const [isAddTS, setIsAddTS] = useState(false)

  const onAddNewTS = (nameForm) => {
    setAddTSChildrenName(nameForm)
    setIsAddTS(true)
  }

  const onCloseNewTSForm = () => {
    setAddTSChildrenName('')
    setIsAddTS(false)
  }

  const setDependence = (type, id) => {
    const sum = {
      driver: myDrivers,
      car: myCars,
      trailer: myTrailers,
    }
    const result = sum[type].find((obj) => obj.id === id)
    if (result) fnFormValue(type, result)
  }

  const forAddNewTS = {
    driver: (
      <AddNewDriver fnClose={onCloseNewTSForm} fnUpdateState={fnFormValue} />
    ),
    car: <AddNewCar fnClose={onCloseNewTSForm} fnUpdateState={fnFormValue} />,
    trailer: (
      <AddNewTrailer fnClose={onCloseNewTSForm} fnUpdateState={fnFormValue} />
    ),
  }

  useEffect(() => {
    dispatch({ type: 'SAGA_GET_MY_PARK_FOR_SMART_SELECT' })
  }, []) //eslint-disable-line
  useEffect(() => {
    if (typeUpdate === 'driver') {
      if (data.driver.car && data.driver.car !== data.car.id) {
        setDependence('car', data.driver.car)
      }
    }
    if (typeUpdate === 'car') {
      if (data.car.trailer && data.car.trailer !== data.trailer.id) {
        setDependence('trailer', data.car.trailer)
      }
    }
  }, [data]) //eslint-disable-line

  return (
    <>
      {isAddTS && forAddNewTS[TSChildrenName]}
      <Wrapper style={style}>
        <Box>
          <BtnLikeLink
            label={`+ ${strToUpperCase(dic.driver_new)}`}
            style={newParkItemStyle}
            onClick={() => onAddNewTS('driver')}
          />
          <SelectInput
            label={errors.driver ? `${dic.driver || ''}*` : dic.driver || ''}
            value={data.driver.union}
            name="driver"
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            list={dataList}
            error={errors.driver ? errors.driver.bool : null}
            message={errors.driver ? errors.driver.msg : null}
            nothingToDisplay={dic.info_1 || ''}
          />
        </Box>
        <Box>
          <BtnLikeLink
            label={`+ ${strToUpperCase(dic.car_new)}`}
            style={newParkItemStyle}
            onClick={() => onAddNewTS('car')}
          />
          <SelectInput
            label={errors.car ? `${dic.car || ''}*` : dic.car || ''}
            value={data.car.union}
            name="car"
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            list={dataList}
            error={errors.car ? errors.car.bool : null}
            message={errors.car ? errors.car.msg : null}
            nothingToDisplay={dic.info_1 || ''}
          />
        </Box>
        <Box>
          <BtnLikeLink
            label={`+ ${strToUpperCase(dic.trailer_new)}`}
            style={newParkItemStyle}
            onClick={() => onAddNewTS('trailer')}
          />
          <SelectInput
            label={errors.trailer ? `${dic.trailer || ''}*` : dic.trailer || ''}
            value={data.trailer.union}
            name="trailer"
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            list={dataList}
            error={errors.trailer ? errors.trailer.bool : null}
            message={errors.trailer ? errors.trailer.msg : null}
            nothingToDisplay={dic.info_1 || ''}
          />
        </Box>
      </Wrapper>
    </>
  )
}
