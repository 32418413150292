import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import useRouter from '../../hooks/useRouter'
import Icon from '../../components/Icon'

const Wrapper = styled.div`
  padding: 1.5rem 0;
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => p.theme.additional};
  display: flex;
  cursor: pointer;
`
const LinkWrp = styled.a`
  display: block;
  text-decoration: none;
  padding: 1.5rem 0;
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => p.theme.additional};
  display: flex;
  cursor: pointer;
`
const Text = styled.p`
  margin-left: 1rem;
  ${(p) =>
    p.upperCase &&
    `
  ::first-letter{
    text-transform:uppercase;
  }
  `}
`

export default ({
  title = 'link',
  iconType = 'settings',
  iconColor = 'additionalInfo',
  upperCase = false,
  path = null,
  externalLink = null,
  action = null,
}) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const currentTheme = useSelector((s) => s.general.theme)
  const onInstallPWA = useSelector((s) => s.mainMenu.onInstallPWA)
  const onChangeTheme = () => {
    const result = currentTheme === 'base' ? 'dark' : 'base'
    dispatch({
      type: 'SET_THEME',
      payload: result,
    })
    localStorage.setItem('sovTheme', result)
    dispatch({ type: 'CLOSE_MAIN_MENU' })
  }
  const onAction = (action) => {
    switch (action) {
      case 'theme':
        onChangeTheme()
        break
      case 'logout':
        localStorage.removeItem('sovToken')
        localStorage.removeItem('sovLink')
        localStorage.removeItem('sovCurrentNavTab')
        dispatch({ type: 'LOGOUT' })
        dispatch({ type: 'CLOSE_MAIN_MENU' })
        break
      case 'pwa':
        onInstallPWA()
        dispatch({ type: 'NECESSARY_INSTALL_PWA', payload: false })
        break
      default:
        break
    }
  }
  const onRedirect = (path) => {
    router.push(path)
    dispatch({ type: 'CLOSE_MAIN_MENU' })
  }
  const component = (
    action,
    path,
    externalLink,
    iconType,
    iconColor,
    title
  ) => {
    if (action) {
      return (
        <Wrapper onClick={() => onAction(action)}>
          <Icon name={iconType} type={iconColor} />
          <Text id={iconType} upperCase={upperCase}>
            {title}
          </Text>
        </Wrapper>
      )
    }
    if (path) {
      return (
        <Wrapper onClick={() => onRedirect(path)}>
          <Icon name={iconType} type={iconColor} />
          <Text id={iconType} upperCase={upperCase}>
            {title}
          </Text>
        </Wrapper>
      )
    }
    if (externalLink) {
      return (
        <LinkWrp href={externalLink} target="_blank">
          <Icon name={iconType} type={iconColor} />
          <Text id={iconType}>{title}</Text>
        </LinkWrp>
      )
    }
  }

  return component(action, path, externalLink, iconType, iconColor, title)
}
