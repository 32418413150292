import React, { useState } from 'react'
import styled from 'styled-components'
import BaseTextarea from '../../../inputs/BaseTextarea'
import BaseButton from '../../../buttons/BaseBtn'
import SelectDateBtn from '../../../buttons/SelectDateBtn'
import { useSelector } from 'react-redux'

const Wrapper = styled.div`
  width: 100%;
`
const Title = styled.div`
  width: 100%;
  height: 6rem;
  font-size: ${(p) => p.theme.h4};
`
const BtnWrap = styled.div`
  width: 100%;
  min-height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default ({
  content = { title: 'default title', action: 'test action' },
  onAction = () => {},
  onCancel = () => {},
  reasonTitle = 'Why',
}) => {
  const routeparts = useSelector((s) =>
    s.route.cardRoute.routeparts.map((item) => {
      return {
        address: `${item.checkpoint.town.title_ru} ${item.checkpoint.address}`,
        title: item.workaction_data.title_ru,
        date1: item.date1,
        id: item.id,
      }
    })
  )

  const [dates, setDates] = useState(routeparts)
  const [message, setMessage] = useState('')

  const onChangeTextArea = (e) => {
    setMessage(e.target.value)
  }
  const saveNewDate = (val, index) => {
    const result = dates.map((obj, idx) => {
      if (idx === index) {
        return { ...obj, date1: val }
      } else {
        return obj
      }
    })
    setDates(result)
  }
  //CHANGE_LANG
  return (
    <Wrapper>
      <Title>{content.title}</Title>
      {dates &&
        dates.length > 0 &&
        dates.map((routepart, index) => (
          <div key={routepart.id} style={{ height: '8rem' }}>
            <SelectDateBtn
              width="100%"
              label={routepart.title}
              extraString={routepart.address}
              value={new Date(routepart.date1)}
              index={index}
              fnClick={saveNewDate}
            />
          </div>
        ))}

      <BaseTextarea
        fnChange={onChangeTextArea}
        value={message}
        label={reasonTitle}
      />
      <BtnWrap>
        <BaseButton inContext="popup" label="Cancel" fnClick={onCancel} />
        <BaseButton
          inContext="popup"
          label="Save"
          fnClick={() => onAction(content.action, { message, newDates: dates })}
        />
      </BtnWrap>
    </Wrapper>
  )
}
