import { takeLatest, put } from 'redux-saga/effects' //select, call,

export function* worker({ route }) {
  yield put({
    type: 'SHOW_ERROR_API',
    code: 4,
    message: 'метод еще не готов',
  })
}

export default function* postRejectOperator() {
  yield takeLatest('SAGA_REJECT_OPERATOR', worker)
}
