import React, { useState } from 'react'
import styled from 'styled-components'
import Icon from '../../components/Icon'
import Timeselect from '../popUps/Time'
import ModalPortal from '../../components/layouts/ModalPortal'

const Wrapper = styled.div`
  position: relative;
  width: ${(p) => p.width};
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
`
const Btn = styled.button`
  width: 100%;
  padding: 1.3rem 1.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${(p) => p.theme.selected};
  border: 0.1rem solid ${(p) => p.theme.selected};
  :active {
    border: 0.1rem solid ${(p) => p.theme.inputBorder};
  }
`
const Label = styled.p`
  position: absolute;
  top: -2rem;
  left: 1.2rem;
  font-size: ${(p) => p.theme.h5};
  color: ${(p) => p.theme.additional};
  opacity: 0.8;
`
const Content = styled.p`
  font-size: ${(p) => p.theme.h4};
  margin: 0 auto;
  color: ${(p) => p.theme.regular_txt};
`
//HAVE TO USE

// width="100%" || "5rem"
// label= name input
// value= "1:15" || null
// index= this parameter indicates the position in the array, it is needed when the inputs are rendered from the array, in order to know which one to update.
// fnClick= fn from parrent who update main data. haves 2 parametrs - new value, index

export default ({
  value = '00:00',
  label = 'Time',
  fnClick = () => {},
  disabled = false,
  index = 0,
  width = `16rem`,
  id = 'SelectTimeBtn',
  style,
}) => {
  const [isShowTime, setIsShowTime] = useState(false)

  const forOpenTimeselect = () => {
    setIsShowTime(!isShowTime)
  }
  const saveData = (value, index) => {
    fnClick(value, index)
    setIsShowTime(!isShowTime)
  }
  return isShowTime ? (
    <ModalPortal>
      <Timeselect
        fnSave={saveData}
        fnCancel={forOpenTimeselect}
        value={value}
        index={index}
      />
    </ModalPortal>
  ) : (
    <Wrapper width={width} style={style} disabled={disabled}>
      <Label>{label}</Label>
      <Btn
        type="button"
        onClick={forOpenTimeselect}
        disabled={disabled}
        id={id}
        data-testid={id}
      >
        <Icon
          name="clock"
          type="icons"
          style={{ marginRight: 'auto', marginTop: '0.15rem' }}
        />
        <Content>{value}</Content>
      </Btn>
    </Wrapper>
  )
}
