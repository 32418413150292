import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import shortid from 'shortid'
import { LikeLabel } from '../../../baseStylesComponents'
import SingleWrp from './SingleWrp'
import BaseTextarea from '../../../components/inputs/BaseTextarea'
import SelectTruckType from '../../selects/SelectTruckType'
import SelectDateBtn from '../../../components/buttons/SelectDateBtn'
import BtnLikeLink from '../../../components/buttons/BtnLikeLink'
import ModalPortal from '../../../components/layouts/ModalPortal'
import Checklist from '../../../components/popUps/Checklist'
import { strToUpperCase } from '../../../lib/helpers/forStyling'
import { dataParse } from "../../../lib/helpers/addNewCar"

import GeneralFieldsList from "../Fields/GeneralFieldsList"
import { useAddNewCar } from "../hooks/useAddNewCar";

export default ({
  fnClose = () => {},
  fnUpdateState = () => {},
  periodic = '',
  withoutPortal = false,
}) => {
  //TODO remove this default objs
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const dispatch = useDispatch()

  const {
    truckTypes,
    requiredFields,
    extraFields,
    additionalFields,
    errors,
    onSubmitForm,
  } = useAddNewCar()

  const selectList = useSelector((s) => s.selectList.searchList)
  const data = useSelector((s) => s.newTS.car)
  const list = useSelector((s) => s.checkList.list)
  const [isExtraParams, setIsExtraParams] = useState(false)
  const [isComment, setIsComment] = useState(false)

  const [uniq, setUniq] = useState({}) // create uniq input id
  const [parser, setParser] = useState({}) // convert back uniqId to data obj

  const [isFetched, setIsFetched] = useState(false)
  const [isHaveData, setIsHaveData] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [localTitle, setLocalTitle] = useState('')
  const [selector, setSelector] = useState('')

  const currentCarType = useMemo(()=> truckTypes?.fromStore[data?.carstyle] || '', [data])

  const onDateUpdate = (VALUE, NAME) => {
    dispatch({
      type: 'NEW_TS_UPDATE_CAR',
      payload: { ...data, [NAME]: VALUE },
    })
  }

  const onFocusInputs = (inputData) => {

    const actionName =  {
      "chargetype": "NEW_TS_UPDATE_LIST_CHARGE_TYPE",
      "ferryproperty": "NEW_TS_UPDATE_LIST_FERRY_PROPERTIES",
    }
    const {
      action,
      name,
    } = inputData;

    dispatch({
      type: actionName[name],
      payload: { name, formName: "car" },
    })

    dispatch(action)
    dispatch({
      type: 'OPEN_MODAL',
      payload: "transportForm",
    })
  }

  const onChangeInputs = (e) => {
    const VALUE = e.target.value
    const NAME = parser[e.target.name]
    dispatch({
      type: 'NEW_TS_UPDATE_CAR',
      payload: { ...data, [NAME]: VALUE },
    })
  }

  const onSetCarStyle = (value) => {
    dispatch({
      type: 'NEW_TS_UPDATE_CAR',
      payload: { ...data, carstyle: truckTypes.toStore[value] },
    })
  }

  const togglerExtraParams = () => {
    setIsExtraParams(!isExtraParams)
  }
  const togglerComment = () => {
    setIsComment(!isComment)
  }

  const onSaveNewData = () => {
    dispatch({ type: 'START_REQUEST', payload: `newCar` })
    dispatch({
      type: 'SAGA_ADD_NEW_TS',
      data: dataParse(data),
      action: 'car',
      fnClose,
      fnUpdateState,
    })
  }

  const fnFormValue = (where, obj) => {
    dispatch({
      type: 'NEW_TS_UPDATE_CAR',
      payload: { ...data, [parser[where]]: obj },
    })
  }

  const dataSelector = {
    typeAuto: data.cartype,
    typeLoad: data.chargeType,
    ferryProperty: data.ferryproperty,
  }
  const keys = {
    typeAuto: 'cartype',
    typeLoad: 'chargeType',
    ferryProperty: 'ferryproperty',
  }

  const onChangeList = (id, where) => {
    let result = []
    if (dataSelector[where].find((el) => el.id === id)) {
      result = dataSelector[where].filter((el) => el.id !== id)
    } else {
      const item = list.find((el) => el.id === id)
      result = [...dataSelector[where], { ...item, checked: true }]
    }
    dispatch({
      type: 'NEW_TS_UPDATE_CAR',
      payload: { ...data, [keys[where]]: result },
    })

    dispatch({
      type: 'SET_CHEKLIST',
      payload: list.map((el) => {
        if (el.id === id) {
          return { ...el, checked: !el.checked }
        } else {
          return el
        }
      }),
    })
  }
  const onChangeListItemQuantity = (where, id, val) => {
    let result = []

    result = dataSelector[where].map((el) =>
      el.id === id ? { ...el, quantity: val } : el
    )

    dispatch({
      type: 'NEW_TS_UPDATE_CAR',
      payload: { ...data, [keys[where]]: result },
    })

    dispatch({
      type: 'SET_CHEKLIST',
      payload: list.map((el) => {
        if (el.id === id) {
          return { ...el, quantity: val }
        } else {
          return el
        }
      }),
    })
  }

  const onCloseModal = (bool) => {
    if (bool) {
    }
    setIsModal(false)
  }


  useEffect(() => {
    if (isHaveData && !isFetched) {
      const uniqNames = Object.keys(data).reduce((result, item) => {
        return { ...result, [item]: shortid.generate() }
      }, {})

      setUniq(uniqNames)
      setParser(
        Object.keys(uniqNames).reduce((result, item) => {
          return { ...result, [uniqNames[item]]: item }
        }, {})
      )
      setIsFetched(true)
    }
  }, [isHaveData]) //eslint-disable-line

  useEffect(() => {
    if (data.hasOwnProperty('carstyle')) {
      setIsHaveData(true)
    }
  }, [data])

  useEffect(() =>{
    const errorName = Object.keys(errors)[0] || ""
    if(errorName){
      const nodeName = uniq[errorName] || ""
      const isAdditional = additionalFields.find(el=> el.name === errorName);
      if(isAdditional){
        setIsExtraParams(true)
      }
      const node = document.querySelector(`input[name='${nodeName}']`)
      if(node){
        node.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
      }
    }
  },[errors])

  useEffect(()=>{
    if(!data?.cartype?.id && currentCarType === "truck"){
      dispatch({
        type: 'NEW_TS_UPDATE_CAR',
        payload: {
        ...data,
        "cartype": { id: "1", title: "тент" , union:"тент" } },
      })
    }

  },[currentCarType])


  useEffect(() => {
    return () => dispatch({ type: 'NEW_TS_SET_DEFAULT_DATA' })
  }, []) //eslint-disable-line

  return (
      isFetched && (
      <>
        {isModal && (
          <ModalPortal>
            <Checklist
              list={list}
              fnUpdateCheck={onChangeList}
              fnUpdateQuantity={onChangeListItemQuantity}
              fnCancel={() => onCloseModal(true)}
              fnSave={onCloseModal}
              title={localTitle}
              id={selector}
            />
          </ModalPortal>
        )}
        <SingleWrp
          fnSubmit={()=>onSubmitForm(data, onSaveNewData)}
          fnClose={fnClose}
          periodic={periodic}
          withoutPortal={withoutPortal}
          type="car"
          localId="newCar"
          style={{ height: '95vh' }}
        >
          <GeneralFieldsList
              onChange={onChangeInputs}
              fields={requiredFields}
              uniq={uniq} // uniq input id
              errors={errors}
              data={data}
              optionsList={selectList}
              onChangeSelect={fnFormValue}
              onChangeCheckbox={onFocusInputs}
          />
          <LikeLabel>{strToUpperCase(dic.kind)}</LikeLabel>
          <SelectTruckType
            style={{ width: '90%', margin: '0 auto 2.6rem' }}
            fnUpdate={onSetCarStyle}
            currentType={currentCarType}
          />

          {currentCarType !== 'tractor' && (
                <GeneralFieldsList
                    onChange={onChangeInputs}
                    fields={extraFields}
                    uniq={uniq} // uniq input id
                    errors={errors}
                    data={data}
                    optionsList={selectList}
                    onChangeSelect={fnFormValue}
                    onChangeCheckbox={onFocusInputs}
                />
          )}

          <BtnLikeLink
            label={`${dic.extra}...`}
            firstLetter
            style={{ marginBottom: '3.2rem' }}
            onClick={togglerExtraParams}
          />
          {isExtraParams && (
            <>
              <GeneralFieldsList
                  onChange={onChangeInputs}
                  fields={additionalFields}
                  uniq={uniq}
                  errors={errors}
                  data={data}
                  optionsList={selectList}
                  onChangeSelect={fnFormValue}
                  onChangeCheckbox={onFocusInputs}
              />
              <SelectDateBtn
                style={{ marginBottom: '1rem'}}
                label={strToUpperCase(
                  `${dic.car_insurance_term}: ${dic.to}...`
                )}
                fnClick={onDateUpdate}
                index={uniq.insuredTil}
                value={data.insuredTil}
              />
            </>
          )}
          <BtnLikeLink
            label={`${dic.remark_add}...`}
            firstLetter
            style={{ marginBottom: isComment ? '3rem' : '4rem' }}
            onClick={togglerComment}
          />
          {isComment && (
            <BaseTextarea
              label={dic.remark}
              style={{ marginBottom: '4rem' }}
              fnChange={onChangeInputs}
              name={uniq.remark}
              value={data.remark}
              error={errors?.remark ? errors?.remark?.bool : null}
              message={errors?.remark ? errors?.remark?.msg : null}
            />
          )}
        </SingleWrp>
      </>
    )
  )
}
