import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.button`
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: none;
  border-bottom: 0.1rem dashed ${(p) => p.theme.links};
  color: ${(p) => p.theme.links};
  font-size: ${(p) => (p.bold ? p.theme.h3 : p.theme.h4)};
  font-weight: ${(p) => (p.bold ? '600' : '400')};
  transition: 0.3s ease;
  cursor: pointer;

  :active {
    transform: scale(0.97);
  }
  :disabled {
    opacity: 0.4;
    cursor: default;
  }
  .linkBtnLabel {
    color: ${(p) => p.theme.links};
    ${(p) =>
      p.firstLetter &&
      `
    :first-letter {
    text-transform: uppercase;
  }

  `};
  }
`
export default ({
  style = {},
  disabled = false,
  bold = false,
  onClick = () => {},
  label = 'Btn like link',
  firstLetter = false,
  id = 'BtnLikeLink',
}) => {
  return (
    <Wrapper
      disabled={disabled}
      onClick={onClick}
      style={style}
      firstLetter={firstLetter}
      bold={bold}
      id={id}
    >
      <p className="linkBtnLabel">{label}</p>
    </Wrapper>
  )
}
