import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import TimeKeeper from 'react-timekeeper'
import BaseButton from '../buttons/BaseBtn'

const Wrapper = styled.div`
  width: 80%;
  min-width: 28rem;
  max-width: 40rem;
  min-height: 47rem;
`
const NpmWrapper = styled.div`
  /* change default styles */
  &&& {
    --main-box-shadow: none;
    --top-selected-color: ${(p) => p.theme.basic_txt};
    --top-colon-color: ${(p) => p.theme.opposite_txt};
    --top-text-color: ${(p) => p.theme.opposite_txt};
    --numbers-text-color: ${(p) => p.theme.regular_txt};
    --clock-bg: ${(p) => p.theme.opposite2};

    .react-timekeeper {
      width: 28rem;
      border-radius: 0.8rem 0.8rem 0 0;
    }
    .react-timekeeper__clock-wrapper {
      background-color: ${(p) => p.theme.opposite};
    }
    .react-timekeeper__top-bar {
      width: 28rem;
      background-color: ${(p) => p.theme.flat_headers};
      border-radius: 0.8rem 0.8rem 0 0;
    }
  }
`
const BtnWrap = styled.div`
  width: 100%;
  min-height: 5rem;
  padding: 0 0 1rem;
  border-radius: 0 0 1rem 1rem;
  background-color: ${(p) => p.theme.opposite};
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export default ({
  fnSave = () => {},
  fnCancel = () => {},
  value = null,
  index,
}) => {
  const [myTime, setMyTime] = useState('11:30')
  const dic = useSelector((s) => s.defaultLang.serverDictionary)

  const updateStateMiddleware = () => {
    const hour = myTime.split(':')[0]
    const min = myTime.split(':')[1]
    fnSave(hour.length === 1 ? `0${hour}:${min}` : `${hour}:${min}`, index)
  }

  useEffect(() => {
    if (value) {
      setMyTime(value)
    }
  }, []) //eslint-disable-line
  return (
    <Wrapper>
      <NpmWrapper>
        <TimeKeeper
          time={myTime}
          hour24Mode
          onChange={(data) => setMyTime(data.formatted24)}
          switchToMinuteOnHourSelect
        />
      </NpmWrapper>
      <BtnWrap>
        <BaseButton
          inContext="popup"
          label={dic.btn_cancel}
          fnClick={fnCancel}
          toUpperCase
        />
        <BaseButton
          inContext="popup"
          label={dic.btn_ok}
          fnClick={updateStateMiddleware}
          toUpperCase
        />
      </BtnWrap>
    </Wrapper>
  )
}
