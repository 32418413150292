import { takeEvery, call, put, select, race, delay } from 'redux-saga/effects'
import { getChats } from '../../lib/requests/chatRequest'
import { delays } from '../../lib/constants'
import {getCurrentLang, getToken, getDicionary, getCompanyHash, getDialogues} from '../selectors'

export function* worker({ periodic }) {
  try {
    const currentLang = yield select(getCurrentLang)
    const token = yield select(getToken)
    const dic = yield select(getDicionary)
    const dialogues = yield select(getDialogues)

    const { result } = yield race({
      result: call(getChats, token, currentLang, periodic),
      timeout: delay(delays.forCancelRequest),
    })
    yield put({ type: 'SET_CHAT_LOADER', payload: true })

    if (result && result.status === 200) {
      yield put({
        type: 'SET_DIALOGUES',
        payload: result.data.data.reduce((acc, dialog)=>{
              const { clientHash = ""} = dialog;
              if(acc[clientHash]){
                return acc
              }

              return {
                ...acc,
                [clientHash]: []
              }
        }, dialogues),
      })
      yield put({ type: 'SET_CHAT_LOADER', payload: false })
      yield put({ type: 'SET_CHAT_FETCH', payload: true })
    } else {
      yield put({
        type: 'SHOW_ERROR_API',
        code: 6,
        message: dic.msg_server_err || 'internal server error',
      })
      yield put({ type: 'SET_CHAT_LOADER', payload: false })
    }
  } catch (e) {
    yield put({
      type: 'SHOW_ERROR_API',
      code: String(e.response.status).slice(0, 1),
      message: e.response ? e.response.data.message : e.message,
    })
    yield put({ type: 'SET_CHAT_LOADER', payload: false })
    if (e.response && e.response.data.relogin) {
      yield put({ type: 'SAGA_LOGOUT' })
    }
    if (e.response && e.response.data.forwardTo) {
      yield put({
        type: 'REDIRECT_OUTSIDE',
        payload: e.response.data.forwardTo,
      })
    }
  }
}

export default function* allRoutes() {
  yield takeEvery('GET_ROUTE_CHATS', worker)
}
