import Type from '../types'

const initialState = {
  path: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.REDIRECT_OUTSIDE:
      return {
        ...state,
        path: action.payload,
      }
    default:
      return state
  }
}
