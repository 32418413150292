import React, {useEffect, useMemo} from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Active from '../widgetForList/Active2'
import Completed from '../widgetForList/Completed2'
import Icon from '../../../Icon'
import SmallLoader from '../../../layouts/SmallLoader'
import LoadMoreBtn from '../../../buttons/LoadMoreBtn'
import YellowMarker from '../../../YellowMarker'
import FilterForTab from '../../../../containers/other/FilterForTab'
import { delays } from '../../../../lib/constants'
import {filterRoutes} from "../../../../lib/helpers/forRouteFilter";

const Wrapper = styled.div``
const Tap = styled.div`
  width: 100%;
  padding: 2rem 2.8rem 2.5rem;
  ${(p) => !p.isopen && `border-bottom: 0.1rem solid ${p.theme.lines}`};
  background-color: ${(p) => (p.isopen ? p.theme.selected : 'unset')};
  font-size: ${(p) => p.theme.h4};
  font-weight: 500;
`
const TapHeaderWrp = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`
const TabFilterWrp = styled.div`
  width: 100%;
  margin-top: 2.4rem;
`
const TabFilter = styled.div`
  width: fit-content;
  font-weight: 400;
  margin-bottom: 1rem;
  font-size: ${(p) => p.theme.h5};
  color: ${(p) => p.theme.links};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Label = styled.span`
  ${(p) => !p.isopen && `margin-right: auto;`};
  padding-right: 2rem;
  font-size: ${(p) => p.theme.h4};
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Quantity = styled.span`
  font-size: ${(p) => p.theme.h4};
  font-weight: 700;
  ${(p) => p.marginAuto && `margin-right: auto;`};
`
const ArrowWrp = styled.span`
  margin-right: 2rem;
  margin-left: ${(p) => (p.isopen ? `0.4rem` : `0`)};
  transform: ${(p) => (p.isopen ? `rotate(-90deg)` : `rotate(90deg)`)};
`
const Content = styled.div`
  padding: 0 0 1.5rem;
  background-color: ${(p) => p.theme.selected};
`
const defaultObj = {
  title: 'vertical tab',
  id: '0',
  types: ['0'],
  content: [{ id: '11' }],
}

export default ({ obj = defaultObj, typeUpdate = '', location }) => {
  const dispatch = useDispatch()
  const dictionary = useSelector((s) => s.defaultLang.serverDictionary)
  const isOpen = useSelector((s) => s.tender.isOpen[location])
  const totalCompleteTenders = useSelector((s) => s.tender.totalCompleteTenders)
  const tabContent = useSelector((s) => s.tender.tabContent)
  const badRequest = useSelector((s) => s.tender.tenderTabBadRequest)
  const perPage = useSelector((s) => s.tender.perPage)
  const currentPage = useSelector((s) => s.tender.currentPage)
  const defaultIsOpen = useSelector((s) => s.tender.isOpen)
  const lastPosition = useSelector((s) => s.tender.lastPosition)
  const currentFilterFor = useSelector((s) => s.filterForTab.currentId)
  const selectedFilters = useSelector((s) => s.filterForTab.selectedArgs)
  const data = useSelector((s) => s.tender.widgetList);

  const widgetList = useMemo(()=>{
    return currentFilterFor === obj.id
        ? filterRoutes(data, selectedFilters)
        : data;

  },[currentFilterFor, selectedFilters,  data])

  const toggler = () => {
    if (obj.id !== isOpen) {
      dispatch({ type: 'CLEAR_WIDGET_TENDERS' })
    }
    if (obj.id === isOpen) {
      dispatch({ type: 'CLEAR_FLAG_FOR_LIST_TENDERS_PAGE' })
    }
    if (Number(obj.id) === 6) {
      dispatch({
        type: 'SET_OPEN_WIDGET_TENDERS',
        payload:
          isOpen === obj.id
            ? { all: null, my: null }
            : { ...defaultIsOpen, [location]: obj.id },
        location,
      })
      if (isOpen !== null) {
        dispatch({ type: typeUpdate })
        dispatch({ type: 'REMEMBER_TENDER_WIDGET', payload: '' })
      }
      return
    }
    const result =
      isOpen === obj.id
        ? { all: null, my: null }
        : obj.content.length
        ? { ...defaultIsOpen, [location]: obj.id }
        : { all: null, my: null }
    dispatch({ type: 'SET_OPEN_WIDGET_TENDERS', payload: result })
    // dispatch({ type: 'CLEAR_WIDGET_TENDERS' })
    if (isOpen !== null) {
      dispatch({ type: typeUpdate })
      dispatch({ type: 'REMEMBER_TENDER_WIDGET', payload: '' })
    }
  }

  const loadMoreWidgets = () => {
    //TODO repeat code
    dispatch({
      type: 'SAGA_GET_COMPLETE_TENDERS',
      data: { page: currentPage + 1, perPage },
    })
  }

  const onRemoveFilter = (obj) => {
    dispatch({ type: 'REMOVE_ARG_FOR_FILTER', payload: obj })
  }

  const WidgetType = (id, data, dictionary, index) => ({
    1: (
      <Active
        key={id}
        dic={dictionary}
        obj={data}
        memory={lastPosition}
        contentList={tabContent}
      />
    ),
    3: <Completed key={id} dic={dictionary} obj={data} memory={lastPosition} />,
    4: <Completed key={id} dic={dictionary} obj={data} memory={lastPosition} />,
    6: (
      <Completed
        key={id}
        dic={dictionary}
        obj={data}
        memory={lastPosition}
        idx={index}
      />
    ),
  })

  useEffect(() => {
    let interval = setInterval(() => {}, 0)
    if (isOpen === obj.id && Number(obj.id) === 6) {
      //TODO repeat code
      dispatch({
        type: 'SAGA_GET_COMPLETE_TENDERS',
        data: { page: currentPage + 1, perPage },
      })
    } else if (isOpen === obj.id && Number(obj.id) !== 6) {
      dispatch({
        type: 'SAGA_GET_TENDER_DETAILS',
        tenderList: obj.content.map((item) => item.periodic).toString(),
        typeAction: 'base',
      })
    }
    if (obj.id === 1 && obj.id === isOpen) {
      interval = setInterval(
        () =>
          dispatch({
            type: 'SAGA_GET_TENDER_DETAILS',
            tenderList: obj.content.map((item) => item.periodic).toString(),
            typeAction: 'base',
          }),
        delays.forTenderBids
      )
    }
    return () => clearInterval(interval)
  }, [isOpen]) //eslint-disable-line

  return (
    <Wrapper>
      <Tap isopen={isOpen === obj.id}>
        <TapHeaderWrp onClick={toggler}>
          <ArrowWrp isopen={isOpen === obj.id}>
            <Icon name="arrowShevron" type="regularText" />
          </ArrowWrp>
          <Label isopen={isOpen === obj.id}>
            {obj.title} {obj.needAttention > 0 && <YellowMarker />}
          </Label>
          {Boolean(isOpen === obj.id) && (
            <Quantity marginAuto>
              {Number(obj.id) !== 6
                ? currentFilterFor === obj.id && selectedFilters.length > 0
                  ? widgetList.length
                  : obj.content.length
                : totalCompleteTenders}
            </Quantity>
          )}
          {
            <Quantity>
              {Boolean(isOpen !== obj.id) && Number(obj.id) !== 6 ? (
                currentFilterFor === obj.id && selectedFilters.length > 0 ? (
                  widgetList.length
                ) : obj.isSort ? (
                  obj.content.length
                ) : (
                  <SmallLoader
                    id="contentLengthLoader"
                    style={{ marginTop: '0.5rem' }}
                  />
                )
              ) : obj.id !== 6 && (
                <FilterForTab
                  id={obj.id}
                  currentFilter={currentFilterFor}
                  where={{ typeUpdate, location }}
                  content={widgetList}
                  badRequest={badRequest}
                />
              )}
            </Quantity>
          }
        </TapHeaderWrp>
        {isOpen === obj.id &&
          currentFilterFor === obj.id &&
          selectedFilters.length > 0 && (
            <TabFilterWrp>
              {selectedFilters.map((el) => (
                <TabFilter onClick={() => onRemoveFilter(el)} key={el.filterId}>
                  <p>{el.title}</p>
                  <Icon
                    name="close"
                    type="additionalInfo"
                    width="1.2rem"
                    height="1.2rem"
                    style={{ marginLeft: '1rem' }}
                  />
                </TabFilter>
              ))}
            </TabFilterWrp>
          )}
      </Tap>
      {Boolean(isOpen === obj.id) && (
        <Content>
          {widgetList.length > 0 ? (
            widgetList.map(
              (item, index) =>
                WidgetType(item.id, item, dictionary, index + 1)[obj.id]
            )
          ) : badRequest ? null : (
            <SmallLoader />
          )}
          {Number(obj.id) === 6 && totalCompleteTenders > widgetList.length && (
            <LoadMoreBtn
              title={`${dictionary.load_more} ${perPage}`}
              style={{ marginTop: '3.2rem', marginBottom: '4rem' }}
              onClick={loadMoreWidgets}
            />
          )}
        </Content>
      )}
    </Wrapper>
  )
}
