import Type from '../types'
import {
  newRoutePart_TEMPLATE,
  newRoutePart_REQUIRED_FIELD,
} from '../../lib/constants'

const defaultState = {
  routeparts: [{ ...newRoutePart_TEMPLATE }],
  // routeparts: forStep2,
  requiredFields: [{ ...newRoutePart_REQUIRED_FIELD }],
  isPostNewRoute: false,
  codeApi: '',
  quantity_auto: 1,
  ferryproperty: [],
  cartype: [],
  chargetype: [],
  nds: [1, 0],
  currentNds: { id: 0 },
  remark: '',
  startprice: '',
  istender: false,
  tendertype: 1,
  tenderIsBlind: false,
  tenderterms: '',
  tenderIsNow: true,
  tenderavailableuntilmoment: '',
  tenderavailableuntilmoment_time: '',
  plannedTenderDate: '',
  plannedTenderTime: '',
  tenderStep: '',
  routePrice: '',
  tenderStartPrice: '',
  tenderBasePrice: '',
  tenderWinnerPrice: '',
  totalcount: '',
  currency: { id: 'uah', symbol: '₴', union: '₴', currencycode: 'uah' },
  priceFor: { id: '3', title: 'рейс', union: 'рейс', code: 'ride' },
  paymentType: { id: '3', title: 'Наличные', union: 'Наличные' },
  payorcompany: { id: '', title: '', union: '' },
  isHaveDefaultData: false,
  operators: [],
  settings: {
    codeApi: false,
    category: false,
    operatorselect: false,
    baseprice: false,
    winnerprice: false,
    blindTender: false,
    showPayor: false,
  },
}

const initialState = {
  ...defaultState,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_NEWROUTE_DEFAULT_DATA:
      return {
        ...defaultState,
      }
    case Type.SET_NEWROUTE_DEFAULT_DATA_FLAG:
      return {
        ...state,
        isHaveDefaultData: action.payload,
      }

    case Type.UPDATE_NEWROUTE_CODEAPI:
      return {
        ...state,
        codeApi: action.payload,
      }

    case Type.UPDATE_NEWROUTE_PARTS:
      return {
        ...state,
        routeparts: [...action.payload],
      }
    case Type.UPDATE_NEWROUTE_PARTS_ERROR:
      return {
        ...state,
        requiredFields: [...action.payload],
      }
    case Type.UPDATE_NEWROUTE_QUANTITY_AUTO:
      return {
        ...state,
        quantity_auto: action.payload,
      }
    case Type.UPDATE_NEWROUTE_FERRYPROPERTIES:
      return {
        ...state,
        ferryproperty: action.payload,
      }
    case Type.UPDATE_NEWROUTE_CARTYPE:
      return {
        ...state,
        cartype: action.payload,
      }
    case Type.UPDATE_NEWROUTE_CHARGETYPE:
      return {
        ...state,
        chargetype: action.payload,
      }
    case Type.UPDATE_NEWROUTE_COMMENT:
      return {
        ...state,
        remark: action.payload,
      }
    case Type.UPDATE_NEWROUTE_TENDERTERMS:
      return {
        ...state,
        tenderterms: action.payload,
      }

    case Type.UPDATE_NEWROUTE_TENDER_ROUTEPRICE:
      return {
        ...state,
        routePrice: action.payload,
      }
    case Type.UPDATE_NEWROUTE_ISTENDER:
      return {
        ...state,
        istender: action.payload,
      }
    case Type.UPDATE_NEWROUTE_TENDERISNOW:
      return {
        ...state,
        tenderIsNow: action.payload,
        plannedTenderDate: '',
      }
    case Type.UPDATE_NEWROUTE_TENDER_START_DATE:
      return {
        ...state,
        plannedTenderDate: action.payload,
        tenderavailableuntilmoment: '',
      }
    case Type.UPDATE_NEWROUTE_TENDER_START_TIME:
      return {
        ...state,
        plannedTenderTime: action.payload,
      }
    case Type.UPDATE_NEWROUTE_TENDER_FINISH_DATE:
      return {
        ...state,
        tenderavailableuntilmoment: action.payload,
      }
    case Type.UPDATE_NEWROUTE_TENDER_FINISH_TIME:
      return {
        ...state,
        tenderavailableuntilmoment_time: action.payload,
      }
    case Type.UPDATE_NEWROUTE_TENDER_STEP:
      return {
        ...state,
        tenderStep: action.payload,
      }
    case Type.UPDATE_NEWROUTE_TENDER_STARTPRICE:
      return {
        ...state,
        tenderStartPrice: action.payload,
      }
    case Type.UPDATE_NEWROUTE_TENDER_WINNERPRICE:
      return {
        ...state,
        tenderWinnerPrice: action.payload,
      }
    case Type.UPDATE_NEWROUTE_TENDER_BASEPRICE:
      return {
        ...state,
        tenderBasePrice: action.payload,
      }
    case Type.UPDATE_NEWROUTE_TENDER_TOTALCOUNT:
      return {
        ...state,
        totalcount: action.payload,
      }
    case Type.UPDATE_NEWROUTE_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      }
    case Type.UPDATE_NEWROUTE_PRICEFOR:
      return {
        ...state,
        priceFor: action.payload,
      }
    case Type.UPDATE_NEWROUTE_PAYMENTTYPE:
      return {
        ...state,
        paymentType: action.payload,
      }
    case Type.UPDATE_NEWROUTE_PAYORDER_COMPANY:
      return {
        ...state,
        payorcompany: action.payload,
      }
    case Type.UPDATE_NEWROUTE_NDS:
      return {
        ...state,
        currentNds: action.payload,
      }
    case Type.UPDATE_NEWROUTE_TENDERTYPE:
      return {
        ...state,
        tendertype: action.payload,
      }
    case Type.UPDATE_NEWROUTE_TENDERISBLIND:
      return {
        ...state,
        tenderIsBlind: action.payload,
      }
    case Type.UPDATE_NEWROUTE_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      }
    case Type.UPDATE_NEWROUTE_OPERATORS:
      return {
        ...state,
        operators: action.payload,
      }
    case Type.UPDATE_NEWROUTE_FLAG_FOR_BEFOREUNLOAD:
      return {
        ...state,
        isPostNewRoute: action.payload,
      }

    default:
      return state
  }
}
