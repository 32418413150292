import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Checkbox from '../inputs/BaseCheckboxInput'
import BaseButton from '../buttons/BaseBtn'
import SmallLoader from '../layouts/SmallLoader'

const Wrapper = styled.div`
  width: 85vw;
  max-width: 64rem;
  min-height: 48rem;
  margin: 0 auto;
`
const Label = styled.div`
  width: 100%;
  height: ${(p) => p.length > 15 ? "7rem" : "9rem"};
  padding: 0 2rem 2rem;
  border-radius: 1rem 1rem 0 0;
  background-color: ${(p) => p.theme.flat_headers};
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  color: ${(p) => p.theme.opposite_txt};
  font-size: ${(p) => p.length > 15 ? p.theme.h2 : p.theme.h1};
`
const Content = styled.div`
  width: 100%;
  height: 33rem;
  padding: 2rem;
  background-color: ${(p) => p.theme.opposite};
  overflow-y: auto;
  overflow-x: hidden;
`
const BtnWrap = styled.div`
  width: 100%;
  min-height: 5rem;
  padding: 0 2rem 1rem;
  border-radius: 0 0 1rem 1rem;
  background-color: ${(p) => p.theme.opposite};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default ({
  list = [],
  fnUpdateCheck = () => {},
  fnUpdateQuantity = () => {},
  fnSave = () => {},
  fnCancel = () => {},
  title = '',
  id = 'test id',
}) => {
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const isLoader = useSelector((s) => s.checkList.isLoader)

  const onChangeQuantity = (checkBoxId, quantity) => {
    fnUpdateQuantity(id, checkBoxId, quantity)
  }

  const onCheck = (checkBoxId) => {
    fnUpdateCheck(checkBoxId, id)
  }

  return (
    <Wrapper>
      <Label length={title?.length}>
        <p>{title}</p>
      </Label>
      <Content>
        {list && list.length > 0 ? (
          list.map((obj) => (
            <Checkbox
              key={obj.id}
              label={obj.label}
              id={obj.id}
              checked={obj.checked}
              fnChange={onCheck}
              fnChangeQuantity={onChangeQuantity}
              data={obj}
              style={{ marginBottom: '2rem' }}
            />
          ))
        ) : isLoader ? (
          <SmallLoader style={{ marginTop: '5%' }} size="4rem" />
        ) : null}
      </Content>
      <BtnWrap>
        <BaseButton
          inContext="popup"
          label={dic.btn_cancel}
          fnClick={fnCancel}
          toUpperCase
        />
        <BaseButton
          inContext="popup"
          label={dic.btn_ok}
          fnClick={fnSave}
          toUpperCase
        />
      </BtnWrap>
    </Wrapper>
  )
}
