import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import useOutsideClick from '../../hooks/useOutsideClick'
import { strToUpperCase } from '../../lib/helpers/forStyling'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  .this-input {
    width: 100%;
    height: 4.6rem;
    padding: 0 4.5rem;
    background-color: ${(p) => p.theme.selected};
    font-size: ${(p) => p.theme.h4};
    color: ${(p) => (p.error ? p.theme.error : p.theme.regular_txt)};
    ${(p) => (p.error ? `border: 0.1rem solid ${p.theme.error};` : null)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: ${(p) => (p.disabled ? 0.5 : 1)};
    :focus {
      border: 0.1rem solid ${(p) => p.theme.inputBorder};
    }
  }
`
const Label = styled.p`
  width: 100%;
  margin-bottom: 0.5rem;
  padding-left: 0.4rem;
  text-align: left;
  color: ${(p) => (p.error ? p.theme.error : p.theme.additional)};
  font-size: ${(p) => p.theme.h5};
  display: flex;
  opacity: ${(p) => (p.disabled ? 0.3 : 0.8)}; ;
`
const List = styled.div`
  z-index: 5;
  position: absolute;
  width: 99%;
  max-height: 30rem;
  top: 100%;
  left: 0.5%;
  padding: 1.5rem;
  background-color: ${(p) => p.theme.opposite};
  overflow: auto;
  box-shadow: ${(p) => p.theme.listShadow};
`
const ListItem = styled.p`
  padding: 1rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`
const ErrorMessage = styled.span`
  padding-left: 0.5rem;
  color: ${(p) => p.theme.error};
`
const Input = styled.input`
  width: 100%;
  height: 4.6rem;
  padding: ${(p) => (p.isHaveIcon ? '0 4.5rem' : '0 4.5rem 0 1.5rem ')};
  background-color: ${(p) => p.theme.selected};
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => (p.error ? p.theme.error : p.theme.regular_txt)};

  ${(p) => p.inputColorAccent && `color:${p.theme.error}`}
  ${(p) => (p.error ? `border: 0.1rem solid ${p.theme.error};` : null)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
  :focus {
    border: 0.1rem solid ${(p) => p.theme.inputBorder};
  }
`

export default ({
  value = '',
  name = 'driver',
  id = '',
  parametr = '',
  label = 'Input with tips',
  inputColorAccent = false,
  error = 0,
  message = 'this required field',
  list = [],
  action = { type: null, clear: null, payload: null, loader: null },
  fnChange = () => {},
  fnSetValue = () => {},
  fnFormValue = () => {},
  disabled = false,
  style,
}) => {
  const [isList, setShowIsList] = useState(false)
  const myRef = useRef(null)
  const dispatch = useDispatch()

  const showList = () => {
    dispatch({
      type: action.type ? action.type : 'SAGA_GET_MY_PARK',
      requestType: action.payload ? action.payload : name,
      parametr: parametr,
    })
    setShowIsList(true)
  }
  const closeList = () => {
    if (isList)
      dispatch({ type: action.clear ? action.clear : 'CLEAR_SEARCH_LIST' })
    setShowIsList(false)
  }
  const clickFromItem = (value, obj) => {
    setShowIsList(false)
    fnSetValue(value)
    fnFormValue(name, obj)
  }
  useOutsideClick(myRef, closeList)

  return (
    <Wrapper style={style} ref={myRef} error={error} disabled={disabled}>
      <Label error={error} disabled={disabled}>
        {strToUpperCase(label)}
        {(error === 1 || error === true) && (
          <ErrorMessage>{`${message}`}</ErrorMessage>
        )}
      </Label>
      <Input
        disabled={disabled}
        data-testid={id}
        id={id}
        type="text"
        name={name}
        value={value}
        onChange={disabled ? () => {} : fnChange}
        error={error}
        onClick={disabled ? () => {} : showList}
        inputColorAccent={inputColorAccent}
      />
      {isList && list && list.length >= 1 && (
        <List>
          {list.map((el) => {
            return (
              <ListItem key={el.id} onClick={() => clickFromItem(el.union, el)}>
                {el.union}
              </ListItem>
            )
          })}
        </List>
      )}
    </Wrapper>
  )
}
