import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import shortid from 'shortid'
import useRouter from '../../../hooks/useRouter'
import NewRoutePartForm from './NewRoutePartForm'
import VehicleRequirementsForm from './VehicleRequirementsForm'
import PaymentTypeForm from './PaymentTypeForm'
import ProgressBarPoints from '../../../components/ProgressBarPoints'
import ThinBtn from '../../../components/buttons/ThinBtn'
import BaseBtn from '../../../components/buttons/BaseBtn'
import ModalPortal from '../../../components/layouts/ModalPortal'
import {
  newRoutePart_TEMPLATE,
  newRoutePart_REQUIRED_FIELD,
} from '../../../lib/constants'
import {
  validateNewRouteItems,
  validateNewRoutePaymentStep,
} from '../../../lib/helpers/forValidate'
import {
  concatinationRouteObj_NewRoute,
  concatinationRoutePartsArr_NewRoute,
} from '../../../lib/helpers/comparisonObj'
import {
  IndexHeader,
  IndexFooter,
  SelectNewRoutePartPopup,
  Line,
  FlexWrp,
  IndexWrp,
} from './SmallComponentsForNewRouteForm'
import { strToUpperCase } from '../../../lib/helpers/forStyling'

export default () => {
  const dispatch = useDispatch()
  const history = useRouter()
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const routeParts = useSelector((s) => s.newRoute.routeparts)
  const settings = useSelector((s) => s.newRoute.settings)
  const routePartsError = useSelector((s) => s.newRoute.requiredFields)
  const routePrice = useSelector((s) => s.newRoute.routePrice)
  const isTender = useSelector((s) => s.newRoute.istender)
  const tenderIsNow = useSelector((s) => s.newRoute.tenderIsNow)
  const codeApi = useSelector((s) => s.newRoute.codeApi)
  const totalcount = useSelector((s) => s.newRoute.totalcount)
  const newRoute = useSelector((s) => s.newRoute)
  const priceFor = useSelector((s) => s.newRoute.priceFor)
  const tenderFinishDate = useSelector(
    (s) => s.newRoute.tenderavailableuntilmoment
  )
  const tenderFinishTime = useSelector(
    (s) => s.newRoute.tenderavailableuntilmoment_time
  )
  const tenderStartDate = useSelector((s) => s.newRoute.plannedTenderDate)
  const tenderStartTime = useSelector((s) => s.newRoute.plannedTenderTime)
  const [totalProgressSteps] = useState(3)
  const [currentStep, setCurrentStep] = useState(1)
  const [btnMargin, setBtnMargin] = useState('0')
  const [inEditor, setInEditor] = useState('')
  const [isShowSelector, setIsShowSelector] = useState(false)
  const [beforeIndex, setBeforeIndex] = useState(null)
  const [isShow, setIsShow] = useState(false)

  const errorText = {
    codeApi: `${dic.indicate} ${dic.api_code}`,
    routePrice: dic.price_indicate,
    tenderTotalcount: `${dic.indicate} ${dic.quantity}: "${priceFor.union}"`,
    tenderFinishDate: dic.tender_indicate_finish_date,
    tenderFinishTime: dic.tender_indicate_finish_time,
    tenderStartDate: dic.tender_indicate_start_date,
    tenderStartTime: dic.tender_indicate_start_time,
    workaction: dic.route_err_msg_1,
    arrLength: dic.route_err_msg_2,
    ferryProperties:
      'В дополнительных параметрах груза отсутсвует количество для "Мои единицы измерения"',
  }

  const setRouteParts = (arr) => {
    dispatch({ type: 'UPDATE_NEWROUTE_PARTS', payload: arr })
  }
  const setRoutePartsError = (arr) => {
    dispatch({ type: 'UPDATE_NEWROUTE_PARTS_ERROR', payload: arr })
  }

  const onCancelAddRoutePart = () => {
    setInEditor('')
    setBeforeIndex(null)
    setIsShowSelector(false)
  }
  const onValidatePaymentForm = (string) => {
    if (string.length > 0) {
      setInEditor(string)
      return
    }
    if (
      validateNewRoutePaymentStep(
        codeApi,
        isTender,
        routePrice,
        totalcount,
        {
          tenderIsNow,
          tenderFinishTime,
          tenderStartDate,
          tenderStartTime,
          tenderFinishDate,
        },
        routeParts,
        errorText,
        settings
      )
    ) {
      return
    }

    setInEditor('')
  }
  const onSetIdInEditor = (num) => {
    const condition = {
      1: '',
      2: '',
      3: '',
      4: '',
    }
    return setInEditor(condition[num])
  }

  const onPublish = () => {
    if (validateNewRouteItems(routeParts, errorText)) {
      return
    }
    if (
      validateNewRoutePaymentStep(
        codeApi,
        isTender,
        routePrice,
        totalcount,
        {
          tenderIsNow,
          tenderFinishTime,
          tenderStartDate,
          tenderStartTime,
          tenderFinishDate,
        },
        routeParts,
        errorText,
        settings
      )
    ) {
      return
    }

    dispatch({ type: 'UPDATE_NEWROUTE_FLAG_FOR_BEFOREUNLOAD', payload: true })

    const newData = {
      routes: {
        route: {
          ...concatinationRouteObj_NewRoute(newRoute),
          routeparts: concatinationRoutePartsArr_NewRoute(routeParts),
        },
      },
    }

    dispatch({ type: 'SAGA_TEST_CREATE_ROUTE', data: newData, history })
  }
  const onChangeStep = (course) => {
    switch (course) {
      case 'plus':
        if (validateNewRouteItems(routeParts, errorText)) {
          return
        }
        if (currentStep === 3) {
          if (
            validateNewRoutePaymentStep(
              codeApi,
              isTender,
              routePrice,
              totalcount,
              {
                tenderIsNow,
                tenderFinishTime,
                tenderStartDate,
                tenderStartTime,
                tenderFinishDate,
              },
              routeParts,
              errorText,
              settings
            )
          ) {
            return
          }
        }

        const positive = currentStep + 1
        setCurrentStep(positive)
        onSetIdInEditor(positive)
        break
      case 'minus':
        const negative = currentStep - 1
        setCurrentStep(negative)
        onSetIdInEditor(negative)
        break

      default:
        return
    }
  }

  const onAddRoutePart = (type) => {
    const newId = shortid.generate()
    const cashRouteParts = [...routeParts]
    const cashRoutePartsError = [...routePartsError]

    cashRouteParts.splice(beforeIndex + 1, 0, {
      ...newRoutePart_TEMPLATE,
      workaction: type,
      id: newId,
    })
    cashRoutePartsError.splice(beforeIndex + 1, 0, {
      ...newRoutePart_REQUIRED_FIELD,
    })

    setRouteParts(cashRouteParts)
    setRoutePartsError(cashRoutePartsError)
    setInEditor(newId)

    setIsShowSelector(false)
  }

  const onRemoveRoutePart = (id) => {
    setRouteParts(routeParts.filter((obj) => obj.id !== id))
    setInEditor('')
  }
  const onSelectPointType = (index) => {
    setIsShowSelector(true)
    setBeforeIndex(index)
  }

  const stepMarcup = {
    1: (
      <div style={{ width: '100%', marginBottom: '3rem' }}>
        <NewRoutePartForm
          activePart={inEditor}
          onEditPart={setInEditor}
          onRemoveRoutePart={onRemoveRoutePart}
          routeParts={routeParts}
          errors={routePartsError}
          inEditor={inEditor}
          currentStep={currentStep}
          onSelectPointType={onSelectPointType}
          settings={settings}
        />
      </div>
    ),
    2: (
      <VehicleRequirementsForm
        currentStep={currentStep}
        onEditPart={setInEditor}
        inEditor={inEditor}
      />
    ),
    3: (
      <PaymentTypeForm
        currentStep={currentStep}
        onEditPart={setInEditor}
        inEditor={inEditor}
      />
    ),
    4: (
      <>
        <div style={{ width: '100%', marginBottom: '3rem' }}>
          <NewRoutePartForm
            activePart={inEditor}
            onEditPart={setInEditor}
            onRemoveRoutePart={onRemoveRoutePart}
            routeParts={routeParts}
            errors={routePartsError}
            inEditor={inEditor}
            currentStep={currentStep}
            onSelectPointType={onSelectPointType}
          />
        </div>
        <Line />
        <VehicleRequirementsForm
          currentStep={currentStep}
          onEditPart={setInEditor}
          inEditor={inEditor}
        />
        <Line />
        <PaymentTypeForm
          currentStep={currentStep}
          onEditPart={onValidatePaymentForm}
          inEditor={inEditor}
        />
        <Line />
        {!inEditor && (
          <>
            <FlexWrp style={{ marginBottom: '3rem' }}>
              <ThinBtn type="delete" label={dic.btn_delete} />
            </FlexWrp>

            <BaseBtn
              fullSize
              label={dic.btn_post}
              style={{ marginBottom: '5rem' }}
              fnClick={onPublish}
            />
          </>
        )}
      </>
    ),
  }

  useEffect(() => {
    setInEditor(routeParts[0].id)
  }, []) //eslint-disable-line
  useEffect(() => {
    if (inEditor) setIsShow(true)
  }, [inEditor])
  useEffect(() => {
    if (currentStep <= 1) {
      setBtnMargin('0')
    } else {
      setBtnMargin('5rem')
    }
  }, [currentStep])

  return (
    isShow && (
        <IndexWrp>
          {isShowSelector && (
            <ModalPortal>
              <SelectNewRoutePartPopup
                title={strToUpperCase(dic.point_add)}
                data={[
                  { label: `+ ${dic.workaction_1}`, workaction: 1 },
                  { label: `+ ${dic.workaction_2}`, workaction: 2 },
                  { label: `+ ${dic.workaction_3}`, workaction: 3 },
                  { label: `+ ${dic.workaction_4}`, workaction: 4 },
                ]}
                btnTitle={dic.btn_cancel}
                onAddRoutePart={onAddRoutePart}
                onCancelAddRoutePart={onCancelAddRoutePart}
              />
            </ModalPortal>
          )}
          <IndexHeader>
            <p className="header-title">{dic.route_new}</p>
          </IndexHeader>
          {stepMarcup[currentStep]}
          {currentStep <= 3 && (
            <IndexFooter>
              {!inEditor && (
                <FlexWrp style={{ marginBottom: '3rem' }}>
                  {currentStep > 1 && (
                    <ThinBtn
                      type="back"
                      label={dic.btn_back}
                      onClick={() => onChangeStep('minus')}
                    />
                  )}
                  <BaseBtn
                    fullSize
                    fnClick={() => onChangeStep('plus')}
                    label={dic.btn_next}
                    style={{ marginLeft: btnMargin }}
                  />
                </FlexWrp>
              )}
              <ProgressBarPoints
                steps={Array.from({ length: totalProgressSteps }, (a, b) => b)}
                active={currentStep}
              />
            </IndexFooter>
          )}
        </IndexWrp>
    )
  )
}
