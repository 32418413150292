import { takeEvery, put, delay, select, race, call } from 'redux-saga/effects'
import {getCurrentLang, getDicionary, getToken} from "../selectors";
import { getRouteDocuments } from "../../lib/requests/routesRequests";
import {delays} from "../../lib/constants";

export function* worker({ periodic }) {
  try {
    const currentLang = yield select(getCurrentLang)
    const token = yield select(getToken)
    const dic = yield select(getDicionary)

    const { result } = yield race({
      result: call(getRouteDocuments, token, currentLang, periodic),
      timeout: delay(delays.forCancelRequest),
    })

    yield put({ type: 'SET_DOCUMENT_LOADER', payload: true })

    if (result && result.status === 200) {
      // yield put({
      //   type: 'SET_DOCUMENT_GENERAL_DATA',
      //   payload: []
      // })
      yield put({
        type: 'SET_DOCUMENT_UPLOAD_DATA',
        payload: result?.data?.data,
      })
      yield put({ type: 'SET_DOCUMENT_LOADER', payload: false })

    } else {
      yield put({
        type: 'SHOW_ERROR_API',
        code: 6,
        message: dic.msg_server_err || 'internal server error',
      })
      yield put({ type: 'SET_DOCUMENT_LOADER', payload: false })
    }
  } catch (e) {
    yield put({
      type: 'SHOW_ERROR_API',
      code: String(e.response.status).slice(0, 1),
      message: e.response ? e.response.data.message : e.message,
    })
    yield put({ type: 'SET_DOCUMENT_LOADER', payload: false })
    if (e.response && e.response.data.relogin) {
      yield put({ type: 'SAGA_LOGOUT' })
    }
    if (e.response && e.response.data.forwardTo) {
      yield put({
        type: 'REDIRECT_OUTSIDE',
        payload: e.response.data.forwardTo,
      })
    }
  }
}

export default function* singleRoute() {
  yield takeEvery('SAGA_GET_ROUTE_DOCUMENTS', worker)
}
