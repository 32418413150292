import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import BaseButton from '../buttons/BaseBtn'
import { strToUpperCase } from '../../lib/helpers/forStyling'
import Icon from '../Icon'

const Wrapper = styled.div`
  width: 85vw;
  max-width: 64rem;
  min-height: 48rem;
  margin: 0 auto;
`
const Header = styled.div`
  width: 100%;
  padding: 2rem;
  border-radius: 1rem 1rem 0 0;
  background-color: ${(p) => p.theme.flat_headers};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(p) => p.theme.opposite_txt};
  font-size: ${(p) => p.theme[p.fsTitle]};
`
const HeaderBright = styled.div`
  width: 100%;
  padding: 2rem;
  border-radius: 1rem 1rem 0 0;
  background-color: ${(p) => p.theme.opposite};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${(p) => p.theme[p.fsTitle]};
`
const Content = styled.div`
  width: 100%;
  height: 33rem;
  padding: 2rem;
  background-color: ${(p) => p.theme.opposite};
  overflow-y: auto;
  overflow-x: hidden;
  ${(p) =>
    !p.withBtn &&
    `
  height:auto;
  max-height: 38rem;
  padding: 4rem 2rem 4rem;
  border-radius: 0 0 1rem 1rem;
  

  `}
`
const BtnWrap = styled.div`
  width: 100%;
  min-height: 5rem;
  padding: 0 0 1rem;
  border-radius: 0 0 1rem 1rem;
  background-color: ${(p) => p.theme.opposite};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(p) =>
    p.withoutContent &&
    `
  padding-top:2.6rem;
  `}
`

export default ({
  title = '',
  fsTitle = 'h2',
  children,
  fnSave = () => {},
  fnCancel = () => {},
  isBright = false,
  withBtn = false,
  withoutContent = false,
  btnOk = '',
}) => {
  const dic = useSelector((s) => s.defaultLang.serverDictionary)

  return (
    <Wrapper>
      {isBright ? (
        <HeaderBright fsTitle={fsTitle}>
          <p>{strToUpperCase(title)}</p>
          {!withBtn && <Icon name="close" onClick={fnCancel} />}
        </HeaderBright>
      ) : (
        <Header fsTitle={fsTitle}>
          <p>{strToUpperCase(title)}</p>
          {!withBtn && <Icon name="close" type="opposite" onClick={fnCancel} />}
        </Header>
      )}
      {!withoutContent && <Content withBtn={withBtn}>{children}</Content>}
      {withBtn && (
        <BtnWrap withoutContent={withoutContent}>
          <BaseButton
            inContext="popup"
            label={dic.btn_cancel}
            fnClick={fnCancel}
            toUpperCase
          />
          <BaseButton
            inContext="popup"
            label={btnOk || dic.btn_ok}
            fnClick={fnSave}
            toUpperCase
          />
        </BtnWrap>
      )}
    </Wrapper>
  )
}
