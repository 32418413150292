import React from 'react'
import styled from 'styled-components'
import Icon from '../../components/Icon'

const Wrapper = styled.span`
  width: 100%;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  padding-left: ${(p) => (p.withSidePads ? '1.8rem' : '0')};
  padding-right: ${(p) => (p.withSidePads ? '2rem' : '0')};
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => p.theme.regular_txt};
  font-weight: 500;
  border-bottom: 0.1rem solid ${(p) => p.theme.lines};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

const Right = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Quantity = styled.p`
  ${(p) =>
    p.isAccent &&
    `
color:${p.theme.trigger};
`}
  margin-right: 1rem;
`
const defaultObj = {
  title: 'horizontal tab',
  id: '0',
  path: '/',
}

export default ({
  obj = defaultObj,
  extraData = '',
  fnClick = () => {},
  id = 'horizontalTabId',
  withSidePads = false,
  isAccent = false,
}) => {
  return (
    <Wrapper onClick={fnClick} id={id} withSidePads={withSidePads}>
      {obj.title}
      <Right>
        {extraData && <Quantity isAccent={isAccent}>{extraData}</Quantity>}
        <Icon
          name="arrowShevron"
          type={isAccent ? 'trigger' : 'regularText'}
          width={extraData && '1rem'}
          height={extraData && '1rem'}
        />
      </Right>
    </Wrapper>
  )
}
