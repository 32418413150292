import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import shortid from 'shortid'
import SingleWrp from './SingleWrp'
import BaseTextarea from '../../../components/inputs/BaseTextarea'
import BtnLikeLink from '../../../components/buttons/BtnLikeLink'
import ModalPortal from '../../../components/layouts/ModalPortal'
import Checklist from '../../../components/popUps/Checklist'

import { useAddNewTrailer } from "../hooks/useAddNewTrailer";
import { dataParse } from "../../../lib/helpers/addNewTrailer"
import GeneralFieldsList from "../Fields/GeneralFieldsList";

export default ({
  fnClose = () => {},
  fnUpdateState = () => {},
  periodic = '',
  withoutPortal = false,
}) => {

  const {
    requiredFields,
    additionalFields,
    errors,
    onSubmitForm,
  } = useAddNewTrailer()

  const dispatch = useDispatch()
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const selectList = useSelector((s) => s.selectList.searchList)
  const data = useSelector((s) => s.newTS.trailer)
  const list = useSelector((s) => s.checkList.list)

  const [isExtraParams, setIsExtraParams] = useState(true)
  const [isComment, setIsComment] = useState(false)
  const [uniq, setUniq] = useState({})
  const [parser, setParser] = useState({})
  const [isFetched, setIsFetched] = useState(false)
  const [isHaveData, setIsHaveData] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [localTitle, setLocalTitle] = useState('')
  const [selector, setSelector] = useState('')

  const onChangeInputs = (e) => {
    const VALUE = e.target.value
    const NAME = parser[e.target.name]
    dispatch({
      type: 'NEW_TS_UPDATE_TRAILER',
      payload: { ...data, [NAME]: VALUE },
    })
  }

  const onFocusInputs = (inputData) => {

    const actionName =  {
      "chargetype": "NEW_TS_UPDATE_LIST_CHARGE_TYPE",
      "ferryproperty": "NEW_TS_UPDATE_LIST_FERRY_PROPERTIES",
    }
    const {
      action,
      name,
    } = inputData;

    dispatch({
      type: actionName[name],
      payload: { name, formName: "trailer" },
    })

    dispatch(action)
    dispatch({
      type: 'OPEN_MODAL',
      payload: "transportForm",
    })
  }

  const togglerExtraParams = () => {
    setIsExtraParams(!isExtraParams)
  }
  const togglerComment = () => {
    setIsComment(!isComment)
  }

  const onSaveNewData = () => {
    dispatch({ type: 'START_REQUEST', payload: `newTrailer` })
    dispatch({
      type: 'SAGA_ADD_NEW_TS',
      data: dataParse(data),
      action: 'trailer',
      fnClose,
      fnUpdateState,
    })
  }

  const fnFormValue = (where, obj) => {
    dispatch({
      type: 'NEW_TS_UPDATE_TRAILER',
      payload: { ...data, [parser[where]]: obj },
    })
  }

  const dataSelector = {
    typeAuto: data.cartype,
    typeLoad: data.chargeType,
    ferryProperty: data.ferryproperty,
  }
  const keys = {
    typeAuto: 'cartype',
    typeLoad: 'chargeType',
    ferryProperty: 'ferryproperty',
  }

  const onChangeList = (id, where) => {
    let result = []
    if (dataSelector[where].find((el) => el.id === id)) {
      result = dataSelector[where].filter((el) => el.id !== id)
    } else {
      const item = list.find((el) => el.id === id)
      result = [...dataSelector[where], { ...item, checked: true }]
    }
    dispatch({
      type: 'NEW_TS_UPDATE_TRAILER',
      payload: { ...data, [keys[where]]: result },
    })

    dispatch({
      type: 'SET_CHEKLIST',
      payload: list.map((el) => {
        if (el.id === id) {
          return { ...el, checked: !el.checked }
        } else {
          return el
        }
      }),
    })
  }
  const onChangeListItemQuantity = (where, id, val) => {
    let result = []

    result = dataSelector[where].map((el) =>
      el.id === id ? { ...el, quantity: val } : el
    )

    dispatch({
      type: 'NEW_TS_UPDATE_TRAILER',
      payload: { ...data, [keys[where]]: result },
    })

    dispatch({
      type: 'SET_CHEKLIST',
      payload: list.map((el) => {
        if (el.id === id) {
          return { ...el, quantity: val }
        } else {
          return el
        }
      }),
    })
  }

  const onCloseModal = (bool) => {
    if (bool) {
      //clear filtred list
    }
    setIsModal(false)
  }


  useEffect(() => {
    if (isHaveData && !isFetched) {
      const uniqNames = Object.keys(data).reduce((result, item) => {
        return { ...result, [item]: shortid.generate() }
      }, {})

      setUniq(uniqNames)
      setParser(
        Object.keys(uniqNames).reduce((result, item) => {
          return { ...result, [uniqNames[item]]: item }
        }, {})
      )
      setIsFetched(true)
    }
  }, [isHaveData]) //eslint-disable-line

  useEffect(() => {
    if (data.hasOwnProperty('number')) {
      setIsHaveData(true)
    }
  }, [data])

  useEffect(() => {
    return () => dispatch({ type: 'NEW_TS_SET_DEFAULT_DATA' })
  }, []) //eslint-disable-line

  useEffect(() =>{
    const errorName = Object.keys(errors)[0] || ""
    if(errorName){
      const nodeName = uniq[errorName] || ""
      const isAdditional = additionalFields.find(el=> el.name === errorName);
      if(isAdditional){
        setIsExtraParams(true)
      }
      const node = document.querySelector(`input[name='${nodeName}']`)
      if(node){
        node.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
      }
    }
  },[errors])

  return (
      isFetched && (
      <>
        {isModal && (
          <ModalPortal>
            <Checklist
              list={list}
              fnUpdateCheck={onChangeList}
              fnUpdateQuantity={onChangeListItemQuantity}
              fnCancel={() => onCloseModal(true)}
              fnSave={onCloseModal}
              title={localTitle}
              id={selector}
            />
          </ModalPortal>
        )}
        <SingleWrp
          fnSubmit={()=>onSubmitForm(data, onSaveNewData)}
          fnClose={fnClose}
          periodic={periodic}
          type="trailer"
          localId="newTrailer"
          withoutPortal={withoutPortal}
        >
          <GeneralFieldsList
              onChange={onChangeInputs}
              fields={requiredFields}
              uniq={uniq} // uniq input id
              errors={errors}
              data={data}
              optionsList={selectList}
              onChangeSelect={fnFormValue}
              onChangeCheckbox={onFocusInputs}
          />
          {/*<BtnLikeLink*/}
          {/*  label={`${dic.extra}...`}*/}
          {/*  firstLetter*/}
          {/*  style={{ marginBottom: '3.2rem' }}*/}
          {/*  onClick={togglerExtraParams}*/}
          {/*/>*/}
          {isExtraParams && (
              <GeneralFieldsList
                  onChange={onChangeInputs}
                  fields={additionalFields}
                  uniq={uniq} // uniq input id
                  errors={errors}
                  data={data}
                  optionsList={selectList}
                  onChangeSelect={fnFormValue}
                  onChangeCheckbox={onFocusInputs}
              />
          )}
          <BtnLikeLink
            label={`${dic.remark_add}...`}
            firstLetter
            style={{ marginBottom: isComment ? '3rem' : '4rem' }}
            onClick={togglerComment}
          />
          {isComment && (
            <BaseTextarea
              label={dic.remark}
              style={{ marginBottom: '4rem' }}
              fnChange={onChangeInputs}
              name={uniq.remark}
              value={data.remark}
              error={errors.remark ? errors.remark.bool : null}
              message={errors.remark ? errors.remark.msg : null}
            />
          )}
        </SingleWrp>
      </>
    )
  )
}
