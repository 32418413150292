import React, { useState } from 'react'
import styled from 'styled-components'
import iconCall from '../../assets/img/bitrixCall.png'
import BitrixCallBack from '../../bitrix/BitrixCallBack'
import Modal from '../layouts/ModalPortal'
import Icon from '../Icon'

const Content = styled.div`
  position: relative;
  width: 100vw;
  min-width: 28rem;
  min-height: 20rem;
  height: 100vh;
  padding: 1.6rem;
  background-color: ${(p) => p.theme.opposite};
  /* border-radius: ${(p) => p.theme.PopUpRadius}; */
`
const crossStyle = {
  position: 'absolute',
  top: '1.6rem',
  right: '1.6rem',
  zIndex: 1000,
}

const Button = styled.div`
  z-index: 1000;
  position: fixed;
  width: 6rem;
  height: 6rem;
  bottom: 13rem;
  right: 1.2rem;
  border-radius: 50%;
  background-color: ${(p) => p.theme.opposite};
  background-image: ${`url(${iconCall})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: 0.2s ease;
`
export default () => {
  const [isBitrix, setIsBitrix] = useState(false)
  return isBitrix ? (
    <Modal>
      <Icon
        name="close"
        type="regularText"
        style={crossStyle}
        onClick={() => setIsBitrix(false)}
      />
      <Content>
        <BitrixCallBack />
      </Content>
    </Modal>
  ) : (
    <Button onClick={() => setIsBitrix(true)} />
  )
}
