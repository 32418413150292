import Type from '../types'

const initialState = {
  data:{},
  recipients:[],
  isLoader: true,
  isFetched: false,
  postProcess: 0, // 0 = loading, 1 = success, 2 = error
}

export default (state = initialState, action) => {
  switch (action.type) {

    case Type.SET_DIALOGUES:
      return {
        ...state,
        data: action.payload,
      }

    case Type.SET_CHAT_RECIPIENTS:
      return {
        ...state,
        recipients: action.payload,
      }

    case Type.SET_DIALOG_MSG:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.key]: action.payload.data,
        }

      }

    case Type.SET_CHAT_LOADER:
      return {
        ...state,
        isLoader: action.payload,
      }

    case Type.SET_CHAT_FETCH:
      return {
        ...state,
        isFetched: action.payload,
      }

    case Type.SET_POST_PROCESS:
      return {
        ...state,
        postProcess: action.payload,
      }

    default:
      return state
  }
}
