import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import SmallLoader from '../../layouts/SmallLoader'
import {
  Info,
  SeparateLine,
  Title,
} from '../../smallPartsForInterface/SmallPartsCard'
import { strToUpperCase } from '../../../lib/helpers/forStyling'
import { parseDateOfLocal } from '../../../lib/helpers/forRoutes'
import useDataStore from '../../../hooks/useDataStore'

const Wrapper = styled.div`
  width: 100%;
  padding-top: 1.5rem;
`
const Item = styled.div`
  width: 100%;
  padding: 1.4rem 1.6rem 0;
  ${(p) =>
    p.bg &&
    `
  background-color:${p.theme.currentPoint};
  `}
`

export default ({ periodic = '' }) => {
  const dispatch = useDispatch()
  // const profile = useSelector((s) => s.user.profile)
  const isLoader = useSelector((s) => s.routeChronology.isLoader)
  const list = useSelector((s) => s.routeChronology.chronology)
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const { MONTH_SHORT } = useDataStore()

  useEffect(() => {
    dispatch({ type: 'SAGA_GET_ROUTE_CHRONOLOGY', periodic })
    return () => dispatch({ type: 'SET_CHRONOLOGY_DATA', payload: [] })
  }, []) //eslint-disable-line
  return (
    <Wrapper data-testid="routeChronology">
      <Title big mrgnBtm="2.6rem">
        {strToUpperCase(dic.chronology)}
      </Title>

      {isLoader ? (
        <SmallLoader size="2.4rem" />
      ) : (
        list &&
        list.length > 0 &&
        list.map((obj) => (
          <Item bg={obj.isMyCompany} key={obj.id}>
            <Title mrgnBtm="0.8rem">{strToUpperCase(obj.action)}</Title>
            <Info mrgnBtm="0.8rem" fs="h4">
              {strToUpperCase(obj.author)}
            </Info>
            <Info mrgnBtm="1.4rem" >
              {parseDateOfLocal(obj.date, obj.time, MONTH_SHORT, true)}
            </Info>
            <SeparateLine />
          </Item>
        ))
      )}
    </Wrapper>
  )
}
