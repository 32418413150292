import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import useDataStore from '../../hooks/useDataStore'
import DayPicker from 'react-day-picker'
import BaseButton from '../buttons/BaseBtn'

const Wrapper = styled.div`
  /* width: 80%; */
  min-width: 28rem;
  max-width: 40rem;
  .year_select {
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
    background-color: ${(p) => p.theme.flat_headers};
    color: ${(p) => p.theme.additional2};
    font-size: ${(p) => p.theme.h5};
    border-color: ${(p) => p.theme.flat_headers};
    appearance: none;
  }
`
const Label = styled.div`
  width: 100%;
  height: 9rem;
  padding: 0 3rem 2rem 2rem;
  border-radius: 1rem 1rem 0 0;
  background-color: ${(p) => p.theme.flat_headers};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: ${(p) => p.theme.opposite_txt};
  font-size: ${(p) => p.theme.h1};
  .year {
    font-size: ${(p) => p.theme.h5};
    color: ${(p) => p.theme.additional2};
  }
`
const Content = styled.div`
  width: 100%;
  min-height: 28rem;
  padding: 2rem;
  background-color: ${(p) => p.theme.opposite};
  &&& {
    .DayPicker {
      width: 100%;
      display: inline-block;
      font-size: 1.5rem;
      outline: 0;
      outline-offset: 0;
    }
    .DayPicker-wrapper {
      position: relative;
      flex-direction: row;
      padding-bottom: 1em;
      user-select: none;
    }

    .DayPicker-Months {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .DayPicker-Month {
      display: table;
      margin: 0 1em;
      margin-top: 1em;
      border-spacing: 0;
      border-collapse: collapse;
      user-select: none;
    }

    .DayPicker-NavButton {
      position: absolute;
      top: 1em;
      right: 0.5em;
      left: auto;
      display: inline-block;
      margin-top: 2px;
      width: 1.25em;
      height: 1.25em;
      background-position: center;
      background-size: 50%;
      background-repeat: no-repeat;
      color: ${(p) => p.theme.regular_txt};
      cursor: pointer;
      transition: 0.2s;
    }

    .DayPicker-NavButton:hover {
      background-color: ${(p) => p.theme.basic};
    }
    .DayPicker-NavButton:active {
      /* color: ${(p) => p.theme.regular_txt}; */
      background-color: ${(p) => p.theme.basic};
    }

    .DayPicker-NavButton--prev {
      left: 0.5em;
      margin-right: 1.5em;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
    }

    .DayPicker-NavButton--next {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
    }

    .DayPicker-NavButton--interactionDisabled {
      display: none;
    }

    .DayPicker-Caption {
      display: table-caption;
      margin-bottom: 2rem;
      padding: 0 0.5em;
      text-align: center;
    }

    .DayPicker-Caption > div {
      font-weight: 500;
      font-size: 1.15em;
    }

    .DayPicker-Weekdays {
      display: table-header-group;
      margin-top: 1em;
    }

    .DayPicker-WeekdaysRow {
      display: table-row;
    }

    .DayPicker-Weekday {
      display: table-cell;
      padding: 0.5em;
      color: ${(p) => p.theme.additional};
      text-transform: uppercase;
      text-align: center;
      font-size: 1rem;
    }

    .DayPicker-Weekday abbr[title] {
      border-bottom: none;
      text-decoration: none;
    }

    .DayPicker-Body {
      display: table-row-group;
    }

    .DayPicker-Week {
      display: table-row;
    }

    .DayPicker-Day {
      display: table-cell;
      padding: 0.5em;
      border-radius: 50%;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
    }

    .DayPicker-WeekNumber {
      display: table-cell;
      padding: 0.5em;
      min-width: 1em;
      border-right: 1px solid #eaecec;
      color: #8b9898;
      vertical-align: middle;
      text-align: right;
      font-size: 0.75em;
      cursor: pointer;
    }

    .DayPicker--interactionDisabled .DayPicker-Day {
      cursor: default;
    }

    .DayPicker-Footer {
      padding-top: 0.5em;
    }

    .DayPicker-TodayButton {
      border: none;
      background-color: transparent;
      background-image: none;
      box-shadow: none;
      color: #4a90e2;
      font-size: 0.875em;
      cursor: pointer;
    }

    /* Default modifiers */

    .DayPicker-Day--today {
      padding: 0.5rem;
      border-radius: 50%;
      background-color: ${(p) => p.theme.lines};
    }

    .DayPicker-Day--outside {
      color: #8b9898;
      cursor: default;
    }

    .DayPicker-Day--disabled {
      color: #dce0e0;
      cursor: default;
    }
    .DayPicker-Day--sunday {
      background-color: #f7f8f8;
    }

    .DayPicker-Day--sunday:not(.DayPicker-Day--today) {
      color: #dce0e0;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      position: relative;
      /* background-color: ${(p) => p.theme.basic}; */
      background-color: ${(p) => p.theme.flat_headers};
      color: ${(p) => p.theme.opposite};
      /* color: ${(p) => p.theme.additional}; */
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
      background-color: ${(p) => p.theme.flat_headers};
    }

    .DayPicker:not(.DayPicker--interactionDisabled)
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      color: white;
      background-color: ${(p) => p.theme.basic};
    }
    .DayPickerInput {
      display: inline-block;
    }

    .DayPickerInput-OverlayWrapper {
      position: relative;
    }

    .DayPickerInput-Overlay {
      position: absolute;
      left: 0;
      z-index: 1;

      background: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    }
  }
`
const BtnWrap = styled.div`
  width: 100%;
  min-height: 5rem;
  padding: 0 0 1rem;
  border-radius: 0 0 1rem 1rem;
  background-color: ${(p) => p.theme.opposite};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const getDateValue = (value) => {
  return new Date(value).toISOString().slice(0, 10)
}

export default ({
  fnSave = () => {},
  fnCancel = () => {},
  value = null,
  index,
  disabledPeriod = 'nothing', // for document and driver license
  checkDate = '',
}) => {
  const { DIC, MONTHS, MONTH_SHORT, WEEKDAYS_SHORT } = useDataStore()
  const [myDate, setMyDate] = useState()
  const [isShow, setIsShow] = useState(false)

  const handleDayClick = (day, { disabled, setFlag }) => {
    if (disabled) {
      return
    }
    setMyDate(getDateValue(day))
    if (setFlag) {
      setIsShow(true)
    }
  }

  const forDisabledNum = {
    nothing: [],
    after: {
      after: checkDate ? new Date(checkDate) : new Date(),
    },
    past: {
      before: checkDate ? new Date(checkDate) : new Date(),
    },
  }

  const localDate = (val) => {
    const day = moment(val).format('D')
    const dayTxt = WEEKDAYS_SHORT[moment(val).format('d')]
    const month = MONTH_SHORT[moment(val).format('M') - 1]
    return `${dayTxt}, ${day} ${month}.`
  }

  const getYear = (val) => {
    return moment(val).format('YYYY')
  }

  const handleYearChange = (year) => {
    setIsShow(false)
    handleDayClick(
      moment(myDate).set({ year: year.getFullYear(), hours: 23 }),
      {
        disabled: false,
        setFlag: true,
      }
    )
  }

  const YearMonthForm = ({ date, onChange, getYear }) => {
    const years = []
    const currentYear = Number(getYear(new Date()))

    for (let i = currentYear; i >= currentYear - 75; i -= 1) {
      years.push(i)
    }

    const handleChange = function handleChange(e) {
      const { year } = e.target.form
      onChange(new Date(year.value))
    }
    return (
      <form className="DayPicker-Caption">
        <select
          name="year"
          className="year_select"
          onChange={handleChange}
          value={date ? getYear(date) : getYear(new Date())}
        >
          {years.map((year) => (
            <option key={year} value={year} className="year_option">
              {year}
            </option>
          ))}
        </select>
      </form>
    )
  }

  useEffect(() => {
    if (value) {
      const parse = new Date(value)

      setMyDate(getDateValue(parse))
    } else {
      const now = new Date()
      setMyDate(getDateValue(now))
    }
    setIsShow(true)
  }, []) //eslint-disable-line
  return (
    <Wrapper>
      <Label>
        <p>{localDate(myDate)}</p>
        {/* <p className="year">{getYear(myDate)}</p> */}
        <YearMonthForm
          date={myDate}
          getYear={getYear}
          onChange={handleYearChange}
        />
      </Label>
      <Content>
        {isShow && (
          <DayPicker
            selectedDays={new Date(myDate)}
            month={new Date(myDate)}
            onDayClick={handleDayClick}
            disabledDays={forDisabledNum[disabledPeriod]}
            firstDayOfWeek={1}
            months={MONTHS}
            weekdaysShort={WEEKDAYS_SHORT}
          />
        )}
      </Content>
      <BtnWrap>
        <BaseButton
          inContext="popup"
          label={DIC.btn_cancel}
          fnClick={fnCancel}
          toUpperCase
        />
        <BaseButton
          inContext="popup"
          label={DIC.btn_ok}
          fnClick={() => fnSave(myDate, index)}
          toUpperCase
        />
      </BtnWrap>
    </Wrapper>
  )
}
