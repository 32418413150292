import React, { useEffect } from 'react'

import { Prompt } from 'react-router-dom'

export default ({
  when = true,
  msg = 'Are you sure you want to leave the page?',
}) => {
  const onUnload = (e) => {
    e.returnValue = 'Are you sure you want to leave?'
  }

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload)
  }, [])

  return (
    <Prompt
      when={when}
      message={msg}
      //   message={(location) =>
      //     `Are you sure you want to go to ${location.pathname}`
      //   }
    />
  )
}
