import Type from '../types'

const initialState = {
  list: [
    { label: 'first', phone: '+380677776655', id: '1', checked: false },
    { label: 'second', phone: '+380677776655', id: '2', checked: false },
    { label: 'third', phone: '+380677776655', id: '3', checked: false },
    { label: 'fourth', phone: '+380677776655', id: '4', checked: false },
    { label: 'fifth', phone: '+380677776655', id: '5', checked: false },
    { label: 'sixth', phone: '+380677776655', id: '6', checked: false },
    { label: 'seventh', phone: '+380677776655', id: '7', checked: false },
  ],
  serchFields: [
    { label: 'маршрута', icon: 'number', id: '1f' },
    { label: 'город', icon: 'location', id: '2f' },
    { label: 'водитель (фамилия)', icon: 'driver', id: '3f' },
    { label: 'номер авто', icon: 'mack', id: '4f' },
    { label: 'номер счета', icon: 'bill', id: '5f' },
    { label: 'код API', icon: 'api', id: '6f' },
    { label: 'пользователь', icon: 'user', id: '7f' },
  ],
  filtredList: [],
  userDate: null,
  serchList: [],
  userTime: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.FILTER_TEST_LIST:
      return {
        ...state,
        filtredList: state.list.filter((obj) => obj.checked),
      }
    case Type.CHANGE_CHECK_TEST_LIST:
      return {
        ...state,
        list: state.list.map((obj) =>
          obj.id === action.payload ? { ...obj, checked: !obj.checked } : obj
        ),
      }
    case Type.SET_USER_DATE:
      return {
        ...state,
        userDate: action.payload,
      }
    case Type.FILTER_SEARCH_LIST:
      return {
        ...state,
        serchList: action.payload
          ? state.list.filter((el) => el.label.includes(action.payload))
          : [...state.list],
      }
    case Type.SET_USER_TIME:
      return {
        ...state,
        userTime: action.payload,
      }
    default:
      return state
  }
}
