import React from 'react'
import { useSelector } from 'react-redux'
import FormLayout from '../components/layouts/FormLayout'
import LoginForm from '../containers/forms/loginForm'
import BaseLink from '../components/links/BaseLink'
import LanguageSelect from '../containers/selects/LanguageSelect'
import SeoMetaTegs from '../components/SeoMetaTegs'
import BaseLinkNewWindow from "../components/links/BaseLinkNewWindow";
import { registrationLink } from "../lib/constants";

export default () => {
  const lang = useSelector((s) => s.defaultLang.langObj.autorization)

  return (
    <>
      <SeoMetaTegs title={lang.title} />
      <FormLayout isLogo>
        <LoginForm marginTop="3rem" />
          <BaseLink
            nav
            label={lang.link_renewal}
            rout="/renewal"
            style={{ marginTop: '5rem' }}
          />

          <BaseLinkNewWindow
              label={`${lang.link_registration} ...`}
              id=""
              rout={registrationLink}
              style={{
                  textTransform: 'uppercase',
                  fontWeight: '500',
                  marginTop: '2.6rem',
                  marginBottom: '3.2rem',
              }}
          />
        <div className="flexWrp" style={{ width: '100%',justifyContent:"center", marginTop: 'auto' }}>
          <LanguageSelect />
        </div>
      </FormLayout>
    </>
  )
}
