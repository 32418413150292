import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import shortid from 'shortid'
import SelectInput from './SelectInput'
import TextLikeInput from '../../components/inputs/TextLikeInput'
import { executeScroll } from '../../lib/helpers/forValidate'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const Line = styled.div`
  width: 100%;
  height: 0.1rem;
  margin-bottom: 2.4rem;
  background-color: ${(p) => p.theme.lines};
`

export default ({
  index = 2,
  templateWorkaction = 1,
  onUpdateItemRoutePart = () => {},
  prewData = {},
  errors = [],
  style = {},
}) => {
  const dispatch = useDispatch()
  const list = useSelector((s) => s.checkpoint.searchList)
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const [firstRender, setFirstRender] = useState(0)

  const [parametr, setParametr] = useState({
    country: 215,
    town: null,
    company: null,
    address: null,
    checkpointcontactdata: null,
  })

  const [data, setData] = useState({
    country: { union: 'Украина', id: 215, isoNum: 804 },
    town: { union: '' },
    company: { union: '' },
    address: { union: '' },
    checkpointcontactdata: { union: '' },
  })
  const [activeSelect, setActiveSelect] = useState({
    country: false,
    town: false,
    company: true,
    address: true,
    checkpointcontactdata: true,
  })
  const [dataForDispatch] = useState({
    type: 'SAGA_GET_CHECKPOINT_DATA',
    clear: 'CLEAR_CHECKPOINT_LIST',
    loader: 'checkpoint',
  })

  const refs = Object.keys(parametr).reduce((result, item) => {
    return { ...result, [item]: useRef(null) }
  }, {})

  const uniq = {
    country: shortid.generate(),
    town: shortid.generate(),
    company: shortid.generate(),
    address: shortid.generate(),
    checkpointcontactdata: shortid.generate(),
  }
  const NAME = {
    [uniq.country]: 'country',
    [uniq.town]: 'town',
    [uniq.company]: 'company',
    [uniq.address]: 'address',
    [uniq.checkpointcontactdata]: 'checkpointcontactdata',
  }

  const fnFormValue = (id, obj) => {
    const whereUpdate = NAME[id]
    setParametr({ ...parametr, [whereUpdate]: obj.id })
    const index = {
      country: {
        isShow: {
          country: false,
          town: false,
          company: true,
          address: true,
          checkpointcontactdata: true,
        },
        defaultData: {
          ...data,
          [whereUpdate]: obj,
          town: { union: '' },
          company: { union: '' },
          address: { union: '' },
          checkpointcontactdata: { union: '' },
        },
      },
      town: {
        isShow: {
          country: false,
          town: false,
          company: false,
          address: true,
          checkpointcontactdata: true,
        },
        defaultData: {
          ...data,
          [whereUpdate]: obj,
          company: { union: '' },
          address: { union: '' },
          checkpointcontactdata: { union: '' },
        },
      },
      company: {
        isShow: {
          country: false,
          town: false,
          company: false,
          address: false,
          checkpointcontactdata: true,
        },
        defaultData: {
          ...data,
          [whereUpdate]: obj,
          address: { union: '' },
          checkpointcontactdata: { union: '' },
        },
      },
      address: {
        isShow: {
          country: false,
          town: false,
          company: false,
          address: false,
          checkpointcontactdata: false,
        },
        defaultData: {
          ...data,
          [whereUpdate]: obj,
          checkpointcontactdata: { union: '' },
        },
      },
      checkpointcontactdata: {
        isShow: {
          country: false,
          town: false,
          company: false,
          address: false,
          checkpointcontactdata: false,
        },
        defaultData: {
          ...data,
          [whereUpdate]: obj,
        },
      },
    }
    setActiveSelect(index[whereUpdate].isShow)
    setData(index[whereUpdate].defaultData)
  }

  const fnChange = (e) => {
    const VALUE = e.target.value
    const id = e.target.name

    dispatch({ type: 'FILTER_CHECKPOINT_LIST', payload: VALUE })

    setData({
      ...data,
      [NAME[id]]: { ...data[NAME[id]], union: VALUE },
    })
  }

  const updateDefaultState = () => {
    setActiveSelect({
      country: false,
      town: false,
      company: false,
      address: false,
      checkpointcontactdata: false,
    })

    //TODO change country value from user profile data = setData({ ...prewData})
    setParametr({
      country: prewData.country ? prewData.country.id : 215,
      town: prewData.town ? prewData.town.id : null,
      company: prewData.company ? prewData.company.id : null,
      address: prewData.address ? prewData.address.id : null,
      checkpointcontactdata: prewData.checkpointcontactdata
        ? prewData.checkpointcontactdata.id
        : null,
    })
    setData({
      ...prewData,
      country:
        prewData.country && prewData.country.isoNum
          ? prewData.country
          : data.country,
    })
  }

  const updateReducer = () => {
    const TEMPLATE = {
      1: {
        country: data.country ? data.country : null,
        town: data.town && data.town.id ? data.town : null,
        // //or
        // // bordercrossingat:String,
        // // bordercrossforoperator : ?
        company: data.company && data.company.id ? data.company : null,
        address: data.address && data.address.id ? data.address : null,
        workaction: prewData.workaction,
        checkpointcontactdata:
          data.checkpointcontactdata && data.checkpointcontactdata.id
            ? data.checkpointcontactdata
            : null,
      },
      2: {
        country: data.country ? data.country : null,
        town: data.town && data.town.id ? data.town : null,
        // //or
        // // bordercrossingat:String,
        // // bordercrossforoperator : ?
        company: data.company && data.company.id ? data.company : null,
        address: data.address && data.address.id ? data.address : null,
        workaction: prewData.workaction,
        checkpointcontactdata:
          data.checkpointcontactdata && data.checkpointcontactdata.id
            ? data.checkpointcontactdata
            : null,
      },
      4: {
        country: data.country ? data.country : null,
        town: data.town && data.town.id ? data.town : null,
        // //or
        // // bordercrossingat:String,
        // // bordercrossforoperator : ?
        company: data.company && data.company.id ? data.company : null,
        address: data.address && data.address.id ? data.address : null,
        workaction: prewData.workaction,
        checkpointcontactdata:
          data.checkpointcontactdata && data.checkpointcontactdata.id
            ? data.checkpointcontactdata
            : null,
      },
      3: {
        country: data.country ? data.country : null,
        town: data.town && data.town.id ? data.town : null,
        // //or
        // // bordercrossingat:String,
        // // bordercrossforoperator : ?
        company: data.company && data.company.id ? data.company : null,
        address: data.address && data.address.id ? data.address : null,
        workaction: prewData.workaction,
        checkpointcontactdata:
          data.checkpointcontactdata && data.checkpointcontactdata.id
            ? data.checkpointcontactdata
            : null,
      },
    }
    onUpdateItemRoutePart(index, TEMPLATE[templateWorkaction])
  }

  const component = {
    1: (
      <>
        <span ref={refs.country} style={{ width: '100%' }}>
          <SelectInput
            name={uniq.country}
            id={uniq.country}
            style={{ marginBottom: '2.4rem' }}
            label={
              errors[index] && errors[index].country
                ? `${dic.country || ''}*`
                : dic.country || ''
            }
            value={data.country ? data.country.union : ''}
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            action={{ ...dataForDispatch, payload: 'country' }}
            list={list}
            nothingToDisplay={dic.info_1}
            error={
              errors[index] && errors[index].country
                ? errors[index].country.bool
                : null
            }
            message={
              errors[index] && errors[index].country
                ? errors[index].country.msg
                : null
            }
          />
        </span>
        <span ref={refs.town} style={{ width: '100%' }}>
          <SelectInput
            label={
              errors[index] && errors[index].town
                ? `${dic.town_dispatch || ''}*`
                : dic.town_dispatch || ''
            }
            name={uniq.town}
            id={uniq.town}
            parametr={parametr.country}
            style={{ marginBottom: '2.4rem' }}
            disabled={activeSelect.town}
            action={{ ...dataForDispatch, payload: 'town' }}
            list={list}
            value={data.town ? data.town.union : ''}
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            nothingToDisplay={dic.info_1}
            error={
              errors[index] && errors[index].town
                ? errors[index].town.bool
                : null
            }
            message={
              errors[index] && errors[index].town
                ? errors[index].town.msg
                : null
            }
          />
        </span>
        <span ref={refs.company} style={{ width: '100%' }}>
          <SelectInput
            label={
              errors[index] && errors[index].company
                ? `${dic.shipper || ''}*`
                : dic.shipper || ''
            }
            name={uniq.company}
            id={uniq.company}
            style={{ marginBottom: '2.4rem' }}
            disabled={activeSelect.company}
            value={data.company ? data.company.union : ''}
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            action={{ ...dataForDispatch, payload: 'company' }}
            list={list}
            parametr={parametr.town}
            nothingToDisplay={dic.info_1}
            error={
              errors[index] && errors[index].company
                ? errors[index].company.bool
                : null
            }
            message={
              errors[index] && errors[index].company
                ? errors[index].company.msg
                : null
            }
          />
        </span>
        <span ref={refs.address} style={{ width: '100%' }}>
          <SelectInput
            label={
              errors[index] && errors[index].address
                ? `${dic.address || ''}*`
                : dic.address || ''
            }
            name={uniq.address}
            id={uniq.address}
            style={{ marginBottom: '2.4rem' }}
            disabled={activeSelect.address}
            value={data.address ? data.address.union : ''}
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            action={{ ...dataForDispatch, payload: 'address' }}
            list={list}
            parametr={{ byCompany: parametr.company, byTown: parametr.town }}
            nothingToDisplay={dic.info_1}
            error={
              errors[index] && errors[index].address
                ? errors[index].address.bool
                : null
            }
            message={
              errors[index] && errors[index].address
                ? errors[index].address.msg
                : null
            }
          />
        </span>
        <Line />
        <span ref={refs.checkpointcontactdata} style={{ width: '100%' }}>
          <SelectInput
            label={
              errors[index] && errors[index].checkpointcontactdata
                ? `${dic.contact_person || ''}*`
                : dic.contact_person || ''
            }
            name={uniq.checkpointcontactdata}
            id={uniq.checkpointcontactdata}
            style={{
              marginBottom:
                data.checkpointcontactdata && data.checkpointcontactdata.tel
                  ? '2.4rem'
                  : '4.5rem',
            }}
            disabled={activeSelect.checkpointcontactdata}
            value={
              data.checkpointcontactdata ? data.checkpointcontactdata.union : ''
            }
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            action={{ ...dataForDispatch, payload: 'checkpointcontactdata' }}
            list={list}
            parametr={parametr.address}
            nothingToDisplay={dic.info_1}
            error={
              errors[index] && errors[index].checkpointcontactdata
                ? errors[index].checkpointcontactdata.bool
                : null
            }
            message={
              errors[index] && errors[index].checkpointcontactdata
                ? errors[index].checkpointcontactdata.msg
                : null
            }
          />
        </span>
        {/* email /faxnum / telnum /cellnum */}
        {data.checkpointcontactdata && data.checkpointcontactdata.cellnum && (
          <TextLikeInput
            isBright
            style={{ marginBottom: '2rem', justifyContent: 'flex-start' }}
            value={`${data.checkpointcontactdata.cellnum}`}
            label={dic.tel}
          />
        )}
        {data.checkpointcontactdata && data.checkpointcontactdata.telnum && (
          <TextLikeInput
            isBright
            style={{ marginBottom: '2rem', justifyContent: 'flex-start' }}
            value={`${data.checkpointcontactdata.telnum}`}
            label={dic.tel}
          />
        )}
        {data.checkpointcontactdata && data.checkpointcontactdata.email && (
          <TextLikeInput
            isBright
            style={{ marginBottom: '2rem', justifyContent: 'flex-start' }}
            value={`${data.checkpointcontactdata.email}`}
            label="Email"
          />
        )}
        <div style={{ marginBottom: '2.5rem' }} />
        <Line />
      </>
    ),
    2: (
      <>
        <span ref={refs.country} style={{ width: '100%' }}>
          <SelectInput
            name={uniq.country}
            id={uniq.country}
            style={{ marginBottom: '2.4rem' }}
            label={
              errors[index] && errors[index].country
                ? `${dic.country || ''}*`
                : dic.country || ''
            }
            value={data.country ? data.country.union : ''}
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            action={{ ...dataForDispatch, payload: 'country' }}
            list={list}
            nothingToDisplay={dic.info_1}
            error={
              errors[index] && errors[index].country
                ? errors[index].country.bool
                : null
            }
            message={
              errors[index] && errors[index].country
                ? errors[index].country.msg
                : null
            }
          />
        </span>
        <span ref={refs.town} style={{ width: '100%' }}>
          <SelectInput
            label={
              errors[index] && errors[index].town
                ? `${dic.town_unload || ''}*`
                : dic.town_unload || ''
            }
            name={uniq.town}
            id={uniq.town}
            parametr={parametr.country}
            style={{ marginBottom: '2.4rem' }}
            disabled={activeSelect.town}
            action={{ ...dataForDispatch, payload: 'town' }}
            list={list}
            value={data.town ? data.town.union : ''}
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            nothingToDisplay={dic.info_1}
            error={
              errors[index] && errors[index].town
                ? errors[index].town.bool
                : null
            }
            message={
              errors[index] && errors[index].town
                ? errors[index].town.msg
                : null
            }
          />
        </span>
        <span ref={refs.company} style={{ width: '100%' }}>
          <SelectInput
            label={
              errors[index] && errors[index].company
                ? `${dic.consignee || ''}*`
                : dic.consignee || ''
            }
            name={uniq.company}
            id={uniq.company}
            style={{ marginBottom: '2.4rem' }}
            disabled={activeSelect.company}
            value={data.company ? data.company.union : ''}
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            action={{ ...dataForDispatch, payload: 'company' }}
            list={list}
            parametr={parametr.town}
            nothingToDisplay={dic.info_1}
            error={
              errors[index] && errors[index].company
                ? errors[index].company.bool
                : null
            }
            message={
              errors[index] && errors[index].company
                ? errors[index].company.msg
                : null
            }
          />
        </span>
        <span ref={refs.address} style={{ width: '100%' }}>
          <SelectInput
            label={
              errors[index] && errors[index].address
                ? `${dic.address || ''}*`
                : dic.address || ''
            }
            name={uniq.address}
            id={uniq.address}
            style={{ marginBottom: '2.4rem' }}
            disabled={activeSelect.address}
            value={data.address ? data.address.union : ''}
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            action={{ ...dataForDispatch, payload: 'address' }}
            list={list}
            parametr={{ byCompany: parametr.company, byTown: parametr.town }}
            nothingToDisplay={dic.info_1}
            error={
              errors[index] && errors[index].address
                ? errors[index].address.bool
                : null
            }
            message={
              errors[index] && errors[index].address
                ? errors[index].address.msg
                : null
            }
          />
        </span>
        <Line />
        <span ref={refs.checkpointcontactdata} style={{ width: '100%' }}>
          <SelectInput
            label={
              errors[index] && errors[index].checkpointcontactdata
                ? `${dic.contact_person || ''}*`
                : dic.contact_person || ''
            }
            name={uniq.checkpointcontactdata}
            style={{
              marginBottom:
                data.checkpointcontactdata && data.checkpointcontactdata.tel
                  ? '2.4rem'
                  : '4.5rem',
            }}
            disabled={activeSelect.checkpointcontactdata}
            value={
              data.checkpointcontactdata ? data.checkpointcontactdata.union : ''
            }
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            action={{ ...dataForDispatch, payload: 'checkpointcontactdata' }}
            list={list}
            parametr={parametr.address}
            nothingToDisplay={dic.info_1}
            error={
              errors[index] && errors[index].checkpointcontactdata
                ? errors[index].checkpointcontactdata.bool
                : null
            }
            message={
              errors[index] && errors[index].checkpointcontactdata
                ? errors[index].checkpointcontactdata.msg
                : null
            }
          />
        </span>

        {/* email /faxnum / telnum /cellnum */}
        {data.checkpointcontactdata && data.checkpointcontactdata.cellnum && (
          <TextLikeInput
            isBright
            style={{ marginBottom: '2rem', justifyContent: 'flex-start' }}
            value={`${data.checkpointcontactdata.cellnum}`}
            label={dic.tel}
          />
        )}
        {data.checkpointcontactdata && data.checkpointcontactdata.telnum && (
          <TextLikeInput
            isBright
            style={{ marginBottom: '2rem', justifyContent: 'flex-start' }}
            value={`${data.checkpointcontactdata.telnum}`}
            label={dic.tel}
          />
        )}
        {data.checkpointcontactdata && data.checkpointcontactdata.email && (
          <TextLikeInput
            isBright
            style={{ marginBottom: '2rem', justifyContent: 'flex-start' }}
            value={`${data.checkpointcontactdata.email}`}
            label="Email"
          />
        )}
        <div style={{ marginBottom: '2.5rem' }} />
        <Line />
      </>
    ),
    4: (
      <>
        <span ref={refs.town} style={{ width: '100%' }}>
          <SelectInput
            label={
              errors[index] && errors[index].town
                ? `${dic.point_border || ''}*`
                : dic.point_border || ''
            }
            name={uniq.town}
            id={uniq.town}
            parametr={parametr.country}
            style={{ marginBottom: '2.4rem' }}
            disabled={activeSelect.town}
            action={{ ...dataForDispatch, payload: 'border' }}
            list={list}
            value={data.town ? data.town.union : ''}
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            nothingToDisplay={dic.info_1}
            error={
              errors[index] && errors[index].town
                ? errors[index].town.bool
                : null
            }
            message={
              errors[index] && errors[index].town
                ? errors[index].town.msg
                : null
            }
          />
        </span>
        <Line />
      </>
    ),
    3: (
      <>
        <span ref={refs.country} style={{ width: '100%' }}>
          <SelectInput
            name={uniq.country}
            id={uniq.country}
            style={{ marginBottom: '2.4rem' }}
            label={
              errors[index] && errors[index].country
                ? `${dic.country || ''}*`
                : dic.country || ''
            }
            value={data.country ? data.country.union : ''}
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            action={{ ...dataForDispatch, payload: 'country' }}
            list={list}
            nothingToDisplay={dic.info_1}
            error={
              errors[index] && errors[index].country
                ? errors[index].country.bool
                : null
            }
            message={
              errors[index] && errors[index].country
                ? errors[index].country.msg
                : null
            }
          />
        </span>
        <span ref={refs.town} style={{ width: '100%' }}>
          <SelectInput
            label={
              errors[index] && errors[index].town
                ? `${dic.town || ''}*`
                : dic.town || ''
            }
            name={uniq.town}
            id={uniq.town}
            parametr={parametr.country}
            style={{ marginBottom: '2.4rem' }}
            disabled={activeSelect.town}
            action={{ ...dataForDispatch, payload: 'townCustoms' }}
            list={list}
            value={data.town ? data.town.union : ''}
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            nothingToDisplay={dic.info_1}
            error={
              errors[index] && errors[index].town
                ? errors[index].town.bool
                : null
            }
            message={
              errors[index] && errors[index].town
                ? errors[index].town.msg
                : null
            }
          />
        </span>
        <span ref={refs.company} style={{ width: '100%' }}>
          <SelectInput
            label={
              errors[index] && errors[index].company
                ? `${dic.custom_name || ''}*`
                : dic.custom_name || ''
            }
            name={uniq.company}
            id={uniq.company}
            style={{ marginBottom: '2.4rem' }}
            disabled={activeSelect.company}
            value={data.company ? data.company.union : ''}
            fnFormValue={fnFormValue}
            fnChange={fnChange}
            action={{ ...dataForDispatch, payload: 'customs' }}
            list={list}
            parametr={parametr.town}
            nothingToDisplay={dic.info_1}
            error={
              errors[index] && errors[index].company
                ? errors[index].company.bool
                : null
            }
            message={
              errors[index] && errors[index].company
                ? errors[index].company.msg
                : null
            }
          />
        </span>
        <div style={{ marginBottom: '2.5rem' }} />
        <Line />
      </>
    ),
  }

  useEffect(() => {
    if (prewData.town && prewData.town.id) updateDefaultState()
    setFirstRender(1)
  }, []) //eslint-disable-line

  useEffect(() => {
    if (index + 1 && firstRender) {
      updateReducer()
    }
  }, [data, index, firstRender]) //eslint-disable-line
  useEffect(() => {
    if (firstRender && errors.length) {
      Object.keys(parametr).forEach((el) => {
        if (errors[index] && errors[index][el].bool) {
          executeScroll(refs, el)
        }
      })
    }
  }, [errors, firstRender, errors, index]) //eslint-disable-line

  return (
    <Wrapper style={style}>
      {prewData.workaction && component[prewData.workaction]}
    </Wrapper>
  )
}
