import { useState, useEffect } from 'react'

// HAVE TO USE
// timerValue = string
// isShowTimer = timerValue < 5 min ? true : false
// dateOfEndTimer = {date: 'YYY-MM-DD', time:'MM:SS'}

// const [ timerValue, isShowTimer] = useTimer(dateOfEndTimer)

// <p>{timerValue}</p> = always show
// {isShowTimer && <p>{timerValue}</p>} = show last 5 min

export default (dateOfEndTimer, interval = 0, msg = 'более 2 дней') => {
  const [hour, setHour] = useState('00')
  const [min, setMin] = useState('00')
  const [sec, setSec] = useState('00')
  const [isShow, setIsShow] = useState(false)
  const [finish, setFinish] = useState(null)
  const [isLongValue, setIsLongValue] = useState(false)
  useEffect(() => {
    if (dateOfEndTimer) {
      setFinish(
        Date.parse(`${dateOfEndTimer.time} ${dateOfEndTimer.date}`) / 1000
      )
    }
  }, [dateOfEndTimer]) //eslint-disable-line
  useEffect(() => {
    if (finish) {
      const counter = setTimeout(
        () => {
          const now = new Date()
          const start = Date.parse(now) / 1000
          const result = finish - start
          if (result <= 0) {
            setFinish(null)
          }
          const hour = Math.floor(Number((result / (60 * 60)) % 60))
          const min = Math.floor(Number((result / 60) % 60))
          const sec = Number(result % 60)
          setIsLongValue(hour >= 48)
          setIsShow(
            interval > 0
              ? interval > min && hour === 0
              : hour >= 0 && min >= 0 && sec >= 0
          )
          setHour(hour < 10 ? `0${hour}` : hour)
          setMin(min < 10 ? `0${min}` : min)
          setSec(sec < 10 ? `0${sec}` : sec)
        },
        1000,
        true
      )
      return () => clearTimeout(counter)
    }
  }, [finish, min, sec]) //eslint-disable-line

  return [isLongValue ? msg : `${hour}:${min}:${sec}`, isShow, !isLongValue]
}
