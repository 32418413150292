import React from 'react'
import styled from 'styled-components'
import picture from '../../assets/img/logo_domino.png'

const Wrapper = styled.div`
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  padding: 3rem 1.5rem 5rem;
  background: ${(p) => p.theme.formGradient};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
const Logo = styled.img`
  width: 17%;
  min-width: 6.4rem;
  max-width: 12rem;
`
export default ({ isLogo = false, children }) => {
  return (
    <Wrapper>
      {isLogo && <Logo src={picture} alt="sovtes" />}
      {children}
    </Wrapper>
  )
}
