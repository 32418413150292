import { api, routs } from '../api'

// Get new notifications summary
export const getNotificationStatus = (token, currentLang) => {
  return api.get(routs.notificationStatus, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

export const getChats = (token, currentLang, periodic) => {
  return api.get(routs.getRouteMessageGroups, {

    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: { route: periodic },
  })
}

export const getRecepients = (token, currentLang, periodic) => {
  return api.get(routs.getRecepients, {

    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: { route: periodic },
  })
}

export const getMessagesByChat = (token, currentLang, periodic, companyHash) => {
  return api.get(routs.getRouteMessageGroups, {

    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: {route: periodic, collocutor: companyHash},
  })
}

export const sendMessage = (token, currentLang, periodic, recipient, message) => {
  return api.post(
      routs.sendMessage,
      { periodic, recipient,  message},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Language: currentLang,
        },
      }
  )
}
