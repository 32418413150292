import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const PointsWrp = styled.div`
  max-width: 20rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Point = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 3rem;
  border-radius: 50%;
  background-color: ${(p) =>
    p.isActive ? p.theme.flat_headers : p.theme.system_button};
  :last-child {
    margin-right: unset;
  }
`
export default ({ steps = [], active = 0, style = {} }) => {
  return (
    <Wrapper style={style}>
      <PointsWrp>
        {steps.map((step, index) => (
          <Point key={step} isActive={index + 1 <= active} />
        ))}
      </PointsWrp>
    </Wrapper>
  )
}
