import React, { useState, useEffect, useRef } from 'react'
import {
  Wrapper,
  Title,
  PeriodicWrp,
  DriversInfo,
  Action,
  Message,
  ConfirmOfAction,
  Scale2RouteParts,
} from '../../../smallPartsForInterface/SmallPartsWidgets'
import YellowMarker from '../../../YellowMarker'
import BaseBtn from '../../../buttons/BaseBtn'
import useRouter from '../../../../hooks/useRouter'
import { useDispatch, useSelector } from 'react-redux'
import { strToUpperCase } from '../../../../lib/helpers/forStyling'

export default ({ obj, dic, memory = '' }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const myref = useRef(null)
  const flagList = useSelector((s) => s.route.flagList)
  const [active, setActive] = useState(0)
  const [isBtn, setIsBtn] = useState(false)
  const [activePoint, setActivePoint] = useState({
    workaction: 0,
    triggered: 0,
  })
  const toDetermineTheHotPoint = (id) => {
    setActive(id)
  }
  const {
    routeparts,
    periodic,
    routeresponse_relation,
    routeresponse,
    operator_relation,
  } = obj
  const aboutDrivers = routeresponse_relation.find(
    (item) => item.id === routeresponse
  )
  const titles = (workAction) => {
    if (workAction === 3 || workAction === 4) {
      return {
        1: dic.btn_action_11,
        2: dic.btn_action_22,
        4: activePoint.triggered ? dic.btn_action_44 : dic.btn_action_4,
        3: activePoint.triggered ? dic.btn_action_33 : dic.btn_action_3,
      }
    } else {
      return {
        1: dic.btn_action_11,
        2: dic.btn_action_22,
        4: dic.btn_action_3,
        3: dic.btn_action_4,
      }
    }
  }
  const onBtnAction = (id, routeId) => {
    dispatch({
      type: 'SAGA_CONFIRM_AT',
      actionType: id,
      data: { routepart: activePoint.id },
      routeId,
    })
  }
  useEffect(() => {
    if (active) {
      const result = routeparts.find((item) => item.id === active)
      const index = routeparts.findIndex((item) => item.id === active)
      setActivePoint(result)
      setIsBtn(
        index === 0
          ? true
          : obj.routestatus === 15 ||
            obj.routestatus === 16 ||
            obj.routestatus === 17
          ? true
          : Boolean(!result.complete) //trigerred
      )
    }
  }, [active, routeparts, obj])
  useEffect(() => {
    if (flagList[obj.periodic]) setIsBtn(false)
  }, [flagList, obj])

  const widgetClick = () => {
    dispatch({ type: 'REMEMBER_ROUTE_WIDGET', payload: periodic })
    router.push(`/route/${periodic}`)
  }

  const executeScroll = () =>
    myref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })

  useEffect(() => {
    if (memory === periodic) {
      executeScroll()
    }
  }, [memory, periodic])

  return (
    obj &&
    dic && (
      <Wrapper>
        <span onClick={widgetClick} ref={myref}>
          <PeriodicWrp right>{`№ ${periodic}`}</PeriodicWrp>

          <div
            className="flexWrp"
            style={{ marginTop: '1rem', marginBottom: '0.5rem' }}
          >
            {operator_relation && (
              <Title large isMessage={false}>
                {operator_relation.company_relation.title_ru}
              </Title>
            )}
            {obj.needAttention && <YellowMarker />}
          </div>
          {routeparts && (
            <>
              <Scale2RouteParts
                arr={routeparts}
                dic={dic}
                toDetermineTheHotPoint={toDetermineTheHotPoint}
                status="enroute"
              />
            </>
          )}
          {aboutDrivers && <DriversInfo obj={aboutDrivers} />}
        </span>

        {isBtn ? (
          <Action>
            {activePoint.workaction === 1 || activePoint.workaction === 2 ? (
              <BaseBtn
                fullSize
                label={titles(activePoint.workaction)[activePoint.workaction]}
                nameIcon={activePoint.workaction}
                fnClick={() =>
                  onBtnAction(
                    activePoint.triggered === 1 ||
                      Boolean(activePoint.triggered)
                      ? `${activePoint.workaction}${activePoint.workaction}`
                      : activePoint.workaction,
                    obj.periodic
                  )
                }
                localId={obj.id}
                forLoadFlow
              />
            ) : (
              <Message>
                {strToUpperCase(
                  titles(activePoint.workaction)[activePoint.workaction]
                )}
              </Message>
            )}
          </Action>
        ) : (
          flagList[obj.periodic] && (
            <ConfirmOfAction
              title={`${strToUpperCase(dic.msg_1)}!`}
              text={strToUpperCase(dic.msg_2)}
            />
          )
        )}
      </Wrapper>
    )
  )
}
