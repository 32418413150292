const TRACTOR = 'forcesplitcarstyle'
const COUPLING = 'optionalsplitcarstyle'
const TRUCK = 'singlecarstyle'

export const truckTypes = {
  toStore: {
    coupling: COUPLING,
    tractor: TRACTOR,
    truck: TRUCK,
  },
  fromStore: {
    [COUPLING]: 'coupling',
    [TRACTOR]: 'tractor',
    [TRUCK]:  'truck',
  }
}

export const CAR_FIELDS = {
  //make-new-form === make.id
  model: {
    name: "model", // required field
    isRequired: true,
    placeholder: '',
    getDicName: (d) => d?.model || "model",
    byDefault: "",
    expectedValue:["string"]
  },
  carstyle:{
    name: "carstyle", //Indicates the tractor type
    isRequired: true,
    byDefault: TRACTOR,
    expectedValue:['string'],
  },
  number: {
    name: "number", //License plate number
    isRequired: true,
    getDicName: (d) => d?.number || "number",
    placeholder: '',
    byDefault: "",
    expectedValue:["string"],
  },
  carbodytype: {
    name: "carbodytype", // ID of national registry truck
    isRequired: true,
    getDicName: (d) => d?.carbodytype || "carbodytype",
    byDefault: "",
    expectedValue:["oneOfType", "number"],
  },
  year_of_manufact: {
    name: "year_of_manufact", // Truck manufacturing year
    isRequired: true,
    getDicName: (d) => d?.year_of_manufact || "year_of_manufact",
    isShortInput: true,
    placeholder: '',
    afterDicName: (d) => d?.year_sort  || "y",
    byDefault:"",
    expectedValue:["number"],
  },
  loadcapacity: {
    name: "loadcapacity", //Maximum loaded truck weight, in tonns // yes	Load capacity in tonns
    isRequired: true,
    getDicName: (d) => d?.loadcapacity || "loadcapacity",
    isShortInput: true,
    placeholder: '',
    afterDicName:(d) => d?.t || "t",
    byDefault: "",
    expectedValue:["number"],
  },
  width: {
    name: "width", //Truck width, in m
    isRequired: true,
    getDicName: (d) => d?.width || "width",
    isShortInput: true,
    placeholder: '',
    afterDicName: (d) => d?.m ||  "m",
    byDefault: "",
    expectedValue:["number"],
  },
  height: {
    name: "height", //Truck height in m
    isRequired: true,
    getDicName: (d) => d?.height|| "height",
    placeholder: '',
    isShortInput: true,
    afterDicName: (d) => d?.m || "m",
    byDefault: "",
    expectedValue:["number"],

  },
  length: {
    name: "length",   // Truck length in m
    isRequired: true,
    getDicName: (d) => d?.length|| "length",
    placeholder: '',
    isShortInput: true,
    afterDicName: (d) => d?.m || "m",
    byDefault: "",
    expectedValue:["number"],

  },
  volume: {
    name: "volume",
    getDicName: (d) => d?.volume|| "volume",
    isShortInput: true,
    placeholder: '',
    afterDicName: (d) => d?.mcube || "mcube",
    beforeDicName: (d) => d?.to || "to",
    byDefault: "",
    expectedValue:["number"],

  },
  total_weight: {
    name: "total_weight", //Maximum loaded truck weight, in tonns
    isRequired: true,
    getDicName: (d) => d?.total_weight || "total_weight",
    isShortInput: true,
    placeholder: '',
    afterDicName:(d) => d?.t || "t",
    byDefault: "",
    expectedValue:["number"],
  },
  vin: {
    name: "vin", //VIN number
    isRequired: true,
    placeholder:"",
    getDicName: (d) => `${d?.chassis} (VIN)` || "chassis",
    byDefault: "",
    expectedValue:["string"],
  },
  carbase: { //ID of the base point of the truck
    name:"carbase",
    getDicName:(d) => d?.car_location || "car_location",
    placeholder:'',
    byDefault: "",
    expectedValue:["number"],
    disabled:true,
  },
  cellnum: {
    name:"cellnum", // Direct phone number in the truck
    getDicName:(d) => d?.driver_tel || "driver_tel",
    placeholder:"",
    byDefault: "",
    expectedValue:["string"],
  },
  craft: {
    name:"craft",
    getDicName:(d) => d?.board || "board",
    byDefault: "",
    expectedValue:["string"],
  },
  weight: {
    name:"weight", // Truck weight
    isRequired: true,
    getDicName:(d) => d?.weight_without_cargo || "weight",
    isShortInput:true,
    placeholder:'',
    afterDicName:(d) => d?.t || "t",
    byDefault: "",
    expectedValue:["number"],
  },
  axes: {
    name:"axes", //Number of axes
    getDicName:(d) => d?.axes_quantity || "axes_quantity",
    isShortInput:true,
    placeholder:'',
    afterDicName:(d) => d?.pieces || "pieces",
    byDefault: "",
    expectedValue:["number"],
  },
  gpsid: {
    name:"gpsid",// Internal GPS identifier for location tracking
    byDefault: "",
    placeholder:'',
    expectedValue:["string"],
  },
  remark: {
    name:"remark",
    byDefault: "",
    expectedValue:["string"],
  },
  make: {
    name:"make",  // ID of the make of the truck
    getDicName: (d) =>`${d?.manufacturer} (${d?.brand})` ||'manufacturer brand',
    byDefault: { union: '', id: null }, // id<number>
    expectedValue:["oneOfType", "number"],
  },
  vehicleclass:{
    name:"vehicleclass",
    byDefault: { union: '', id: null }, // id<number>
    getDicName: (d) => d?.car_class || "car_class",
    expectedValue:["oneOfType", "number"],
  },
  cartype: {
    name: "cartype", //ID of the type of truck
    getDicName: (d) => d?.car_type || "car_type",
    byDefault: {union: '', id: null},
    expectedValue: ["oneOfType", "number"],
  },
  chargetype: {
    name:"chargetype", //ID of the type of truck
    getDicName:(d) => d?.charge_type || "charge_type",
    byDefault: "",
    expectedValue:["manyTypes", "string"],
  },
  ferryproperty: {
    name:"ferryproperty", //ID of the type of truck
    getDicName:(d) => d?.ferry_property || "ferry_property",
    byDefault: "",
    expectedValue:["manyTypes", "string"],
  },
//insuredTil Insurance expiration date	YYYY-MM-DD
//deadpath		Minimum price of dead path, in local currency	float
//fullpath		Minimum price of loaded path, in local currency	float
//minRoutePrice		Minimum price of delivery, in local currency	float
//quantity-ferryproperty-N		Additional data for a box property of ID N, for example, temperature requirments. Example: quantity-ferryproperty-11: "-5"	string

};
export const TRAILER_FIELDS = {
  number: {
    name: "number", //License plate number
    isRequired: true,
    getDicName: (d) => d?.number || "number",
    placeholder: '',
    byDefault: "",
    expectedValue:["string"],
  },

  loadcapacity: {
    name: "loadcapacity", //Maximum loaded truck weight, in tonns // yes	Load capacity in tonns
    isRequired: true,
    getDicName: (d) => d?.loadcapacity || "loadcapacity",
    isShortInput: true,
    placeholder: '',
    afterDicName:(d) => d?.t || "t",
    byDefault: "",
    expectedValue:["number"],
  },
  cartype: {
    name: "cartype", // ID of the trailer type
    isRequired: true,
    getDicName: (d) => d?.trailer_type || "trailer_type",
    byDefault: {union: '', id: null},
    expectedValue: ["oneOfType", "number"],
  },
  model: {
    name: "model", // Model title
    isRequired: true,
    placeholder: '',
    getDicName: (d) => d?.model || "model",
    byDefault: "",
    expectedValue:["string"]
  },
  make: {
    name:"make",  // User supplied trailer make
    isRequired: true,
    getDicName: (d) =>`${d?.manufacturer} (${d?.brand})` ||'manufacturer brand',
    byDefault: { union: '', id: null }, // id<number>
    expectedValue:["unionType", "string"],
  },
  total_weight: {
    name: "total_weight", //Maximum loaded trailer weight, in tonns
    isRequired: true,
    getDicName: (d) => d?.total_weight || "total_weight",
    isShortInput: true,
    placeholder: '',
    afterDicName:(d) => d?.t || "t",
    byDefault: "",
    expectedValue:["number"],
  },
  trailerbodytype: {
    name: "trailerbodytype", // ID of national registry box type
    isRequired: true,
    getDicName: (d) => d?.carbodytype || "carbodytype",
    byDefault: { union: '', id: null },
    expectedValue:["oneOfType", "number"],
  },
  vin: {
    name: "vin", //VIN number
    isRequired: true,
    placeholder:"",
    getDicName: (d) => `${d?.chassis} (VIN)` || "chassis",
    byDefault: "",
    expectedValue:["string"],
  },
  year_of_manufact: {
    name: "year_of_manufact", // Trailer manufacturing year
    isRequired: true,
    getDicName: (d) => d?.year_of_manufact || "year_of_manufact",
    isShortInput: true,
    placeholder: '',
    afterDicName: (d) => d?.year_sort  || "Y",
    byDefault:"",
    expectedValue:["number"],
  },
  width: {
    name: "width", //Truck width, in m
    isRequired: true,
    getDicName: (d) => d?.width || "width",
    isShortInput: true,
    placeholder: '',
    afterDicName: (d) => d?.m ||  "m",
    byDefault: "",
    expectedValue:["number"],
  },
  height: {
    name: "height", //Truck height in m
    isRequired: true,
    getDicName: (d) => d?.height|| "height",
    placeholder: '',
    isShortInput: true,
    afterDicName: (d) => d?.m || "m",
    byDefault: "",
    expectedValue:["number"],

  },
  length: {
    name: "length",   // Truck length in m
    isRequired: true,
    getDicName: (d) => d?.length|| "length",
    placeholder: '',
    isShortInput: true,
    afterDicName: (d) => d?.m || "m",
    byDefault: "",
    expectedValue:["number"],

  },
  volume: {
    name: "volume",
    getDicName: (d) => d?.volume|| "volume",
    isShortInput: true,
    placeholder: '',
    afterDicName: (d) => d?.mcube || "mcube",
    beforeDicName: (d) => d?.to || "to",
    byDefault: "",
    expectedValue:["number"],

  },
  weight: {
    name:"weight", // Truck weight
    getDicName:(d) => d?.weight_without_cargo || "weight",
    isRequired:true,
    isShortInput:true,
    placeholder:'',
    afterDicName:(d) => d?.t || "t",
    byDefault: "",
    expectedValue:["number"],
  },
  axes: {
    name:"axes", //Number of axes
    getDicName:(d) => d?.axes_quantity || "axes_quantity",
    isShortInput:true,
    placeholder:'',
    afterDicName:(d) => d?.pieces || "pieces",
    byDefault: "",
    expectedValue:["number"],
  },
  chargetype: {
    name:"chargetype", //ID of the type of truck
    getDicName:(d) => d?.charge_type || "charge_type",
    byDefault: "",
    expectedValue:["manyTypes", "string"],
  },
  ferryproperty: {
    name:"ferryproperty", //ID of the type of truck
    getDicName:(d) => d?.ferry_property || "ferry_property",
    byDefault: "",
    expectedValue:["manyTypes", "string"],
  },
  remark: {
    name:"remark",
    byDefault: "",
    expectedValue:["string"],
  },
// quantity-ferryproperty-N		Additional data for a box property of ID N, for example, temperature requirments. Example: quantity-ferryproperty-11: "-5"	string

}


export const getCarModel = () => {
  return Object.entries(CAR_FIELDS).reduce( (acc, [key, {byDefault}]) => {
    return {
      ...acc,
      [key]:byDefault
    }
  }, {} )
};

export const getTrailerModel = () => {
  return Object.entries(TRAILER_FIELDS).reduce( (acc, [key, {byDefault}]) => {
    return {
      ...acc,
      [key]:byDefault
    }
  }, {} )
};

export const forNewTS = {
  driver: {
    lastname: '',
    firstname: '',
    patronymic: '',
    maincellphone: '',
    driverlicenseserial: '',
    driverlicensenumber: '',
    driverlicenseissued: '',
    driverlicenseissuedby: '',
    passportserial: '',
    passportnumber: '',
    taxid: '',
    passportissued: '',
    passportissuedby: '',
    birthdate: '',
    remark: '',
  },
  car: getCarModel(),
  trailer: getTrailerModel(),
}
