import Type from '../types'

const initialState = {
  info: {},
  badRequest: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_COMPANY_INFO:
      return {
        ...state,
        info: action.payload,
      }
    case Type.SET_COMPANY_BADREQEST:
      return {
        ...state,
        badRequest: action.payload,
      }

    default:
      return state
  }
}
