import { takeEvery, call, put, select, race, delay } from 'redux-saga/effects'
import { getCartype } from '../../../lib/requests/auxRequests'
import { delays } from '../../../lib/constants'
import { getCurrentLang, getToken, getDicionary } from '../../selectors'

export function* worker({ prewData }) {
  try {
    const currentLang = yield select(getCurrentLang)
    const token = yield select(getToken)
    const dic = yield select(getDicionary)
    yield put({ type: 'SET_SELECTLIST_LOADER', payload: true })
    const { result } = yield race({
      result: call(getCartype, token, currentLang),
      timeout: delay(delays.forCancelRequest),
    })

    if (result && result.status === 200) {
        yield put({
          type: 'SET_SELECTLIST_DATA',
          payload: result.data.data.map((obj) => ({
            ...obj,
            union: `${obj.title}`,
          })),
        })
        yield put({ type: 'SET_SELECTLIST_LOADER', payload: false })
        yield put({ type: 'FILTER_SELECTLIST' })
    } else {
        yield put({ type: 'SET_SELECTLIST_LOADER', payload: false })
      yield put({
        type: 'SAGA_SHOW_ERROR',
        codeId: 6,
        msg: dic.msg_server_err,
      })
    }
  } catch (e) {
      yield put({ type: 'SET_SELECTLIST_LOADER', payload: false })
    yield put({
      type: 'SAGA_SHOW_ERROR',
      codeId: String(e.response.status).slice(0, 1),
      msg: e.response ? e.response.data.message : e.message,
    })
    if (e.response && e.response.data.relogin) {
      yield put({ type: 'SAGA_LOGOUT' })
    }
    if (e.response && e.response.data.forwardTo) {
      yield put({
        type: 'REDIRECT_OUTSIDE',
        payload: e.response.data.forwardTo,
      })
    }
  }
}

export default function* allRoutes() {
  yield takeEvery('SAGA_GET_CARTYPES', worker)
}
