import React from 'react'
import styled from 'styled-components'
import useRouter from '../../hooks/useRouter'
import Icon from '../../components/Icon'
import { useDispatch } from 'react-redux'

const Wrapper = styled.button`
  width: 100%;
  padding: 1.3rem 1.7rem;
  background-color: ${(p) => p.theme.basic};
  font-size: ${(p) => p.theme.h4};
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease;
  :active {
    box-shadow: inset ${(p) => p.theme.hotKeyShadow};
  }
`
const Label = styled.p`
  margin-right: auto;
  color: ${(p) => p.theme.regular_txt};
  font-size: ${(p) => p.theme.h4};
  font-weight: 500;
`
const closeStyles = {
  marginRight: '0.6rem',
  transform: 'rotate(45deg) scale(0.7)',
}
const defaultObj = {
  title: 'horizontal tab',
  id: '0',
  path: '/',
}

export default ({ obj = defaultObj }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const onAction = (path) => {
    if (path) {
      router.push(path)
    }
    dispatch({ type: 'CLOSE_MAIN_MENU' })
  }
  return (
    <Wrapper type="button" onClick={() => onAction(obj.path)}>
      <Icon name="close" type="regularText" style={closeStyles} />
      <Label>{obj.title}</Label>
      <Icon name="arrowShevron" type="regularText" />
    </Wrapper>
  )
}
