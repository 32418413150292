import { takeEvery, call, put, select, race, delay } from 'redux-saga/effects'
import { getRouteDetails } from '../../lib/requests/routesRequests'
import { delays } from '../../lib/constants'
import { getCurrentLang, getToken, getDicionary } from '../selectors'

export function* worker({ tenderList, typeAction }) {
  try {
    const currentLang = yield select(getCurrentLang)
    const token = yield select(getToken)
    const dic = yield select(getDicionary)

    const { result } = yield race({
      result: call(getRouteDetails, token, currentLang, tenderList),
      timeout: delay(delays.forCancelRequest),
    })

    if (result && result.status === 200) {
      yield put({
        type:
          typeAction === 'complete'
            ? 'SET_WIDGET_TENDERS_FOR_COMPLETE'
            : 'SET_WIDGET_TENDERS',
        payload: result.data.data,
        tenderList: tenderList,
        flagForBtn:
          typeAction !== 'complete'
            ? result.data.data.routes.reduce((sum, item) => {
                return { ...sum, [item.periodic]: false }
              }, {})
            : null,
      })
    } else {
      yield put({
        type: 'SHOW_ERROR_API',
        code: 6,
        message: dic.msg_server_err || 'internal server error',
      })
    }
  } catch (e) {
    yield put({
      type: 'SHOW_ERROR_API',
      code: String(e.response.status).slice(0, 1),
      message: e.response ? e.response.data.message : e.message,
    })
    if (e.response && e.response.data.relogin) {
      yield put({ type: 'SAGA_LOGOUT' })
    }
  }
}

export default function* tenderForWidget() {
  yield takeEvery('SAGA_GET_TENDER_DETAILS', worker)
}
