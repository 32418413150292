import Type from '../types'

const initialState = {
  cardRoute: null,
  widgetList: [],
  flagList: {},
  dictionary: null,
  isOpen: { all: null, my: null },
  totalCompleteRoutes: '',
  perPage: 10,
  currentPage: 0,
  lastPosition: '',
  badRequest: false,
  routeTabBadRequest: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_SINGLE_ROUTE:
      return {
        ...state,
        cardRoute: action.payload === null ? null : action.payload.route,
        dictionary:
          action.payload === null ? state.dictionary : action.payload.dic,
        flagList: action.flagForBtn ? action.flagForBtn : {},
      }
    case Type.SET_WIDGET_ROUTES:
      return {
        ...state,
        widgetList: action.payload.routes,
        dictionary: action.payload.dic,
        flagList: action.flagForBtn,
      }
    case Type.UPDATE_ROUTES_FLAG_LIST:
      return {
        ...state,
        flagList: action.payload,
      }
    case Type.SET_WIDGET_ROUTES_FOR_COMPLETE:
      return {
        ...state,
        widgetList: [...state.widgetList, ...action.payload.routes],
        dictionary: action.payload.dic,
      }

    case Type.CLEAR_WIDGET_ROUTES:
      return {
        ...state,
        widgetList: [],
        flagList: {},
        completeWidgetList: [],
        dictionary: null,
        totalCompleteRoutes: '',
        perPage: 10,
        currentPage: 0,
      }
    case Type.SET_OPEN_WIDGET_ROUTES:
      return {
        ...state,
        isOpen: action.payload,
      }
    case Type.SET_TOTAL_COMPLETE_ROUTES:
      return {
        ...state,
        totalCompleteRoutes: action.payload,
      }
    case Type.SET_PAGE_COMPLETE_ROUTES:
      return {
        ...state,
        currentPage: action.payload,
      }

    case Type.REMEMBER_ROUTE_WIDGET:
      return {
        ...state,
        lastPosition: action.payload,
      }
    case Type.STOP_LOADER_FOR_SINGLE_ROUTE_PAGE:
      return {
        ...state,
        badRequest: true,
      }
    case Type.CLEAR_FLAG_FOR_SINGLE_ROUTE_PAGE:
      return {
        ...state,
        badRequest: false,
      }
    case Type.STOP_LOADER_FOR_LIST_ROUTES_PAGE:
      return {
        ...state,
        routeTabBadRequest: true,
      }
    case Type.CLEAR_FLAG_FOR_LIST_ROUTES_PAGE:
      return {
        ...state,
        routeTabBadRequest: false,
      }

    default:
      return state
  }
}
