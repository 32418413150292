import React, { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useRouter from '../../../../hooks/useRouter'
import {
  Wrapper,
  Title,
  Info,
  Price,
  Message,
  DriversInfo,
  Scale2RouteParts,
  PeriodicWrp,
  CargoTitle,
} from '../../../smallPartsForInterface/SmallPartsWidgets'
import { currencyIcons } from '../../../../lib/constants'
import YellowMarker from '../../../YellowMarker'
import Icon from '../../../Icon'
import {
  NumberWithoutNulls,
  KmWithoutNulls,
  parseCargoInfo,
  parseExtraCarOption,
} from '../../../../lib/helpers/forRoutes'
import { strToUpperCase } from '../../../../lib/helpers/forStyling'

export default ({ obj, dic, memory = '' }) => {
  const router = useRouter()
  const myref = useRef(null)
  const dispatch = useDispatch()
  const {
    routeparts,
    periodic,
    defaultcurrency,
    budget,
    distance,
    operator_relation,
    routeresponse_relation,
    routeresponse,
  } = obj
  const aboutDrivers = routeresponse_relation.find(
    (item) => item.id === routeresponse
  )
  const widgetClick = () => {
    dispatch({ type: 'REMEMBER_ROUTE_WIDGET', payload: periodic })
    router.push(`/route/${periodic}`)
  }

  const executeScroll = () =>
    myref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })

  useEffect(() => {
    if (memory === periodic) {
      executeScroll()
    }
  }, [memory, periodic])

  return (
    obj &&
    dic && (
      <span ref={myref}>
        <Wrapper isMrgnBtm onClick={widgetClick}>
          <div className="flexWrp" style={{ marginBottom: '1.5rem' }}>
            {Boolean(obj.budget) && obj.budget > 1 && (
              <Price>{`${
                defaultcurrency
                  ? currencyIcons[defaultcurrency]
                  : currencyIcons.uah
              } ${NumberWithoutNulls(budget)}`}</Price>
            )}
            {Boolean(distance) && distance > 1 && (
              <Info head>{`${KmWithoutNulls(distance)} ${dic.km}`}</Info>
            )}
            {routeparts && (
              <Info head>{`${dic.points} ${routeparts.length}`}</Info>
            )}
          </div>
          <div className="flexWrp" style={{ marginBottom: '0.5rem' }}>
            {operator_relation && (
              <Title isMessage={false}>
                {operator_relation.company_relation.title_ru}
              </Title>
            )}
            {obj.needAttention && <YellowMarker />}
          </div>
          <Info className="flexWrp">
            <Icon name="hook" type="focused" style={{ marginTop: '0.4rem' }} />
            {routeparts && (
              <CargoTitle style={{ textTransform: 'lowercase' }}>
                {parseCargoInfo(routeparts)}
              </CargoTitle>
            )}
          </Info>
          <Info style={{ paddingLeft: '1.35rem' }}>
            {parseExtraCarOption(obj)}
          </Info>
          {routeparts && (
            <Scale2RouteParts arr={routeparts} dic={dic} status="free" />
          )}
          {aboutDrivers && <DriversInfo obj={aboutDrivers} />}
          <PeriodicWrp right>{`№ ${periodic}`}</PeriodicWrp>
          <div style={{ marginTop: '1rem' }}>
            <Message>{strToUpperCase(dic.msg_333)}</Message>
          </div>
        </Wrapper>
      </span>
    )
  )
}
