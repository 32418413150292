import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import SmallLoader from '../../layouts/SmallLoader'
import { parseWholeDate } from '../../../lib/helpers/forRoutes'
import ModalPortal from '../../../components/layouts/ModalPortal'
import SendNewMessage from "./SendNewMessage";

const Wrapper = styled.div`
  width: 100%;
  min-height: 60vh;
  margin-top: 1.6rem;
  margin-bottom:  ${({ hasRecipient }) => hasRecipient && '12rem'};
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .general {
    margin-top: 45%;
    align-self: center;
  }
  .no-content {
    font-size: ${p=> p.theme.h4};
    color: ${p=> p.theme.additional2};
  }
`

const List = styled.div`
  width: 99%;
  min-width: 28rem;
  max-height: 30rem;
  padding: 1.6rem;
  border-radius: ${(p) => p.theme.PopUpRadius};
  background-color: ${(p) => p.theme.opposite};
  overflow: auto;
`
const ListItem = styled.p`
  padding: 1.6rem 0;
  border-bottom: 0.1rem  ${(p) => p.theme.stroke};
  color: ${(p) => p.isCurrent && p.theme.links };
  :last-child {
    border-bottom: none;
  }
`
const Message  = styled.div`
  width: 85vw;
  padding: 0.8rem;
  align-self: ${p=> p.isMy ? 'flex-end' : 'flex-start'};
  margin-bottom: 0.8rem;
  border-radius: ${p=> p.theme.itemRadius};
  background: ${p=> p.isMy 
      ? p.theme.trigger 
      : p.isNew ? p.theme.basic : p.theme.selected
   
  };
  font-size: ${p=> p.theme.h4};
  color: ${p=> p.isMy ? p.theme.opposite_txt : p.theme.regular_txt};
  
  .about {
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
  }
  
  .name {
    font-size: ${p=> p.theme.h5};
    color: ${p=> p.isMy ? p.theme.opposite_txt : p.theme.links}
  }
  .avatar {
    width: 2.8rem;
    height: 2.8rem;
    background-image: ${p => `url(${p.avatar})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
  }
  
  .content{
    margin-bottom: 0.8rem;
  }
  
  .date {
    font-size: ${p=> p.theme.h6};
    font-style: normal;
    font-weight: 400;
    color: ${p=> p.isMy ? p.theme.opposite_txt : p.theme.additional2}
  }
`

export default ({ periodic = '' }) => {
  const dispatch = useDispatch()
  const {
    data,
    isLoader,
    isFetched,
    postProcess,
    recipients,
  } = useSelector(s => s.chats)

  const profileCompanyHash = useSelector(s => s.user.profile.hash)
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const [recipient, setRecipient] = useState({})
  const [isShowRecipients, setIsShowRecipients] = useState(false)
  const chatIds = Object.keys(data)
  const noContentText = dic?.chat_no_messages || "text from dictionary..."

  const onChoiceRecipient = () => {
    if(recipients.length > 1){
      setIsShowRecipients(true)
    }
  }

  const onChangeRecipient = (obj) => {
    setRecipient(obj)
    setIsShowRecipients(false)
  }

  const chatByRecipient = useMemo(()=>{
    if(chatIds.length && recipient?.recepientHash){
      return data[recipient.recepientHash] || []
    }
    return []
  },[data, recipient])

  useEffect(()=>{
    dispatch({ type: 'GET_CHAT_RECIPIENTS', periodic })
    dispatch({ type: 'GET_ROUTE_CHATS', periodic })

    return () => dispatch({ type: 'SET_CHAT_FETCH', payload: false })

  }, [])

  useEffect(()=> {
    if(chatIds?.length && !isFetched){
      chatIds.map((companyHash)=>{
        dispatch({ type: 'GET_CHAT_MESSAGES', periodic, companyHash })
      })
    }
  }, [chatIds])

  useEffect(()=>{
    if(chatByRecipient?.length){
      const nodeId = chatByRecipient[chatByRecipient.length -1]?.moment
      const node = document.querySelector(`div[data-id='${nodeId}']`)
      if(node){
        node.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
      }
    }

  }, [chatByRecipient])

  useEffect(()=> {
    if(recipients?.length && !recipient?.recepientHash){
        setRecipient(recipients[0])
    }
  }, [recipients])


  return  (
      <>
      {isShowRecipients && (
          <ModalPortal>
            <List>
              {recipients.map((item) => {
                const {recepientHash, recepientCompany } = item
                return (
                    <ListItem
                      isCurrent={recepientHash === recipient.recepientHash}
                      key={recepientHash}
                      className="list-item"
                      onClick={()=>onChangeRecipient(item)}
                    >
                      {recepientCompany}
                    </ListItem>
                )

              })}
            </List>
          </ModalPortal>
      )}
      <Wrapper
          data-testid="routeChat"
          hasRecipient={recipient?.recepientHash}
      >
        {isLoader && (
            <div className="general">
              <SmallLoader size="2.4rem"/>
            </div>
        )}
        {!chatByRecipient.length && isFetched && (
            <div className="general">
              <p className="no-content">{noContentText}</p>
            </div>
        )}
        {chatByRecipient.length > 0 && (
            chatByRecipient.map((msg) => {
              const {
                authorHash,
                authorName,
                authorCompanyTitle,
                content_plaintext,
                moment,
                new: isNew,
                userAvatar,
              } = msg

              return (
                  <Message
                      data-id={moment}
                      key={moment}
                      isNew={isNew}
                      isMy={authorHash === profileCompanyHash}
                      avatar={userAvatar}
                  >
                    <div className="about">
                        <div className="name">{`${authorName} ${authorCompanyTitle}`}</div>
                        {userAvatar && <div className="avatar"/>}
                    </div>
                    <div className="content">{content_plaintext}</div>
                    <div className="date">{parseWholeDate(moment, dic)}</div>
                  </Message>
              )
            })
        )}
        <SendNewMessage
            periodic={periodic}
            recipient={recipient}
            postProcess={postProcess}
            recipients={recipients}
            onChoiceRecipient={setRecipient}

        />
      </Wrapper>
      </>
  )
}
