import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LoadMore from '../../assets/img/loadMore.png'

const Wrapper = styled.button`
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => p.theme.links};
  cursor: pointer;
`
const Circle = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 1rem;
  background-image: ${`url(${LoadMore})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ${(p) =>
    p.isRotate &&
    `
animation: spin 2s infinite linear;
   @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  `}
`
export default ({
  title = 'load more',
  onClick = () => {},
  style = null,
  id = 'loadMoreBtn',
}) => {
  const [isRotate, setIsRotate] = useState(false)
  const startRotate = () => {
    onClick()
    setIsRotate(true)
  }
  useEffect(() => {
    if (isRotate) {
      const timer = setTimeout(() => setIsRotate(false), 2000, true)
      return () => clearTimeout(timer)
    }
  }, [isRotate])
  return (
    <Wrapper onClick={startRotate} style={style} id={id}>
      <Circle isRotate={isRotate} />
      {title}
    </Wrapper>
  )
}
