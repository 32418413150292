import { useMemo, useState } from 'react'
import { CAR_FIELDS, truckTypes } from '../../../lib/dataTemplateForForms'
import { dataPreValidate } from "../../../lib/helpers/addNewCar"
import {useSelector} from "react-redux";

const renderRequiredInputChain = [
    {
        name:"make",
        action: {
            type: 'SAGA_GET_CAR_BRANDS',
            loader: 'selectList',
        },
    },
    { name:"model" },
    { name:"number" },
    {
        name:"carbodytype",
        action: {
            type: 'SAGA_GET_CAR_BODY_TYPES', // getCartype
            loader: 'selectList',
        },
    },
    { name:"year_of_manufact" },
    { name:"width" },
    { name:"height" },
    { name:"length" },
    { name:"total_weight" },
    { name: 'weight' },
    { name:"vin" },
];

const renderExtraInputChain = [
    {
        name:"cartype",
        action: {
            type: 'SAGA_GET_CARTYPES',
            loader: 'selectList',
        },
        styles: { marginBottom: '1rem' },// marginBottom: "3.5rem",
    },
    { name:"chargetype",
      action: {
            type: 'SAGA_GET_CHARGE_TYPES',
      },
    },
    { name:"ferryproperty",
      action: {
            type: 'SAGA_GET_FERRY_PROPERTIES',
      },
    },
];

const renderAdditionalInputChain = [
    {
        name:"vehicleclass",
        action: {
            type: 'SAGA_GET_VEHICLE_CLASSES',
            loader: 'selectList',
        },
    },
    { name: 'carbase' },
    { name: 'cellnum' },
    {
        name: 'axes',
        styles: {
            marginBottom: "3.5rem"
        }
    },
];

export const useAddNewCar = () => {

    //error-item example  - number: { bool: 0, msg: dic.form_err_required }
    const [ errors, setErrors ] = useState({})
    const dic = useSelector((s) => s.defaultLang.serverDictionary)
    const formData = useSelector((s) => s.newTS.car)
    const isTractor = truckTypes?.fromStore[formData?.carstyle]  === 'tractor'
    const isTruck = truckTypes?.fromStore[formData?.carstyle]  === 'truck';
    const isCoupling = truckTypes?.fromStore[formData?.carstyle]  === 'coupling';

    const requiredFields = useMemo(()=>{
       return renderRequiredInputChain.reduce((acc, render)=>{
            const {name} = render
            const data = CAR_FIELDS[name]

           // aad extra filed loadcapacity before width
           if((isTruck || isCoupling) && name ==="width" && data){
               const extraField = CAR_FIELDS["loadcapacity"];
              return [...acc, extraField, { ...render, ...data }]
           }

            if(data){
                return [...acc, { ...render, ...data }]
            }
            return acc
        }, [])
    },[isTruck, isCoupling])

    const extraFields = useMemo(()=>{
        return renderExtraInputChain.reduce((acc, render)=>{
            const {name} = render
            const data = CAR_FIELDS[name]

            if(data){
                return [...acc, { ...render, ...data }]
            }
            return acc
        }, [])
    },[])

    const additionalFields = useMemo(()=>{

        return renderAdditionalInputChain.reduce((acc, render)=>{
            const {name} = render
            //the array unit depends on the type of currentCar,
            // filter removes / adds the same fields in render groups
            const data = isTractor
                ?  CAR_FIELDS[name]
                :  Object.keys(CAR_FIELDS[name]).reduce((acc, key)=>{
                    if(key === 'volume'){
                        return acc;
                    }
                    return {
                        ...acc,
                        [key]:CAR_FIELDS[name][key]
                    }
                })

            if(data){
                return [...acc, { ...render, ...data }]
            }
            return acc
        }, [])
    },[isTractor])

    const onSetError = (NAME, msg = dic.form_err_required ) => {
        setErrors({
            ...errors,
            [NAME]: {
                ...errors[NAME],
                bool: 1,
                msg,
            }
        })

        const copy = { ...errors}
         delete copy[NAME]

        setTimeout(
            () => setErrors(copy),
            3000
        )
    }

    const onSubmitForm = (data, actionSubmit) => {
       return dataPreValidate(
            {
                onSetError,
                dataModel : CAR_FIELDS,
            },
            data,
            actionSubmit
        )
    }

    return {
        truckTypes,
        requiredFields,
        extraFields,
        additionalFields,
        errors,
        onSubmitForm,
    }

}