import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: ${(p) => p.flag};
`
export default ({ children }) => {
  const [flag, setFlag] = useState('block')
  const [position, setPosition] = useState(0)

  const calculation = useCallback(() => {
    setPosition(window.pageYOffset)
    if (position < window.pageYOffset && window.pageYOffset > 60) {
      setFlag('none')
    } else {
      setFlag('block')
    }
  }, [position])

  useEffect(() => {
    window.addEventListener('scroll', calculation)
    return () => window.removeEventListener('scroll', calculation)
  }, [calculation])

  return <Wrapper flag={flag}>{children}</Wrapper>
}
