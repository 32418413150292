import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Icon from '../../components/Icon'
import useOutsideClick from '../../hooks/useOutsideClick'
import SmallLoader from '../../components/layouts/SmallLoader'
import { strToUpperCase } from '../../lib/helpers/forStyling'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  .this-input {
    width: 100%;
    height: 4.6rem;
    padding: 0 4.5rem;
    background-color: ${(p) => p.theme.selected};
    font-size: ${(p) => p.theme.h4};
    color: ${(p) => (p.error ? p.theme.error : p.theme.regular_txt)};
    ${(p) => (p.error ? `border: 0.1rem solid ${p.theme.error};` : null)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: ${(p) => (p.disabled ? 0.5 : 1)};
    :focus {
      border: 0.1rem solid ${(p) => p.theme.inputBorder};
    }
  }
`
const Label = styled.p`
  width: 100%;
  margin-bottom: 0.5rem;
  padding-left: 0.4rem;
  text-align: left;
  color: ${(p) => (p.error ? p.theme.error : p.theme.additional)};
  font-size: ${(p) => p.theme.h5};
  display: flex;
  opacity: ${(p) => (p.disabled ? 0.3 : 0.8)}; ;
`
const List = styled.div`
  z-index: 5;
  position: absolute;
  width: 99%;
  max-height: 30rem;
  top: 100%;
  left: 0.5%;
  padding: 1.5rem;
  background-color: ${(p) => p.theme.opposite};
  overflow: auto;
  box-shadow: ${(p) => p.theme.listShadow};
`
const ListItem = styled.p`
  padding: 1rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`
const ErrorMessage = styled.span`
  padding-left: 0.5rem;
  color: ${(p) => p.theme.error};
`
const ArrowWrp = styled.span`
  position: absolute;
  top: ${(p) => (p.flag ? 'calc(55% - 0.7rem)' : '27%')};
  right: 1.5rem;
  padding: 0.7rem 0.5rem;
  transform: ${(p) => (p.isrotate ? `rotate(-90deg)` : `rotate(90deg)`)};
  transition: 0.2s ease;
  cursor: pointer;
`
const IconWrp = styled.span`
  position: absolute;
  top: 54%;
  left: 1.5rem;
  cursor: pointer;
`
const Input = styled.input`
  width: 100%;
  height: 4.6rem;
  padding: ${(p) => (p.isHaveIcon ? '0 4.5rem' : '0 4.5rem 0 1.5rem ')};
  background-color: ${(p) => p.theme.selected};
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => (p.error ? p.theme.error : p.theme.regular_txt)};
  caret-color: transparent;

  ${(p) => p.inputColorAccent && `color:${p.theme.error}`}
  ${(p) => (p.error ? `border: 0.1rem solid ${p.theme.error};` : null)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
  :focus {
    border: 0.1rem solid ${(p) => p.theme.inputBorder};
  }
`

export default ({
  value = '',
  name = 'driver',
  id = '',
  parametr = '',
  label = 'Select with search',
  inputColorAccent = false,
  error = 0,
  message = 'this required field',
  nothingToDisplay = 'nothing to display',
  list = [],
  action = { type: null, clear: null, payload: null, loader: null },
  fnChange = () => {},
  fnSetValue = () => {},
  fnFormValue = () => {},
  disabled = false,
  style,
}) => {
  const [isList, setShowIsList] = useState(false)
  const [isRotate, setIsRotate] = useState(0)
  const myRef = useRef(null)
  const dispatch = useDispatch()
  const isLoader = useSelector((s) =>
    action.loader ? s[action.loader].loader : s.myPark.loader
  )

  const icons = {
    driver: 'driver',
    driver2: 'driver',
    car: 'mack',
    trailer: 'trailer',
    user: 'user',
    cargo: 'hookBig',
    border:'border',
  }

  const togglerList = () => {
    if (!isList) {
      dispatch({
        type: action.type ? action.type : 'SAGA_GET_MY_PARK',
        requestType: action.payload ? action.payload : name,
        parametr: parametr,
      })
      setShowIsList(true)
      myRef.current.focus()
      setIsRotate(1)
      return
    } else {
      dispatch({ type: action.clear ? action.clear : 'CLEAR_SEARCH_LIST' })
      setIsRotate(0)
      setShowIsList(false)
    }
  }
  const showList = () => {
    dispatch({
      type: action.type ? action.type : 'SAGA_GET_MY_PARK',
      requestType: action.payload ? action.payload : name,
      parametr: parametr,
    })
    setShowIsList(true)
    setIsRotate(1)
  }
  const closeList = () => {
    if (isList)
      dispatch({ type: action.clear ? action.clear : 'CLEAR_SEARCH_LIST' })
    setShowIsList(false)
    setIsRotate(0)
  }
  const clickFromItem = (value, obj) => {
    setShowIsList(false)
    setIsRotate(0)
    fnSetValue(value)
    fnFormValue(name, obj, id)
  }
  useOutsideClick(myRef, closeList)

  return (
    <Wrapper style={style} ref={myRef} error={error} disabled={disabled}>
      <Label error={error} disabled={disabled}>
        {strToUpperCase(label)}
        {(error === 1 || error === true) && (
          <ErrorMessage>{`${message}`}</ErrorMessage>
        )}
      </Label>
      <Input
        disabled={disabled}
        data-testid={id}
        id={id}
        type="text"
        name={name}
        value={value}
        onChange={disabled ? () => {} : fnChange}
        error={error}
        onClick={disabled ? () => {} : showList}
        autoComplete="new-password"
        isHaveIcon={icons.hasOwnProperty(name)}
        inputColorAccent={inputColorAccent}
      />
      {isList && (
        <List>
          {list && list.length > 0 ? (
            list.map((el) => {
              return (
                <ListItem
                  key={el.id}
                  onClick={() => clickFromItem(el.union, el)}
                >
                  {el.union}
                </ListItem>
              )
            })
          ) : isLoader ? (
            <SmallLoader />
          ) : (
            <p>{strToUpperCase(nothingToDisplay)}</p>
          )}
        </List>
      )}
      {name && (
        <IconWrp onClick={disabled ? () => {} : togglerList}>
          <Icon name={icons[name]} type="icons" />
        </IconWrp>
      )}
      <ArrowWrp
        isrotate={isRotate}
        flag={label}
        style={{ display: disabled ? 'none' : 'block' }}
        onClick={disabled ? () => {} : togglerList}
      >
        <Icon name="arrowShevron" type="regularText" />
      </ArrowWrp>
    </Wrapper>
  )
}
