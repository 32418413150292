import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import ActionText from './ActionText'
import ActionDriver from './ActionDriver'

const Wrapper = styled.div`
  width: 100%;
`
const Tab = styled.div`
  width: fit-content;
  text-decoration: none;
  margin-bottom: 2.5rem;
  padding-bottom: 0.2rem;
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => p.theme.links};
  border-bottom: 0.1rem dotted ${(p) => p.theme.links};
  transition: 0.2s ease;
  :last-child {
    margin-bottom: 2rem;
  }
`
const Text = styled.p`
  color: ${(p) => p.theme.approver};
  font-size: ${(p) => p.theme.h4};
`
export default ({
  onAction = () => {},
  afterActionText = 'afterActionText',
  isClick = false,
  list = [],
  dic,
}) => {
  const [content, setContent] = useState(null)
  const showContent = (obj) => {
    setContent(obj)
  }
  const showKebabMenu = () => {
    setContent(null)
  }
  const Widgets = (content) => ({
    deleteRoute: (
      <ActionText
        content={content}
        onAction={onAction}
        onCancel={showKebabMenu}
        dic={dic}
      />
    ),
    changeRoute: (
      <ActionText
        content={content}
        onAction={onAction}
        onCancel={showKebabMenu}
        dic={dic}
      />
    ),
    changeTransport: (
      <ActionDriver
        content={content}
        onAction={onAction}
        onCancel={showKebabMenu}
        dic={dic}
      />
    ),
  })
  return (
    <Wrapper isClick={isClick}>
      {isClick ? (
        <Text>{afterActionText}</Text>
      ) : (
        <>
          {content
            ? Widgets(content)[content.action]
            : list &&
              list.length > 0 &&
              list.map(({ title, action }) => (
                <Tab key={title} onClick={() => showContent({ title, action })}>
                  {title}
                </Tab>
              ))}
        </>
      )}
    </Wrapper>
  )
}
