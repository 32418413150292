import { takeEvery, call, put, select } from 'redux-saga/effects'
import { getDictionary } from '../../lib/requests/auxRequests'
import { getCurrentLang, storeDictionary } from '../selectors'
import { comparisonObj, replaceObj } from '../../lib/helpers/comparisonObj'

export function* worker({ comparison }) {
  try {
    const language = yield select(getCurrentLang)
    const dictionary = yield select(storeDictionary)
    const result = yield call(getDictionary, language)
    if (result.status === 200) {
      yield put({
        type: 'SET_DICTIONARY',
        payload: replaceObj(result.data.data),
      })

      if (comparison) {
        const resultComparison = comparisonObj(dictionary, result.data.data)
        if (resultComparison && resultComparison.length > 0) {
          yield put({
            type: 'SAGA_POST_NEW_WORDS',
            data: resultComparison,
          })
        }
      }
    }
  } catch (e) {
    yield put({
      type: 'SET_API_ERROR',
      payload: true,
    })
    yield put({
      type: 'SET_BUBBLE_MESSAGE',
      payload: e.response ? e.response.data.message : e.message,
    })
  }
}

export default function* getBaseDictionary() {
  yield takeEvery('SAGA_GET_DICTIONARY', worker)
}
