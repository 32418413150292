import React from 'react'
import styled from 'styled-components'

const Border = styled.div`
  /* width: fit-content; */
  max-width: 18rem;
  padding-bottom: 0.2rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.links};
`

const Wrapper = styled.a`
  text-decoration: none;
  font-size: ${(p) => (p.small ? p.theme.h5 : p.theme.h4)};
  transition: 0.2s ease;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :link {
    color: ${(p) => p.theme.links};
  }
  :active {
    color: ${(p) => p.theme.regular_txt};
  }
  :visited {
    color: ${(p) => p.theme.mint_Visited_Links};
  }
`
export default ({
  email = 'email',
  title = 'default label for email',
  small = false,
  fitContent = false,
  style = {},
}) => {
  return (
    <Border style={style}>
      <Wrapper
        small={small}
        fitContent={fitContent}
        href={`mailto:${email}?subject=${title}`}
      >
        {email}
      </Wrapper>
    </Border>
  )
}
