import { takeEvery, put } from 'redux-saga/effects'

// import { delays } from '../../lib/constants'
// import { getCurrentLang, getToken, getDicionary } from '../selectors'

export function* worker({ requestType, parametr }) {
  // const currentLang = yield select(getCurrentLang)
  // const token = yield select(getToken)
  // const dic = yield select(getDicionary)

  // yield delay(1000)

  yield put({ type: 'SET_NEWROUTE_DEFAULT_DATA_FLAG', payload: true })
}

export default function* getNewRouteDefaultData() {
  yield takeEvery('SAGA_GET_NEWROUTE_DEFAULT_DATA', worker)
}
