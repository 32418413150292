import { takeEvery, put } from 'redux-saga/effects'

export function* worker({ selector, prewData }) {
  switch (selector) {
    case 'typeAuto':
      yield put({
        type: 'SAGA_GET_CARTYPE',
        prewData,
      })
      break
    case 'typeLoad':
      yield put({
        type: 'SAGA_GET_CHARGETYPE',
        prewData,
      })
      break

    case 'ferryProperty':
      yield put({
        type: 'SAGA_GET_FERRYPROPERTY',
        prewData,
      })
      break
    default:
      break
  }
}

export default function* getNewRouteProperties() {
  yield takeEvery('SAGA_GET_CAR_ALL_PROPERTIES', worker)
}
