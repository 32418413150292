import React, {useEffect, useMemo} from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Free from '../widgetsForList/Free1'
import Approved from '../widgetsForList/Approved1'
import Complited from '../widgetsForList/Complited1'
import AwaitApprovalTransport from '../widgetsForList/AwaitApprovalTransport1'
import AwaitApprovalAction from '../widgetsForList/EnRoute1'
import EnRoute from '../widgetsForList/AwaitApprovalAction1'
import Icon from '../../../Icon'
import SmallLoader from '../../../layouts/SmallLoader'
import LoadMoreBtn from '../../../buttons/LoadMoreBtn'
import YellowMarker from '../../../YellowMarker'
import FilterForTab from '../../../../containers/other/FilterForTab'
import {filterRoutes} from "../../../../lib/helpers/forRouteFilter";

const Wrapper = styled.div``
const Tap = styled.div`
  width: 100%;
  padding: 2rem 2.8rem 2.5rem;
  ${(p) => !p.isopen && `border-bottom: 0.1rem solid ${p.theme.lines}`};
  background-color: ${(p) =>
    p.isopen ? p.theme.selected : p.isAttension ? p.theme.basic : 'unset'};
  font-size: ${(p) => p.theme.h4};
  font-weight: 500;
`
const TapHeaderWrp = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`
const TabFilterWrp = styled.div`
  width: 100%;
  margin-top: 2.4rem;
`
const TabFilter = styled.div`
  width: fit-content;
  font-weight: 400;
  margin-bottom: 1rem;
  font-size: ${(p) => p.theme.h5};
  color: ${(p) => p.theme.links};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Label = styled.span`
  ${(p) => !p.isopen && `margin-right: auto;`};
  padding-right: 2rem;
  font-size: ${(p) => p.theme.h4};
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Quantity = styled.span`
  font-size: ${(p) => p.theme.h4};
  font-weight: 700;
  ${(p) => p.marginAuto && `margin-right: auto;`};
`
const ArrowWrp = styled.span`
  margin-right: 2rem;
  margin-left: ${(p) => (p.isopen ? `0.4rem` : `0`)};
  transform: ${(p) => (p.isopen ? `rotate(-90deg)` : `rotate(90deg)`)};
`
const Content = styled.div`
  padding: 0 0 1.5rem;
  background-color: ${(p) => p.theme.selected};
`
const defaultObj = {
  title: 'vertical tab',
  id: '0',
  stati: ['0'],
  content: [{ id: '11' }],
}
export default ({ obj = defaultObj, typeUpdate = '', location }) => {
  const dispatch = useDispatch()
  const dictionary = useSelector((s) => s.defaultLang.serverDictionary)
  const totalCompleteRoutes = useSelector((s) => s.route.totalCompleteRoutes)
  const perPage = useSelector((s) => s.route.perPage)
  const currentPage = useSelector((s) => s.route.currentPage)
  const lastPosition = useSelector((s) => s.route.lastPosition)
  const defaultIsOpen = useSelector((s) => s.route.isOpen)
  const isOpen = useSelector((s) => s.route.isOpen[location])
  const badRequest = useSelector((s) => s.route.routeTabBadRequest)
  const currentFilterFor = useSelector((s) => s.filterForTab.currentId)
  const selectedFilters = useSelector((s) => s.filterForTab.selectedArgs)
  const data = useSelector((s) => s.route.widgetList);

  const widgetList = useMemo(()=>{
    return currentFilterFor === obj.id
        ? filterRoutes(data, selectedFilters)
        : data;

  },[currentFilterFor, selectedFilters,  data])

  const toggler = () => {
    // return
    if (obj.id !== isOpen) {
      dispatch({ type: 'CLEAR_WIDGET_ROUTES' })
    }
    if (obj.id === isOpen) {
      dispatch({ type: 'CLEAR_FLAG_FOR_LIST_ROUTES_PAGE' })
      // dispatch({ type: 'CLEAR_ARGS_FOR_FILTER' })
    }
    if (Number(obj.id) === 42) {
      dispatch({
        type: 'SET_OPEN_WIDGET_ROUTES',
        payload:
          isOpen === obj.id
            ? { all: null, my: null }
            : { ...defaultIsOpen, [location]: obj.id },
        location,
      })

      if (isOpen !== null) {
        dispatch({ type: typeUpdate })
        dispatch({ type: 'REMEMBER_ROUTE_WIDGET', payload: '' })
      }
      return
    }
    const result =
      isOpen === obj.id
        ? { all: null, my: null }
        : obj.content.length
        ? { ...defaultIsOpen, [location]: obj.id }
        : { all: null, my: null }

    dispatch({ type: 'SET_OPEN_WIDGET_ROUTES', payload: result, location })
    // dispatch({ type: 'CLEAR_WIDGET_ROUTES' })
    if (isOpen !== null) {
      dispatch({ type: typeUpdate })
      dispatch({ type: 'REMEMBER_ROUTE_WIDGET', payload: '' })
    }
  }

  const loadMoreWidgets = () => {
    //TODO repeat code
    dispatch({
      type: 'SAGA_GET_COMPLETE_ROUTES',
      data: { page: currentPage + 1, perPage },
    })
  }

  const onRemoveFilter = (obj) => {
    dispatch({ type: 'REMOVE_ARG_FOR_FILTER', payload: obj })
  }

  const WidgetType = (id, data, dictionary, index) => ({
    23: <Free key={id} obj={data} dic={dictionary} memory={lastPosition} />, // свободные
    25: (
      <AwaitApprovalTransport
        key={id}
        obj={data}
        dic={dictionary}
        memory={lastPosition}
      />
    ),
    24: <Approved key={id} obj={data} dic={dictionary} memory={lastPosition} />, // подписатся на маршрут
    26: <EnRoute key={id} obj={data} dic={dictionary} memory={lastPosition} />, // в пути
    29: (
      <AwaitApprovalAction
        key={id}
        obj={data}
        dic={dictionary}
        memory={lastPosition}
      />
    ), // разгрузка
    27: (
      <AwaitApprovalAction
        key={id}
        obj={data}
        dic={dictionary}
        memory={lastPosition}
      />
    ), // загрузка
    42: <Complited key={id} obj={data} dic={dictionary} idx={index} />, // архив или завершенные ?
  })
  useEffect(() => {
    if (isOpen === obj.id && Number(obj.id) === 42) {
      //TODO repeat code
      dispatch({
        type: 'SAGA_GET_COMPLETE_ROUTES',
        data: { page: currentPage + 1, perPage },
      })
    } else if (isOpen === obj.id && Number(obj.id) !== 42) {
      const result = obj.content.map((item) => item.periodic).toString()
      if (result) {
        dispatch({
          type: 'SAGA_GET_ROUTE_DETAILS',
          routeList: result,
          typeAction: 'base',
        })
      } else {
        dispatch({
          type: 'SET_OPEN_WIDGET_ROUTES',
          payload: { ...defaultIsOpen, [location]: null },
          location,
        })
      }
    }
  }, [isOpen]) //eslint-disable-line

  return (
    <Wrapper>
      <Tap isopen={isOpen === obj.id} isAttension={Number(obj.id) === 33}>
        <TapHeaderWrp onClick={toggler}>
          <ArrowWrp isopen={isOpen === obj.id}>
            <Icon name="arrowShevron" type="regularText" />
          </ArrowWrp>
          <Label isopen={isOpen === obj.id}>
            {obj.title} {obj.needAttention > 0 && <YellowMarker />}
          </Label>
          {Boolean(isOpen === obj.id) && (
            <Quantity marginAuto>
              {Number(obj.id) !== 42
                ? currentFilterFor === obj.id && selectedFilters.length > 0
                  ? widgetList.length
                  : obj.content.length
                : totalCompleteRoutes}
            </Quantity>
          )}
          {
            <Quantity>
              {Boolean(isOpen !== obj.id) && Number(obj.id) !== 42 ? (
                obj.isSort ? (
                  obj.content.length
                ) : (
                  <SmallLoader
                    id="contentLengthLoader"
                    style={{ marginTop: '0.5rem' }}
                  />
                )
              ) : obj.id !== 42  && (
                <FilterForTab
                  id={obj.id}
                  currentFilter={currentFilterFor}
                  where={{ typeUpdate, location }}
                  content={widgetList}
                  badRequest={badRequest}
                />
              )}
            </Quantity>
          }
        </TapHeaderWrp>
        {isOpen === obj.id &&
          currentFilterFor === obj.id &&
          selectedFilters.length > 0 && (
            <TabFilterWrp>
              {selectedFilters.map((el) => (
                <TabFilter
                  onClick={() => onRemoveFilter(el)}
                  key={`${el.filterId}`}
                >
                  <p>{el.title}</p>
                  <Icon
                    name="close"
                    type="additionalInfo"
                    width="1.2rem"
                    height="1.2rem"
                    style={{ marginLeft: '1rem' }}
                  />
                </TabFilter>
              ))}
            </TabFilterWrp>
          )}
      </Tap>
      {Boolean(isOpen === obj.id) && (
        <Content>
          {widgetList.length > 0 ? (
            widgetList.map(
              (item, index) =>
                WidgetType(item.id, item, dictionary, index + 1)[obj.id]
            )
          ) : badRequest ? null : (
            <SmallLoader id="smallLoader" />
          )}
          {Number(obj.id) === 42 && totalCompleteRoutes > widgetList.length && (
            <LoadMoreBtn
              title={`${dictionary.load_more} ${perPage}`}
              style={{ marginTop: '3.2rem', marginBottom: '4rem' }}
              onClick={loadMoreWidgets}
            />
          )}
        </Content>
      )}
    </Wrapper>
  )
}
