import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.a`
  width: fit-content;
  display: block;
  text-decoration: none;
  padding-bottom: 0.2rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.links};
  font-size: ${(p) => (p.small ? p.theme.h5 : p.theme.h4)};
  transition: 0.2s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :link {
    color: ${(p) => p.theme.links};
    border-bottom: 0.1rem solid ${(p) => p.theme.links};
  }
  :active {
    color: ${(p) => p.theme.regular_txt};
    border-bottom: 0.1rem solid ${(p) => p.theme.regular_txt};
  }
  :visited {
    color: ${(p) => p.theme.mint_Visited_Links};
    border-bottom: 0.1rem solid ${(p) => p.theme.mint_Visited_Links};
  }
`
export default ({ cell = '', small = false, style = {} }) => {
  return (
    cell && (
      <Wrapper small={small} href={`tel:${cell}`} style={style}>
        {cell}
      </Wrapper>
    )
  )
}
