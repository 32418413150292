import Type from '../types'

const initialState = {
  cardTender: null,
  currentBets: [],
  allBids: [],
  widgetList: [],
  completeWidgetList: [],
  totalCompleteTenders: '',
  tabContent: [],
  flagList: {},
  dictionary: null,
  isOpen: { my: null, all: null },
  lastPosition: '',
  closeBetWindow: false,
  perPage: 10,
  currentPage: 0,
  badRequest: false,
  tenderTabBadRequest: false,
  flagGetSteps: false,
  bidSteps: [],
  children: [],
  childrenLoader: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_SINGLE_TENDER:
      return {
        ...state,
        cardTender: action.payload === null ? null : action.payload.route,
        dictionary:
          action.payload === null ? state.dictionary : action.payload.dic,
        flagList: action.flagForBtn ? action.flagForBtn : {},
      }
    case Type.SET_WIDGET_TENDERS:
      return {
        ...state,
        widgetList: action.payload.routes,
        dictionary: action.payload.dic,
        flagList: action.flagForBtn,
        tabContent: action.tenderList,
      }
    case Type.SET_WIDGET_TENDERS_FOR_COMPLETE:
      return {
        ...state,
        widgetList: [...state.widgetList, ...action.payload.routes],
        dictionary: action.payload.dic,
      }
    case Type.CLEAR_WIDGET_TENDERS:
      return {
        ...state,
        widgetList: [],
        tabContent: [],
        dictionary: null,
        completeWidgetList: [],
        flagList: {},
        totalCompleteRoutes: '',
        perPage: 10,
        currentPage: 0,
      }
    case Type.SET_OPEN_WIDGET_TENDERS:
      return {
        ...state,
        isOpen: action.payload,
      }

    case Type.SET_TOTAL_COMPLETE_TENDERS:
      return {
        ...state,
        totalCompleteTenders: action.payload,
      }
    case Type.SET_PAGE_COMPLETE_TENDERS:
      return {
        ...state,
        currentPage: action.payload,
      }
    case Type.SET_BETS:
      return {
        ...state,
        currentBets: [...action.payload],
        allBids: [...action.payload],
      }

    case Type.REMEMBER_TENDER_WIDGET:
      return {
        ...state,
        lastPosition: action.payload,
      }
    case Type.CLOSE_BET_WINDOW:
      return {
        ...state,
        closeBetWindow: action.payload,
      }
    case Type.STOP_LOADER_FOR_SINGLE_TENDER_PAGE:
      return {
        ...state,
        badRequest: true,
      }
    case Type.CLEAR_FLAG_FOR_SINGLE_TENDER_PAGE:
      return {
        ...state,
        badRequest: false,
      }
    case Type.STOP_LOADER_FOR_LIST_TENDERS_PAGE:
      return {
        ...state,
        tenderTabBadRequest: true,
      }
    case Type.CLEAR_FLAG_FOR_LIST_TENDERS_PAGE:
      return {
        ...state,
        tenderTabBadRequest: false,
      }
    case Type.SET_FLAG_FOR_BID_STEPS:
      return {
        ...state,
        flagGetSteps: action.payload,
      }
    case Type.SET_BID_STEPS:
      return {
        ...state,
        bidSteps: action.payload,
      }
    case Type.SET_TENDER_CHILDREN:
      return {
        ...state,
        children: action.payload,
      }
    case Type.SET_TENDER_CHILDREN_LOADER:
      return {
        ...state,
        childrenLoader: action.payload,
      }


    default:
      return state
  }
}
