import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 13.5rem;
  height: 4.5rem;
  background-color: ${(p) => p.theme.opposite};
  border: 0.1rem solid ${(p) => p.theme.stroke};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`
const Item = styled.div`
  width: 33%;
  height: 60%;
  border-left: ${(p) =>
    p.border ? `0.1rem solid ${p.theme.stroke}` : 'unset'};
  border-right: ${(p) =>
    p.border ? `0.1rem solid ${p.theme.stroke}` : 'unset'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.theme.h3};
  transition: 0.2s ease;
  color: ${(p) => p.theme.regular_txt};
  ${(p) =>
    p.cursor &&
    `transform: scale(1.2); cursor:pointer;
  :hover{
      color:${p.theme.basic_txt};
  }
  :active {
    transform: scale(0.97);
  }`};
`
const Label = styled.p`
  width: 100%;
  margin-bottom: 0.5rem;
  padding-left: 0.4rem;
  text-align: left;
  color: ${(p) => (p.error ? p.theme.error : p.theme.additional)};
  font-size: ${(p) => p.theme.h5};
  display: flex;
  opacity: 0.8;
`

export default ({
  max = 1000,
  label = '',
  value = 0,
  min = 0,
  onUpdate = () => {},
  style = {},
}) => {
  return (
    <div style={style}>
      {label && <Label>{label}</Label>}
      <Wrapper>
        <Item
          cursor={1}
          onClick={value > min ? () => onUpdate('minus') : null}
          style={{ paddingBottom: '0.45rem' }}
        >
          {value > min ? '-' : null}
        </Item>
        <Item border>{value}</Item>
        <Item
          cursor={1}
          onClick={value < max ? () => onUpdate('plus') : null}
          style={{ paddingBottom: '0.4rem' }}
        >
          {value < max ? '+' : null}
        </Item>
      </Wrapper>
    </div>
  )
}
