import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Icon from '../../components/Icon'

const Wrapper = styled.button`
  z-index: 5;
  position: fixed;
  width: 4.2rem;
  height: 4.2rem;
  padding: 0;
  bottom: 4.5rem;
  left: 1.5rem;
  display: ${(p) => p.isShow};
  border-radius: 50%;
  transition: 0.2s ease;
  opacity: ${(p) => p.opacity};
  :active {
    transform: scale(0.95);
    opacity: 1;
  }
`

export default () => {
  const [value, setValue] = useState(0.6)
  const [isShow, setIsShow] = useState('none')
  const onClick = () => {
    setValue(1)
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset >= 1000) {
        setIsShow('block')
      } else {
        setIsShow('none')
        setValue(0.6)
      }
    })
    return () => window.removeEventListener('scroll', () => {})
  }, [])
  return (
    <Wrapper onClick={() => onClick()} opacity={value} isShow={isShow}>
      <Icon name="scrollToTop" type="links" />
    </Wrapper>
  )
}
