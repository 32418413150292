import React from 'react'
import ThinBtn from '../../buttons/ThinBtn'

export default ({
  className = '',
  style = {},
  dic = {},
  isMyBet = null,
  fnCancel = () => {},
  fnEdit = () => {},
  fnReturn = () => {},
}) => {
  return (
    isMyBet && (
      <div className={className} style={style}>
        {isMyBet.cancelled ? (
          <ThinBtn
            type="return"
            label={dic.return}
            firstLetter
            onClick={fnReturn}
          />
        ) : (
          <>
            <ThinBtn
              type="delete"
              label={dic.delete}
              firstLetter
              onClick={fnCancel}
            />
            <ThinBtn
              type="edit"
              label={dic.bid_edit}
              firstLetter
              onClick={fnEdit}
            />
          </>
        )}
      </div>
    )
  )
}
