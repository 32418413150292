import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Image from '../../assets/img/notFound.png'
import DotterLightLink from '../links/DottedLightLink'
import { bitrixCallBackLink } from '../../lib/constants'

const Wrapper = styled.div`
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: ${(p) => p.theme.opposite};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Title = styled.p`
  margin-bottom: 2.3rem;
  width: 80%;
  font-size: ${(p) => p.theme.h2};
  text-align: center;
`
const ImageWrp = styled.div`
  width: 90%;
  max-width: 80rem;
  margin-bottom: 2.3rem;
`
const Img = styled.img`
  width: 100%;
  height: auto;
`
const Text = styled.span`
  width: 80%;
  color: ${(p) => p.theme.additional};
  text-align: center;
`

export default () => {
  const dic = useSelector((s) => s.defaultLang.langObj)
  return (
    <Wrapper>
      <Title>
        {dic.notFound
          ? dic.notFound.title
          : 'Unfortunately this page does not exist :('}
      </Title>
      <ImageWrp>
        <Img src={Image} />
      </ImageWrp>
      <Text>
        {dic.notFound
          ? dic.notFound.text
          : 'To clarify the details, you can contact the support service'}
        <DotterLightLink
          title={dic.notFound ? dic.notFound.link : 'Request a call back'}
          rout={bitrixCallBackLink}
          style={{ marginLeft: '1rem' }}
        />
      </Text>
    </Wrapper>
  )
}
