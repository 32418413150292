import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import BaseBtn from '../../../components/buttons/BaseBtn'
import ModalPortal from '../../../components/layouts/ModalPortal'
import Icon from '../../../components/Icon'
import { strToUpperCase } from '../../../lib/helpers/forStyling'

const Wrapper = styled.div`
  width: 98%;
  min-width: 32rem;
  max-width: 33rem;
  margin-bottom: 1rem;
  padding: ${(p) => p.theme.formSidePad};
  padding-top: 2.6rem;
  background-color: ${(p) => p.theme.opposite};
  border-radius: ${(p) => p.theme.PopUpRadius};
`
const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .headerContent {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .title {
    margin-left: 1rem;
    font-size: ${(p) => p.theme.h2};
    font-weight: 600;
  }
`
const Info = styled.p`
  margin-top: 1rem;
  font-size: ${(p) => p.theme.h5};
  color: ${(p) => p.theme.additional};
`
const Content = styled.div`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-height: 70vh;
  overflow-x: auto;
`
export default ({
  fnSubmit = () => {},
  fnClose = () => {},
  periodic = '',
  type = 'driver',
  localId = '',
  children,
  withoutPortal = false,
  style = {},
}) => {
  const dic = useSelector((s) => s.defaultLang.serverDictionary)

  const titles = {
    driver: dic.driver_new,
    car: dic.car_new,
    trailer: dic.trailer_new,
  }
  const icons = {
    driver: (
      <Icon
        name="driver"
        type="regularText"
        width="2rem"
        height="2rem"
        style={{ marginBottom: '0.3rem' }}
      />
    ),
    car: (
      <Icon
        name="mack"
        type="regularText"
        width="2rem"
        height="2rem"
        style={{ marginBottom: '0.3rem' }}
      />
    ),
    trailer: (
      <Icon
        name="trailer"
        type="regularText"
        width="2.3rem"
        height="2.3rem"
        style={{ marginTop: '0.5rem' }}
      />
    ),
  }

  const body = () => (
    <Wrapper style={style}>
      <Header>
        <div className="headerContent">
          {icons[type]}

          <p className="title">{strToUpperCase(titles[type])}</p>
        </div>
        <Icon
          name="close"
          type="regularText"
          onClick={() => fnClose(false)}
          id={`${localId}Close` || `${type}4`}
          style={{ margin: '-1.6rem 0 auto' }}
        />
      </Header>
      {periodic && <Info>{`${strToUpperCase(dic.route)} № ${periodic}`}</Info>}
      <Content>{children}</Content>
      <BaseBtn
        fullSize
        label={dic.btn_add}
        fnClick={fnSubmit}
        localId={`${localId}Save` || `${type}2`}
        style={{ marginBottom: '2rem', marginTop: '1rem' }}
      />
    </Wrapper>
  )

  return withoutPortal ? body() : <ModalPortal>{body()}</ModalPortal>
}
