import React from 'react'

import {
  Title,
  Info,
  CargoTitle,
  Scale2RouteParts,
  PeriodicWrp,
} from '../../../smallPartsForInterface/SmallPartsWidgets'
import Icon from '../../../Icon'
import {
  parseCargoInfo,
  KmWithoutNulls,
  parseExtraCarOption,
} from '../../../../lib/helpers/forRoutes'

export default ({ obj, dic, isShowTitle = false }) => {
  const { distance, routeparts, periodic, client_relation } = obj

  return (
    obj &&
    dic && (
      <>
        <div
          className="flexWrp"
          style={{
            marginBottom: '1.6rem',
            width: obj.context && obj.context.title ? '100%' : '80%',
          }}
        >
          {Boolean(distance) && distance > 1 && (
            <Info head>{`${KmWithoutNulls(distance)} ${dic.km}`}</Info>
          )}
          {routeparts && (
            <Info head>{`${dic.points} ${routeparts.length}`}</Info>
          )}
          <div className="flexWrp" style={{ maxWidth: '45%' }}>
            <Info right>{`${
              obj.context && obj.context.title ? obj.context.title : ''
            }`}</Info>
            {obj.context && obj.context.iconTitle && (
              <Icon
                name={obj.context.iconTitle}
                type="additionalInfo"
                width="1.6rem"
                style={{ marginTop: '0.7rem', marginLeft: '0.5rem' }}
              />
            )}
          </div>
        </div>
        <div style={{ marginBottom: '0.5rem' }}>
          {client_relation && isShowTitle && (
            <Title isMessage={false} style={{ marginBottom: '0.8rem' }}>
              {client_relation.company_relation.title_ru}
            </Title>
          )}
          <Info className="flexWrp">
            <Icon name="hook" type="focused" />
            {routeparts && (
              <CargoTitle>{parseCargoInfo(routeparts)}</CargoTitle>
            )}
          </Info>
          <Info style={{ paddingLeft: '1.35rem' }}>
            {parseExtraCarOption(obj)}
          </Info>
        </div>
        {routeparts && (
          <Scale2RouteParts arr={routeparts} dic={dic} status="free" />
        )}
        <PeriodicWrp right>{`№ ${periodic}`}</PeriodicWrp>
      </>
    )
  )
}
