import React, { useState, useRef, useEffect } from 'react'
import {
  Wrapper,
  Title,
  Info,
  Price,
  Scale2RouteParts,
  DriversInfo,
  Action,
  ConfirmOfAction,
  PeriodicWrp,
} from '../../../smallPartsForInterface/SmallPartsWidgets'
import BaseBtn from '../../../buttons/BaseBtn'
import YellowMarker from '../../../YellowMarker'
import {
  NumberWithoutNulls,
  KmWithoutNulls,
} from '../../../../lib/helpers/forRoutes'
import { currencyIcons } from '../../../../lib/constants'
import { strToUpperCase } from '../../../../lib/helpers/forStyling'
import useRouter from '../../../../hooks/useRouter'

import { useDispatch } from 'react-redux'

export default ({ obj, dic, memory = '' }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const myref = useRef(null)
  const [isBtn, setIsBtn] = useState(true)
  const [aboutDrivers, setAboutDrivers] = useState(null)

  const onBtnAction = () => {
    setIsBtn(false)
    dispatch({ type: 'SAGA_RATIFY_OPERATOR', route: obj.periodic })
  }

  const widgetClick = () => {
    dispatch({ type: 'REMEMBER_ROUTE_WIDGET', payload: obj.periodic })
    router.push(`/route/${obj.periodic}`)
  }

  const executeScroll = () =>
    myref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })

  useEffect(() => {
    if (memory === obj.periodic) {
      executeScroll()
    }
  }, [memory]) //eslint-disable-line
  useEffect(() => {
    if (obj) {
      setAboutDrivers(
        obj.routeresponse_relation.find((item) => item.id === obj.routeresponse)
      )
    }
  }, [obj])
  return (
    obj && (
      <span ref={myref}>
        <Wrapper>
          <span onClick={widgetClick}>
            <div className="flexWrp" style={{ marginBottom: '0.5rem' }}>
              {Boolean(obj.budget) && obj.budget > 1 && (
                <Price>{`${
                  obj.defaultcurrency
                    ? currencyIcons[obj.defaultcurrency]
                    : currencyIcons.uah
                } ${NumberWithoutNulls(obj.budget)}`}</Price>
              )}
              {Boolean(obj.distance) && obj.distance > 1 && (
                <Info head>{`${KmWithoutNulls(obj.distance)} ${dic.km}`}</Info>
              )}
              {obj.routeparts && (
                <Info head>{`${dic.points} ${obj.routeparts.length}`}</Info>
              )}
            </div>
            {aboutDrivers && <DriversInfo obj={aboutDrivers} />}
            <div className="flexWrp">
              {obj.operator_relation && (
                <Title isMessage={false}>
                  {obj.operator_relation.company_relation.title_ru}
                </Title>
              )}
              {obj.needAttention && <YellowMarker />}
            </div>
            {obj.routeparts && (
              <Scale2RouteParts arr={obj.routeparts} dic={dic} status="free" />
            )}
            <PeriodicWrp
              style={{ marginBottom: '1.2rem' }}
              right
            >{`№ ${obj.periodic}`}</PeriodicWrp>
          </span>
          {isBtn ? (
            <Action>
              <BaseBtn fullSize label={dic.msg_34} fnClick={onBtnAction} />
            </Action>
          ) : (
            <ConfirmOfAction
              title={`${strToUpperCase(dic.msg_1)}!`}
              text={strToUpperCase(dic.msg_2)}
            />
          )}
        </Wrapper>
      </span>
    )
  )
}
