import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import SmartParkSelect from './SmartParkSelect'
import TestInput from './SelectInput'
import BaseBtn from '../../components/buttons/BaseBtn'

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem 1.6rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
const Text = styled.p`
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: ${(p) => p.theme.approver};
  font-size: ${(p) => p.theme.h3};
`
const styles = {
  height: '25rem',
}
const defaultData = {
  car: { union: '' },
  driver: { union: '' },
  driver2: { union: '' },
  trailer: { union: '' },
}
const defaultErrors = {
  car: { bool: 0, msg: 'Обязательное поле' },
  driver: { bool: 0, msg: 'Обязательное поле' },
  driver2: { bool: 0, msg: 'совпадает с водителем 1' },
}
const dic = {
  notFoundPark: 'нет такого',
  driver: 'водиль',
  car: 'машинка',
  trailer: 'прицеп',
}
export default () => {
  const dispatch = useDispatch()
  const searchList = useSelector((s) => s.myPark.serchList)
  const [drivers, setDrivers] = useState({ ...defaultData })
  const [errors, setErrors] = useState({ ...defaultErrors })
  const [typeUpdate, setTypeUpdate] = useState('')
  const [title, setTitle] = useState('')

  const onValidate = () => {
    if (!drivers.driver.hasOwnProperty('id')) {
      setErrors({ ...errors, driver: { ...errors.driver, bool: 1 } })
      setTimeout(
        () => setErrors({ ...errors, driver: { ...errors.driver, bool: 0 } }),
        2000,
        true
      )
      return
    }
    if (!drivers.car.hasOwnProperty('id')) {
      setErrors({ ...errors, car: { ...errors.car, bool: 1 } })
      setTimeout(
        () => setErrors({ ...errors, car: { ...errors.car, bool: 0 } }),
        2000,
        true
      )
      return
    }
    if (drivers.driver.id === drivers.driver2.id) {
      saveNewDriver('driver2', { union: '' })
      setErrors({ ...errors, driver2: { ...errors.driver2, bool: 1 } })
      setTimeout(
        () => setErrors({ ...errors, driver2: { ...errors.driver2, bool: 0 } }),
        3000,
        true
      )
      return
    }
    setTitle('all is good')
    setTimeout(() => setTitle(''), 2000, true)
  }

  const saveNewDriver = (typeAction, obj) => {
    switch (typeAction) {
      case 'driver': {
        setDrivers({ ...drivers, driver: obj })
        setTypeUpdate('driver')
        break
      }
      case 'driver2': {
        setDrivers({ ...drivers, driver2: obj })
        setTypeUpdate('driver2')
        break
      }
      case 'car': {
        setDrivers({ ...drivers, car: obj })
        setTypeUpdate('car')
        break
      }
      case 'trailer': {
        setDrivers({ ...drivers, trailer: obj })
        setTypeUpdate('trailer')
        break
      }
      default:
        break
    }
  }
  const onChangeInputDriver = (e) => {
    const NAME = e.target.name
    const VALUE = e.target.value
    if (NAME === 'driver2') {
      dispatch({ type: 'FILTER_SEARCH_LIST', payload: VALUE })
      setDrivers({
        ...drivers,
        [NAME]: { ...[NAME], union: VALUE },
      })
    } else {
      dispatch({
        type: 'FILTER_SMART_LIST',
        payload: VALUE,
        filterType: NAME,
      })
      setDrivers({
        ...drivers,
        [NAME]: { ...[NAME], union: VALUE },
      })
    }
  }
  return (
    <Wrapper>
      {title && <Text>{title}</Text>}
      <SmartParkSelect
        dataList={searchList}
        style={styles}
        data={drivers}
        fnFormValue={saveNewDriver}
        errors={errors}
        fnChange={onChangeInputDriver}
        dic={dic}
        typeUpdate={typeUpdate}
      />
      <TestInput
        label={`${dic.driver}2`}
        value={drivers.driver2.union}
        name="driver2"
        list={searchList}
        fnFormValue={saveNewDriver}
        fnChange={onChangeInputDriver}
        style={{ marginTop: '2.5rem' }}
        nothingToDisplay={dic.notFoundPark}
        error={errors.driver2 ? errors.driver2.bool : null}
        message={errors.driver2 ? errors.driver2.msg : null}
      />
      <BaseBtn
        label="Validate"
        style={{ marginTop: '2rem' }}
        fnClick={onValidate}
      />
    </Wrapper>
  )
}
