import React from 'react'
import styled from 'styled-components'
import TruckTab from '../../components/tabs/TruckTab'

const Wrapper = styled.div`
  width: 30rem;
  height: 3.5rem;
  background-color: ${(p) => p.theme.opposite};
  border: 0.1rem solid ${(p) => p.theme.stroke};
  border-right: unset;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`
const testData = ['truck', 'tractor', 'coupling']
export default ({ currentType = '', fnUpdate = () => {}, style = {}, items = testData }) => {
  const onChangeTypeTruck = (string) => {
    fnUpdate(string)
  }

  return (
    <Wrapper style={style}>
      {items.map((item) => (
        <TruckTab
          key={item}
          typeTruck={item}
          currentType={currentType}
          fnClick={onChangeTypeTruck}
        />
      ))}
    </Wrapper>
  )
}
