import React from 'react'
import styled from 'styled-components'
import BtnLikeLink from '../../../components/buttons/BtnLikeLink'
import BaseBtn from '../../../components/buttons/BaseBtn'
import ThinBtn from '../../../components/buttons/ThinBtn'
import SmartRoutepartSelect from '../../selects/SmartRoutepartSelect'
import CargoSelect from '../../selects/CargoSelect'
import DateAndTimeSelect from '../../selects/DateAndTimeSelect'
import BaseRadioInput from '../../../components/inputs/BaseRadioInput'
import { strToUpperCase } from '../../../lib/helpers/forStyling'

const Selector = styled.div`
  width: 28rem;
  height: 30rem;
  padding: 1.6rem 1rem;
  background-color: ${(p) => p.theme.opposite};
  border-radius: ${(p) => p.theme.PopUpRadius};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const SelectorTitle = styled.h3`
  margin-bottom: 2rem;
  font-size: ${(p) => p.theme.h2};
`
export const IndexWrp = styled.div`
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
export const Container = styled.div`
  width: 100%;
`
export const FlexWrp = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const HalfBox = styled.div`
  width: ${(p) => p.width};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const IndexHeader = styled.div`
  width: 100%;
  margin-top: 8rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-title {
    margin-left: 1rem;
    color: ${(p) => p.theme.additional};
    font-size: ${(p) => p.theme.h5};
  }
`
export const IndexFooter = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: 3.4rem;
`
export const Line = styled.div`
  width: 100%;
  height: 0.1rem;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  background-color: ${(p) => p.theme.lines};
`
export const TitleRoutePartEdit = styled.p`
  margin-right: auto;
  margin-bottom: 3.4rem;
  font-size: ${(p) => p.theme.h2};
`

const TitleRoutePartView = styled.p`
  margin-bottom: 0.8rem;
  font-size: ${(p) => p.theme.h3};
  font-weight: 300;
`
const TownRoutePartView = styled.p`
  margin-bottom: 0.5rem;
  font-size: ${(p) => p.theme.h4};
  font-weight: bold;
  line-height: 1.8rem;
`
export const ContentRoutePartView = styled.p`
  font-size: ${(p) => p.theme.h5};
  color: ${(p) => p.theme.additional2};
  line-height: 1.8rem;
`
export const TextRoutePartView = styled.p`
  font-size: ${(p) => p.theme.h4};
`
export const CargoRoutePartView = styled.p`
  font-size: ${(p) => p.theme.h5};
  color: ${(p) => p.theme.additional};
  line-height: 1.8rem;
`
const IndexRoutePartView = styled(TitleRoutePartView)`
  margin-right: 0.8rem;
`
export const RoutePartForSave = styled.div`
  width: 100%;
  padding: 1rem 1rem 2rem 1rem;
  background-color: ${(p) => p.theme.substrate};
  border-radius: ${(p) => p.theme.PopUpRadius};
  border: 0.1rem solid ${(p) => p.theme.lines};
  opacity: ${(p) => (p.isClickable ? 1 : 0.5)};
  cursor: ${(p) => (p.isClickable ? 'pointer' : 'default')};
`

export const RoutePartForEdit = styled.div`
  width: 100%;
  min-height: 30rem;
  padding: 1rem;
  padding-bottom: ${(p) => (p.isIos ? '10rem' : '3rem')};
  border-radius: ${(p) => p.theme.PopUpRadius};
  box-shadow: ${(p) => p.theme.partShadow};
  background-color: ${(p) => p.theme.opposite};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
export const RoutePartBetweenWrp = styled.div`
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: ${(p) => (p.contentBetween ? 'space-between' : 'center')};
  align-items: center;
`
const RoutePartMarker = styled.div`
  width: 0.3rem;
  height: 5rem;
  margin-top: 0.3rem;
  margin-left: 0.3rem;
  margin-right: 1.6rem;
  background-color: ${(p) => p.bg};
`
const Content = styled.div``

const ViewCheckpointData = ({
  town,
  company,
  address,
  checkpointcontactdata,
}) => (
  <>
    <TownRoutePartView>{town ? town.title : ''}</TownRoutePartView>
    <ContentRoutePartView>
      {company ? company.title || company.address : ''}
    </ContentRoutePartView>
    <ContentRoutePartView style={{ marginBottom: '1rem' }}>
      {address ? address.address : ''}
    </ContentRoutePartView>
    <ContentRoutePartView>
      {`${checkpointcontactdata ? checkpointcontactdata.contactperson : ''}
      ${
        checkpointcontactdata && checkpointcontactdata.cellnum
          ? `${checkpointcontactdata.cellnum} ,`
          : ''
      }
      ${
        checkpointcontactdata && checkpointcontactdata.telnum
          ? `${checkpointcontactdata.telnum}, `
          : ''
      }
      ${
        checkpointcontactdata && checkpointcontactdata.email
          ? `${checkpointcontactdata.email}`
          : ''
      }`}
    </ContentRoutePartView>
  </>
)

const ViewDateTime = ({ date, time, dic, formatDate }) => (
  <>
    <CargoRoutePartView style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      {date.mode === 3
        ? dic.date_nearest
        : date.mode === 2
        ? `${formatDate(date.from)} - ${formatDate(date.to)}`
        : formatDate(date.exactly)}
      {', '}
      {time.mode === 3
        ? dic.roundTheClock
        : time.mode === 2
        ? `${dic.from} ${time.from} - ${dic.to} ${time.to}`
        : time.exactly}
    </CargoRoutePartView>
  </>
)

export const componentView = (
  index,
  item,
  isClickable,
  onEditPart,
  formatDate,
  routeParts,
  colorPart,
  typePart,
  dic = {},
  settings
) => ({
  1: (
    <>
      <FlexWrp>
        <FlexWrp style={{ width: '90%', justifyContent: 'flex-start' }}>
          <IndexRoutePartView>{`${index + 1}.`}</IndexRoutePartView>
          <TitleRoutePartView>{typePart[item.workaction]}</TitleRoutePartView>
          <ContentRoutePartView
            style={{ marginBottom: '0.8rem', paddingLeft: '1rem' }}
          >
            {routeParts[index].group &&
            routeParts[index].group.union &&
            settings.category
              ? `${dic.category} ${routeParts[index].group.union}`
              : ''}
          </ContentRoutePartView>
        </FlexWrp>

        <ThinBtn
          type="edit"
          label=""
          onClick={isClickable ? () => onEditPart(item.id) : null}
        />
      </FlexWrp>
      <FlexWrp
        style={{
          marginTop: '1rem',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <RoutePartMarker bg={colorPart[item.workaction]} />
        <Content>
          <ViewCheckpointData
            town={routeParts[index].town}
            company={routeParts[index].company}
            address={routeParts[index].address}
            checkpointcontactdata={routeParts[index].checkpointcontactdata}
          />
          <ViewDateTime
            date={routeParts[index].date}
            time={routeParts[index].time}
            dic={dic}
            formatDate={formatDate}
          />
          <CargoRoutePartView>{`${routeParts[index].cargo.union} - ${
            routeParts[index].weight && routeParts[index].weight.union
              ? `${dic.weight} ${routeParts[index].weight.union} ${dic.t}, `
              : ''
          } ${
            routeParts[index].volume && routeParts[index].volume.union
              ? `${dic.volume} ${routeParts[index].volume.union} ${dic.mcube}, `
              : ''
          } ${
            routeParts[index].height
              ? `${dic.height} ${routeParts[index].height.union} ${dic.m}, `
              : ''
          } ${
            routeParts[index].length
              ? `${dic.length} ${routeParts[index].length.union} ${dic.m}, `
              : ''
          } ${
            routeParts[index].width
              ? `${dic.width} ${routeParts[index].width.union} ${dic.m}`
              : ''
          } ${
            routeParts[index].category &&
            routeParts[index].category.quantity &&
            routeParts[index].category.union
              ? `, ${routeParts[index].category.union}: ${routeParts[index].category.quantity} ${dic.pieces}`
              : ''
          }`}</CargoRoutePartView>
        </Content>
      </FlexWrp>
    </>
  ),
  2: (
    <>
      <FlexWrp>
        <FlexWrp style={{ width: '50%', justifyContent: 'flex-start' }}>
          <IndexRoutePartView>{`${index + 1}.`}</IndexRoutePartView>
          <TitleRoutePartView>{typePart[item.workaction]}</TitleRoutePartView>
        </FlexWrp>

        <ThinBtn
          type="edit"
          label=""
          onClick={isClickable ? () => onEditPart(item.id) : null}
        />
      </FlexWrp>
      <FlexWrp
        style={{
          marginTop: '1rem',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <RoutePartMarker bg={colorPart[item.workaction]} />
        <Content>
          <ViewCheckpointData
            town={routeParts[index].town}
            company={routeParts[index].company}
            address={routeParts[index].address}
            checkpointcontactdata={routeParts[index].checkpointcontactdata}
          />
          <ViewDateTime
            date={routeParts[index].date}
            time={routeParts[index].time}
            dic={dic}
            formatDate={formatDate}
          />
        </Content>
      </FlexWrp>
    </>
  ),
  4: (
    <>
      <FlexWrp>
        <FlexWrp style={{ width: '50%', justifyContent: 'flex-start' }}>
          <IndexRoutePartView>{`${index + 1}.`}</IndexRoutePartView>
          <TitleRoutePartView>{typePart[item.workaction]}</TitleRoutePartView>
        </FlexWrp>

        <ThinBtn
          type="edit"
          label=""
          onClick={isClickable ? () => onEditPart(item.id) : null}
        />
      </FlexWrp>
      <FlexWrp
        style={{
          marginTop: '1rem',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <RoutePartMarker
          bg={colorPart[item.workaction]}
          style={{ height: '3rem' }}
        />
        <Content>
          <TownRoutePartView>
            {item.indicatesСarrier
              ? dic.carrier_indicates
              : routeParts[index].town
              ? routeParts[index].town.title
              : ''}
          </TownRoutePartView>
        </Content>
      </FlexWrp>
    </>
  ),
  3: (
    <>
      <FlexWrp>
        <FlexWrp style={{ width: '50%', justifyContent: 'flex-start' }}>
          <IndexRoutePartView>{`${index + 1}.`}</IndexRoutePartView>
          <TitleRoutePartView>{typePart[item.workaction]}</TitleRoutePartView>
        </FlexWrp>
        <ThinBtn
          type="edit"
          label=""
          onClick={isClickable ? () => onEditPart(item.id) : null}
        />
      </FlexWrp>
      <FlexWrp
        style={{
          marginTop: '1rem',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <RoutePartMarker bg={colorPart[item.workaction]} />
        <Content>
          <ViewCheckpointData
            town={routeParts[index].town}
            company={routeParts[index].company}
            address={routeParts[index].address}
            checkpointcontactdata={routeParts[index].checkpointcontactdata}
          />
          <ViewDateTime
            date={routeParts[index].date}
            time={routeParts[index].time}
            dic={dic}
            formatDate={formatDate}
          />
        </Content>
      </FlexWrp>
    </>
  ),
})

export const componentEdit = (
  index,
  item,
  onUpdateItemRoutePart,
  errors,
  typePart,
  dic,
  settings
) => ({
  1: (
    <>
      <TitleRoutePartEdit>
        {`${index + 1}.`} {typePart[item.workaction]}
      </TitleRoutePartEdit>
      <DateAndTimeSelect
        index={index}
        onUpdateItemRoutePart={onUpdateItemRoutePart}
        prewData={item}
        errors={errors}
      />
      <SmartRoutepartSelect
        index={index}
        onUpdateItemRoutePart={onUpdateItemRoutePart}
        prewData={item}
        errors={errors}
        templateWorkaction={1}
      />
      <CargoSelect
        index={index}
        onUpdateItemRoutePart={onUpdateItemRoutePart}
        prewData={item}
        errors={errors}
        settings={settings}
      />
    </>
  ),
  2: (
    <>
      <TitleRoutePartEdit>
        {`${index + 1}.`} {typePart[item.workaction]}
      </TitleRoutePartEdit>
      <DateAndTimeSelect
        index={index}
        onUpdateItemRoutePart={onUpdateItemRoutePart}
        prewData={item}
        errors={errors}
      />
      <SmartRoutepartSelect
        index={index}
        onUpdateItemRoutePart={onUpdateItemRoutePart}
        prewData={item}
        errors={errors}
        templateWorkaction={2}
      />
    </>
  ),
  4: (
    <>
      <TitleRoutePartEdit>
        {`${index + 1}.`} {typePart[item.workaction]}
      </TitleRoutePartEdit>
      <ContentRoutePartView
        style={{ marginRight: 'auto', marginBottom: '1.6rem' }}
      >
        {`${strToUpperCase(dic.carrier_indicates)}?`}
      </ContentRoutePartView>
      <FlexWrp
        style={{
          width: '50%',
          marginRight: 'auto',
          marginBottom: '2rem',
          paddingLeft: '2rem',
        }}
      >
        <BaseRadioInput
          label={dic.btn_yes}
          id={1}
          currentValue={item.indicatesСarrier}
          fnChange={() =>
            onUpdateItemRoutePart(index, { ...item, indicatesСarrier: true })
          }
        />
        <BaseRadioInput
          label={dic.btn_no}
          id={0}
          currentValue={!item.indicatesСarrier}
          fnChange={() =>
            onUpdateItemRoutePart(index, { ...item, indicatesСarrier: false })
          }
        />
      </FlexWrp>
      {!item.indicatesСarrier && (
        <SmartRoutepartSelect
          index={index}
          onUpdateItemRoutePart={onUpdateItemRoutePart}
          prewData={item}
          errors={errors}
          templateWorkaction={4}
        />
      )}
    </>
  ),
  3: (
    <>
      <TitleRoutePartEdit>
        {`${index + 1}.`} {typePart[item.workaction]}
      </TitleRoutePartEdit>
      <DateAndTimeSelect
        index={index}
        onUpdateItemRoutePart={onUpdateItemRoutePart}
        prewData={item}
        errors={errors}
      />
      <SmartRoutepartSelect
        index={index}
        onUpdateItemRoutePart={onUpdateItemRoutePart}
        prewData={item}
        errors={errors}
        templateWorkaction={3}
      />
    </>
  ),
})

export const SelectNewRoutePartPopup = ({
  data = [],
  title = '',
  btnTitle = 'cancel',
  onAddRoutePart = () => {},
  onCancelAddRoutePart = () => {},
}) => {
  return (
    <Selector>
      <SelectorTitle>{title}</SelectorTitle>
      {data &&
        data.length > 0 &&
        data.map((obj) => (
          <BtnLikeLink
            key={obj.workaction}
            label={obj.label}
            onClick={() => onAddRoutePart(obj.workaction)}
            style={{ marginBottom: '1rem' }}
          />
        ))}

      <BaseBtn
        inContext="popup"
        label={btnTitle}
        fnClick={onCancelAddRoutePart}
      />
    </Selector>
  )
}
