import { api, routs } from '../api'

// create route
export const createRoute = (token, currentLang, data, hmac) => {
  return api.post(
    routs.createRoute,
    {
      post: data,
      signature: hmac,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

// create tender
export const createTender = (token, currentLang, data, hmac) => {
  return api.post(
    routs.createTender,
    {
      post: data,
      signature: hmac,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

export const deleteRoute = (token, currentLang, periodic) => {
  return api.delete(routs.route, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: {
      route: periodic,
    },
  })
}

export const finishRoute = (token, currentLang, periodic) => {
  return api.post(
    routs.finishRoute,
    { route: periodic },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

export const ratifyOperator = (token, currentLang, route) => {
  return api.post(
    routs.ratifyOperator,
    { route },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

export const confirmCharge = (token, currentLang, routepartId, periodic) => {
  return api.post(
    routs.confirmCharge,
    { route: periodic, routepart: routepartId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

export const confirmUncharge = (token, currentLang, routepartId, periodic) => {
  return api.post(
    routs.confirmUncharge,
    { route: periodic, routepart: routepartId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

// /publishTenderChild
// /pickTenderWinner
// /oppositeOffer
