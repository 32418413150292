export const getCurrentLang = (s) => s.defaultLang.currentLang
export const storeDictionary = (s) => s.defaultLang.baseDictionary
export const getToken = (s) => s.user.token
export const getCompanyHash = (s) => s.user.profile.hash
export const getStartPath = (s) => s.general.startPath
export const auth = (s) => s.general.isAuth
export const routeFlagList = (s) => s.route.flagList
export const getDicionary = (s) => s.defaultLang.serverDictionary
export const getUserProfile = (s) => s.user.profile
export const getDialogues = (s) => s.chats.data
