import React , { useState , useEffect }  from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import Icon from "../../Icon"



const Wrapper = styled.div`
  z-index: 3;
  position: fixed;
  bottom: 0rem;
  left: 0;
  width: 100%;
  padding: 1rem 1.6rem 2.6rem;
  background-color: ${(p) => p.theme.opposite};
`

const RecipientSelect = styled.div`
  width: 100%;
  padding: 1.6rem;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  .icon {
  }
  .tabs {
    width:100%;
    display:flex;
    justify-content: space-around;
    align-items: center;
  }
  background-color: ${(p) => p.theme.selected};
`

const Tab = styled.div`
  margin-left: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(p) => p.theme.links};
  opacity: ${(p) => p.isActive ? 1 : 0.5};
`

const Form = styled.div`
  width: 100%;
  height:auto;
  padding: 0 1.2rem;
  display:flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => p.theme.selected};
`

const Textarea = styled.textarea`
  width: 100%;
  padding: 1.2rem 1.2rem 0 1.2rem;
  resize: none;
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => p.theme.regular_txt};
`


export default ({
                    periodic = '',
                    recipient = {}, // { recepientHash, recepientCompany }
                    postProcess = 0,
                    postType = "SAGA_SEND_CHAT_MESSAGE",
                    onChoiceRecipient = () =>{},
                    recipients=[]
}) => {
    const dispatch = useDispatch()
    const [message, setMessage] = useState("")
    const [isProcess, setIsProcess] = useState(false)
    const onChangeMessage = (e) => {
        setMessage(e?.target?.value)
    }

    const onSendMessage = () => {
        if(!message.length){
            return
        }
        setIsProcess(true)
        dispatch({
            type:postType,
            periodic,
            recipient: recipient?.recepientHash,
            message,
        })
    }

    useEffect(()=>{
        if(postProcess === 1 && isProcess){
            setMessage('')
            setIsProcess(false)
        }
    },[postProcess])

    if(!recipient?.recepientHash){
        return null;
    }

    return (
        <Wrapper>
            <RecipientSelect>
                <div className="icon">
                    <Icon
                        name="forwardArrow2"
                        type="trigger"
                    />
                </div>
                <div className="tabs">
                    {recipients?.map((item)=>{
                        return (
                            <Tab
                                className="title"
                                key={item?.recepientCompany}
                                isActive={item?.recepientCompany === recipient?.recepientCompany}
                                onClick={()=>
                                    recipients.length > 1
                                    ? onChoiceRecipient(item)
                                    : () => {}

                                }
                            >
                                {item?.recepientCompany}
                            </Tab>
                        )

                    })}
                </div>

            </RecipientSelect>
            <Form>
                <Textarea
                    onChange={onChangeMessage}
                    value={message}
                    />
                <Icon
                    name="sendMsgArrow"
                    type="trigger"
                    onClick={onSendMessage}
                />
            </Form>
        </Wrapper>
    )
}