import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import {
  Wrapper,
  Price,
} from '../../../smallPartsForInterface/SmallPartsWidgets'
import BodyTendersWidget from './BodyTenderWidget'
import BaseBtn from '../../../buttons/BaseBtn'
import SendBet from '../../../../containers/forms/SendBet'
import { NumberWithoutNulls } from '../../../../lib/helpers/forRoutes'
import { currencyIcons, exampleSimbols } from '../../../../lib/constants'
import { strToUpperCase } from '../../../../lib/helpers/forStyling'
import Icon from '../../../Icon'
import useTimer from '../../../../hooks/useTimer'
import useRouter from '../../../../hooks/useRouter'

export default ({ obj, dic, memory = '', contentList = [] }) => {
  const router = useRouter()
  const myref = useRef(null)
  const dispatch = useDispatch()
  const [isMy, setIsMy] = useState(false)
  const {
    defaultcurrency,
    tenderuntil,
    minquote,
    maxquotewithcommission,
    routetender,
    periodic,
  } = obj
  const [timer, isShowTimer] = useTimer(tenderuntil, 5)
  const [isSendBet, setIsSendBet] = useState(false)

  const local = {
    mcube: dic.mcube,
    tonn: dic.t,
    km: dic.km,
    ride: dic.flightAccusative,
    undefined: '',
  }

  const widgetClick = () => {
    dispatch({ type: 'REMEMBER_TENDER_WIDGET', payload: periodic })
    router.push(`/tender/${periodic}`)
  }

  const executeScroll = () =>
    myref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })

  const onClosePopup = (bool) => {
    dispatch({
      type: 'SAGA_GET_TENDER_DETAILS',
      tenderList: contentList.toString() || '',
      typeAction: 'base',
    })
    setIsSendBet(bool)
  }

  useEffect(() => {
    if (minquote && minquote.hasOwnProperty('mine')) {
      setIsMy(minquote.mine)
    }
  }, [obj, minquote])

  useEffect(() => {
    if (memory === periodic) {
      executeScroll()
    }
  }, [memory, periodic])

  return (
    <>
      {isSendBet && (
        <SendBet
          titleBet={`${dic.bid}`}
          titleQuantity={`${dic.willCarry} ${local[routetender.pricefor]}`}
          titleComment={`${dic.comment_add}...`}
          totalcount={NumberWithoutNulls(routetender.totalcount)}
          outOf={`${dic.of} ${NumberWithoutNulls(routetender.totalcount)}`}
          periodic={periodic}
          currency={
            defaultcurrency ? currencyIcons[defaultcurrency] : currencyIcons.uah
          }
          fnClose={onClosePopup}
          value={isMy}
        />
      )}
      <span ref={myref}>
        <Wrapper isMrgnBtm>
          <span onClick={widgetClick}>
            <div
              className={
                isShowTimer ? 'dateContent timer warmBg' : 'dateContent timer'
              }
            >
              {isShowTimer ? (
                <>
                  <Icon
                    name="timer"
                    type="opposite"
                    style={{ marginRight: '1rem', paddingBottom: '0.7rem' }}
                  />
                  <p>{timer}</p>
                </>
              ) : (
                `${dic.to} ${moment(tenderuntil.date).format('DD.MM')}${
                  exampleSimbols.doobleSpace
                }${exampleSimbols.boldPoint}${
                  exampleSimbols.doobleSpace
                }${tenderuntil.time.slice(0, -3)}`
              )}
            </div>
            {(maxquotewithcommission && maxquotewithcommission !== '0.00') ||
            minquote !== null ? (
              <Price
                attention={
                  minquote
                    ? obj.tenderparticipant
                      ? !minquote.mine
                      : false
                    : false
                }
                // isFree={minquote ? !obj.tenderparticipant : true}
                style={{ marginBottom: '1rem' }}
              >
                {`${
                  defaultcurrency
                    ? currencyIcons[defaultcurrency]
                    : currencyIcons.uah
                } ${NumberWithoutNulls(
                  minquote
                    ? minquote.pricequotewithcommission
                    : maxquotewithcommission
                )}`}
                {minquote && obj.tenderparticipant && (
                  <Icon
                    name={isMy ? 'star' : 'atencion'}
                    type={isMy ? 'trigger' : 'error'}
                    style={{ marginLeft: '1rem', paddingBottom: '0.7rem' }}
                  />
                )}
                <p style={{ fontSize: '1.2rem', marginLeft: '1rem' }}>
                  {minquote
                    ? isMy
                      ? strToUpperCase(dic.your)
                      : obj.tenderparticipant
                      ? strToUpperCase(dic.belowMine)
                      : strToUpperCase(dic.bid_current)
                    : dic.price_start}
                </p>
              </Price>
            ) : (
              <div style={{ marginBottom: '4rem' }} />
            )}
            <BodyTendersWidget obj={obj} dic={dic} isShowTitle />
          </span>
          <BaseBtn
            fullSize
            style={{ marginTop: '1.6rem' }}
            label={isMy ? dic.bid_my_last : `${dic.bid_send}...`}
            fnClick={() => setIsSendBet(true)}
          />
        </Wrapper>
      </span>
    </>
  )
}
