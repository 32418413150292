import { takeEvery, call, put, select, race, delay } from 'redux-saga/effects'
import { delays } from '../../lib/constants'
import { getSettings } from '../../lib/requests/authRequest'
import { getCurrentLang, getToken } from '../selectors'

export function* worker() {
  try {
    const currentLang = yield select(getCurrentLang)
    const token = yield select(getToken)

    const { result } = yield race({
      result: call(getSettings, token, currentLang),
      timeout: delay(delays.forCancelRequest),
    })

    if (result && result.status === 200) {
      yield put({
        type: 'SAGA_UPDATE_NEWROUTE_SETTINGS',
        data: result.data.data,
      })
    } else {
      // ?????
    }
  } catch (e) {
    yield put({
      type: 'SHOW_ERROR_API',
      code: e.response ? String(e.response.status).slice(0, 1) : 4,
      message: e.response ? e.response.data.message : e.message,
    })
  }
}

export default function* getUserSettings() {
  yield takeEvery('SAGA_GET_DEFAULT_SETTINGS', worker)
}
