import React, { useState } from 'react'
import styled from 'styled-components'
import Icon from '../../components/Icon'

const Wrapper = styled.button`
  z-index: 5;
  position: fixed;
  width: 4.2rem;
  height: 4.2rem;
  bottom: 4.5rem;
  right: 1.5rem;
  border-radius: 50%;
  background-color: ${(p) => p.theme.opposite};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease;
  opacity: ${(p) => p.opacity};
  :active {
    transform: scale(0.95);
    opacity: 1;
  }
`

export default ({ onClick = () => {} }) => {
  const [value, setValue] = useState(0.75)
  const run = () => {
    setValue(value === 0.75 ? 1 : 0.75)
    onClick()
  }
  return (
    <Wrapper onClick={run} opacity={value}>
      <Icon name="support" type="regularText" />
    </Wrapper>
  )
}
