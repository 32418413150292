import React from 'react'
import styled from 'styled-components'
import Icon from '../../../Icon'

const Wrapper = styled.div`
  width: 100%;
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => (p.isClick ? p.theme.approver : p.theme.regular_txt)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
export default ({
  onAction = () => {},
  title = 'default text',
  afterActionText = 'afterActionText',
  isClick = false,
}) => (
  <Wrapper onClick={() => onAction('badConditions')} isClick={isClick}>
    {isClick ? (
      <>
        <Icon name="done" type="approver" style={{ marginRight: '1rem' }} />
        <p>{afterActionText}</p>
      </>
    ) : (
      <>
        <Icon
          name="basket"
          type="regularText"
          style={{ marginRight: '1rem' }}
        />
        <p>{title}</p>
      </>
    )}
  </Wrapper>
)
