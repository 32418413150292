import Type from '../types'

const initialState = {
  profile: null,
  hotKeys: [],
  navlist: [],
  //TODO for test - after remove this
  tablist: [
    { typeIcon: 'info', id: '11', route: '/' },
    { typeIcon: 'bill', id: '22', route: '/' },
    { typeIcon: 'chat', id: '33', route: '/' },
    { typeIcon: 'time', id: '44', route: '/' },
  ],
  currentTab: '11',
  //==========
  auxMenu: [],
  currentNavRoute: '0',
  token: null,
  isRenewalProcess:false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.LOGOUT:
      return {
        ...state,
        profile: null,
        navlist: [],
      }
    case Type.SET_USER_PROFILE:
      return {
        ...state,
        profile: action.payload,
      }
    case Type.SET_AUX_MENU:
      return {
        ...state,
        auxMenu: action.payload,
      }
    case Type.SET_USER_MENU:
      return {
        ...state,
        navlist: action.payload,
      }
    case Type.SET_HOTKEYS:
      return {
        ...state,
        hotKeys: action.payload,
      }
    case Type.SET_AUTH:
      return {
        ...state,
        profile: action.payload ? state.profile : null,
        token: action.payload ? state.token : null,
      }
    case Type.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      }
    case Type.NAVMENU_SET_CURRENT_TAP:
      return {
        ...state,
        currentNavRoute: action.payload,
      }
    case Type.TABMENU_SET_CURRENT_TAP:
      return {
        ...state,
        currentTab: action.payload,
      }
    case Type.SET_RENEWAL_PROCESS:
      return {
        ...state,
        isRenewalProcess: action.payload,
      }

    default:
      return state
  }
}
