import { takeEvery, call, put, select, race, delay } from 'redux-saga/effects'
import { requestRenewal } from '../../lib/requests/authRequest'
import { delays } from '../../lib/constants'
import { getCurrentLang, getDicionary } from '../selectors'

export function* worker({ cellnum, email }) {
  try {
    const currentLang = yield select(getCurrentLang)
    const dic = yield select(getDicionary)

    const { result } = yield race({
      result: call(requestRenewal, cellnum, email, currentLang),
      timeout: delay(delays.forCancelRequest),
    })

    if (result && result.status === 200) {
      yield put({ type: 'CLOSE_ERROR_API' })
      yield put({ type: 'SET_RENEWAL_PROCESS', payload: true, })
    } else {
      yield put({
        type: 'SHOW_ERROR_API',
        code: 6,
        message: dic.msg_server_err || 'internal server error',
      })
    }
  } catch (e) {
    yield put({
      type: 'SHOW_ERROR_API',
      code: String(e.response.status).slice(0, 1),
      message: e.response ? e.response.data.message : e.message,
    })
  }
}

export default function* postRenewal() {
  yield takeEvery('POST_RENEWAL', worker)
}
