import React, { useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BaseLayout from '../components/layouts/BaseLayout'
import Tab1 from '../components/InterfaceForCarrier/forRoutes/Tabs/RouteMenuTab1'
import Tab2 from '../components/InterfaceForCarrier/forRoutes/Tabs/RouteMenuTab2'
import SmallLoader from '../components/layouts/SmallLoader'
import SeoMetaTegs from '../components/SeoMetaTegs'

export default () => {
  const dispatch = useDispatch()
  const [typeUpdate] = useState('GET_ALL_ROUTES')
  const [location] = useState('all')
  const userType = useSelector((s) => s.user.profile.usertype)
  const routesList = useSelector((s) => s.forAllRoutes.routesList)
  const navId = useSelector((s) => s.user.currentNavRoute)
  const tabId = useSelector((s) => s.route.isOpen[location])
  const navList = useSelector((s) => s.user.navlist)
  const [seoTabTitle, setSeoTabTitle] = useState('')
  const [seoPageTitle, setSeoPageTitle] = useState('')

  const Tab = (item) => ({
    1: (
      <Tab1
        key={item.id}
        obj={item}
        typeUpdate={typeUpdate}
        location={location}
      />
    ),
    2: (
      <Tab2
        key={item.id}
        obj={item}
        typeUpdate={typeUpdate}
        location={location}
      />
    ),
  })

  useEffect(() => {
    if(routesList.length <= 0){
      dispatch({ type: 'SAGA_GET_DATA_FOR_ALL_ROUTES_PAGE' })
    }else {
      dispatch({ type: typeUpdate })
    }
  }, [])

  useEffect(() => {
    const result = navList.find((obj) => String(obj.id) === String(navId))
    if (result && result.title) {
      setSeoPageTitle(result.title)
    }
    setSeoTabTitle(
      tabId && routesList.length
        ? routesList.find((obj) => String(obj.id) === String(tabId)).title
        : ''
    )
  }, [tabId, navId, navList, routesList])

  return (
      <BaseLayout>
        <SeoMetaTegs
            title={`${seoPageTitle} ${seoTabTitle ? `> ${seoTabTitle}` : ''}`}
        />
        {routesList.length > 0 && routesList[0].content ? (
            routesList.map((item) => {
              if (
                  !item.content.length &&
                  (Number(item.id) === 33 || Number(item.id) === 32)
              ) {
                return //eslint-disable-line
              } else {
                return Tab(item)[userType]
              }
            })
        ) : (
            <SmallLoader style={{ height: '50vh' }} size="5.6rem" />
        )}
      </BaseLayout>
  )
}
