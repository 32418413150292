import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Header, Footer as FilterControl, currentContent } from './ForFilter_parts'

import { strToUpperCase } from '../../../lib/helpers/forStyling'
import { parseDateOfLocal } from '../../../lib/helpers/forRoutes'
import { routeFilters } from "../../../lib/constants"

const Wrapper = styled.div`
  width: 95vw;
  height: 95vh;
  padding: 2rem 1.6rem;
  background-color: ${(p) => p.theme.opposite};
  border-radius: ${(p) => p.theme.PopUpRadius};
`
const Line = styled.div`
  width: calc(100% + 3.2rem);
  margin-left: -1.6rem;
  height: 0.1rem;
  background-color: ${(p) => p.theme.lines};
`
const Content = styled.div`
  ${(p) =>
    p.withoutSidePad &&
    `
  width: calc(100% + 3.2rem);
  margin-left: -1.6rem;
`}
  height:73vh;
  overflow: auto;
`

export default () => {
  const dispatch = useDispatch()
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  //const routesList = useSelector((s) => s.myRoutes.routesList)
  const myRoutesList = useSelector((s) => s.forMyRoutes.routesList)
  const allRoutesList = useSelector((s) => s.forAllRoutes.routesList)
 // const tendersList = useSelector((s) => s.myTenders.tendersList)
  const myTendersList = useSelector((s) => s.forMyTenders.tendersList)
  const allTendersList = useSelector((s) => s.forAllTenders.tendersList)
  const routesWidgetList = useSelector((s) => s.route.widgetList)
  const tendersWidgetList = useSelector((s) => s.tender.widgetList)
  const selectedArgs = useSelector((s) => s.filterForTab.selectedArgs)
  const currentFilterId = useSelector((s) => s.filterForTab.currentId)
  const whereUseFilter = useSelector((s) => s.filterForTab.where)

  const [data, setData] = useState({ title: '' })
  const [shippers, setShippers] = useState([])
  const [loadTowns, setLoadTowns] = useState([])
  const [unloadTowns, setUnloadTowns] = useState([])
  const [typeAuto, setTypeAuto] = useState([])

  const [tabs] = useState([
    { title: strToUpperCase(dic.msg_25), id: 'start' },
    { title: strToUpperCase(dic.msg_26), id: 'finish' },
    { title: strToUpperCase(dic.customer), id: 'shipper' },
    { title: strToUpperCase(dic.car_type), id: 'type' },
    { title: strToUpperCase(dic.msg_23), id: 'date' },
  ])
  const [component, setComponent] = useState('base')
  const [headerTitle, setHeaderTitle] = useState('')
  const [period, setPeriod] = useState({
    from: '',
    to: '',
    title: '',
    filterId: 'dateId',
  })
  const [validatePeriod, setValidatePeriod] = useState({
    to: {
      value: '',
      direction: 'nothing',
    },
    from: {
      value: '',
      direction: 'nothing',
    },
  })

  const MONTH_SHORT = [
    dic.month_short_name_1,
    dic.month_short_name_2,
    dic.month_short_name_3,
    dic.month_short_name_4,
    dic.month_short_name_5,
    dic.month_short_name_6,
    dic.month_short_name_7,
    dic.month_short_name_8,
    dic.month_short_name_9,
    dic.month_short_name_10,
    dic.month_short_name_11,
    dic.month_short_name_12,
  ]

  const forSelectData = {
    GET_MY_ROUTES: {
      forDispatch: 'SET_OPEN_WIDGET_ROUTES',
      forFilterCat: routesWidgetList,
      forTab: myRoutesList,
    },
    GET_ALL_ROUTES: {
      forDispatch: 'SET_OPEN_WIDGET_ROUTES',
      forFilterCat: routesWidgetList,
      forTab: allRoutesList,
    },
    GET_MY_TENDERS: {
      forDispatch: 'SET_OPEN_WIDGET_TENDERS',
      forFilterCat: tendersWidgetList,
      forTab: myTendersList,
    },
    GET_ALL_TENDERS: {
      forDispatch: 'SET_OPEN_WIDGET_TENDERS',
      forFilterCat: tendersWidgetList,
      forTab: allTendersList,
    },
  }

  const forQuantityData = {
    start: loadTowns.filter((el) => el.checked).length,
    finish: unloadTowns.filter((el) => el.checked).length,
    shipper: shippers.filter((el) => el.checked).length,
    type: typeAuto.filter((el) => el.checked).length,
    date: period.title,
  }

  const togglerContent = (name) => {
    setComponent(name)
  }
  const onSetNewDate = (newDate, key) => {
    if (key === 'from') {
      setValidatePeriod({
        ...validatePeriod,
        to: { value: newDate, direction: 'past' },
      })
    } else {
      setValidatePeriod({
        ...validatePeriod,
        from: { value: newDate, direction: 'after' },
      })
    }
    const condition = {
      from: `${parseDateOfLocal(newDate, null, MONTH_SHORT)}- ${
        period.to ? `-${parseDateOfLocal(period.to, null, MONTH_SHORT)}` : ''
      }`,
      to: `${
        period.from
          ? `${parseDateOfLocal(period.from, null, MONTH_SHORT)}-`
          : ''
      } -${parseDateOfLocal(newDate, null, MONTH_SHORT)}`,
    }
    const result = {
      ...period,
      [key]: newDate,
      title: condition[key],
    }
    setPeriod(result)
    dispatch({
      type: 'REWRITE_ARG_FOR_FILTER',
      payload: { ...result, id: 'date' },
    })
  }

  const onSetFilter = (where, filter, prevState) => {
    const typeAction = {
      true: 'REMOVE_ARG_FOR_FILTER',
      false: 'ADD_ARG_FOR_FILTER',
    }
    const typeTitle = (filter, where) => {
      const condition = {
        [routeFilters.loadTowns]: `${strToUpperCase(dic.workaction_1)} :`,
        [routeFilters.unloadTowns]: `${strToUpperCase(dic.workaction_2)} :`,
        [routeFilters.shippers]: null,
        [routeFilters.typeAuto]: null,
        date: null,
      }
      const compileTitle = condition[where]
      return compileTitle ? `${compileTitle} ${filter}` : filter
    }
    dispatch({
      type: typeAction[prevState],
      payload: {
        title: typeTitle(filter, where),
        filter: filter,
        id: where,
        filterId: `${filter}${where}`,
      },
    })
    const allCategory = {
      [routeFilters.shippers]: { data: shippers, fn: setShippers },
      [routeFilters.loadTowns]: { data: loadTowns, fn: setLoadTowns },
      [routeFilters.unloadTowns]: { data: unloadTowns, fn: setUnloadTowns },
      [routeFilters.typeAuto]: { data: typeAuto, fn: setTypeAuto },
    }
    const result = allCategory[where].data.map((el) =>
      el.filter === filter ? { ...el, checked: !prevState } : el
    )
    allCategory[where].fn(result)
  }

  const onSetGroups = (clear = false) => {
    const actuality = whereUseFilter.typeUpdate
      ? forSelectData[whereUseFilter.typeUpdate].forFilterCat
      : []
    const isHavePrewData = selectedArgs.length > 0

    if (isHavePrewData && !clear) {
      const result = selectedArgs.find((el) => el.id === 'date')
      if (result) setPeriod(result)
    }

    setShippers(
      [
        ...new Set(
          actuality.map((el) => el.client_relation.company_relation.title_ru)
        ),
      ].map((el) => ({
        filter: el,
        checked:
          isHavePrewData && !clear
            ? Boolean(
                selectedArgs.find(
                  (obj) => obj.filter === el && obj.id === routeFilters.shippers
                )
              )
            : false,
      }))
    )

    setLoadTowns(
      [
        ...new Set(
          actuality.map(
            (el) => el.routeparts[0].checkpoint_relation.town_relation.title_ru
          )
        ),
      ].map((el) => ({
        filter: el,
        checked:
          isHavePrewData && !clear
            ? Boolean(
                selectedArgs.find(
                  (obj) => obj.filter === el && obj.id === routeFilters.loadTowns
                )
              )
            : false,
      }))
    )

    setUnloadTowns(
      [
        ...new Set(
          actuality.map(
            (el) =>
              el.routeparts[el.routeparts.length - 1].checkpoint_relation
                .town_relation.title_ru
          )
        ),
      ].map((el) => ({
        filter: el,
        checked:
          isHavePrewData && !clear
            ? Boolean(
                selectedArgs.find(
                  (obj) => obj.filter === el && obj.id === routeFilters.unloadTowns
                )
              )
            : false,
      }))
    )
    const flattening = actuality.flatMap((el) => el.cartypeRelation)

    if (
      flattening.length > 0 &&
      flattening.filter((el) => Boolean(el)).length > 0
    ) {
      setTypeAuto(
        [...new Set(flattening.map((el) => el.carTypeTitle))].map((el) => ({
          filter: el,
          checked:
            isHavePrewData && !clear
              ? Boolean(
                  selectedArgs.find(
                    (obj) =>
                      obj.filter === el.carTypeTitle && obj.id === 'typeAuto'
                  )
                )
              : false,
        }))
      )
    }
  }

  const onOpenTab = () => {
    dispatch({
      type: forSelectData[whereUseFilter.typeUpdate].forDispatch,
      payload: {
        ...{ all: null, my: null },
        [whereUseFilter.location]: currentFilterId,
      },
      location: whereUseFilter.location,
    })
  }

  const onCloseModal = () => {
    onOpenTab()
    dispatch({ type: 'CLOSE_MODAL' })
  }
  const onClearFilter = () => {
    onSetGroups(true)
    setPeriod({ from: '', to: '', title: '' })
    setValidatePeriod({
      to: {
        value: '',
        direction: 'nothing',
      },
      from: {
        value: '',
        direction: 'nothing',
      },
    })
    dispatch({ type: 'CLEAR_ARGS_FOR_FILTER' })
  }

  const onShowFilter = () => {
    onCloseModal()
  }

  useEffect(() => {
    if (component === 'base' && dic) {
      setHeaderTitle(`${data.title.length ? data.title : ''}`)
    } else {
      setHeaderTitle(tabs.find((el) => el.id === component).title)
    }
  }, [component, dic]) // eslint-disable-line

  useEffect(() => {
    onSetGroups()
    const result = whereUseFilter.typeUpdate
      ? forSelectData[whereUseFilter.typeUpdate].forTab.find(
          (el) => el.id === currentFilterId
        )
      : null
    if (result) {
      setHeaderTitle(`${result.title}`)
      setData(result)
    }
  }, []) //eslint-disable-line

  return (
    <Wrapper>
      <Header
        onCloseModal={onCloseModal}
        title={headerTitle}
        component={component}
        setComponent={setComponent}
      />
      <Line />
      <FilterControl
          isHaveArgs={selectedArgs.length > 0}
          component={component}
          onClearFilter={onClearFilter}
          onShowFilter={onShowFilter}
          onCloseModal={onCloseModal}
          onBackToBase={setComponent}
      />
      <Line />
      <Content withoutSidePad={component === 'base'}>
        {
          currentContent(
            tabs,
            loadTowns,
            unloadTowns,
            shippers,
            typeAuto,
            dic,
            period,
            validatePeriod,
            forQuantityData,
            togglerContent,
            onSetFilter,
            onSetNewDate
          )[component]
        }
      </Content>
    </Wrapper>
  )
}
