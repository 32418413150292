import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import SmallLoader from '../components/layouts/SmallLoader'
import CompanyCard from '../components/smallPartsForInterface/CompanyCard'
import {
  Title,
  Info,
} from '../components/smallPartsForInterface/SmallPartsCard'
import BaseLayout from '../components/layouts/BaseLayout'
import SeoMetaTegs from '../components/SeoMetaTegs'
import { strToUpperCase } from '../lib/helpers/forStyling'
//TODO refactor address RouteCardDocs
// import RouteCardDocs from '../components/InterfaceForCarrier/forRoutes/RouteCardDocs'

export default () => {
  const { hash } = useParams()
  const dispatch = useDispatch()
  const [seoTitle, setSeoTitle] = useState('')
  const data = useSelector((s) => s.company.info)
  const badRequest = useSelector((s) => s.company.badRequest)
  const dic = useSelector((s) => s.defaultLang.serverDictionary)

  const clearStore = () => {
    dispatch({ type: 'SET_COMPANY_INFO', payload: {} })
    dispatch({ type: 'SET_COMPANY_BADREQEST', payload: false })
  }
  useEffect(() => {
    return () => clearStore()
  }, []) //eslint-disable-line

  useEffect(() => {
    if (hash) {
      dispatch({ type: 'SAGA_GET_COMPANY_INFO', hash })
    }
  }, [hash]) //eslint-disable-line

  useEffect(() => {
    if (data && data.titleRu) {
      setSeoTitle(data.titleRu)
    }
  }, [data])

  return (
    <>
      <SeoMetaTegs title={seoTitle} />
      <BaseLayout withoutNav>
        {Object.keys(data).length === 0 ? (
          badRequest ? null : (
            <SmallLoader style={{ height: '50vh' }} size="5.6rem" />
          )
        ) : (
          <div
            style={{ width: '100%', marginBottom: '5rem', padding: '0 1.6rem' }}
          >
            <CompanyCard obj={data} />
            <Title big>{strToUpperCase(dic.company_docs)}</Title>
            <Info fs="h6">{dic.info_4}</Info>
            {/* <RouteCardDocs periodic={hash} forCompany /> */}
          </div>
        )}
      </BaseLayout>
    </>
  )
}
