import moment from 'moment'
import store from '../../redux/store'

export const executeScroll = (objRefs, refName) =>
  objRefs[refName].current.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  })

export const linkWithHttp = (link) => {
  const regex = new RegExp('http')
  return regex.test(link) ? link : `https://${link}`
}

export const invalidLength = (str) => {
  // to pass validation, you must return false
  return str.length > 1 ? false : true
}

export const equalsZero = (str) => {
  // to pass validation, you must return false
  return str.length === 0 ? true : false
}

export const testEarlierLater = (date, index, routepartDates, flag) => {
  // step for check milliseconds

  //flag = 'not earlier' || 'not later'
  // to pass validation, you must return false

  //HAVE TO USE
  //export fn in your form
  // testEarlierLater(
  //                   date = new value from input,
  //                   index  = index of the element being modified in the array,
  //                   routepartDates = arr routeparts,
  //                   flag = type validation
  // )

  // check for correct value

  if (date === '0000-00-00' || date === '' || date === null) {
    return true
  }

  // check if date for first routepart
  if (index === 0) {
    const newVal = moment(date)
    const baseVal = moment()
    const hour = baseVal.hour()
    const min = baseVal.minute()
    const sec = baseVal.second() + 1
    newVal.hour(hour).minute(min).second(sec)
    const result =
      flag === 'not later'
        ? baseVal.format('x') < newVal.format('x')
        : baseVal.format('x') > newVal.format('x')
    return result
  }
  // check against previous routepart
  if (index > 0) {
    const newVal = moment(date)
    const baseVal = moment(routepartDates[index - 1].date1)
    const result =
      flag === 'not later'
        ? baseVal.format('x') < newVal.format('x')
        : baseVal.format('x') > newVal.format('x')
    return result
  }
}

export const lightTestEarlierLater = (date, index, routepartDates, flag) => {
  // step for check day

  //flag = 'not earlier' || 'not later'
  // to pass validation, you must return false
  // check for correct value

  // exsample rules = expresion return true
  // moment('2021-01-01').isSame('2021-01-01', 'day')
  //moment('2021-01-01').isBefore('2021-01-02', 'day')
  // moment('2021-01-01').isAfter('2020-12-31', 'day')

  if (date === '0000-00-00' || date === '' || date === null) {
    return true
  }

  // check if date for first routepart
  if (index === 0) {
    // const newVal = moment(date)
    // const baseVal = moment()
    // const result =
    //   flag === 'not later'
    //     ? moment(baseVal).isBefore(newVal, 'day')
    //     : moment(baseVal).isAfter(newVal, 'day')
    // return result
    return false
  }
  // check against previous routepart
  if (index > 0) {
    const newVal = moment(date)
    const baseVal = moment(routepartDates[index - 1].date1)
    const result =
      flag === 'not later'
        ? moment(baseVal).isBefore(newVal, 'day')
        : moment(baseVal).isAfter(newVal, 'day')
    return result
  }
}

// const isMobile = /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
//   navigator.userAgent

export const isIos = (navigator) => {
  const test = navigator ? /iP(ad|od|hone)/i.test(navigator) : null
  return test ? true : false
}

export const validateAthorizationForm = (helpers, login, password, request) => {
  if (helpers.equalsZero(login.value)) {
    login.setLoginError(true)
    setTimeout(
      () => {
        login.setLoginError(false)
      },
      3000,
      true
    )
    return
  }

  if (helpers.equalsZero(password.value)) {
    password.setPasswordError(true)
    setTimeout(
      () => {
        password.setPasswordError(false)
      },
      3000,
      true
    )
    return
  }
  if (helpers.invalidLength(login.value)) {
    login.setLoginMessage(
      `${helpers.dic.notLess} 2 ${helpers.dic.simbolAccusative}`
    )
    login.setLoginError(true)

    setTimeout(
      () => {
        login.setLoginError(false)
        login.setLoginMessage(helpers.dic.form_err_required)
      },
      3000,
      true
    )
    return
  }

  if (helpers.invalidLength(password.value)) {
    password.setPasswordMessage(
      `${helpers.dic.notLess} 2 ${helpers.dic.simbolAccusative}`
    )
    password.setPasswordError(true)

    setTimeout(
      () => {
        password.setPasswordError(false)
        password.setPasswordMessage(helpers.dic.form_err_required)
      },
      3000,
      true
    )
    return
  }

  return request()
}

export const validateSubmitOfferTrasportForm = (
  helpers,
  drivers,
  errors,
  dates,
  request
) => {
  const requireDriver = drivers.driver.id
  const requireCar = drivers.car.id
  let flag = false
  let i = 0

  if (!requireDriver) {
    helpers.setErrors({ ...errors, driver: { ...errors.driver, bool: 1 } })
    helpers.executeScroll('smart')
    setTimeout(
      () =>
        helpers.setErrors({ ...errors, driver: { ...errors.driver, bool: 0 } }),
      3000,
      true
    )
    return
  }
  if (!requireCar) {
    helpers.setErrors({ ...errors, car: { ...errors.car, bool: 1 } })
    helpers.executeScroll('smart')
    setTimeout(
      () => helpers.setErrors({ ...errors, car: { ...errors.car, bool: 0 } }),
      3000,
      true
    )
    return
  }

  if (drivers.driver.id === drivers.driver2.id) {
    helpers.setIsDriverTwo(true)
    helpers.saveNewDriver('driver2', { union: '' })
    setTimeout(() => helpers.executeScroll('driver2'), 0)
    helpers.setErrors({ ...errors, driver2: { ...errors.driver2, bool: 1 } })
    setTimeout(
      () =>
        helpers.setErrors({
          ...errors,
          driver2: { ...errors.driver2, bool: 0 },
        }),
      3000,
      true
    )
    return
  }

  for (const obj of dates) {
    if(obj?.workaction === 1 || obj?.workaction === 2){
      const test = helpers.lightTestEarlierLater(
          obj.date1,
          i,
          dates,
          'not earlier'
      )
      if (test) {
        helpers.setNotCorrectDatesMsg(i, obj.id)
        flag = true
        break
      }
    }
    if(obj?.workaction === 4 ){
      if(obj.isRequired && !obj?.requiredId){
        helpers.setNotCorrectDatesMsg(i, obj.id)
        flag = true
        break
      }
    }

    i = i + 1
  }

  if (flag) {
    return
  }

  return request()
}

export const validateSubmitSendBetForm = (
  step,
  quantity,
  totalcount,
  helpers,
  dic,
  request
) => {
  if (String(step).length === 0) {
    helpers.setStepErr(true)
    setTimeout(
      () => {
        helpers.setStepErr(false)
      },
      3000,
      true
    )
    return
  }
  if (!Number(step)) {
    helpers.setStepErr(true)
    helpers.setStepMessage(dic.form_err_onlyNumbrers)
    setTimeout(
      () => {
        helpers.setStepMessage(dic.form_err_required)
        helpers.setStepErr(false)
      },
      3000,
      true
    )
    return
  }

  if (quantity.length === 0) {
    helpers.setQuantityErr(true)
    setTimeout(
      () => {
        helpers.setQuantityErr(false)
      },
      3000,
      true
    )
    return
  }
  if (!Number(quantity)) {
    helpers.setQuantityErr(true)
    helpers.setQuantityMessage(dic.form_err_onlyNumbrers)
    setTimeout(
      () => {
        helpers.setQuantityMessage(dic.form_err_required)
        helpers.setQuantityErr(false)
      },
      3000,
      true
    )
    return
  }
  if (quantity > totalcount) {
    helpers.setQuantityErr(true)
    helpers.setQuantityMessage(dic.form_err_valueIsGreater)
    setTimeout(
      () => {
        helpers.setQuantityMessage(dic.form_err_required)
        helpers.setQuantityErr(false)
      },
      3000,
      true
    )
    return
  }
  return request()
}

const showErrPopup = (msg) => {
  return store.dispatch({
    type: 'SHOW_ERROR_API',
    code: 4,
    message: msg || 'error',
  })
}

export const validateNewRouteItems = (arr, errTexts) => {
  // this fn validate:
  //1.  min length routeparts (min 2 points)
  //2. workaction from last point must be unload (2)

  // to pass validation, fn must return false
  if (arr.length < 2) {
    showErrPopup(errTexts.arrLength)
    return true
  }
  if (arr[arr.length - 1].workaction !== 2) {
    showErrPopup(errTexts.workaction)
    return true
  }

  // const testForFerryProperties = arr
  //   .filter((obj) => obj.workaction === 1 || obj.workaction === '1')
  //   .reduce((bool, obj) => {
  //     if (obj.hasOwnProperty('category')) {
  //       if (obj.category.union) {
  //         return obj.category.hasOwnProperty('quantity') &&
  //           obj.category.quantity > 0
  //           ? false
  //           : true
  //       }
  //       return false
  //     }
  //     return false
  //   }, false)

  // if (testForFerryProperties) {
  //   showErrPopup(errTexts.ferryProperties)
  //   return true
  // }

  return false
}

export const validateNewRoutePaymentStep = (
  codeApi,
  isTender,
  routePrice,
  totalcount,
  {
    tenderIsNow,
    tenderFinishTime,
    tenderStartDate,
    tenderStartTime,
    tenderFinishDate,
  },
  routeParts,
  errTexts,
  settings
) => {
  // this fn validate:
  //  - route have codeApi

  //- if route
  // 1. route have routePrice
  // - if tender
  //1. tender have finish date
  //2. tender have totalcount

  // to pass validation, fn must return false

  if (String(codeApi).length <= 0 && settings.codeApi) {
    showErrPopup(errTexts.codeApi)
    return true
  }
  if (isTender) {
    if (!tenderIsNow) {
      if (tenderStartDate.length <= 0) {
        showErrPopup(errTexts.tenderStartDate)
        return true
      }
      if (tenderStartTime.length <= 0) {
        showErrPopup(errTexts.tenderStartTime)
        return true
      }
    }
    if (tenderFinishDate.length <= 0) {
      showErrPopup(errTexts.tenderFinishDate)
      return true
    }
    if (tenderFinishTime.length <= 0) {
      showErrPopup(errTexts.tenderFinishTime)
      return true
    }
    if (String(totalcount.length) <= 0) {
      showErrPopup(errTexts.tenderTotalcount)
      return true
    }
  } else {
    if (String(routePrice.length) <= 0) {
      showErrPopup(errTexts.routePrice)
      return true
    }
  }
  return false
}

export const validateRoutePartItemData = (
  routeParts,
  index,
  onUpdateItemRoutePartError
) => {
  // this fn validate all fields form for routepart item:
  // to pass validation, fn must return false

  const {
    workaction,
    date,
    // country,
    town,
    address,
    company,
    checkpointcontactdata,
    cargo,
    weight,
    volume,
    indicatesСarrier,
  } = routeParts[index]

  switch (workaction) {
    case 1:
      if (!date || !date.id) {
        onUpdateItemRoutePartError(index, 'date')
        return true
      }
      // if (!country || !country.id) {
      //   onUpdateItemRoutePartError(index, 'country')
      //   return
      // }
      if (!town || !town.id) {
        onUpdateItemRoutePartError(index, 'town')
        return true
      }
      if (!company || !company.id) {
        onUpdateItemRoutePartError(index, 'company')
        return true
      }
      if (!address || !address.id) {
        onUpdateItemRoutePartError(index, 'address')
        return true
      }
      if (!checkpointcontactdata || !checkpointcontactdata.id) {
        onUpdateItemRoutePartError(index, 'checkpointcontactdata')
        return true
      }
      if (!cargo || !Boolean(cargo.union.length)) {
        onUpdateItemRoutePartError(index, 'cargo')
        return true
      }
      if (!Boolean(weight.union.length + volume.union.length)) {
        onUpdateItemRoutePartError(
          index,
          'weight',
          weight.length + volume.length
        )
        return true
      }
      return false
    case 2:
      if (!date || !date.id) {
        onUpdateItemRoutePartError(index, 'date')
        return true
      }
      // if (!country || !country.id) {
      //   onUpdateItemRoutePartError(index, 'country')
      //   return
      // }
      if (!town || !town.id) {
        onUpdateItemRoutePartError(index, 'town')
        return true
      }
      if (!company || !company.id) {
        onUpdateItemRoutePartError(index, 'company')
        return true
      }
      if (!address || !address.id) {
        onUpdateItemRoutePartError(index, 'address')
        return true
      }
      if (!checkpointcontactdata || !checkpointcontactdata.id) {
        onUpdateItemRoutePartError(index, 'checkpointcontactdata')
        return true
      }
      return false
    case 4:
      // if (!country || !country.id) {
      //   onUpdateItemRoutePartError(index, 'country')
      //   return
      // }
      if (indicatesСarrier) {
        return false
      }

      if (!town || !town.id) {
        onUpdateItemRoutePartError(index, 'town')
        return true
      }

      return false
    case 3:
      if (!date || !date.id) {
        onUpdateItemRoutePartError(index, 'date')
        return true
      }
      // if (!country || !country.id) {
      //   onUpdateItemRoutePartError(index, 'country')
      //   return
      // }
      if (!town || !town.id) {
        onUpdateItemRoutePartError(index, 'town')
        return true
      }
      if (!company || !company.id) {
        onUpdateItemRoutePartError(index, 'company')
        return true
      }
      // if (!address || !address.id) {
      //   onUpdateItemRoutePartError(index, 'address')
      //   return true
      // }
      return false
    default:
      return false
  }
}

export const validateTypeFile = (arrRules, type) => {
  // this fn find argstype of arr appruved types
  // to pass validation, fn must return false
  return arrRules.find((el) => el === type) ? false : true
}

export const validateSubmitAddNewDriver = (helpers, data, errors, request) => {


   // case submit with wrong value
   const notFixedError = Object.entries(errors).find(([key, data]) => data.bool);

   if(notFixedError && notFixedError?.length){
     return helpers.onSetErrors(notFixedError[0])
   }

   //case empty field
    const emptyFields = Object.keys(errors).filter(
      (key) => data[key].length === 0
    )


    if (emptyFields.length > 0) {
      return helpers.onSetErrors(emptyFields[0])
    }

    //case valid tel number
    if(!helpers.validateTel(data["maincellphone"])){
      return helpers.onSetErrors("maincellphone",helpers.dic.form_err_international_phone_number)
    }

    return request()
}
