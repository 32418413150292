import { takeLatest, put } from 'redux-saga/effects'
export function* worker({ error }) {
  yield put({
    type: 'SHOW_ERROR_API',
    code: String(error.response.status).slice(0, 1),
    message: error.response ? error.response.data.message : error.message,
  })
  if (error.response && error.response.data.relogin) {
    yield put({ type: 'SAGA_LOGOUT' })
  }
  if (error.response && error.response.data.forwardTo) {
    yield put({
      type: 'REDIRECT_OUTSIDE',
      payload: error.response.data.forwardTo,
    })
  }
}

export default function* catchAction() {
  yield takeLatest('SAGA_CATCH_ACTION', worker)
}
