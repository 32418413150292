import { beforeAuth, api, routs } from '../api'

//Get strings for login form
export const getLoginFormStrings = (lang) => {
  return beforeAuth.get(routs.dumpLang, {
    headers: {
      Language: lang,
    },
  })
}
// getListLang
export const getListLang = () => {
  return beforeAuth.get(routs.listLang, {})
}

// Authenticate a user
export const auth = (login, password, currentLang) => {
  return beforeAuth.post(
    routs.auth,
    {
      login,
      password,
    },
    {
      headers: {
        Language: currentLang,
      },
    }
  )
}
//Get user profile
export const getUserProfile = (token, currentLang) => {
  return api.get(routs.profile, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
      Extend: 'dictionary,menu',
      'Content-Type': 'application/json',
    },
  })
}

//Get user settings
export const getSettings = (token, currentLang) => {
  return api.get(routs.getSettings, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

// Request Password Reset
export const requestRenewal = (cellnum, email, currentLang) => {
  return beforeAuth.post(
      routs.renewal,
      {
        email,
        cellnum,
      },
      {
        headers: {
          Language: currentLang,
        },
      }
  )
}

// new Password
export const newPassword = ( password1, password2, code, currentLang) => {
  return beforeAuth.post(
      routs.newPassword,
      {
        password1,
        password2,
        token: code,
      },
      {
        headers: {
          Language: currentLang,
        },
        params: { token: code },
      }
  )
}
