import Type from '../types'

const initialState = {
  routes: [],
  routesList: [],
  routesForWidget: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_ALL_ROUTE_GROUP:
      return {
        ...state,
        routesList: action.payload,
      }
    case Type.SET_ALL_ROUTES:
      return {
        ...state,
        routes: action.payload,
        routesList: state.routesList.map((obj) => {
          const computation = obj.stati.flatMap((type) => {
            return action.payload.filter((el) => el.routestatus === type)
          })
          return {
            ...obj,
            isSort: true,
            content: computation,
            needAttention: computation.filter((el) => el.needAttention).length,
          }
        }),
      }
    default:
      return state
  }
}
