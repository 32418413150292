import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import shortid from 'shortid'
import styled from 'styled-components'
import BaseBtn from '../../components/buttons/BaseBtn'
import BaseInput from '../../components/inputs/BaseInput'
import BaseTextarea from '../../components/inputs/BaseTextarea'
import ModalPortal from '../../components/layouts/ModalPortal'
import Icon from '../../components/Icon'
import { validateSubmitSendBetForm } from '../../lib/helpers/forValidate'
import SelectInput from '../selects/SelectInput'
import BtnLoader from '../../components/layouts/BtnLoader'

const Wrapper = styled.div`
  width: 95%;
  min-width: 32rem;
  max-width: 64rem;
  background-color: ${(p) => p.theme.opposite};
  border-radius: ${(p) => p.theme.PopUpRadius};
`
const Content = styled.div`
  width: 100%;
  padding: 1.6rem 1.6rem 0.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`
const InputWrp = styled.div`
  width: 100%;
  margin-bottom: 1.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Extra = styled.p`
  margin-top: 1.6rem;
  margin-left: 1rem;
  font-size: ${(p) => p.theme.h4};
  font-weight: 600;
`
const Select = styled.p`
  width: fit-content;
  margin-top: 0.8rem;
  margin-bottom: 2rem;
  margin-right: auto;
  font-size: ${(p) => p.theme.h5};
  color: ${(p) => p.theme.links};
  border-bottom: 0.1rem dashed ${(p) => p.theme.links};
  cursor: pointer;
`
const BtnWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1rem;
  border-radius: 0 0 1rem 1rem;
  background-color: ${(p) => p.theme.opposite};
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export default ({
  titleBet = 'Your step',
  titleQuantity = 'Quantity',
  titleComment = 'Add comment ...',
  outOf = 'out of',
  totalcount = 0,
  periodic = null,
  value = null,
  currency = '',
  fnClose = () => {},
  withoutPortal = false,
}) => {
  const dispatch = useDispatch()
  const uniqStepNames = {
    step: shortid.generate(),
    quantity: shortid.generate(),
  }
  const closeBetWindow = useSelector((s) => s.tender.closeBetWindow)
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const [step, setStep] = useState('')
  const [quantity, setQuantity] = useState('')
  const [stepErr, setStepErr] = useState(false)
  const [quantityErr, setQuantityErr] = useState(false)
  const [stepMessage, setStepMessage] = useState(dic.form_err_required)
  const [quantityMessage, setQuantityMessage] = useState(dic.form_err_required)
  const [isComment, setIsComment] = useState(false)
  const [message, setMessage] = useState('')
  const stepList = useSelector((s) => s.tender.bidSteps)

  const flagForSteps = useSelector((s) => s.tender.flagGetSteps)

  const commentToggler = () => {
    setIsComment(!isComment)
  }
  const onChangeTextArea = (e) => {
    setMessage(e.target.value)
  }

  const onChangeInput = (e) => {
    if (e.target.name === uniqStepNames.step) {
      setStep(e.target.value)
    } else {
      setQuantity(e.target.value)
    }
  }

  const onChangeSelect = (value) => {
    setStep(value)
  }

  const onSetMyBid = () => {
    dispatch({
      type: 'SAGA_SEND_MY_BET',
      data: {
        route: periodic,
        pricequote: Number(step),
        remark: message ? message : null,
        loadquote: Number(quantity),
      },
    })
    dispatch({ type: 'START_REQUEST', payload: '1' })
    setStep('')
    setQuantity('')
  }

  const onSubmitForm = () => {
    validateSubmitSendBetForm(
      step,
      quantity,
      totalcount,
      { setStepErr, setStepMessage, setQuantityErr, setQuantityMessage },
      dic,
      onSetMyBid
    )
  }

  const body = () => (
    <Wrapper>
      <Content>
        <Icon
          name="close"
          type="regularText"
          style={{ marginLeft: 'auto' }}
          onClick={() => fnClose(false)}
        />
        <span style={{ width: '100%', minHeight: '16rem' }}>
          {flagForSteps ? (
            <BtnLoader style={{ margin: '5rem auto' }} />
          ) : (
            <>
              <InputWrp>
                {stepList && stepList.length <= 0 ? (
                  <BaseInput
                    style={{ width: '50%' }}
                    label={titleBet}
                    name={uniqStepNames.step}
                    value={step}
                    error={stepErr}
                    message={stepMessage}
                    fnChange={onChangeInput}
                  />
                ) : (
                  <SelectInput
                    style={{ width: '50%' }}
                    name=""
                    value={step}
                    list={stepList}
                    label={titleBet}
                    fnSetValue={onChangeSelect}
                  />
                )}

                <Extra>{currency}</Extra>
              </InputWrp>
              <InputWrp>
                <BaseInput
                  style={{ width: '50%' }}
                  label={titleQuantity}
                  name={uniqStepNames.quantity}
                  value={quantity}
                  error={quantityErr}
                  message={quantityMessage}
                  fnChange={onChangeInput}
                />
                <Extra>{outOf}</Extra>
              </InputWrp>
            </>
          )}
        </span>
        {<Select onClick={commentToggler}>{titleComment}</Select>}
        {isComment && (
          <BaseTextarea
            fnChange={onChangeTextArea}
            value={message}
            label=""
            style={{ marginBottom: '1rem' }}
          />
        )}
      </Content>
      <BtnWrap>
        <BaseBtn
          inContext="popup"
          label={dic.btn_cancel}
          fnClick={() => fnClose(false)}
        />
        <BaseBtn
          inContext="popup"
          label={dic.btn_ok}
          fnClick={onSubmitForm}
          localId="1"
        />
      </BtnWrap>
    </Wrapper>
  )
  useEffect(() => {
    if (value) {
      setStep(value.price)
      setQuantity(value.quantity)
    }
  }, [value])
  useEffect(() => {
    if (closeBetWindow) {
      fnClose(false)
      dispatch({ type: 'CLOSE_BET_WINDOW', payload: false })
    }
  }, [closeBetWindow]) //eslint-disable-line

  useEffect(() => {
    dispatch({ type: 'SAGA_GET_TENDER_BID_STEPS', periodic })
    if (totalcount === 1 || totalcount === '1') {
      setQuantity(1)
    }
    return () => dispatch({ type: 'SET_FLAG_FOR_BID_STEPS', payload: false })
  }, []) //eslint-disable-line
  return withoutPortal ? body() : <ModalPortal>{body()}</ModalPortal>
}
