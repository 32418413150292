import Type from '../types'

const initialState = {
  chat: 0,
  info: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_CHAT_MSG:
      return {
        ...state,
        chat: action.payload,
      }
    case Type.SET_INFO_MSG:
      return {
        ...state,
        info: action.payload,
      }
    case Type.SET_USER_PROFILE:
      return {
        ...state,
        chat: 0,
        info: 0,
      }

    default:
      return state
  }
}
