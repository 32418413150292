import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useDataStore from '../../hooks/useDataStore'
import { FlexWrp } from '../../baseStylesComponents'
import {
  parseDriverInfo,
  parseCarInfo,
  isOverdueTime,
  // parseDateOfLocal,
  datesForScales,
} from '../../lib/helpers/forRoutes'
import Icon from '../Icon'

const WidgetWrp = styled.div`
  position: relative;
  width: 94%;
  left: 3%;
  padding: 2rem 1.6rem;
  margin-bottom: 1.6rem;
  background-color: ${(p) => p.theme.opposite};
  border-radius: ${(p) => p.theme.widgetRadius};
  box-shadow: ${(p) => p.theme.listShadow};
  .dateContent {
    position: absolute;
    top: 1.6rem;
    right: 0;
    padding: 0.8rem 1.5rem;
    border-radius: ${(p) =>
      `${p.theme.widgetRadius} 0  0 ${p.theme.widgetRadius}`};
    background-color: ${(p) => p.theme.substrate};
    color: ${(p) => p.theme.opposite_txt};
    display: flex;
  }
  .warmBg {
    background-color: ${(p) => p.theme.lightError};
  }
  .timer {
    color: ${(p) => p.theme.error};
    font-size: ${(p) => p.theme.h5};
    font-weight: 500;
  }
`
const TitleWrp = styled.p`
  font-size: ${(p) => (p.large ? p.theme.h3 : p.theme.h4)};
  font-weight: 500;
  color: ${(p) => p.theme.regular_txt};
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const CargoTitleWrp = styled.span`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const PeriodicWrp = styled.p`
  text-align: ${(p) => (p.right ? 'right' : 'left')};
  color: ${(p) => p.theme.routeNumb};
  font-size: ${(p) => p.theme.h6};
  font-weight: 300;
`
const InfoWrp = styled.p`
  font-size: ${(p) => (p.isLarger ? p.theme.h4 : p.theme.h5)};
  color: ${(p) => (p.head ? p.theme.trigger_txt : p.theme.additional)};
  text-align: ${(p) => (p.right ? 'right' : 'left')};
  ${(p) =>
    p.isLate &&
    `
    color: ${p.theme.error};
  `};
  ${(p) =>
    p.isTrigerred &&
    `
    color: ${p.theme.trigger_txt};
  `};
  ${(p) =>
    p.short &&
    `
  max-width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  `}
`
const PriceWrp = styled.div`
  font-size: ${(p) => (p.isSmall ? p.theme.h3 : p.theme.h2)};
  color: ${(p) => (p.attention ? p.theme.error : p.theme.trigger_txt)};
  font-weight: 500;
  ${(p) => p.isFree && `color: ${p.theme.additional}`};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const MessageWrp = styled.p`
  font-size: ${(p) => p.theme.h4};
  font-weight: 500;
  color: ${(p) => p.theme.approver};
  margin-bottom: 1rem;
`
const PassedPoint = styled.div`
  z-index: 1;
  width: 0.6rem;
  height: 0.6rem;
  background-color: ${(p) => p.theme.flat_headers};
  border-radius: 50%;
`
const UnloadWrp = styled.div`
  z-index: 1;
  width: ${(p) => (p.small ? '0.6rem' : '2rem')};
  height: ${(p) => (p.small ? '0.6rem' : '2rem')};
  background-color: ${(p) =>
    p.bg
      ? p.theme.basic
      : p.status === 'free' || p.status === 'finish'
      ? p.theme.flat_headers
      : p.theme.opposite};
  ${(p) =>
    !p.bg &&
    `
  border: 0.1rem solid ${p.theme.regular_txt}
  `};
  display: flex;
  justify-content: center;
  align-items: center;
`
const LoadWrp = styled(UnloadWrp)`
  border-radius: 50%;
`
const TriangleWrp = styled.div`
  z-index: 1;
  position: relative;
  border-style: solid;
  left: 0;
  border-width: ${(p) =>
    p.small ? '0.6rem 0.4rem 0 0.4rem' : '2rem 1.2rem 0 1.2rem'};
  border-color: ${(p) => (p.bg ? p.theme.basic : p.theme.regular_txt)}
    transparent;
  border-radius: 0.1rem 0.1rem 0 0.1rem;
  .triangleBg {
    position: absolute;
    top: ${(p) => (p.small ? '-0.53rem' : '-1.9rem')};
    left: ${(p) => (p.small ? '-0.26rem' : '-0.9rem')};
    border-style: solid;
    border-width: ${(p) =>
      p.small ? '0.43rem 0.25rem 0 0.25rem' : '1.6rem 0.9rem 0 0.9rem'};
    border-color: ${(p) =>
        p.bg
          ? p.theme.basic
          : p.status === 'free' || p.status === 'finish'
          ? p.theme.flat_headers
          : p.theme.opposite}
      transparent;
  }
  .content {
    position: absolute;
    top: -1.8rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: ${(p) => p.theme.h5};
  }
`
const Rhombus = styled.div`
  z-index: 1;
  position: relative;
  /* margin-left: ${(p) => (p.small ? '0' : '-0.7rem')}; */
  left: 0;
  width: ${(p) => (p.small ? '0.6rem' : '2rem')};
  height: ${(p) => (p.small ? '0.6rem' : '2rem')};
  background-color: ${(p) =>
    p.bg
      ? p.theme.basic
      : p.status === 'free' || p.status === 'finish'
      ? p.theme.flat_headers
      : p.theme.opposite};
  ${(p) =>
    !p.bg &&
    `
  border: 0.1rem solid ${p.theme.regular_txt}
  `};
  transform: rotate(45deg);
  display: flex;
  justify-items: center;
  align-items: center;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`
const ActionWrp = styled.div`
  width: 100%;
  background-color: ${(p) => p.theme.opposite};
  display: flex;
  justify-content: center;
  align-items: center;
`
const SuccessMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${(p) => p.theme.approver};
  .title {
    font-size: ${(p) => p.theme.h3};
  }
  .text {
    font-size: ${(p) => p.theme.h5};
  }
`
export const ConfirmOfAction = ({
  title = 'title',
  text = 'text text text',
}) => (
  <SuccessMessage>
    <Icon name="done" type="approver" style={{ marginRight: '1rem' }} />
    <div>
      <p className="title">{title}</p>
      <p className="text">{text}</p>
    </div>
  </SuccessMessage>
)

export const Load = ({
  small = false,
  passed = false,
  bg = false,
  content = null,
  status = 'free',
  style,
}) =>
  passed ? (
    <PassedPoint />
  ) : (
    <LoadWrp small={small} bg={bg} style={style} status={status}>
      <div style={{ paddingRight: '0.1rem' }}>{content && content}</div>
    </LoadWrp>
  )
export const Unload = ({
  small = false,
  passed = false,
  bg = false,
  content = null,
  status = 'free',
  style,
}) =>
  passed ? (
    <PassedPoint />
  ) : (
    <UnloadWrp small={small} bg={bg} style={style} status={status}>
      <div>{content && content}</div>
    </UnloadWrp>
  )
export const CrossedBorder = ({
  small = false,
  passed = false,
  bg = false,
  content = null,
  status = 'free',
  style,
}) =>
  passed ? (
    <PassedPoint />
  ) : (
    <TriangleWrp small={small} bg={bg} style={style} status={status}>
      <div className="triangleBg">
        {content && <span className="content">{content}</span>}
      </div>
    </TriangleWrp>
  )
export const Customs = ({
  small = false,
  passed = false,
  bg = false,
  content = null,
  status = 'free',
  style,
}) =>
  passed ? (
    <PassedPoint />
  ) : (
    <Rhombus small={small} bg={bg} style={style} status={status}>
      {content && <span className="content">{content}</span>}
    </Rhombus>
  )

export const Wrapper = ({
  children,
  style,
  isMrgnBtm = false,
  onClick = () => {},
}) => (
  <WidgetWrp style={style} isMrgnBtm={isMrgnBtm} onClick={onClick}>
    {children}
  </WidgetWrp>
)
export const Title = ({
  children,
  large = false,
  isMessage = false,
  style,
}) => (
  <TitleWrp style={style} large={large}>
    {children}
    {isMessage && (
      <Icon
        name="mail"
        type="regular"
        style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
      />
    )}
  </TitleWrp>
)
export const CargoTitle = ({ children, style }) => (
  <CargoTitleWrp style={style}>{children}</CargoTitleWrp>
)
export const Info = ({
  children,
  style,
  isLate = false,
  right = false,
  head = false,
  short = false,
  isLarger = false,
  isTrigerred = false,
  className,
}) => (
  <InfoWrp
    className={className}
    short={short}
    right={right}
    head={head}
    isLate={isLate}
    isTrigerred={isTrigerred}
    style={style}
    isLarger={isLarger}
  >
    {children}
    {isLate && (
      <Icon
        name="atencion"
        type="error"
        style={{ marginLeft: '0.5rem', marginBottom: '1rem' }}
      />
    )}
  </InfoWrp>
)
export const Price = ({
  children,
  style,
  attention = false,
  isFree = false,
  isSmall = false,
}) => (
  <PriceWrp
    style={style}
    attention={attention}
    isFree={isFree}
    isSmall={isSmall}
  >
    {children}
  </PriceWrp>
)
export const Message = ({ children, style }) => (
  <MessageWrp style={style}>{children}</MessageWrp>
)
export const Action = ({ children, style }) => (
  <ActionWrp style={style}>{children}</ActionWrp>
)
const defaultDriversInfo = {
  car_relation: {
    make_relation: { title_ru: 'Citroen' },
    number: '23АВА44',
  },
  driver2_relation: null,
  driver_relation: {
    firstname: 'Tamara',
    lastname: 'Витвицкая',
    maincellphone: '0667778844',
  },
  trailer_relation: null,
}

const DriverInfoWrp = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`

export const DriversInfo = ({
  obj = defaultDriversInfo,
  withOutTopMrgn = false,
}) => {
  return (
    <DriverInfoWrp>
      <Icon
        name="car"
        type="icons"
        style={{ marginRight: '1.5rem', marginTop: '1.4rem' }}
      />
      <div>
        {obj.driver_relation && (
          <Info isLarger style={!withOutTopMrgn ? { marginTop: '1rem' } : null}>
            <CargoTitle>{parseDriverInfo(obj.driver_relation)}</CargoTitle>
          </Info>
        )}

        {obj.driver2_relation && (
          <Info isLarger>
            <CargoTitle>{parseDriverInfo(obj.driver2_relation)}</CargoTitle>
          </Info>
        )}
        {obj.car_relation && (
          <Info isLarger style={{ marginBottom: '1.6rem' }}>
            <CargoTitle>{parseCarInfo(obj)}</CargoTitle>
          </Info>
        )}
      </div>
    </DriverInfoWrp>
  )
}
const pointComponent = (id, idx, active, status) => {
  const isSmall = idx !== active
  const isPassed = idx < active
  const content = active === idx ? idx + 1 : null
  return {
    1: (
      <Load
        key={`${id}${idx}`}
        small={isSmall}
        passed={isPassed}
        bg={!isSmall}
        content={content}
        status={status}
      />
    ),
    2: (
      <Unload
        key={`${id}${idx}`}
        small={isSmall}
        passed={isPassed}
        bg={!isSmall}
        content={content}
        status={status}
      />
    ),
    4: (
      <Customs
        key={`${id}${idx}`}
        small={isSmall}
        passed={isPassed}
        bg={!isSmall}
        content={content}
        status={status}
      />
    ),
    3: (
      <CrossedBorder
        key={`${id}${idx}`}
        small={isSmall}
        passed={isPassed}
        bg={!isSmall}
        content={content}
        status={status}
      />
    ),
  }
}

const WrapperScale = styled.div`
  width: 100%;
  height: ${(p) => (p.isTwo ? '11.6rem' : '17rem')};
  margin-top: 1.2rem;
  margin-bottom: ${(p) => (p.isActive ? '2rem' : '0')};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`
const WrapperScale2 = styled.div`
  width: 100%;
  margin-top: 1.2rem;
  /* margin-bottom: ${(p) => (p.isActive ? '2rem' : '0')}; */
`
const PointWrpScale2 = styled.div`
  position: relative;
  width: 2.3rem;
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  :before {
    position: absolute;
    content: '';
    top: ${(p) => (p.isFirst ? '0.5rem' : 0)};
    left: calc(50% - 0.05rem);
    width: 0.1rem;
    height: 680%;
    max-height: ${(p) => (p.isHaveDate ? '6.8rem' : '7rem')};
    background-color: ${(p) => p.theme.inputBorder};
  }
  ${(p) =>
    p.isLast &&
    `
    :before {
      height: 0;
    }
  `}
`
const LeftForScale = styled.div`
  height: 100%;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const Scale1 = styled.div`
  position: relative;
  width: 2.3rem;
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  :before {
    position: absolute;
    content: '';
    top: 0;
    left: calc(50% - 0.05rem);
    width: 0.1rem;
    height: 120%;
    background-color: ${(p) => p.theme.inputBorder};
  }
  ${(p) =>
    p.isLast &&
    `
    :before {
      height: 200%;
    }
  `}
`
const Scale2 = styled(Scale1)`
  height: ${(p) => (p.isTwo ? (p.isActive ? '90%' : '79%') : '61%')};
  :before {
    height: 100%;
  }
  ${(p) =>
    p.isLast &&
    `
  height: 31%;
    :before {
      height:0;
    }
  `};
`
const Town = styled.p`
  margin-bottom: 0.4rem;
  color: ${(p) => p.theme.regular_txt};
  font-size: ${(p) => p.theme.h3};
  ${(p) =>
    p.isHave &&
    `
  color:${p.isActive ? p.theme.regular_txt : p.theme.additional};
  font-size: ${p.isActive ? p.theme.h2 : p.theme.h4};
  `}
`
const Town2 = styled.p`
  margin-bottom: 0.4rem;
  color: ${(p) => (p.isFaded ? p.theme.additional : p.theme.regular_txt)};
  font-size: ${(p) => (p.isActive ? p.theme.h2 : p.theme.h3)};
`

export const ScaleRouteParts = ({
  arr = [],
  toDetermineTheHotPoint = () => {},
  dic,
  status = 'free',
}) => {
  const [first, setFirst] = useState([])
  const [second, setSecond] = useState([])
  const [isTwo, setIsTwo] = useState(true)
  const [isActiveIndex, setActiveIndex] = useState(0)
  const [isHaveActive, setIsHaveActive] = useState(false)
  const { MONTH_SHORT } = useDataStore()

  const statusFilter = {
    free: -1,
    enroute: arr.findIndex((obj) => !obj.complete),
    awaitaction: arr.findIndex((obj) => !obj.complete)
      ? arr.findIndex((obj) => !obj.complete)
      : arr.length - 1,
    finish: -1,
  }
  useEffect(() => {
    const result = statusFilter[status]
    const insideData = [...arr]
    if (result > 0 || status === 'enroute' || status === 'awaitaction')
      toDetermineTheHotPoint(arr[result].id)
    setActiveIndex(result)
    setIsHaveActive(result >= 0)
    setIsTwo(result === 0 || result === arr.length - 1 || result < 0)
    setFirst(insideData.splice(0, result))
    setSecond([...insideData])
  }, [status, arr]) //eslint-disable-line

  return (
    <WrapperScale isTwo={isTwo} isActive={isHaveActive}>
      <LeftForScale>
        {first.length > 0 && (
          <Scale1 isLast={isTwo && second.length === 1}>
            {first.map((item) => (
              <PassedPoint key={item.id} />
            ))}
          </Scale1>
        )}
        {second.length > 0 && (
          <Scale2
            isTwo={isTwo}
            isLast={isTwo && second.length === 1}
            isActive={isTwo && isActiveIndex === 0}
          >
            {second.map((item) => {
              const baseIdx = arr.findIndex((obj) => obj.id === item.id)
              return pointComponent(item.checkpoint, baseIdx, isActiveIndex)[
                item.workaction
              ]
            })}
          </Scale2>
        )}
      </LeftForScale>
      <div>
        <Town
          isHave={isHaveActive}
          isActive={isHaveActive && isActiveIndex === 0}
        >
          {arr[0].checkpoint_relation.town_relation.title_ru}
        </Town>
        <Info
          isLarger={isHaveActive && isActiveIndex === 0}
          isLate={
            status !== 'finish' && (!isHaveActive || isActiveIndex === 0)
              ? statusFilter[status] < 0
                ? false
                : isOverdueTime(arr[0].date1)
              : false
          }
          style={{
            marginBottom: isTwo ? '2.6rem' : '2rem',
            fontWeight: '300',
          }}
        >
          {/* {arr[0].date1 === '0000-00-00'
            ? dic.msg_6
            : parseDateOfLocal(arr[0].date1, arr[0].time1, MONTH_SHORT)} */}
          {datesForScales(
            arr[0].date1,
            arr[0].time1,
            arr[0].date2,
            arr[0].time2,
            MONTH_SHORT,
            dic.msg_6,
            dic.roundTheClock
          )}
        </Info>
        <Town
          isHave={isHaveActive}
          isActive={isTwo ? isActiveIndex === arr.length - 1 : isHaveActive}
        >
          {
            arr[isActiveIndex > 0 ? isActiveIndex : arr.length - 1]
              .checkpoint_relation.town_relation.title_ru
          }
        </Town>
        <Info
          isLarger={isTwo ? isActiveIndex > 0 : isHaveActive}
          isLate={
            status !== 'finish' && !isTwo && isActiveIndex > 0
              ? statusFilter[status] < 0
                ? false
                : isOverdueTime(
                    arr[isActiveIndex > 0 ? isActiveIndex : arr.length - 1]
                      .date1
                  )
              : false
          }
          style={{
            marginBottom: isTwo
              ? status === 'enroute' || status === 'awaitaction'
                ? '1.6rem'
                : '1rem'
              : '2rem',
            fontWeight: '300',
          }}
        >
          {/* {arr[isActiveIndex > 0 ? isActiveIndex : arr.length - 1].date1 ===
          '0000-00-00'
            ? dic.msg_6
            : parseDateOfLocal(
                arr[isActiveIndex > 0 ? isActiveIndex : arr.length - 1].date1,
                arr[isActiveIndex > 0 ? isActiveIndex : arr.length - 1].time1,
                MONTH_SHORT
              )} */}
          {datesForScales(
            arr[isActiveIndex > 0 ? isActiveIndex : arr.length - 1].date1,
            arr[isActiveIndex > 0 ? isActiveIndex : arr.length - 1].time1,
            arr[isActiveIndex > 0 ? isActiveIndex : arr.length - 1].date2,
            arr[isActiveIndex > 0 ? isActiveIndex : arr.length - 1].time2,
            MONTH_SHORT,
            dic.msg_6,
            dic.roundTheClock
          )}
        </Info>
        {!isTwo && (
          <>
            <Town isHave={isHaveActive} isActive={false}>
              {arr[arr.length - 1].checkpoint_relation.town_relation.title_ru}
            </Town>
            <Info
              isLarger={!isHaveActive}
              style={{
                marginBottom: status === 'enroute' ? '1.6rem' : '2rem',
                fontWeight: '300',
              }}
            >
              {/* {arr[arr.length - 1].date1 === '0000-00-00'
                ? dic.msg_6
                : parseDateOfLocal(
                    arr[arr.length - 1].date1,
                    arr[arr.length - 1].time1,
                    MONTH_SHORT
                  )} */}
              {datesForScales(
                arr[arr.length - 1].date1,
                arr[arr.length - 1].time1,
                arr[arr.length - 1].date2,
                arr[arr.length - 1].time2,
                MONTH_SHORT,
                dic.msg_6,
                dic.roundTheClock
              )}
            </Info>
          </>
        )}
      </div>
    </WrapperScale>
  )
}

export const Scale2RouteParts = ({
  arr = [],
  toDetermineTheHotPoint = () => {},
  dic,
  status = 'free',
}) => {
  const [isActiveIndex, setActiveIndex] = useState(0)
  const [isHaveActive, setIsHaveActive] = useState(false)
  const { MONTH_SHORT } = useDataStore()

  const statusFilter = {
    free: -1,
    enroute: arr.findIndex((obj) => !obj.complete),
    awaitaction: arr.findIndex((obj) => !obj.complete)
      ? arr.findIndex((obj) => !obj.complete)
      : arr.length - 1,
    finish: -1,
  }
  useEffect(() => {
    const result = statusFilter[status]
    if (result > 0 || status === 'enroute' || status === 'awaitaction')
      toDetermineTheHotPoint(arr[result].id)
    setActiveIndex(result)
    setIsHaveActive(result >= 0)
  }, [status, arr]) //eslint-disable-line

  return (
    <WrapperScale2 isActive={isHaveActive}>
      {arr.map((item, index) => (
        <FlexWrp
          key={item.id}
          style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}
        >
          <PointWrpScale2
            isFirst={index === 0}
            isLast={index === arr.length - 1}
            isHaveDate={item.date1 !== '0000-00-00'}
          >
            {
              pointComponent(item.checkpoint, index, isActiveIndex, status)[
                item.workaction
              ]
            }
          </PointWrpScale2>
          <div
            style={{
              marginLeft: '1rem',
              marginBottom: index === arr.length - 1 ? '1rem' : '2rem',
            }}
          >
            <Town2
              isFaded={index < isActiveIndex && status !== 'free'}
              isActive={index === isActiveIndex && status !== 'free'}
            >
              {item.checkpoint_relation.town_relation.title_ru || dic[`workaction_${item.workaction}`]}
            </Town2>
            <Info
              isLarger={!isHaveActive}
              style={{
                fontWeight: '300',
              }}
            >
              {/* {item.date1 === '0000-00-00'
                ? dic.msg_6
                : parseDateOfLocal(item.date1, item.time1, MONTH_SHORT)} */}
              {datesForScales(
                item.date1,
                item.time1,
                item.date2,
                item.time2,
                MONTH_SHORT,
                dic.msg_6,
                dic.roundTheClock
              )}
            </Info>
          </div>
        </FlexWrp>
      ))}
    </WrapperScale2>
  )
}
