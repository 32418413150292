import React from 'react'
import styled from 'styled-components'
import { strToUpperCase } from '../../lib/helpers/forStyling'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`
const Textarea = styled.textarea`
  width: 100%;
  min-height: 9rem;
  padding: 1rem;
  resize: none;
  background-color: ${(p) => p.theme.selected};
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => (p.error ? p.theme.error : p.theme.regular_txt)};
  ${(p) => (p.error ? `border: 0.1rem solid ${p.theme.error};` : null)};
  :focus {
    border: 0.1rem solid ${(p) => p.theme.inputBorder};
  }
`
const Label = styled.p`
  width: 100%;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  text-align: left;
  opacity: 0.8;
  font-size: ${(p) => p.theme.h5};
  color: ${(p) => p.theme.additional};
  display: flex;
`
const ErrorMessage = styled.span`
  padding-left: 0.5rem;
  color: ${(p) => p.theme.error};
`

export default ({
  value = '',
  name = 'dafault',
  label = 'label',
  error = false,
  message = 'not correct format',
  fnChange = () => {},
  style,
}) => {
  return (
    <Wrapper style={style}>
      <Label>
        {strToUpperCase(label)}
        {error ? <ErrorMessage>{`* ${message}`}</ErrorMessage> : null}
      </Label>
      <Textarea name={name} value={value} onChange={fnChange} error={error} />
    </Wrapper>
  )
}
