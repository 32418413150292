import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Icon from '../../components/Icon'
import useOutsideClick from '../../hooks/useOutsideClick'
import SmallLoader from '../../components/layouts/SmallLoader'
import { strToUpperCase } from '../../lib/helpers/forStyling'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  .this-input {
    width: 100%;
    height: 4.6rem;
    padding: 0 4.5rem 0 4.5rem;
    background-color: ${(p) => p.theme.selected};
    font-size: ${(p) => p.theme.h4};
    color: ${(p) => (p.error ? p.theme.error : p.theme.regular_txt)};
    ${(p) => (p.error ? `border: 0.1rem solid ${p.theme.error};` : null)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    :focus {
      border: 0.1rem solid ${(p) => p.theme.inputBorder};
    }
  }
`
const Label = styled.p`
  width: 100%;
  margin-bottom: 0.5rem;
  padding-left: 0.4rem;
  text-align: left;
  color: ${(p) => (p.error ? p.theme.error : p.theme.additional)};
  font-size: ${(p) => p.theme.h5};
  display: flex;
  opacity: 0.8;
`
const List = styled.div`
  z-index: 5;
  position: absolute;
  width: 99%;
  max-height: 30rem;
  top: 100%;
  left: 0.5%;
  padding: 1.5rem;
  background-color: ${(p) => p.theme.opposite};
  overflow: auto;
  box-shadow: ${(p) => p.theme.listShadow};
`
const ListItem = styled.p`
  padding: 1rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`
const ErrorMessage = styled.span`
  padding-left: 0.5rem;
  color: ${(p) => p.theme.error};
`
const ArrowWrp = styled.span`
  position: absolute;
  top: calc(55% - 0.7rem);
  right: 1.5rem;
  padding: 0.7rem 0.5rem;
  transform: ${(p) => (p.isrotate ? `rotate(-90deg)` : `rotate(90deg)`)};
  transition: 0.2s ease;
  cursor: pointer;
`
const IconWrp = styled.span`
  position: absolute;
  top: 54%;
  left: 1.5rem;
  cursor: pointer;
`

export default ({
  value = '',
  name = 'driver',
  label = 'Select with search',
  error = 0,
  message = 'this required field',
  nothingToDisplay = 'nothing to display',
  list = [],
  fnChange = () => {},
  fnSetValue = () => {},
  fnFormValue = () => {},
  style,
}) => {
  const [isList, setShowIsList] = useState(false)
  const [isRotate, setIsRotate] = useState(0)
  const myRef = useRef(null)
  const dispatch = useDispatch()
  const isLoader = useSelector((s) => s.myPark.loader)

  const icons = {
    driver: 'driver',
    driver2: 'driver',
    car: 'mack',
    trailer: 'trailer',
    user: 'user',
    cargo: 'hookBig',
  }

  const togglerList = () => {
    if (!isList) {
      dispatch({ type: 'FILTER_SMART_LIST', filterType: name })
      setShowIsList(true)
      myRef.current.focus()
      setIsRotate(1)
      return
    } else {
      // dispatch({ type: 'CLEAR_SMART_LIST' })
      setIsRotate(0)
      setShowIsList(false)
    }
  }
  const showList = () => {
    dispatch({ type: 'FILTER_SMART_LIST', filterType: name })
    setShowIsList(true)
    setIsRotate(1)
  }
  const closeList = () => {
    // if (isList) dispatch({ type: 'CLEAR_SMART_LIST' })
    setShowIsList(false)
    setIsRotate(0)
  }
  const clickFromItem = (value, obj) => {
    setShowIsList(false)
    setIsRotate(0)
    fnSetValue(value)
    fnFormValue(name, obj)
  }
  useOutsideClick(myRef, closeList)
  return (
    <Wrapper style={style} ref={myRef} error={error}>
      <Label error={error}>
        {strToUpperCase(label)}
        {(error === 1 || error === true) && (
          <ErrorMessage>{`${message}`}</ErrorMessage>
        )}
      </Label>
      <input
        className="this-input"
        type="text"
        name={name}
        value={value}
        onChange={fnChange}
        error={error}
        onClick={showList}
        autoComplete="off"
      />
      {isList && (
        <List>
          {list && list.length > 0 ? (
            list.map((el) => {
              return (
                <ListItem
                  key={el.id}
                  onClick={() => clickFromItem(el.union, el)}
                >
                  {el.union}
                </ListItem>
              )
            })
          ) : isLoader ? (
            <SmallLoader />
          ) : (
            <p>{strToUpperCase(nothingToDisplay)}</p>
          )}
        </List>
      )}
      {name && (
        <IconWrp onClick={togglerList}>
          <Icon name={icons[name]} type="icons" />
        </IconWrp>
      )}
      <ArrowWrp isrotate={isRotate} onClick={togglerList}>
        <Icon name="arrowShevron" type="regularText" />
      </ArrowWrp>
    </Wrapper>
  )
}
