import React, { useState, useMemo, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import BaseBtn from '../../components/buttons/BaseBtn'
import BaseInput from '../../components/inputs/BaseInput'
import useRouter from '../../hooks/useRouter'


const Wrapper = styled.div`
  width: 100%;
  max-width: 80rem;
  height: 30rem;
  margin-top: ${(p) => p.marginTop};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const Title = styled.p`
  margin-bottom: 2rem;
  font-size: ${(p) => p.theme.h2};
`
const MessageWrap = styled.div`
  width: 100%;
  height: 80%;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
const Text = styled.p`
  margin-bottom: 2rem;
   :last-child {
    margin-bottom: 0;
  }
`


export default ({ marginTop = 0 }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const [hasToken, setHasToken] = useState(false)
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [code, setCode] = useState('')

  const [error, setError] = useState('')
  const [errorMessage, setErrorMessage] = useState(dic.form_err_required)
  const lang = useSelector((s) => s.defaultLang.langObj.newPassword)
  const location = useLocation()
  const token = useMemo(() => {
    const params = new URLSearchParams(location?.search)
    return  params.get('token')
  }, [location])

  const onChangeInput = (e) => {
    if (e.target.name === 'password1') {
      setPassword1(e.target.value)
    }
    if (e.target.name === 'password2') {
      setPassword2(e.target.value)
    }

    if (e.target.name === 'code') {
      setCode(e.target.value)
    }
  }
  const onSubmitForm = () => {

    if (code.length === 0 ) {
      setHasToken(false)
      setErrorMessage(dic.form_err_required)
      setError('code')
      setTimeout(
          () => {
            setErrorMessage("")
            setError("")
          },
          3000,
          true
      )
      return
    }

    if (password1.length === 0) {
      setError("password1")
      setErrorMessage(dic.form_err_required)
      setTimeout(
        () => {
          setErrorMessage("")
          setError("")
        },
        3000,
        true
      )
      return
    }
    if (password1.length < 3) {
      setError("password1")
      setErrorMessage(`${dic.form_err_valueCannotBeShorterThan} 3`)
      setTimeout(
          () => {
            setErrorMessage("")
            setError("false")
          },
          3000,
          true
      )
      return
    }
    if (password1 !== password2) {

      setError("passwords")
      setErrorMessage(dic.form_err_mustBeTheSame)
      setTimeout(
          () => {
            setErrorMessage("")
            setError(false)
          },
          3000,
          true
      )
      return
    }

    dispatch({ type: 'START_REQUEST', payload: 'postNewPasswordBtn' })

    dispatch({
      type: 'POST_NEW_PASSWORD',
      password1,
      password2,
      code,
      history: router,
      successMsg: lang.success_msg
    })
  }

  useEffect(()=>{
    if(token){
      setCode(token)
      setHasToken(true)
    }

  }, [])

  return (
    <Wrapper marginTop={marginTop}>
      <Title>{lang?.title}</Title>
        <>
          {!hasToken && (
              <BaseInput
                  name="code"
                  label={lang?.input_code}
                  error={error === "code"  || error === "all"}
                  message={errorMessage}
                  value={code}
                  fnChange={onChangeInput}
                  style={{ marginBottom: '1rem' }}
              />
          )}
          <BaseInput
            name="password1"
            label={lang?.input_password1}
            error={error === "password1"  || error === "passwords" || error === "all"}
            message={errorMessage}
            value={password1}
            fnChange={onChangeInput}
            style={{ marginBottom: '1rem' }}
          />
          <BaseInput
            name="password2"
            label={lang?.input_password2}
            error={error === "password2"  || error === "passwords" || error === "all"}
            message={errorMessage}
            value={password2}
            fnChange={onChangeInput}
            style={{ marginBottom: '2rem' }}
          />
          <BaseBtn
            label={lang?.button_confirm}
            inContext="confirm"
            toUpperCase
            fullSize
            fnClick={onSubmitForm}
            localId="postNewPasswordBtn"
          />
        </>
    </Wrapper>
  )
}
