import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import shortId from 'shortid'
import RoundBtn from '../../buttons/RoundBtn'
import { Info, Title } from '../../smallPartsForInterface/SmallPartsCard'
import icons from '../../../assets/img/fileIcons'
import socialMediaIcons from '../../../assets/img/socialMediaIcons'
import BasePopup from '../../popUps/BasePopup'
import Modal from '../../layouts/ModalPortal'
import BaseLink from '../../links/BaseLink'
import DottedLink from '../../links/DottedLink'
import { validateTypeFile } from '../../../lib/helpers/forValidate'
import { strToUpperCase } from '../../../lib/helpers/forStyling'
import { ctrlCClick } from '../../../lib/helpers/other'
import { FilesTypesApprovedDefault, forwardTo } from '../../../lib/constants'
import LoadFile from "./LoadFile";

const Wrapper = styled.div`
  width: 100%;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
const Content = styled.div`
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: ${(p) => p.theme.selected};
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`
const FlexWrp = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Item = styled.div`
  position: relative;
  width: 44%;
  margin-right: 2%;
  margin-bottom: 1rem;
  :nth-child(odd) {
    margin-left: 4%;
  }
  :nth-child(even) {
    margin-left: 2%;
    margin-right: 4%;
  }
`
const LeftWrp = styled.div`
  position: absolute;
  top: -1rem;
  left: -1rem;
`
const RightWrp = styled.div`
  position: absolute;
  top: -1rem;
  right: 0.5rem;
`

const IconWrp = styled.div`
  width: 100%;
  padding: 2rem 0;
  background-color: ${(p) => p.theme.opposite};
  border: 0.1rem solid ${(p) => p.theme.lines};
  display: flex;
  justify-content: center;
  align-items: center;
`
const Img = styled.img`
  width: 30%;
`
const UploadImg = styled.img`
  width: 100%;
  height:100%;
`
const FileTitle = styled.p`
  margin-top: 0.5rem;
  color: ${(p) => p.theme.elements_low};
`
const ShareTitle = styled(FileTitle)`
  margin-top: 0;
  font-size: ${(p) => p.theme.h4};
`
const CropTitle = styled(FileTitle)`
  margin-top: 0;
  padding-right: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default ({ periodic = '', forCompany = false }) => {
  const dispatch = useDispatch()
  const [isShare, setIsShare] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const profile = useSelector((s) => s.user.profile)
  const isLoader = useSelector((s) => s.routeDocuments.isLoader)
  const general = useSelector((s) => s.routeDocuments.generalFiles)
  const list = useSelector((s) => s.routeDocuments.uploadFiles)
  const [currentFile, setCurrentFile] = useState({})
  const [shareTypes] = useState(['facebook', 'viber', 'telegram', 'gmail'])
  const linkRef = useRef()
  const downloadRef = useRef()
  const dic = useSelector((s) => s.defaultLang.serverDictionary)

  const getData = () => {
    dispatch({ type: 'SAGA_GET_ROUTE_DOCUMENTS', periodic })
  }
  const setList = (dataArr) => {
    dispatch({ type: 'SAGA_GET_ROUTE_DOCUMENTS', periodic, dataArr })
  }

  const openDeletePopup = (obj) => {
    setCurrentFile(obj)
    setIsDelete(true)
  }
  const closeDeletePopup = () => {
    setCurrentFile({})
    setIsDelete(false)
  }
  const onDeleteFile = (id) => {
    setList(list.filter((obj) => obj.id !== id))
    setCurrentFile({})
    closeDeletePopup()
  }
  const openSharePopup = (obj) => {
    setCurrentFile(obj)
    setIsShare(true)
  }
  const closeSharePopup = () => {
    setCurrentFile({})
    setIsShare(false)
  }

  const onShareLink = (obj, id) => {
    const url = () => {
      const replacePlus = obj?.url
          .split('')
        .map((symbol) => {
          if (symbol === '+') {
            return '%2B'
          } else {
            return symbol
          }
        })
        .join('')
      return `${forwardTo[id]}${replacePlus}`
    }

    const el = linkRef.current
    if (el) {
      el.setAttribute('href', url())
      el.click()
    }
    closeSharePopup()
  }

  const onCopyLink = (link) => {
    ctrlCClick(link)
    closeSharePopup()
  }
  const onLoadFile = (link) => {
    const el = downloadRef.current
    if (el) {
      el.setAttribute('href', link)
      el.click()
    }
  }

  const onChangeInput = (e) => {
    const file = document.getElementById(e.target.name).files[0]
    if (!file) {
      return
    }
    const separate = file.name.split('.')
    const fileType = separate[separate.length - 1].toLowerCase()
    const moment = new Date()
    const newObj = {
      id: shortId.generate(),
      titleRu: file.name,
      type: fileType,
      size: file.size,
      authorID: `${profile.company} > ${profile.name}`,
      dateCreated: moment.toLocaleDateString(),
      timeCreated: moment.toLocaleTimeString().slice(0, -3),
      publicLink:
        'https://storage.sovtes.ua/ZM4xlxWqQECnFuTEX0wxy+u36hQvGzjtjNC8wgffI3ELmzOkXKBBfUKIJAF8T30KMDNlMDgyYTNkY2M5YTE3NDk0YTM4YTU5YjAxNmY1Y2M2MzMwZDNjZg==',
    }
    if (file.size > 8000000) {
      dispatch({
        type: 'SHOW_ERROR_API',
        code: 4,
        message: strToUpperCase(dic.info_55),
      })
      return
    }
    if (validateTypeFile(FilesTypesApprovedDefault, fileType)) {
      dispatch({
        type: 'SHOW_ERROR_API',
        code: 4,
        message: strToUpperCase(`${dic.info_9} ${fileType}`),
      })
      return
    }
    setList([...list, newObj])
  }

  useEffect(() => {
    getData()
  }, []) //eslint-disable-line

  return (
    <>
      {isShare && (
        <Modal>
          <BasePopup title={dic.share} isBright fnCancel={closeSharePopup}>
            {shareTypes.map((type) => (
              <FlexWrp
                style={{ marginBottom: '2.4rem' }}
                onClick={() => onShareLink(currentFile, type)}
                key={type}
              >
                <Img
                  src={socialMediaIcons[type]}
                  style={{ width: '10%', marginRight: '2rem' }}
                />
                <ShareTitle>{strToUpperCase(type)}</ShareTitle>
              </FlexWrp>
            ))}
            <FlexWrp
              style={{ marginBottom: '2.4rem' }}
              onClick={() => onCopyLink(currentFile?.url)}
            >
              <Img
                src={socialMediaIcons.link}
                style={{ width: '10%', marginRight: '2rem' }}
              />
              <DottedLink title={dic.copy} disabled path="/" />
            </FlexWrp>
          </BasePopup>
        </Modal>
      )}
      {isDelete && (
        <Modal>
          <BasePopup
            title={`${strToUpperCase(dic.info_8)}  "${currentFile.titleRu}" ?`}
            fsTitle="h4"
            isBright
            withBtn
            btnOk={dic.btn_delete}
            withoutContent
            fnSave={() => onDeleteFile(currentFile.id)}
            fnCancel={closeDeletePopup}
          />
        </Modal>
      )}
        <Wrapper>
          <Title big mrgnBtm="2.6rem" style={{ marginRight: 'auto' }}>
            {strToUpperCase(dic.documents)}
          </Title>
          <a ref={linkRef} target="_blank" href="/" style={{ display: 'none' }}>
            link for social groups
          </a>
          <a
            ref={downloadRef}
            href="/"
            target="_blank"
            download
            rel="noopener noreferrer"
            style={{ display: 'none' }}
          >
            link for download
          </a>
          {!isLoader && list.length === 0 && general.length === 0 ? (
            <Info>{strToUpperCase(dic.info_7)}</Info>
          ) : (
            <>
              <div
                style={{
                  width: '100%',
                  marginBottom: list.length || forCompany ? '4rem' : 'none',
                }}
              >
                {general.length > 0 &&
                  !forCompany &&
                  general.map((obj) => (
                    <FlexWrp
                      key={obj.id}
                      style={{ marginRight: 'auto', marginBottom: '1rem' }}
                    >
                      <BaseLink
                        label={`${obj.titleRu} ${obj.size}`}
                        rout={obj.url}
                        isInNewWindow
                        download
                      />
                      <RoundBtn
                        type="share"
                        onClick={() => openSharePopup(obj)}
                      />
                    </FlexWrp>
                  ))}
              </div>
              {list.length > 0 && (
                <Content>
                  {list.map((obj) => {
                    const iconSrc = () => {

                      if(obj.type === "jpg"
                          || obj.type === "jpeg"
                          || obj.type === "png"){
                        return obj?.url || icons[obj.type] || icons.all
                      }
                      return icons[obj?.type] || icons.all
                    }
                    return (
                        <Item key={obj.id}>
                          {/* await API - delete doc */}
                          {/*<LeftWrp>*/}
                          {/*  {!forCompany && (*/}
                          {/*    <RoundBtn*/}
                          {/*      type="delete"*/}
                          {/*      onClick={() => openDeletePopup(obj)}*/}
                          {/*      style={{ zIndex: '10' }}*/}
                          {/*    />*/}
                          {/*  )}*/}
                          {/*</LeftWrp>*/}
                          <RightWrp>
                            <RoundBtn
                                type="share"
                                onClick={() => openSharePopup(obj)}
                                style={{ zIndex: '10' }}
                            />
                          </RightWrp>

                          <IconWrp onClick={() => onLoadFile(obj?.url)}>
                            <UploadImg src={iconSrc()} />
                          </IconWrp>

                          {/*<FileTitle>{`${obj.authorID} ${obj.dateCreated} ${obj.timeCreated}`}</FileTitle>*/}
                          <FileTitle>{`${obj.remark}`}</FileTitle>
                          <CropTitle>{`(${obj.moment})`}</CropTitle>
                        </Item>
                    )})}
                </Content>
              )}
            </>
          )}
          {!forCompany && (
            <LoadFile
                periodic={periodic}
                submitResolve={getData}
            />
          )}
        </Wrapper>
    </>
  )
}
