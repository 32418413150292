import { takeEvery, call, put, select, race, delay } from 'redux-saga/effects'
import { delays } from '../../lib/constants'
import { getUserProfile } from '../../lib/requests/authRequest'
import {
  getCurrentLang,
  getToken,
  getStartPath,
  getDicionary,
} from '../selectors'

export function* worker({ history }) {
  try {
    const currentLang = yield select(getCurrentLang)
    const token = yield select(getToken)
    const startPath = yield select(getStartPath)
    const navTab = localStorage.getItem('sovCurrentNavTab')
    const dic = yield select(getDicionary)

    const { result } = yield race({
      result: call(getUserProfile, token, currentLang),
      timeout: delay(delays.forCancelRequest),
    })

    if (result && result.status === 200) {
      yield put({ type: 'SAGA_GET_DEFAULT_SETTINGS' })
      yield put({
        type: 'NAVMENU_SET_CURRENT_TAP',
        payload:
          navTab &&
          navTab !== 'undefined' &&
          result.data.data.menu.menu.find((el) => el.id === navTab)
            ? navTab
            : String(result.data.data.menu.currentMenuId) || '',
      })
      if (result.data.data.menu.hotkey) {
        yield put({
          type: 'SET_HOTKEYS',
          payload: [...result.data.data.menu.hotkey],
        })
      }
      yield put({
        type: 'SET_USER_PROFILE',
        payload: { ...result.data.data.user },
      })
      yield put({
        type: 'SET_AUX_MENU',
        payload: [...result.data.data.menu.aux],
      })
      yield put({
        type: 'SET_USER_MENU',
        payload: [...result.data.data.menu.menu],
      })
      yield put({ type: 'SET_AUTH', payload: true })
      yield put({ type: 'GET_NOTIFICATION_STATUS' })
      const isLang = result.data.data.user.language
        ? result.data.data.user.language
        : null
      if (isLang) {
        yield put({ type: 'SET_LANG', payload: isLang })
        yield put({ type: 'SAGA_GET_DICTIONARY', comparsion: true })
      }
      const path = result.data.data.menu.menu.find(
        ({ id }) => String(id) === String(result.data.data.menu.currentMenuId)
      ).path
      const calculatePosition =
        startPath === '/authorization' ||
        startPath === '/' ||
        startPath === '/home'
          ? path
          : startPath === null
          ? path
          : startPath

      if(history  && calculatePosition){
        yield history.push(calculatePosition)
      }


    } else {
      yield put({
        type: 'SHOW_ERROR_API',
        code: 6,
        message: dic.msg_server_err || 'internal server error',
      })
    }
    yield put({ type: 'CLOSE_ERROR_API' })
    yield put({ type: 'FINISH_APP_LOADER' })
  } catch (e) {
    yield put({ type: 'FINISH_APP_LOADER' })
    yield put({
      type: 'SHOW_ERROR_API',
      code: e.response ? String(e.response.status).slice(0, 1) : 4,
      message: e.response ? e.response.data.message : e.message,
    })
    yield put({ type: 'SAGA_LOGOUT' })
  }
}

export default function* getProfile() {
  yield takeEvery('SET_TOKEN', worker)
}
