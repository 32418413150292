import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Icon from '../../../components/Icon'
import BaseBtn from '../../../components/buttons/BaseBtn'
import HorizontalTab from '../../../components/tabs/HorizontalTab'
import BaseCheckboxInput from '../../../components/inputs/BaseCheckboxInput'
import SelectDateBtn from '../../../components/buttons/SelectDateBtn'
import { routeFilters } from "../../../lib/constants"
 const parseDate = (value) => {
    if(!value || value === "000-00-00"){
        return ""
    }
    return new Date(value)
}

const HeaderWrp = styled.div`
  width: 100%;
  padding-bottom: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const FlexWrp = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Title = styled.h3`
  font-size: ${(p) => p.theme.headerTitle};
  ::first-letter {
    text-transform: uppercase;
  }
`

const FooterWrp = styled.div`
  width: 100%;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Header = ({
  onCloseModal = () => {},
  title = '',
  component = 'base',
  setComponent = () => {},
}) => {
  return (
    <HeaderWrp>
      <FlexWrp style={{ justifyContent: 'flex-start' }}>
        <Icon
          name={component !== 'base' ? 'arrowShevron' : 'filter'}
          type="additionalInfo"
          style={
            component !== 'base'
              ? { marginRight: '1rem', transform: 'rotate(180deg)' }
              : { marginRight: '1rem' }
          }
          onClick={component !== 'base' ? () => setComponent('base') : null}
        />
        <Title>{title}</Title>
      </FlexWrp>
      <Icon name="close" type="additionalInfo" onClick={onCloseModal} />
    </HeaderWrp>
  )
}

export const Footer = ({
  isHaveArgs = false,
  component = 'base',
  onClearFilter = () => {},
  onShowFilter = () => {},
  onCloseModal = () => {},
  onBackToBase = () => {},
}) => {
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  return (
    <FooterWrp>
      <BaseBtn
        pad={isHaveArgs ? 'mid' : 'big'}
        label={
          isHaveArgs
            ? component === 'base'
              ? 'сбросить все' //dic.btn_clear_all
              : dic.btn_back
            : dic.btn_back
        }
        fnClick={
          isHaveArgs
            ? component === 'base'
              ? onClearFilter
              : () => onBackToBase('base')
            : onCloseModal
        }
        inContext="popup" //setup popup
        fullSize={isHaveArgs ? false : true}
        style={{ margin: '0 auto' }}
      />
      {isHaveArgs && <BaseBtn label={dic.btn_show} fnClick={onShowFilter} />}
    </FooterWrp>
  )
}
export const currentContent = (
  tabs,
  loadTowns,
  unloadTowns,
  shippers,
  typeAuto,
  dic,
  period,
  validatePeriod,
  forQuantityData,
  togglerContent,
  onSetFilter,
  onSetNewDate
) => ({
  base: tabs.map((el) => (
    <HorizontalTab
      withSidePads
      isAccent
      key={el.id}
      obj={{ title: el.title }}
      fnClick={() => togglerContent(el.id)}
      extraData={
        forQuantityData[el.id] > 0 || forQuantityData[el.id].length > 0
          ? forQuantityData[el.id]
          : ''
      }
    />
  )),
  start: loadTowns.map((el) => (
    <BaseCheckboxInput
      key={el.filter}
      label={el.filter}
      id={el.filter}
      checked={el.checked}
      style={{
        marginTop: '2rem',
      }}
      fnChange={() => onSetFilter(routeFilters.loadTowns, el.filter, el.checked)}
    />
  )),
  finish: unloadTowns.map((el) => (
    <BaseCheckboxInput
      key={el.filter}
      label={el.filter}
      id={el.filter}
      checked={el.checked}
      style={{
        marginTop: '2rem',
      }}
      fnChange={() => onSetFilter(routeFilters.unloadTowns, el.filter, el.checked)}
    />
  )),
  shipper: shippers.map((el) => (
    <BaseCheckboxInput
      key={el.filter}
      label={el.filter}
      id={el.filter}
      checked={el.checked}
      style={{
        marginTop: '2rem',
      }}
      fnChange={() => onSetFilter(routeFilters.shippers, el.filter, el.checked)}
    />
  )),
  type: typeAuto.map((el) => (
    <BaseCheckboxInput
      key={el.filter}
      label={el.filter}
      id={el.filter}
      checked={el.checked}
      style={{
        marginTop: '2rem',
      }}
      fnChange={() => onSetFilter(routeFilters.typeAuto, el.filter, el.checked)}
    />
  )),
  date: (
    <FlexWrp
      style={{
        justifyContent: 'center',
        flexWrap:"wrap",
        marginTop: '1rem',
      }}
    >
        <span style={{ display: "flex", justifyContent: 'space-between', alignItems:"center", width:"100%" }}>
          <p style={{ }}>{dic.from}</p>
          <SelectDateBtn
            width="90%"
            label=""
            value={parseDate(period.from)}
            index="from"
            fnClick={onSetNewDate}
            disabledPeriod={validatePeriod.from.direction}
            checkDate={validatePeriod.from.value}
            style={{
                marginBottom:"1.6rem",
            }}
          />
        </span>
        <span style={{ display: "flex", justifyContent: 'space-between', alignItems:"center",  width:"100%" }}>
          <p style={{ }}>{dic.to}</p>
          <SelectDateBtn
            width="90%"
            label=""
            value={parseDate(period.to)}
            index="to"
            fnClick={onSetNewDate}
            disabledPeriod={validatePeriod.to.direction}
            checkDate={validatePeriod.to.value}
          />
        </span>
    </FlexWrp>
  ),
})
