import Type from '../types'
import { forNewTS } from '../../lib/dataTemplateForForms'
const initialState = {
  ...forNewTS,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.NEW_TS_SET_DEFAULT_DATA:
      return {
        ...forNewTS,
      }
    case Type.NEW_TS_UPDATE_DRIVER:
      return {
        ...state,
        driver: { ...action.payload },
      }
    case Type.NEW_TS_UPDATE_TRAILER:
      return {
        ...state,
        trailer: { ...action.payload },
      }
    case Type.NEW_TS_UPDATE_CAR:
      return {
        ...state,
        car: { ...action.payload },
      }
    case Type.NEW_TS_UPDATE_LIST_FERRY_PROPERTIES:
      return {
        ...state,
        checklistType: action.payload.name,
        checklistFor: action.payload.formName,
      }
    case Type.NEW_TS_UPDATE_LIST_CHARGE_TYPE:
        return {
          ...state,
          checklistType: action.payload.name,
          checklistFor: action.payload.formName,
        }

    case Type.NEW_TS_UPDATE_CHECKLIST:
      return {
        ...state,
        checklist: action.payload,
      }

    default:
      return state
  }
}
