import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import useRouter from '../../hooks/useRouter'
import PopupQuantity from '../../components/popUps/PopupQuantity'
import SelectInputHeader from '../selects/SelectInputHeader'
import Icon from '../../components/Icon'
import ScrollToTopHeaderWrp from '../other/ScrollToTopHeaderWrp'
//need same change

const Wrapper = styled.header`
  position: relative;
  width: 100%;
  min-width: 100vw;
  height: 6rem;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => p.theme.flat_headers};
  color: ${(p) => p.theme.opposite_txt};
`
const Left = styled.div`
  min-width: 16rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Right = styled.div`
  min-width: 6rem;
  height: 100%;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const SelectWrap = styled.div`
  z-index: 50;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 50rem;
  height: 4rem;
`
const TitleWrap = styled.div`
  position: relative;
`
const Title = styled.div`
  margin-bottom: 1.2rem;
  font-family: 'FM Bolyar Sans Pro';
  color: ${(p) => p.theme.opposite_txt};
  font-weight: 900;
  font-size: ${(p) => p.theme.headerTitle};
  letter-spacing: -0.5%;
  text-transform: uppercase;
`

export default () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const info = useSelector((s) => s.messages.info)
  const chat = useSelector((s) => s.messages.chat)
  const isMenu = useSelector((s) => s.mainMenu.isShow)
  const userMenu = useSelector((s) => s.user.navlist)
  const startPath = useSelector((s) => s.general.startPath)
  const [isSearch, setIsSearch] = useState(false)
  const [searchInput, setSearchInput] = useState({
    field: '',
    value: '',
  })
  const [isArrowBack, setIsArrowBack] = useState(false)
  const path = router.pathname
  const dic = useSelector((s) => s.defaultLang.serverDictionary)

  const toggleMenu = () => {
    if (isMenu) {
      dispatch({ type: 'CLOSE_MAIN_MENU' })
    } else {
      dispatch({ type: 'OPEN_MAIN_MENU' })
    }
  }
  const showSeachInput = () => setIsSearch(true)
  const closeSeachInput = () => setIsSearch(false)
  const onChangeSearchInput = (e) => {
    setSearchInput({ ...searchInput, value: e.target.value })
  }
  const onSetFieldSearh = (val) =>
    setSearchInput({ ...searchInput, field: val })

  const onSearchSubmit = (what, where) => {
    if (!what || !what.length) return
    switch (where) {
      case 'periodic':
        router.push(`/route/${what}`)
        break
      default:
        return
    }

    setSearchInput({ field: '', value: '' })
  }

  useEffect(() => {
    if (path.search(/[0-9]/) > 0 && startPath !== path) {
      setIsArrowBack(true)
    } else {
      setIsArrowBack(false)
    }
  }, [path, startPath])
  return (
    <ScrollToTopHeaderWrp>
      <Wrapper>
        {isSearch && (
          <SelectWrap>
            <SelectInputHeader
              value={searchInput.value}
              name={searchInput.field}
              fnChange={onChangeSearchInput}
              fnSetFieldSearh={onSetFieldSearh}
              fnCloseSerchInput={closeSeachInput}
              fnSubmit={onSearchSubmit}
              placeholder={dic.route_enter_full_route_number}
            />
          </SelectWrap>
        )}
        <Left>
          {isArrowBack ? (
            <Icon
              name="arrow"
              type="opposite"
              style={{
                marginTop: '0.2rem',
                marginRight: '3rem',
                cursor: 'pointer',
              }}
              onClick={() => router.back()}
            />
          ) : (
            <Icon
              name="burger"
              type="opposite"
              // style={{ marginRight: '2rem', cursor: 'pointer' }}
              onClick={() => toggleMenu()}
            />
          )}
          <TitleWrap onClick={() => router.push(`${userMenu[0].path}` || '/')}>
            {info > 0 && <PopupQuantity total={info} id="headerInfoQuantity" />}
            <Title>sovtes</Title>
          </TitleWrap>
        </Left>
        {true && (
          <Right>
            <Icon
              name="search"
              type="opposite"
              style={{ cursor: 'pointer' }}
              onClick={showSeachInput}
            />
            {false && (
              <div style={{ position: 'relative' }}>
                {chat > 0 && (
                  <PopupQuantity
                    total={chat}
                    side="left"
                    top="-0.8rem"
                    id="headerChatQuantity"
                  />
                )}
                <Icon
                  name="mail"
                  type="opposite"
                  style={{ cursor: 'pointer' }}
                  onClick={() => router.push('/chat')}
                />
              </div>
            )}
          </Right>
        )}
      </Wrapper>
    </ScrollToTopHeaderWrp>
  )
}
