import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useRouter from '../hooks/useRouter'
import BaseHeader from '../containers/headers/BaseHeader'
import Card1 from '../components/InterfaceForCarrier/forRoutes/Cards/RouteCard1'
import Card2 from '../components/InterfaceForCarrier/forRoutes/Cards/RouteCard2'
import SmallLoader from '../components/layouts/SmallLoader'
import DumpBadRoute from '../components/layouts/DumpBadRoute'
import SeoMetaTegs from '../components/SeoMetaTegs'
import { strToUpperCase } from '../lib/helpers/forStyling'

export default () => {
  const headerRef = useRef()
  const router = useRouter()
  const userType = useSelector((s) => s.user.profile.usertype)
  const [currentTab, setCurrentTab] = useState('11')
  const [seoTitle, setSeoTitle] = useState('')
  const dispatch = useDispatch()
  const data = useSelector((s) => s.route.cardRoute)
  const badRequest = useSelector((s) => s.route.badRequest)
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const { periodic } = useParams()

  /* isApprovedRoute - when client has approved operators for the route */
  const isApprovedRoute = data?.routestatus >= 5

  const forApprovedList = [
    { typeIcon: 'info', id: '11', route: '/' },
    { typeIcon: 'bill', id: '22', route: '/' },
    { typeIcon: 'chat', id: '33', route: '/' },
    { typeIcon: 'time', id: '44', route: '/' },
  ]
  const forFreeList = [
    { typeIcon: 'info', id: '11', route: '/' },
    { typeIcon: 'chat', id: '33', route: '/' },
  ]
  const onChangeTab = (val) => {
    setCurrentTab(val)
  }

  const clearStore = () => {
    dispatch({ type: 'SET_SINGLE_ROUTE', payload: null })
  }

  const Card = () => ({
    1: (
      <Card1
        obj={data}
        list={isApprovedRoute ? forApprovedList : forFreeList}
        onChangeTab={onChangeTab}
        currentTab={currentTab}
      />
    ),
    2: (
      <Card2
        obj={data}
        list={isApprovedRoute ? forApprovedList : forFreeList}
        onChangeTab={onChangeTab}
        currentTab={currentTab}
      />
    ),
  })
  useEffect(() => {
    if (data && Number(data.istender) !== 0) {
      router.push(`/tender/${periodic}`)
    }
  }, [data]) //eslint-disable-line
  useEffect(() => {
    headerRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
    return () => {
      clearStore()
      dispatch({ type: 'CLEAR_FLAG_FOR_SINGLE_ROUTE_PAGE' })
    }
  }, []) //eslint-disable-line
  useEffect(() => {
    if (periodic) {
      dispatch({ type: 'SAGA_GET_SINGLE_ROUTE', periodic: periodic })
    }
  }, [periodic]) //eslint-disable-line

  useEffect(() => {
    if (data) {
      setSeoTitle(`${strToUpperCase(dic.route) || ''} № ${data.periodic}`)
    }
  }, [dic, data])

  return useMemo(() => {
    return (
      <>
        <SeoMetaTegs title={seoTitle} />
        <span ref={headerRef}>
          <BaseHeader />
        </span>
        {data === null ? (
          badRequest ? < DumpBadRoute /> : (
            <SmallLoader style={{ height: '90vh' }} size="5.6rem" />
          )
        ) : (
          Card()[userType]
        )}
      </>
    )
  }, [data, currentTab, seoTitle, badRequest, userType]) //eslint-disable-line
}
