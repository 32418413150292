import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import circleBase from '../../assets/img/loaders/smallLoaderBase.png'
import circleDark from '../../assets/img/loaders/smallLoaderDark.png'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Circle = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background-image: ${(p) =>
    p.isBase ? `url(${circleBase})` : `url(${circleDark})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: spin 3s infinite linear;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
export default ({ style, forDark = false }) => {
  const theme = useSelector((s) => s.general.theme)
  return (
    <Wrapper style={style}>
      {forDark ? (
        <Circle isBase={theme !== 'base'} />
      ) : (
        <Circle isBase={theme === 'base'} />
      )}
    </Wrapper>
  )
}
