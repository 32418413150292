import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BeforeunloadRouterPopup from '../components/layouts/beforeunloadRouterPopup'
import useDataStore from '../hooks/useDataStore'
import useRouter from '../hooks/useRouter'
import BaseHeader from '../containers/headers/BaseHeader'
import SmallLoader from '../components/layouts/SmallLoader'
import SeoMetaTegs from '../components/SeoMetaTegs'
import CreateNewRouteForm from '../containers/forms/CreateNewRoute/Index'
import { strToUpperCase } from '../lib/helpers/forStyling'

export default () => {
  const dispatch = useDispatch()
  const history = useRouter()
  const [approve, setApprove] = useState(false)
  const [seoTitle, setSeoTitle] = useState('')
  const [isShow, setIsShow] = useState(false)
  const { DIC, USER_PROFILE, BASEROUTE } = useDataStore()
  const isHaveDefaultRouteData = useSelector(
    (s) => s.newRoute.isHaveDefaultData
  )
  const isShowPopup = useSelector((s) => !s.newRoute.isPostNewRoute)
  const approved = {
    1: true,
    2: false,
    3: false,
  }

  useEffect(() => {
    setApprove(approved[USER_PROFILE.usertype])
    dispatch({ type: 'SAGA_GET_NEWROUTE_DEFAULT_DATA' })
    setIsShow(true)
    return () => dispatch({ type: 'SET_NEWROUTE_DEFAULT_DATA' })
  }, []) //eslint-disable-line

  useEffect(() => {
    if (DIC) setSeoTitle(strToUpperCase(DIC.route_create) || '')
  }, [DIC])

  useEffect(() => {
    if (!approve && isShow) {
      dispatch({
        type: 'SHOW_ERROR_API',
        code: 4,
        message: 'у вас нет прав на создание маршрута',
      })
      history.push(BASEROUTE)
    }
  }, [approve, isShow]) //eslint-disable-line

  return useMemo(
    () =>
      approve && (
        <>
          <BeforeunloadRouterPopup msg={DIC.msg_39} when={isShowPopup} />
          <SeoMetaTegs title={seoTitle} />
          <BaseHeader />
          {isShow && isHaveDefaultRouteData ? (
            <CreateNewRouteForm />
          ) : (
            <SmallLoader style={{ height: '95vh' }} size="5.8rem" />
          )}
        </>
      ),
    [DIC, approve, seoTitle, isShow, isHaveDefaultRouteData, isShowPopup]
  )
}
