import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon'
import BtnLoader from '../layouts/BtnLoader'

const Wrapper = styled.div`
  height: 5.2rem;
  background-color: ${(p) => p.theme.selected};
  width: 100%;
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
`
const Input = styled.input`
  display: none;
`
const Label = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .text {
    margin-top: -0.4rem;
    font-size: ${(p) => p.theme.h4};
    color: ${(p) => p.theme.links};
    :first-letter {
      text-transform: uppercase;
    }
  }
`
const Rotate = styled.div`
  margin-bottom: -0.8rem;
  transform: rotate(45deg);
`

export default ({
  name = 'load_file',
  label = 'label',
  isUpload = false,
  fnChange = () => {},
  disabled = false,
  style = {},
}) => {
  return (
    <Wrapper style={style}>
      <Input
        id={name}
        type="file"
        name={name}
        onChange={fnChange}
        disabled={disabled}
      />
      <Label htmlFor={name}>
        <div className="content">
          {isUpload ? (
            <BtnLoader />
          ) : (
            <>
              <Rotate>
                <Icon
                  name="close"
                  type="links"
                  width="1.6rem"
                  height="1.6rem"
                  style={{ marginRight: '1.3rem' }}
                />
              </Rotate>
              <p className="text">{label}</p>
            </>
          )}
        </div>
      </Label>
    </Wrapper>
  )
}
