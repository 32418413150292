import Type from '../types'

const initialState = {
  list: [],
  searchList: [],
  loader: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_CHECKPOINT_LOADER:
      return {
        ...state,
        loader: action.payload,
      }

    case Type.SET_CHECKPOINT_DATA:
      return {
        ...state,
        list: [...action.payload],
      }
    case Type.FILTER_CHECKPOINT_LIST:
      return {
        ...state,
        searchList: action.payload
          ? state.list
              .filter((el) =>
                el.union.toLowerCase().includes(action.payload.toLowerCase())
              )
              .sort((a, b) => {
                return a.union > b.union
              })
          : state.list.sort((a, b) => (a.union > b.union ? 1 : -1)),
      }

    case Type.CLEAR_CHECKPOINT_LIST:
      return {
        ...state,
        searchList: [],
      }

    default:
      return state
  }
}
