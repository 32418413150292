import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  Title,
  Info,
  FlexBox,
} from '../../../smallPartsForInterface/SmallPartsCard'
import Icon from '../../../Icon'
import InfoMsgBubble from '../../../support/InfoMsgBubble'
import { currencyIcons, delays } from '../../../../lib/constants'
import { strToUpperCase } from '../../../../lib/helpers/forStyling'
import { parseWholeDate } from '../../../../lib/helpers/forRoutes'

const Wrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
`
const LastBid = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  padding: 2.5rem 1rem;
  background-color: ${(p) => (p.isMine ? p.theme.trigger : p.theme.basic_low)};
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .remark {
    margin-top: 1rem;
    color: ${(p) => (p.isMine ? p.theme.opposite : p.theme.additional)};
    font-size: ${(p) => p.theme.h5};
    font-weight: 300;
  }
`
const StartWrp = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .text {
    margin-right: 0.5rem;
    color: ${(p) => (p.errorColor ? p.theme.error : p.theme.opposite_txt)};
    font-size: ${(p) => p.theme.h6};
  }
`
const Date = styled.p`
  width: calc(50% - 2rem);
  font-size: ${(p) => p.theme.h6};
  color: ${(p) => (p.isMine ? p.theme.opposite : p.theme.additional)};
`
const Quantity = styled.p`
  margin-right: auto;
  font-size: ${(p) => p.theme.h5};
  color: ${(p) => (p.isMine ? p.theme.opposite : p.theme.regular_txt)};
`

const Value = styled.p`
  font-size: ${(p) => (p.isLastBet ? p.theme.h2 : p.theme.h3)};
  color: ${(p) => (p.isMine ? p.theme.opposite : p.theme.regular_txt)};
  text-align: right;
  font-weight: 600;
  ${(p) =>
    p.isHaveMyBet &&
    `
  color: ${p.isMine ? p.theme.opposite : p.theme.error};
  `};
`
const DumpBlock = styled.p`
  width: 100%;
  height: 8.6rem;
  background-color: ${(p) => p.theme.substrate};
  font-size: ${(p) => p.theme[p.fs] || p.theme.h4};
  color: ${(p) => p.theme.additional};
  display: flex;
  justify-content: center;
  align-items: center;
`

export default ({
  dic,
  local,
  currency = null,
  periodic = '123',
  isFinish = false,
  isWinnerStatus = 1,
  setIsMyBet = () => {},
  isOpenBidForm = false,
}) => {
  const dispatch = useDispatch()
  const result = useSelector((s) => s.tender.currentBets)
  const [isHaveData, setIsHaveData] = useState(false)
  const [lengthIdx] = useState(2)
  const [data, setData] = useState([])
  const [lastBid, setLastBid] = useState(null)
  const [quantity, setQuantity] = useState(0)
  const [isHaveMyBet, setIsHaveMyBet] = useState(false)
  const [blindBet, setIsBlindBet] = useState(null)

  const updateDatas = () => {
    setIsHaveData(false)
    dispatch({ type: 'SAGA_GET_PRICEQUTES', route: periodic })
  }
  useEffect(() => {
    if (isHaveData) {
      const bet = data.find((obj) => obj && obj.isMine)
      setIsBlindBet(bet)
      setLastBid(bet && bet.isMine ? bet : null)
      data.splice(0, lengthIdx) // ?? TODO REWRITE THIS
      setIsHaveData(false)
    }
  }, [isHaveData]) //eslint-disable-line
  useEffect(() => {
    dispatch({ type: 'SAGA_GET_PRICEQUTES', route: periodic })
  }, []) //eslint-disable-line

  useEffect(() => {
    if (result.length > 0) {
      setIsHaveMyBet(result.find((obj) => obj && obj.isMine))
      setData(result)
      setIsHaveData(true)
      setQuantity(result.length)
    }
  }, [result])

  useEffect(() => {
    if (!isFinish) {
      const interval = setInterval(() => {
        if (!isOpenBidForm) updateDatas()
      }, delays.forTenderBids)
      return () => clearInterval(interval)
    }
  }, [isFinish, isOpenBidForm]) //eslint-disable-line

  useEffect(() => {
    if (lastBid) {
      setIsMyBet(
        lastBid.isMine
          ? {
              price: lastBid.pricequote,
              quantity: lastBid.loadqoute,
              cancelled: lastBid.cancelled,
              isMine: lastBid.isMine,
            }
          : null
      )
    }
  }, [lastBid]) //eslint-disable-line

  return (
    isWinnerStatus === 1 && (
      <Wrapper>
        <FlexBox alignCenter mrgnBtm="2rem">
          <Title big>{dic.bids}</Title>
          <div
            className="flex"
            style={{
              justifyContent: 'flex-start',
              cursor: 'pointer',
              marginRight: 'auto',
            }}
          >
            <Icon
              name="crossedEye"
              type="additionalInfo"
              width="1.6rem"
              height="1.6rem"
              style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
            />
            <InfoMsgBubble msg={dic.info_2} />
          </div>
          <Info>{`${dic.bids_total}: ${quantity}`}</Info>
        </FlexBox>
        {quantity >= 0 && blindBet && blindBet.cancelled ? (
          <DumpBlock fs="h6">{dic.bid_deleted}</DumpBlock>
        ) : (
          !blindBet && <DumpBlock fs="h6">{dic.bid_dont_send}</DumpBlock>
        )}
        {lastBid && !lastBid.cancelled && (
          <LastBid isMine={lastBid.isMine || false}>
            {lastBid && isHaveMyBet && (
              <StartWrp errorColor={!lastBid.isMine}>
                <p className="text">
                  {lastBid.isMine
                    ? strToUpperCase(dic.bid_your)
                    : strToUpperCase(dic.belowMine)}
                </p>
                <Icon
                  name={lastBid.isMine ? 'star' : 'atencion'}
                  type={lastBid.isMine ? 'opposite' : 'error'}
                />
              </StartWrp>
            )}
            <div className="content">
              <Date isMine={lastBid.isMine || false}>
                {parseWholeDate(lastBid.moment, dic, true)}
              </Date>
              <Quantity isMine={lastBid.isMine}>
                {`${lastBid.loadqoute} ${local[lastBid.pricefor]}`}
              </Quantity>
              <Value
                isMine={lastBid.isMine || false}
                isHaveMyBet={Boolean(isHaveMyBet)}
                isLastBet
              >{`${lastBid.pricequote}${
                currency ? currencyIcons[currency] : currencyIcons.uah
              }`}</Value>
            </div>
            {lastBid.remark && (
              <p
                isMine={lastBid.isMine}
                className="remark"
                style={{ fontStyle: 'italic' }}
              >{`"${lastBid.remark}"`}</p>
            )}
          </LastBid>
        )}
      </Wrapper>
    )
  )
}
