import React, { useState } from 'react'
import styled from 'styled-components'
import Icon from '../../components/Icon'
import Calendar from '../../components/popUps/Calendar'
import ModalPortal from '../../components/layouts/ModalPortal'

const Wrapper = styled.div`
  position: relative;
  width: ${(p) => p.width};
  color: ${(p) => (p.error ? p.theme.error : p.theme.regular_txt)};
  ${(p) => (p.error ? `border: 0.1rem solid ${p.theme.error};` : null)};
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
`
const Btn = styled.button`
  width: 100%;
  padding: 1.3rem 1.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(p) => p.theme.selected};
  border: 0.1rem solid ${(p) => p.theme.selected};
  :active {
    border: 0.1rem solid ${(p) => p.theme.inputBorder};
  }
`
const Label = styled.span`
  position: absolute;
  width: calc(100% - 0.4rem);
  top: -2rem;
  left: 0.4rem;
  color: ${(p) => (p.error ? p.theme.error : p.theme.additional)};
  font-size: ${(p) => p.theme.h5};
  opacity: 0.8;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  white-space: nowrap;
`
const Extra = styled.p`
  margin-left: 1rem;
  color: ${(p) => p.theme.inputBorder};
  font-size: ${(p) => p.theme.h6};
  overflow: hidden;
  text-overflow: ellipsis;
`
const ErrorMessage = styled.span`
  padding-left: 0.5rem;
  color: ${(p) => p.theme.error};
`

const Content = styled.p`
  padding-right: 1rem;
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => p.theme.regular_txt};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
//HAVE TO USE

// width="100%" || "5rem"
// label= name input
// value= new Date() || null
// index= this parameter indicates the position in the array, it is needed when the inputs are rendered from the array, in order to know which one to update.
// fnClick= fn from parrent who update main data. haves 2 parametrs - new value, index

export default ({
  label = 'Date',
  extraString = null,
  value = null,
  index = 0,
  error = false,
  message = '',
  fnClick = () => {},
  disabled = false,
  disabledPeriod = 'nothing', //nothing, after, past
  checkDate = '',
  width = `16rem`,
  id = 'SelectDateBtn',
  style,
}) => {
  const [isCalendar, setIsCalendar] = useState(false)
  const forOpenCalendar = () => {
    setIsCalendar(!isCalendar)
  }
  const saveData = (value, index) => {
    fnClick(value, index)
    setIsCalendar(!isCalendar)
  }

  return isCalendar ? (
    <ModalPortal closeModal={forOpenCalendar}>
      <Calendar
        fnSave={saveData}
        fnCancel={forOpenCalendar}
        value={value}
        index={index}
        disabledPeriod={disabledPeriod}
        checkDate={checkDate}
      />
    </ModalPortal>
  ) : (
    <Wrapper style={style} width={width} error={error} disabled={disabled}>
      <Label error={error}>
        {label}
        {error ? (
          <ErrorMessage>{message}</ErrorMessage>
        ) : extraString ? (
          <Extra>{extraString}</Extra>
        ) : null}
      </Label>
      <Btn
        type="button"
        onClick={forOpenCalendar}
        id={id}
        data-testid={id}
        disabled={disabled}
      >
        <Icon
          name="calendar"
          type="icons"
          style={{ marginRight: '1rem', marginTop: '0.15rem' }}
        />
        <Content>
          {value
            ? typeof value === 'object'
              ? value.toLocaleDateString()
              : value.slice(0, 10)
            : ''}
        </Content>
      </Btn>
    </Wrapper>
  )
}
