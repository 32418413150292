import Type from '../types'

const initialState = {
  value: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.COUNTER_PLUS:
      return {
        ...state,
        value: state.value + 1,
      }
    case Type.COUNTER_MINUS:
      return {
        ...state,
        value: state.value - 1,
      }
    default:
      return state
  }
}
