import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import useRouter from '../hooks/useRouter'
import BaseHeader from '../containers/headers/BaseHeader'
import Card1 from '../components/InterfaceForCarrier/forTenders/Cards/TenderCard1'
import Card2 from '../components/InterfaceForCarrier/forTenders/Cards/TenderCard2'
import SmallLoader from '../components/layouts/SmallLoader'
import SeoMetaTegs from '../components/SeoMetaTegs'
import { strToUpperCase } from '../lib/helpers/forStyling'

export default () => {
  const headerRef = useRef(null)
  const router = useRouter()
  const userType = useSelector((s) => s.user.profile.usertype)
  const [currentTab, setCurrentTab] = useState('11')
  const [isFinish, setIsFinish] = useState(false)
  const [isWinnerStatus, setIsWinnerStatus] = useState(1)
  const [seoTitle, setSeoTitle] = useState('')
  const dispatch = useDispatch()
  const myCompanyId = useSelector((s) => s.user.profile.client)
  const badRequest = useSelector((s) => s.tender.badRequest)
  const data = useSelector((s) => s.tender.cardTender)
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const { periodic } = useParams()

  const tabList = [
    { typeIcon: 'info', id: '11', route: '/' },
  //  { typeIcon: 'bill', id: '22', route: '/' },
    { typeIcon: 'chat', id: '33', route: '/' },
    { typeIcon: 'time', id: '44', route: '/' },
  ]
  const winnerTabList = [
    { typeIcon: 'info', id: '11', route: '/' },
   // { typeIcon: 'bill', id: '22', route: '/' },
    { typeIcon: 'chat', id: '33', route: '/' },
    { typeIcon: 'time', id: '44', route: '/' },
    { typeIcon: 'winner', id: '55', route: '/' },
  ]
  const onChangeTab = (val) => {
    setCurrentTab(val)
  }

  const clearStore = () => {
    dispatch({ type: 'SET_SINGLE_TENDER', payload: null })
  }

  const Card = () => ({
    1: (
      <Card1
        obj={data}
        list={isWinnerStatus !== 1 ? winnerTabList : tabList}
        onChangeTab={onChangeTab}
        currentTab={currentTab}
        isFinish={isFinish}
        isWinnerStatus={isWinnerStatus}
      />
    ),
    2: (
      <Card2
        obj={data}
        list={isWinnerStatus !== 1 ? winnerTabList : tabList}
        onChangeTab={onChangeTab}
        currentTab={currentTab}
        isFinish={isFinish}
        isWinnerStatus={isWinnerStatus}
      />
    ),
  })

  useEffect(() => {
    headerRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
    return () => {
      clearStore()
      dispatch({ type: 'SET_BETS', payload: [] })
      dispatch({ type: 'CLEAR_FLAG_FOR_SINGLE_TENDER_PAGE' })
    }
  }, []) //eslint-disable-line
  useEffect(() => {
    if (data && Number(data.istender) !== 1) {
      router.push(`/route/${periodic}`)
    }
  }, [data]) //eslint-disable-line

  useEffect(() => {
    if (periodic) {
      dispatch({ type: 'SAGA_GET_SINGLE_TENDER', periodic: periodic })
    }
  }, [periodic]) //eslint-disable-line
  useEffect(() => {
    if (data) {
      const now = moment().valueOf()
      const dateFinish = moment(data.tenderavailableunillmoment).valueOf()
      setIsFinish(dateFinish < now || data.routestatus < 1)
    }
  }, [data]) //eslint-disable-line
  useEffect(() => {
    if (isFinish && data.routeresponse) {
      const { response, routeresponse } = data
      if (Number(response[routeresponse].operator) === myCompanyId) {
        setIsWinnerStatus(2)
      } else {
        setIsWinnerStatus(3)
      }
    }
  }, [isFinish, data]) //eslint-disable-line

  useEffect(() => {
    if (data) {
      setSeoTitle(`${strToUpperCase(dic.tender) || ''} № ${data.periodic}`)
    }
  }, [dic, data])

  return useMemo(() => {
    return (
      <>
        <SeoMetaTegs title={seoTitle} />
        <span ref={headerRef}>
          <BaseHeader />
        </span>
        {data === null ? (
          badRequest ? null : (
            <SmallLoader style={{ height: '90vh' }} size="5.6rem" />
          )
        ) : (
          Card()[userType]
        )}
      </>
    )
    /*eslint-disable*/
  }, [
    data,
    currentTab,
    isFinish,
    isWinnerStatus,
    seoTitle,
    badRequest,
    userType,
  ]) /*eslint-enable*/
}
