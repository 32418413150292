import React, { useContext, useEffect, useState, useRef } from 'react'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  Wrapper,
  Price,
} from '../../../smallPartsForInterface/SmallPartsWidgets'
import BodyTendersWidget from './BodyTenderWidget'
import { NumberWithoutNulls } from '../../../../lib/helpers/forRoutes'
import { currencyIcons } from '../../../../lib/constants'
import { strToUpperCase } from '../../../../lib/helpers/forStyling'
import Icon from '../../../Icon'
import useRouter from '../../../../hooks/useRouter'

export default ({ obj, dic, memory = '' }) => {
  const router = useRouter()
  const myref = useRef(null)
  const dispatch = useDispatch()
  const [isMy, setIsMy] = useState(false)
  const [currentCost, setCurrentCost] = useState(0)
  const currentTheme = useContext(ThemeContext)
  const myCompanyId = useSelector((s) => s.user.profile.client)
  const { defaultcurrency, minquote, operator, periodic } = obj
  useEffect(() => {
    if (minquote) {
      setIsMy(minquote.mine)
      setCurrentCost(minquote.pricequotewithcommission)
    }
  }, []) //eslint-disable-line
  const widgetClick = () => {
    dispatch({ type: 'REMEMBER_TENDER_WIDGET', payload: periodic })
    router.push(`/tender/${periodic}`)
  }

  const executeScroll = () =>
    myref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })

  useEffect(() => {
    if (memory === periodic) {
      executeScroll()
    }
  }, [memory, periodic])

  return (
    <span ref={myref}>
      <Wrapper isMrgnBtm onClick={widgetClick}>
        {!operator ? (
          <div className="flexWrp" style={{ marginBottom: '1.7rem' }}>
            <Price attention={!isMy}>
              {`${
                defaultcurrency
                  ? currencyIcons[defaultcurrency]
                  : currencyIcons.uah
              } ${NumberWithoutNulls(currentCost)}`}
              <Icon
                name={isMy ? 'star' : 'atencion'}
                type={isMy ? 'trigger' : 'error'}
                style={{ marginLeft: '1rem', paddingBottom: '0.7rem' }}
              />
              <p style={{ fontSize: '1.2rem', marginLeft: '1rem' }}>
                {isMy
                  ? strToUpperCase(dic.your)
                  : strToUpperCase(dic.belowMine)}
              </p>
            </Price>
            <p
              style={{
                maxWidth: '40%',
                textAlign: 'right',
                fontSize: currentTheme.h6,
                color: currentTheme.trigger_txt,
              }}
            >
              {strToUpperCase(dic.msg_13)}
            </p>
          </div>
        ) : operator === myCompanyId ? (
          <Price isSmall style={{ marginBottom: '1.7rem' }}>
            <p>{strToUpperCase(dic.won)}</p>
            <Icon name="winner" type="focused" style={{ marginLeft: '1rem' }} />
          </Price>
        ) : (
          <Price isSmall attention style={{ marginBottom: '1.7rem' }}>
            <Icon name="sad" type="error" style={{ marginRight: '1rem' }} />
            <p>{`${strToUpperCase(dic.msg_14)}:(`}</p>
          </Price>
        )}
        <BodyTendersWidget obj={obj} dic={dic} />
      </Wrapper>
    </span>
  )
}
