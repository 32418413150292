import { takeLatest, put, select, delay, call, race } from 'redux-saga/effects'
import { addNewTS } from '../../../lib/requests/operator'
import { delays } from '../../../lib/constants'
import { getCurrentLang, getToken, getDicionary } from '../../selectors'

export function* worker({ data, action, fnClose, fnUpdateState }) {
  try {
    const currentLang = yield select(getCurrentLang)
    const token = yield select(getToken)
    const dic = yield select(getDicionary)

    const { result } = yield race({
      result: call(
        addNewTS,
        token,
        currentLang,
        action ? { [action]: data } : { ...data },
        action
      ),
      timeout: delay(delays.forCancelRequest),
    })
    if ((result && result.status === 200) || result.status === 201) {
      const { data } = result.data
      if (action) {
        switch (action) {
          case 'driver':
            fnUpdateState(action, {
              ...data,
              union: `${data.lastname} ${data.firstname} ${data.maincellphone}`,
            })
            yield put({ type: 'CLOSE_ERROR_API' })
            fnClose()
            yield put({ type: 'SAGA_GET_MY_PARK_FOR_SMART_SELECT' })
            break
          case 'trailer':
            fnUpdateState(action, {
              ...data,
              union: `${data.model} ${String(data.number)}`,
            })
            yield put({ type: 'CLOSE_ERROR_API' })
            fnClose()
            yield put({ type: 'SAGA_GET_MY_PARK_FOR_SMART_SELECT' })
            break
          case 'car':
            fnUpdateState(action, {
              ...data,
              union: `${data.make.title_ru} ${String(data.number)}`,
            })
            yield put({ type: 'CLOSE_ERROR_API' })
            fnClose()
            yield put({ type: 'SAGA_GET_MY_PARK_FOR_SMART_SELECT' })
            break

          default:
            yield put({ type: 'CLOSE_ERROR_API' })
            fnClose()
            yield put({ type: 'SAGA_GET_MY_PARK_FOR_SMART_SELECT' })
            return
        }
      }
    } else {
      yield put({ type: 'CLOSE_ERROR_API' })
      yield put({
        type: 'SHOW_ERROR_API',
        code: 6,
        message: dic.msg_server_err || 'internal server error',
      })
    }
  } catch (e) {
    yield put({ type: 'CLOSE_ERROR_API' })
    yield put({
      type: 'SHOW_ERROR_API',
      code: String(e.response.status).slice(0, 1),
      message: e.response ? e.response.data.message : e.message,
    })

    if (e.response && e.response.data.relogin) {
      yield put({ type: 'SAGA_LOGOUT' })
    }
  }
}

export default function* addNew_TS() {
  yield takeLatest('SAGA_ADD_NEW_TS', worker)
}
