import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Icon from '../../components/Icon'
import useOutsideClick from '../../hooks/useOutsideClick'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const Input = styled.input`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 4.5rem 0 3.5rem;
  background-color: ${(p) => p.theme.opposite};
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => (p.error ? p.theme.error : p.theme.regular_txt)};
  ${(p) => (p.error ? `border: 0.1rem solid ${p.theme.error};` : null)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :focus {
    border: 0.1rem solid ${(p) => p.theme.inputBorder};
  }
`
const List = styled.div`
  position: absolute;
  top: 5.5rem;
  left: 0;
  width: 100%;
  padding: 1.5rem;
  background-color: ${(p) => p.theme.opposite};
  overflow: auto;
  box-shadow: ${(p) => p.theme.listShadow};
  color: ${(p) => p.theme.regular_txt};
`
const ListItem = styled.span`
  width: 100%;
  padding: 1.6rem;
  padding-left: unset;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: ${(p) => p.theme.h4};
  cursor: pointer;
  svg {
    margin-right: 1rem;
    fill: ${(p) => p.theme.regular_txt};
  }
`
const IconWrp = styled.div`
  max-width: 2rem;
  z-index: 51;
  position: absolute;
  top: 50%;
  ${(p) => (p.left ? 'left:1.5rem' : 'right:1.5rem')};
  right: 1.5rem;
  transform: translateY(-50%);
  transition: 0.2s ease;
  cursor: pointer;
`
export default ({
  value = '',
  name = 'default',
  fnChange = () => {},
  fnSubmit = () => {},
  fnSetFieldSearh = () => {},
  fnCloseSerchInput = () => {},
  placeholder=""
}) => {
  //   const dispatch = useDispatch()
  const searchList = useSelector((s) => s.testSelect.serchFields)
  const [isList, setShowIsList] = useState(true)
  const [isIcon, setIsIcon] = useState(true)
  const [icon, setIcon] = useState('number') // ''
  const iconsType = {
    number: <Icon name="number" type="regularText" />,
    location: <Icon name="location" type="regularText" />,
    driver: <Icon name="driver" type="regularText" />,
    mack: <Icon name="mack" type="regularText" />,
    bill: <Icon name="bill" type="regularText" />,
    api: <Icon name="api" type="regularText" />,
    user: <Icon name="user" type="regularText" />,
  }

  const myRef = useRef(null)

  const togglerList = () => {
    setShowIsList(!isList)
    if (!isList) myRef.current.focus()
  }
  const closeList = () => setShowIsList(false)

  const clickFromItem = (typeIcon) => {
    fnSetFieldSearh(typeIcon)
    setIcon(typeIcon)
    setIsIcon(true)
  }
  const findInServer = (what, where) => {
    fnSubmit(what, where)
    fnCloseSerchInput()
  }

  useOutsideClick(myRef, closeList)

  useEffect(() => {
    myRef.current.focus()
    fnSetFieldSearh('periodic')
    document.addEventListener('keydown', (e) => {
      if (e.code === 'Enter' || e.key === 'Enter') {
        myRef.current && findInServer(myRef.current.value, myRef.current.name)
      }
    })
    return () => document.removeEventListener('keydown', findInServer)
  }, []) //eslint-disable-line

  return (
    <Wrapper>
      {isIcon && (
        <IconWrp left>
          {/*onClick={togglerList} */}
          {iconsType[icon]}
        </IconWrp>
      )}
      <IconWrp onClick={fnCloseSerchInput}>
        <Icon name="close" type="regularText" />
      </IconWrp>
      <Input
        type="text"
        name={name}
        value={value}
        onChange={fnChange}
        onClick={isIcon ? null : togglerList}
        autocomplete="off"
        ref={myRef}
        placeholder={placeholder}
      />
      {false && isList && (
        <List>
          {searchList && searchList.length > 0 ? (
            searchList.map((el) => {
              return (
                <ListItem key={el.id} onClick={() => clickFromItem(el.icon)}>
                  {iconsType[el.icon]}
                  {el.label}
                </ListItem>
              )
            })
          ) : (
            <p>nothig to display</p>
          )}
        </List>
      )}
    </Wrapper>
  )
}
