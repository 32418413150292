import { takeEvery, call, put, select, race, delay } from 'redux-saga/effects'
import { getMyRoutes } from '../../lib/requests/routesRequests'
import { getCurrentLang, getToken, getDicionary } from '../selectors'
import { delays } from '../../lib/constants'

export function* worker() {
  try {
    const currentLang = yield select(getCurrentLang)
    const token = yield select(getToken)
    const dic = yield select(getDicionary)
    const { result } = yield race({
      result: call(getMyRoutes, token, currentLang),
      timeout: delay(delays.forCancelRequest),
    })
    if (result && result.status === 200) {
    } else {
      yield put({
        type: 'SHOW_ERROR_API',
        code: 6,
        message: dic.msg_server_err || 'internal server error',
      })
    }
  } catch (e) {
    // yield put({
    //   type: 'SET_BUBBLE_MESSAGE',
    //   payload: e.response ? e.response.data.message : e.message,
    // })
    // if (e.response && e.response.data.relogin) {
    //   yield put({ type: 'SAGA_LOGOUT' })
    // }
    // if (e.response && e.response.data.forwardTo) {
    //   yield put({
    //     type: 'REDIRECT_OUTSIDE',
    //     payload: e.response.data.forwardTo,
    //   })
    // }
  }
}

export default function* myRoutes() {
  yield takeEvery('SAGA_TEST', worker)
}
