import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { useDispatch } from 'react-redux'
import useData from '../../../hooks/useDataStore'
import BaseBtn from '../../../components/buttons/BaseBtn'
import ThinBtn from '../../../components/buttons/ThinBtn'
import BtnLikeLink from '../../../components/buttons/BtnLikeLink'
import {
  FlexWrp,
  componentView,
  componentEdit,
  RoutePartForSave,
  RoutePartForEdit,
  RoutePartBetweenWrp,
} from './SmallComponentsForNewRouteForm'
import { parseDateOfLocal } from '../../../lib/helpers/forRoutes'
import { validateRoutePartItemData } from '../../../lib/helpers/forValidate'
import { strToUpperCase } from '../../../lib/helpers/forStyling'

export default ({
  activePart = '',
  onEditPart = () => {},
  onRemoveRoutePart = () => {},
  routeParts = [],
  errors = [],
  onSelectPointType = () => {},
  settings = {},
}) => {
  const dispatch = useDispatch()
  const currentTheme = useContext(ThemeContext)
  const { DIC, MONTH_SHORT } = useData()

  const typePart = {
    1: strToUpperCase(DIC.workaction_1),
    2: strToUpperCase(DIC.workaction_2),
    3: strToUpperCase(DIC.workaction_3),
    4: strToUpperCase(DIC.workaction_4),
  }

  const colorPart = {
    1: currentTheme.approver,
    2: currentTheme.focus,
    4: currentTheme.error,
    3: currentTheme.mint_Visited_Links,
  }

  const formatDate = (date) => {
    return parseDateOfLocal(date, '', MONTH_SHORT)
    // return date ? moment(date).format('DD-MM-YYYY') : ''
  }

  const onUpdateItemRoutePart = (index, data) => {
    const result = routeParts.map((obj, i) => {
      if (i === index) {
        return { ...obj, ...data }
      } else {
        return obj
      }
    })
    dispatch({ type: 'UPDATE_NEWROUTE_PARTS', payload: result })
  }
  const onRemoveError = (index, where) => {
    const result = errors.map((obj, i) => {
      if (i === index) {
        return {
          ...obj,
          [where]: { ...obj[where], bool: 0, msg: '' },
        }
      } else {
        return obj
      }
    })
    setTimeout(
      () => dispatch({ type: 'UPDATE_NEWROUTE_PARTS_ERROR', payload: result }),
      3000
    )
  }

  const onUpdateItemRoutePartError = (index, where) => {
    const result = errors.map((obj, i) => {
      if (i === index) {
        return {
          ...obj,
          [where]: { ...obj[where], bool: 1, msg: DIC.form_err_required },
        }
      } else {
        return obj
      }
    })
    dispatch({ type: 'UPDATE_NEWROUTE_PARTS_ERROR', payload: result })
    onRemoveError(index, where)
  }

  const onSubmit = (index) => {
    if (
      validateRoutePartItemData(routeParts, index, onUpdateItemRoutePartError)
    ) {
      return
    }

    onEditPart('')
  }

  return routeParts.map((el, index) => (
    <span style={{ width: '100%' }} key={el.id}>
      {activePart === el.id ? (
        <RoutePartForEdit>
          {
            componentEdit(
              index,
              el,
              onUpdateItemRoutePart,
              errors,
              typePart,
              DIC,
              settings
            )[el.workaction]
          }
          <FlexWrp>
            {index > 0 && (
              <ThinBtn
                type="delete"
                label={DIC.btn_delete}
                onClick={() => onRemoveRoutePart(el.id)}
              />
            )}
            <BaseBtn
              fullSize={index <= 0}
              label={DIC.btn_aply}
              fnClick={() => onSubmit(index)}
              // disabled={!isValidData}
            />
          </FlexWrp>
        </RoutePartForEdit>
      ) : (
        <RoutePartForSave isClickable={!activePart}>
          {
            componentView(
              index,
              el,
              !activePart,
              onEditPart,
              formatDate,
              routeParts,
              colorPart,
              typePart,
              DIC,
              settings
            )[el.workaction]
          }
        </RoutePartForSave>
      )}
      <RoutePartBetweenWrp contentBetween={index + 1 !== routeParts.length}>
        <BtnLikeLink
          label={`+ ${DIC.point}`}
          onClick={!activePart ? () => onSelectPointType(index) : null}
        />
      </RoutePartBetweenWrp>
    </span>
  ))
}
