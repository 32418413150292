import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import Icon from '../../components/Icon'

const Wrapper = styled.button`
  padding: 0.6rem 0.8rem 0.4rem;
  background-color: ${(p) => p.theme.filtred};
  border-radius: 0.5rem;
  transition: 0.2s ease;
  transform: scale(1.2);
  :active {
    transform: scale(0.95);
  }
`

export default ({
  id = '',
  currentFilter = '',
  where = { typeUpdate: '', location: '' },
  content = [],
  badRequest,
}) => {
  const dispatch = useDispatch()
  const onChangeCurrentFilter = () => {
    if (currentFilter !== id) {
      dispatch({ type: 'CLEAR_ARGS_FOR_FILTER' })
    }
    dispatch({
      type: 'CHANGE_CURRENT_ID_FOR_FILTER',
      payload: id,
      where,
    })
    dispatch({ type: 'OPEN_MODAL', payload: 'filter' })
  }

  return content.length > 0 || badRequest ? (
    <Wrapper onClick={onChangeCurrentFilter}>
      <Icon name="filter" type="links" width="12px" height="12px" />
    </Wrapper>
  ) : null
}
