import Type from '../types'

const initialState = {
  code: 4,
  message: '',
  isShow: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.SHOW_ERROR_API:
      return {
        ...state,
        code: action.code,
        message: action.message,
        isShow: true,
      }
    case Type.CLOSE_ERROR_API:
      return {
        ...state,
        code: '',
        message: '',
        isShow: false,
      }
    default:
      return state
  }
}
