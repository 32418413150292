import store from '../../redux/store'

export const ctrlCClick = (str) => {
  const dic = store.getState().defaultLang.serverDictionary

  navigator.clipboard
    .writeText(str)
    .then(() => {
      store.dispatch({
        type: 'SET_BUBBLE_MESSAGE',
        payload: dic.copy_link,
        msgType: 2,
      })
    })
    .catch((err) =>
      store.dispatch({ type: 'SET_BUBBLE_MESSAGE', payload: dic.err_try_more })
    )
}
