import Type from '../types'

const initialState = {
  list: [],
  serchList: [],
  loader: false,
  smartList: [],
  driver: [],
  car: [],
  trailer: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_PARK_LOADER:
      return {
        ...state,
        loader: action.payload,
      }
    case Type.SET_DRIVERS_FOR_SMART_LIST:
      return {
        ...state,
        driver: [...action.payload],
      }
    case Type.SET_CARS_FOR_SMART_LIST:
      return {
        ...state,
        car: [...action.payload],
      }
    case Type.SET_TRAILERS_FOR_SMART_LIST:
      return {
        ...state,
        trailer: [...action.payload],
      }

    case Type.SET_PARK_LIST:
      return {
        ...state,
        list: [...action.payload],
      }
    case Type.FILTER_SEARCH_LIST:
      return {
        ...state,
        serchList: action.payload
          ? state.list
              .filter((el) => el.union.toLowerCase().includes(action.payload))
              .sort((a, b) => {
                return a.union > b.union
              })
          : state.list.sort((a, b) => (a.union > b.union ? 1 : -1)),
      }
    case Type.FILTER_SMART_LIST:
      return {
        ...state,
        serchList: action.payload
          ? state[action.filterType]
              .filter((el) => el.union.toLowerCase().includes(action.payload))
              .sort((a, b) => {
                return a.union > b.union
              })
          : state[action.filterType].sort((a, b) =>
              a.union > b.union ? 1 : -1
            ),
      }
    case Type.CLEAR_SEARCH_LIST:
      return {
        ...state,
        serchList: [],
      }
    case Type.CLEAR_SMART_LIST:
      return {
        ...state,
        serchList: [],
      }

    default:
      return state
  }
}
