
export const Android = "Android";
export const iPhone = "iPhone";
export const iPad = "iPad";
export const iPod = "iPod";
export const Linux = "Linux";
export const Chrome = "Chrome";
export const Macintosh = "Macintosh";
export const iOS = "iOS";
export const Windows = "Windows";

export const deviceQuery = {
    [Android]: /Android/i,
    [iPad]: /iPad/i,
    [iPhone]: /iPhone/i,
    [iPod]: /iPod/i,
};

export const systemQuery = {
    [Linux]: /Linux/i,
    [Chrome]: /CrOS/i,
    [Macintosh]: /Macintosh/i,
    [iOS]: /Mac OS/i,
    [Windows]: /IEMobile|Windows/i,
};

    // "BlackBerry": /BlackBerry/i,
    // "Bluebird": /EF500/i,
    // "Datalogic": /DL-AXIS/i,
    // "Honeywell": /CT50/i,
    // "Zebra": /TC70|TC55/i,


export const matchQueryName = (query) => {
    return Object.keys(query).find((key) => {
        if(navigator.userAgent.match(query[key])){
           return key
        }
    });
}