import React from 'react'
import BaseInput from "../../../components/inputs/BaseInput";
import {useSelector} from "react-redux";
import SelectInput from "../../selects/SelectInput";

const GeneralFieldsList = (props) =>{
    const {
        onChange = () => {},
        onChangeSelect = () => {}, // for select
        onChangeCheckbox = () => {}, // for checkbox
        fields = [], //arr of obj { name, dicName }
        uniq = {}, // uniq input id
        errors = {},
        data = {}, // form values
        optionsList=[], // list options for select
    } = props

    const dic = useSelector((s) => s.defaultLang.serverDictionary)

        return (
            <>
                {fields.length > 0 && fields.map((item)=>{

                    const {
                        name ='',
                        getDicName = ()=>{},
                        isShortInput = false,
                        afterDicName = () =>{},
                        beforeDicName = ()=>{},
                        disabled = false,
                        styles = {},
                        placeholder = '',
                        isRequired = false,
                        action = {},
                        expectedValue = [],
                    } = item

                    const getStyles = () => {
                        let result = {
                            marginTop: '2.2rem', marginBottom: '1rem'
                        }
                        if(isShortInput) {
                            result = {
                                ...result,
                                width: '55%',
                                ...styles
                            }
                        }
                        return { ...result, ...styles};
                    }
                    const afterTxt = isShortInput ? afterDicName(dic) : ''
                    const beforeTxt = isShortInput ? beforeDicName(dic) : ''
                    const label = getDicName(dic)
                    if(expectedValue[0] === 'oneOfType' || expectedValue[0] === 'unionType'){
                        return (
                            <SelectInput
                                style={getStyles()}
                                name={uniq[name]}
                                value={data[name]?.union}
                                label={isRequired ? `${ label || ''}*` : label || ''}
                                error={errors[name] && errors[name] }
                                message={errors[name] && errors[name]?.msg }
                                action={action}
                                list={optionsList}
                                fnFormValue={onChangeSelect}
                            />
                        )
                    }

                    if(expectedValue[0] === 'manyTypes'){

                        return (
                            <BaseInput
                                style={getStyles()}
                                name={uniq[name]}
                                value={data[name]}
                                label={isRequired ? `${ label || ''}*` : label || ''}
                                error={errors[name] && errors[name] }
                                message={errors[name] && errors[name]?.msg }
                                action={action}
                                fnFocus={()=> onChangeCheckbox(item)}
                            />
                        )
                    }

                    return (
                        <BaseInput
                            style={getStyles()}
                            fnChange={onChange}
                            name={uniq[name]}
                            beforeTxt={beforeTxt}
                            afterTxt={afterTxt}
                            id={name}
                            label={isRequired ? `${ label || ''}*` : label || ''}
                            value={data[name]}
                            error={errors[name] && errors[name] }
                            message={errors[name] && errors[name]?.msg }
                            disabled={disabled}
                            placeholder={placeholder}
                        />
                    )
                })}
            </>
        )
}

export default GeneralFieldsList