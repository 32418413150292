import React, {useEffect, useState } from 'react'
import styled from "styled-components"
import { useDispatch, useSelector } from 'react-redux'
import shortId from 'shortid'
import BaseLoadInput from '../../inputs/BaseLoadInput'
import { validateTypeFile } from '../../../lib/helpers/forValidate'
import { strToUpperCase } from '../../../lib/helpers/forStyling'
import { FilesTypesApprovedDefault } from '../../../lib/constants'
import { postDocument } from '../../../lib/requests/routesRequests'

const FileTitle = styled.p`
  margin-top: 0.5rem;
  color: ${(p) => p.theme.elements_low};
`


export default ({ periodic = '', submitResolve }) => {
    const dispatch = useDispatch()
    const isLoader = useSelector((s) => s.routeDocuments.isLoader)
    const list = useSelector((s) => s.routeDocuments.uploadFiles)
    const dic = useSelector((s) => s.defaultLang.serverDictionary)

    const currentLang = useSelector((s) => s.defaultLang.currentLang)
    const token = useSelector((s) => s.user.token)

    const [file, setFile] = useState({})

    const setList = (dataArr) => {
        dispatch({ type: 'SAGA_GET_ROUTE_DOCUMENTS', periodic, dataArr })
    }

    //setList([...list, newObj])


    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    }

    const onPostFile = () => {
        if(!file){
            return;
        }

        const formData = new FormData();
        formData.append('documents[0]', file);
        formData.append('periodic', periodic);

        dispatch({type:'SET_DOCUMENT_LOADER', payload: true })

        postDocument(token, currentLang, formData)
            .then((response)=>{
                submitResolve()
            }).catch((error)=>{
                const response = error?.response
                if(response){
                    dispatch({
                        type: 'SHOW_ERROR_API',
                        code: String(response?.status).slice(0, 1),
                        message:  response?.data?.message || error?.message || "Error",
                    })
                }

        }).finally(()=>{
            dispatch({type:'SET_DOCUMENT_LOADER', payload: false })
        })
    }

    useEffect(()=>{
        if(file && file?.size > 1){
            onPostFile()
        }

    },[file])

    return (
        <>
            <BaseLoadInput
                style={{ marginTop: list.length ? '1rem' : '3rem' }}
                label={dic.load_file}
                name={periodic}
                fnChange={handleFileChange}
                isUpload={isLoader}
                type={"file"}
            />
            <FileTitle>
                {`${strToUpperCase(
                    dic.info_6
                )}: ${FilesTypesApprovedDefault.join(', ')}. ${strToUpperCase(
                    dic.info_55
                )}`}
            </FileTitle>
        </>
    )
}
