import { takeLatest, put, call, select, race, delay } from 'redux-saga/effects' //select, call,
import {
  createRoute,
  createTender,
} from '../../../lib/requests/clientRouteAction'
import { delays } from '../../../lib/constants'
import {
  getCurrentLang,
  getToken,
  // routeFlagList,
  getDicionary,
  getUserProfile,
} from '../../selectors'
import crypto from 'crypto'

export function* worker({ data, history }) {
  try {
    const currentLang = yield select(getCurrentLang)
    const token = yield select(getToken)
    // const flagList = yield select(routeFlagList)
    const dic = yield select(getDicionary)
    const profile = yield select(getUserProfile)

    const preparedData = JSON.stringify(data)
    const hmac = crypto
      .createHmac('sha256', profile.mackey)
      .update(preparedData)
    const signature = hmac.digest('hex')

    const { result } = yield race({
      result: call(
        data.routes.route.tender ? createTender : createRoute,
        token,
        currentLang,
        preparedData,
        signature
      ),
      timeout: delay(delays.forCancelRequest),
    })

    if (
      result &&
      (result.status === 200 || result.status === 201 || result.status === 202)
    ) {
      yield history.push(
        `/${data.routes.route.tender ? 'tender' : 'route'}/${
          result.data.data[0].periodic
        }`
      )
      // yield put({ type: 'SAGA_GET_SINGLE_ROUTE', periodic })
      // yield put({
      //   type: 'UPDATE_ROUTES_FLAG_LIST',
      //   payload: { ...flagList, top: true },
      // })
      yield put({ type: 'CLOSE_ERROR_API' })
    } else {
      yield put({
        type: 'SHOW_ERROR_API',
        code: 6,
        message: dic.msg_server_err || 'internal server error',
      })
    }
  } catch (e) {
    yield put({
      type: 'SHOW_ERROR_API',
      code: e.response ? String(e.response.status).slice(0, 1) : 4,
      message: e.response ? e.response.data.message : e.message,
    })
    if (e.response && e.response.data.relogin) {
      yield put({ type: 'SAGA_LOGOUT' })
    }
  }
}

export default function* postCreateRoute() {
  yield takeLatest('SAGA_TEST_CREATE_ROUTE', worker)
}
