import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import Icon from '../Icon'
import error from '../../assets/img/apiError/error.png'
import errConnect from '../../assets/img/apiError/socket.png'
import fatal from '../../assets/img/apiError/fatalError.png'
import { strToUpperCase } from '../../lib/helpers/forStyling'

const Wrapper = styled.div`
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(p) => p.theme.modalGradient};
`
const Content = styled.div`
  position: relative;
  width: 90%;
  max-width: 28rem;
  min-height: 20rem;
  margin: 11rem auto;
  padding: 1.6rem;
  background-color: ${(p) => p.theme.opposite};
  border-radius: ${(p) => p.theme.PopUpRadius};
`
const Img = styled.img`
  max-width: 100%;
  margin-bottom: 0.5rem;
`
const BottomWrp = styled.div`
  width: 100%;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const Message = styled.p`
  margin-bottom: 1.6rem;
  text-align: center;
  font-size: ${(p) => p.theme.h4};
  font-weight: 500;
`

const Btn = styled.button`
  font-size: ${(p) => p.theme.h4};
  font-weight: 500;
  padding: 0.8rem 1.6rem;
  text-transform: uppercase;
  transition: 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 0.3rem;
  :active {
    transform: scale(0.95);
  }
`
const crossStyle = {
  position: 'absolute',
  top: '1.6rem',
  right: '1.6rem',
}

export default () => {
  const dispatch = useDispatch()
  const code = useSelector((s) => s.apiError.code)
  const message = useSelector((s) => s.apiError.message)
  const onCloseWindow = () => dispatch({ type: 'CLOSE_ERROR_API' })

  const views = {
    4: error,
    5: fatal,
    6: errConnect,
  }

  return (
    <Wrapper>
      <Content id="apiErrorWrp">
        <Icon
          name="close"
          type="regularText"
          style={crossStyle}
          onClick={onCloseWindow}
        />
        <Img src={views[code]} />
        <BottomWrp>
          <Message>{strToUpperCase(message)}</Message>
          <Btn type="button" onClick={onCloseWindow}>
            ok
          </Btn>
        </BottomWrp>
      </Content>
    </Wrapper>
  )
}
