import { takeLatest, put, select, call, race, delay } from 'redux-saga/effects'

import {
  arrivedAtCharge,
  arrivedAtUncharge,
  arrivedAtCustoms,
  arrivedAtBorder,
  passedBorder,
  passedCustom,
} from '../../../lib/requests/operatorRouteAction'
import { delays } from '../../../lib/constants'
import {
  getCurrentLang,
  getToken,
  routeFlagList,
  getDicionary,
} from '../../selectors'

const requests = {
  1: arrivedAtCharge, // upload
  2: arrivedAtUncharge, // unload
  3: arrivedAtCustoms, // custom
  33: passedCustom,
  4: arrivedAtBorder, // border
  44: passedBorder,
}

export function* worker({ data, actionType, routeId, actionForCard }) {
  try {
    const currentLang = yield select(getCurrentLang)
    const token = yield select(getToken)
    const flagList = yield select(routeFlagList)
    const dic = yield select(getDicionary)

    const { result } = yield race({
      result: call(
        requests[actionType],
        token,
        currentLang,
        data.routepart,
        routeId
      ),
      timeout: delay(delays.forCancelRequest),
    })

    if (result && result.status === 200) {
      yield put({
        type: 'UPDATE_ROUTES_FLAG_LIST',
        payload: actionForCard
          ? { ...flagList, bottom: true }
          : { ...flagList, [routeId]: true },
      })
      yield put({ type: 'CLOSE_ERROR_API' })
    } else {
      yield put({
        type: 'SHOW_ERROR_API',
        code: 6,
        message: dic.msg_server_err || 'internal server error',
      })
    }
  } catch (e) {
    yield put({
      type: 'SHOW_ERROR_API',
      code: String(e.response.status).slice(0, 1),
      message: e.response ? e.response.data.message : e.message,
    })
    if (e.response && e.response.data.relogin) {
      yield put({ type: 'SAGA_LOGOUT' })
    }
  }
}

export default function* arrivedTo() {
  yield takeLatest('SAGA_ARRIVED_TO', worker)
}
