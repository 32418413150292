import React, { useState } from 'react'
import styled from 'styled-components'
import Icon from '../../components/Icon'
import { strToUpperCase } from '../../lib/helpers/forStyling'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
`
const FlexWrp = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Input = styled.input`
  width: 100%;
  height: 4.6rem;
  padding-left: 1rem;
  background-color: ${(p) =>
    p.isBright ? p.theme.opposite : p.theme.selected};
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => (p.error ? p.theme.error : p.theme.regular_txt)};
  ${(p) => (p.error ? `border: 0.1rem solid ${p.theme.error};` : null)};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  
  :focus {
    border: 0.1rem solid ${(p) => p.theme.inputBorder};
  }
`
const Label = styled.p`
  width: 100%;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  text-align: left;
  opacity: 0.8;
  font-size: ${(p) => p.theme.h5};
  color: ${(p) => p.theme.additional};
  white-space: nowrap;
  display: flex;
`
const ErrorMessage = styled.span`
  padding-left: 0.5rem;
  color: ${(p) => p.theme.error};
`

const Eye = styled.span`
  position: absolute;
  top: 48%;
  right: 5%;
`
const BeforeTxt = styled.p`
  margin-right: 1rem;
  margin-left: 0.5rem;
`
const AfterTxt = styled(BeforeTxt)`
  margin-right: 0;
  margin-left: 1rem;
`

export default ({
  value = '',
  type = 'text',
  name = 'default',
  label = 'label',
  error = false,
  message = 'not correct format',
  placeholder = '',
  isBright = false,
  fnChange = () => {},
  fnFocus = () => {},
  onBlur = () => {},
  disabled = false,
  beforeTxt = '',
  afterTxt = '',
  style = {},
}) => {
  const [typeInput, setTypeInput] = useState('password')
  const [activeEye, setActiveEye] = useState(0)

  const onShowPassword = () => {
    if (typeInput === 'password') {
      setTypeInput('text')
      setActiveEye(1)
    } else {
      setTypeInput('password')
      setActiveEye(0)
    }
  }

  return (
    <Wrapper style={style}>
      <Label>
        {error ? (
          <ErrorMessage>{`* ${message}`}</ErrorMessage>
        ) : (
          strToUpperCase(label)
        )}
      </Label>
      <FlexWrp>
        {beforeTxt && <BeforeTxt>{beforeTxt}</BeforeTxt>}
        <Input
          id={name}
          data-testid={name}
          type={type === 'password' ? typeInput : type}
          name={name}
          value={value}
          onChange={fnChange}
          onFocus={fnFocus}
          onBlur={onBlur}
          error={error}
          isBright={isBright}
          disabled={disabled}
          placeholder={placeholder}
        />
        {type === 'password' && (
          <Eye onClick={() => (disabled ? null : onShowPassword())}>
            <Icon
              name={activeEye ? 'crossedEye' : 'eye'}
              type="icons"
              style={{ opacity: '0.5' }}
            />
          </Eye>
        )}
        {afterTxt && <AfterTxt>{afterTxt}</AfterTxt>}
      </FlexWrp>
    </Wrapper>
  )
}
