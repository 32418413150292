import { api, routs } from '../api'

//Get route groups
export const getRouteWidgetGroups = (token, currentLang) => {
  return api.get(routs.routeWidgetGroups, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

//Get my active routes
export const getMyRoutes = (token, currentLang) => {
  return api.get(routs.myRoutes, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}
//Get all active routes
export const getAllRoutes = (token, currentLang) => {
  return api.get(routs.allRoutes, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

//Get basic details of some routes
export const getRouteDetails = (token, currentLang, routeList) => {
  return api.get(routs.routesForWidget, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: {
      routes: routeList,
    },
  })
}

//Get complete routes
export const getCompleteRoutes = (token, currentLang, data) => {
  return api.get(
    `${routs.getCompleteRoutes}?page=${data.page}&perPage=${data.perPage}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

//Get complete tenders
export const getCompleteTenders = (token, currentLang, data) => {
  return api.get(
    `${routs.getCompleteTenders}?page=${data.page}&perPage=${data.perPage}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: currentLang,
      },
    }
  )
}

//Get single route/tender details
export const getSingleRoute = (token, currentLang, periodic) => {
  return api.get(routs.singleRoute, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: {
      route: periodic,
    },
  })
}

//Get tender children
export const getTenderChildren = (token, currentLang, periodic) => {
  return api.get(routs.getTenderChildren, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: {
      tenderPeriodic: periodic,
    },
  })
}

//Get single company details
export const getSingleCompany = (token, currentLang, clientHash) => {
  return api.get(routs.singleCompany, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: {
      hash: clientHash,
    },
  })
}

//Get tender groups
export const getMyTenderWidgetGroups = (token, currentLang) => {
  return api.get(routs.tenderWidgetGroups, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

//Get my active tenders
export const getMyTenders = (token, currentLang) => {
  return api.get(routs.myTenders, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}
//Get all active tenders
export const getAllTenders = (token, currentLang) => {
  return api.get(routs.allTenders, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

// Get bets for tender
export const getPricequotes = (token, currentLang, route) => {
  return api.get(routs.getPricequotes, {
    params: { route },
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}
// Get Route Chronology
export const getRouteChronology = (token, currentLang, route) => {
  return api.get(routs.routeChronology, {
    params: { route },
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
  })
}

// get Tender Steps
export const getTenderSteps = (token, currentLang, periodic) => {
  return api.get(routs.getTenderSteps, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: { route: periodic },
  })
}

// get All documents
export const getRouteDocuments = (token, currentLang, periodic) => {
  return api.get(routs.getRouteDocuments, {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: currentLang,
    },
    params: { route: periodic },
  })
}

// upload File
export const postDocument = (token, currentLang, data) => {
  return api.post(
      routs.addDocument,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Language: currentLang,
          "Content-Type": "multipart/form-data",
        },
      }
  )
}
