import React from 'react'
import styled from 'styled-components'
import { strToUpperCase } from '../../lib/helpers/forStyling'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
`
const Content = styled.p`
  width: 100%;
  /* height: 4.6rem; */
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${(p) =>
    p.isBright ? p.theme.opposite : p.theme.selected};
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => (p.error ? p.theme.error : p.theme.regular_txt)};
  ${(p) => (p.error ? `border: 0.1rem solid ${p.theme.error};` : null)};
  ${(p) => p.isBright && `border: 0.1rem solid ${p.theme.inputBorder};`}
`
const Label = styled.p`
  width: 100%;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  text-align: left;
  opacity: 0.8;
  font-size: ${(p) => p.theme.h5};
  color: ${(p) => p.theme.additional};
  white-space: nowrap;
  display: flex;
`
const ErrorMessage = styled.span`
  padding-left: 0.5rem;
  color: ${(p) => p.theme.error};
`

export default ({
  value = '',
  name = 'default',
  label = 'label',
  error = false,
  message = 'not correct format',
  isBright = false,
  disabled = false,
  style = {},
}) => {
  return (
    <Wrapper style={style}>
      <Label>
        {error ? (
          <ErrorMessage>{`* ${message}`}</ErrorMessage>
        ) : (
          strToUpperCase(label)
        )}
      </Label>
      <Content
        id={name}
        name={name}
        error={error}
        isBright={isBright}
        disabled={disabled}
      >
        {value}
      </Content>
    </Wrapper>
  )
}
