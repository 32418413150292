import React from 'react'
import styled from 'styled-components'
import Header from '../../containers/headers/BaseHeader'
import NavMenu from '../../containers/menus/NavMenu'

const Content = styled.div`
  width: 100%;
  min-width: 100vw;
  min-height: calc(6rem - 100vh);
  margin-top: ${(p) => (p.withoutNav ? '6rem' : 0)};
`

export default ({ children, withoutNav = false }) => {
  return (
    <>
      <Header />
      {!withoutNav && <NavMenu style={{ marginTop: '6rem' }} />}
      <Content withoutNav={withoutNav}>{children}</Content>
    </>
  )
}
