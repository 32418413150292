import { useSelector } from 'react-redux'

export default () => {
  const DIC = useSelector((s) => s.defaultLang.serverDictionary)
  const USER_PROFILE = useSelector((s) => s.user.profile)
  const BASEROUTE = useSelector((s) => s.user.navlist)[0].path || '/'

  const MONTHS = [
    DIC.month_name_1,
    DIC.month_name_2,
    DIC.month_name_3,
    DIC.month_name_4,
    DIC.month_name_5,
    DIC.month_name_6,
    DIC.month_name_7,
    DIC.month_name_8,
    DIC.month_name_9,
    DIC.month_name_10,
    DIC.month_name_11,
    DIC.month_name_12,
  ]

  const MONTH_SHORT = [
    DIC.month_short_name_1,
    DIC.month_short_name_2,
    DIC.month_short_name_3,
    DIC.month_short_name_4,
    DIC.month_short_name_5,
    DIC.month_short_name_6,
    DIC.month_short_name_7,
    DIC.month_short_name_8,
    DIC.month_short_name_9,
    DIC.month_short_name_10,
    DIC.month_short_name_11,
    DIC.month_short_name_12,
  ]
  const WEEKDAYS_SHORT = [
    DIC.week_day_short_1,
    DIC.week_day_short_2,
    DIC.week_day_short_3,
    DIC.week_day_short_4,
    DIC.week_day_short_5,
    DIC.week_day_short_6,
    DIC.week_day_short_7,
  ]

  return { MONTHS, MONTH_SHORT, WEEKDAYS_SHORT, DIC, USER_PROFILE, BASEROUTE }
}
