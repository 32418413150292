import React from 'react'
import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
   {
    0% {
      opacity:0.1;
    }
    10% {
      opacity: 0.2;
    }
    15% {
      opacity: 0.3;
    }
    20% {
      opacity: 0.4;
    }
    25% {
      opacity: 0.5;
    }
    30% {
      opacity: 0.6;
    }
    35% {
      opacity: 0.7;
    }
    40% {
      opacity: 0.8;
    }
    45% {
      opacity: 0.9;
    }
    50% {
      opacity: 1;
    }
    55% {
      opacity: 0.9;
    }
    60% {
      opacity: 0.8;
    }
    65% {
      opacity: 0.7;
    }
    70% {
      opacity: 0.6;
    }
    75% {
      opacity: 0.5;
    }
    80% {
      opacity: 0.4;
    }
    85% {
      opacity: 0.3;
    }
    90% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.1;
    }
  }
`
const Wrapper = styled.div`
  width: 1rem;
  height: 1rem;
  margin-top: 0.3rem;
  margin-left: 1rem;
  border-radius: 50%;
  background-color: ${(p) => p.theme.basic};
  font-size: ${(p) => p.theme.h4};
  font-weight: 700;
  opacity: 0;
  animation: ${pulse} 2s infinite;
`

export default () => <Wrapper />
