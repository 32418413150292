import { takeEvery, call, put, select, race, delay } from 'redux-saga/effects'
import {
  getMyDrivers,
  getMyCars,
  getMyTrailers,
} from '../../lib/requests/auxRequests'
import { delays } from '../../lib/constants'
import { getCurrentLang, getToken, getDicionary } from '../selectors'

export function* worker() {
  const currentLang = yield select(getCurrentLang)
  const token = yield select(getToken)
  const dic = yield select(getDicionary)
  try {
    const { drivers } = yield race({
      drivers: call(getMyDrivers, token, currentLang),
      timeout: delay(delays.forCancelRequest),
    })
    if (drivers && (drivers.status === 200 || drivers.status === 'success')) {
      yield put({
        type: 'SET_DRIVERS_FOR_SMART_LIST',
        payload: drivers.data.data.map((obj) => ({
          ...obj,
          union: `${obj.lastname} ${obj.firstname} ${obj.maincellphone}`,
        })),
      })
      const { cars } = yield race({
        cars: call(getMyCars, token, currentLang),
        timeout: delay(delays.forCancelRequest),
      })
      if ((cars && cars.status === 200) || cars.status === 'success') {
        yield put({
          type: 'SET_CARS_FOR_SMART_LIST',
          payload: cars.data.data.map((obj) => ({
            ...obj,
            union: `${obj.make.title_ru} ${String(obj.number)}`,
          })),
        })
        const { trailers } = yield race({
          trailers: call(getMyTrailers, token, currentLang),
          timeout: delay(delays.forCancelRequest),
        })
        if (
          (trailers && trailers.status === 200) ||
          trailers.status === 'success'
        ) {
          yield put({
            type: 'SET_TRAILERS_FOR_SMART_LIST',
            payload: trailers.data.data.map((obj) => ({
              ...obj,
              union: `${obj.model} ${String(obj.number)}`,
            })),
          })
        } else {
          yield put({
            type: 'SHOW_ERROR_API',
            code: 6,
            message: dic.msg_server_err || 'internal server error',
          })
        }
      } else {
        yield put({
          type: 'SHOW_ERROR_API',
          code: 6,
          message: dic.msg_server_err || 'internal server error',
        })
      }
    } else {
      yield put({
        type: 'SHOW_ERROR_API',
        code: 6,
        message: dic.msg_server_err || 'internal server error',
      })
    }
  } catch (e) {
    yield put({
      type: 'SHOW_ERROR_API',
      code: String(e.response.status).slice(0, 1),
      message: e.response ? e.response.data.message : e.message,
    })
    if (e.response && e.response.data.relogin) {
      yield put({ type: 'SAGA_LOGOUT' })
    }
    if (e.response && e.response.data.forwardTo) {
      yield put({
        type: 'REDIRECT_OUTSIDE',
        payload: e.response.data.forwardTo,
      })
    }
  }
}

export default function* myAutoPark() {
  yield takeEvery('SAGA_GET_MY_PARK_FOR_SMART_SELECT', worker)
}
