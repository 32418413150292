import { useEffect } from "react";
import {
    deviceQuery,
    systemQuery,
    matchQueryName,
} from "../lib/devices"





export const useUserDevice = () => {
    //get user device and system
    useEffect(()=>{
        // console.log("device", matchQueryName(deviceQuery))
        // console.log("system", matchQueryName(systemQuery))
        // console.log("general", navigator.userAgent)
    },[])

}