import { takeEvery, call, put, select, race, delay } from 'redux-saga/effects'
// import { getCartype } from '../../../lib/requests/auxRequests'
// import { delays } from '../../../lib/constants'
import {getDicionary } from '../../selectors' // getCurrentLang, getToken,

//TODO await api
//now use mockData
const mockData = [
  {"title":"СІДЛОВИЙ ТЯГАЧ", id:1},
  {"title":"БОРТОВА ПЛАТФОРМА (З ГІДРОБОРТОМ)", id:2},
  {"title":"БОРТОВИЙ", id:3},
  {"title":"БОРТОВИЙ (ДУБЛЬ КАБІНА)", id:4},
  {"title":"БОРТОВИЙ (З КРАНОМ-МАНІПУЛЯТОРОМ)", id:5},
  {"title":"БОРТОВИЙ МАЛОТОНАЖНИЙ", id:6},
  {"title":"БОРТОВИЙ-ТЕНТОВАНИЙ", id:7},
  {"title":"БУРОВИЙ", id:8},
  {"title":"ВОДОЦИСТЕРНА", id:9},
  {"title":"ЗЕРНОВОЗ", id:10},
  {"title":"ЗМІННИЙ КУЗОВ", id:11},
  {"title":"КОНТЕЙНЕРОВОЗ", id:12},
  {"title":"КОРМОВОЗ", id:13},
  {"title":"ЛІСОВОЗ", id:14},
  {"title":"ПАЛИВОЦИСТЕРНА", id:15},
  {"title":"ПЛАТФОРМА", id:16},
  {"title":"ПЛАТФОРМА (ДУБЛЬ КАБІНА)", id:17},
  {"title":"ПЛАТФОРМА (З КРАНОМ-МАНІПУЛЯТОРОМ)", id:18},
  {"title":"ПЛАТФОРМА ТЕНТОВАНА", id:19},
  {"title":"САМОСКИД", id:20},
  {"title":"САМОСКИД (З КРАНОМ-МАНІПУЛЯТОРОМ)", id:21},
  {"title":"СПЕЦІАЛЬНИЙ ВАНТАЖНИЙ-C", id:22},
  {"title":"ФУРГОН", id:23},
  {"title":"ФУРГОН (ДУБЛЬ КАБІНА)", id:24},
  {"title":"ФУРГОН (З ГІДРОБОРТОМ)", id:25},
  {"title":"ФУРГОН Д/П ХУДОБИ", id:26},
  {"title":"ФУРГОН ІЗОТЕРМІЧНИЙ", id:27},
  {"title":"ФУРГОН ІЗОТЕРМІЧНИЙ-В", id:28},
  {"title":"ФУРГОН МАЛОТОНАЖНИЙ", id:29},
  {"title":"ФУРГОН РЕФРИЖЕРАТОР", id:30},
  {"title":"ФУРГОН РЕФРИЖЕРАТОР (ДУБЛЬ КАБІНА)", id:31},
  {"title":"ФУРГОН ХЛІБНИЙ", id:32},
  {"title":"ЦИСТЕРНА", id:33},
  {"title":"ЦИСТЕРНА Д/П НЕБЕЗ. ВАН", id:34},
  {"title":"ЦИСТЕРНА ХАРЧОВА", id:35},
];

export function* worker({ prewData }) {
  try {
    // const currentLang = yield select(getCurrentLang)
    // const token = yield select(getToken)
    const dic = yield select(getDicionary)
    yield put({ type: 'SET_SELECTLIST_LOADER', payload: true })
    // const { result } = yield race({
    //   result: call(getCartype, token, currentLang),
    //   timeout: delay(delays.forCancelRequest),
    // })

    if (true) {
        yield put({
          type: 'SET_SELECTLIST_DATA',
          payload: mockData.map((obj)=>({...obj, union: obj.title})),
        })
        yield put({ type: 'SET_SELECTLIST_LOADER', payload: false })
        yield put({ type: 'FILTER_SELECTLIST' })
    } else {
        yield put({ type: 'SET_SELECTLIST_LOADER', payload: false })
      yield put({
        type: 'SAGA_SHOW_ERROR',
        codeId: 6,
        msg: dic.msg_server_err,
      })
    }
  } catch (e) {
      yield put({ type: 'SET_SELECTLIST_LOADER', payload: false })
    yield put({
      type: 'SAGA_SHOW_ERROR',
      codeId: String(e.response.status).slice(0, 1),
      msg: e.response ? e.response.data.message : e.message,
    })
    if (e.response && e.response.data.relogin) {
      yield put({ type: 'SAGA_LOGOUT' })
    }
    if (e.response && e.response.data.forwardTo) {
      yield put({
        type: 'REDIRECT_OUTSIDE',
        payload: e.response.data.forwardTo,
      })
    }
  }
}

export default function* allRoutes() {
  yield takeEvery('SAGA_GET_CAR_BODY_TYPES', worker)
}
