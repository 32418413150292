import React, { useState, useRef, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import ModalPortal from '../../components/layouts/ModalPortal'
import Icon from '../../components/Icon'
import SelectDateBtn from '../../components/buttons/SelectDateBtn'
import SelectInput from '../selects/SelectInput'
import SmartParkSelect from '../selects/SmartParkSelect'
import BaseTextarea from '../../components/inputs/BaseTextarea'
import BaseBtn from '../../components/buttons/BaseBtn'
import { parseDriversObj, addKeysToRouteParts } from '../../lib/helpers/forForms'
import {
  lightTestEarlierLater,
  validateSubmitOfferTrasportForm,
} from '../../lib/helpers/forValidate'
import moment from 'moment'

const Wrapper = styled.div`
  width: 95vw;
  max-width: 50rem;
  height: 95vh;
  padding: ${(p) => p.theme.formSidePad};
  padding-bottom: ${(p) => (p.isIos ? '10rem' : 'auto')};
  border-radius: ${(p) => p.theme.PopUpRadius};
  background-color: ${(p) => p.theme.opposite};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 2.2rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`
const HeaderCity = styled.p`
  font-size: ${(p) => p.theme.h1};
  color: ${(p) => p.theme.regular_txt};
  font-weight: 600;
  :after {
    content: '→';
    margin: 0 0.5rem;
  }
  :last-child {
    :after {
      display: none;
    }
  }
`
const Content = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  overflow: auto;
`
const SelectWrp = styled.div`
  margin-top: 4rem;
  width: 100%;
`
const Select = styled.p`
  width: fit-content;
  margin-bottom: 2rem;
  margin-right: auto;
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => p.theme.links};
  border-bottom: 0.1rem dashed ${(p) => p.theme.links};
  cursor: pointer;
  :first-letter {
    text-transform: uppercase;
  }
`

export default ({
  fnClose = () => {},
  routeparts = [],
  periodic,
  flagList,
}) => {
  const dispatch = useDispatch()
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const isIos = useSelector((s) => s.general.isIos)
  const selectList = useSelector((s) => s.selectList.searchList)
  const [dates, setDates] = useState(addKeysToRouteParts(routeparts))
  const [borderCrossingTown, setBorderCrossingTown] = useState({})
  const [datesError, setDatesError] = useState(routeparts.map(() => false))
  const [datesMsg, setDatesMsg] = useState(dic.form_err_required)
  const [drivers, setDrivers] = useState(
    parseDriversObj({ car: null, trailer: null, driver: null, driver2: null })
  )

  const [errors, setErrors] = useState({
    car: { bool: 0, msg: dic.form_err_required },
    driver: { bool: 0, msg: dic.form_err_required },
    driver2: { bool: 0, msg: dic.form_err_matchesDrivers },
  })
  const [typeUpdate, setTypeUpdate] = useState('')
  const [isComment, setIsComment] = useState(false)
  const [isDriverTwo, setIsDriverTwo] = useState(false)
  const [message, setMessage] = useState('')
  const searchList = useSelector((s) => s.myPark.serchList)

  const refs = [...dates.map((item) => item.id), 'smart', 'driver2'].reduce(
    (result, item) => {
      return { ...result, [item]: useRef(null) }
    },
    {}
  )



  const executeScroll = (refName) =>
    refs[refName].current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })

  const setNotCorrectDatesMsg = (idx, id, bool = true) => {
    setDatesError(
      datesError.map((item, index) => {
        if (index === idx) {
          return true
        } else {
          return item
        }
      })
    )
    if (bool) executeScroll(id)

    setDatesMsg(dic.form_err_dateIsNotValid)
    setTimeout(
      () => {
        setDatesError(datesError.map(() => false))
        setDatesMsg(dic.form_err_required)
      },
      3000,
      true
    )
  }

  const commentToggler = () => {
    setIsComment(!isComment)
  }
  const driverTwoToggler = () => {
    setIsDriverTwo(!isDriverTwo)
  }

  const saveNewDriver = (typeAction, obj) => {
    switch (typeAction) {
      case 'driver':
        setDrivers({ ...drivers, driver: obj })
        setTypeUpdate('driver')
        break
      case 'driver2':
        setDrivers({ ...drivers, driver2: obj })
        setTypeUpdate('driver2')
        break
      case 'car':
        setDrivers({ ...drivers, car: obj })
        setTypeUpdate('car')
        break
      case 'trailer':
        setDrivers({ ...drivers, trailer: obj })
        setTypeUpdate('trailer')
        break
      default:
        break
    }
  }
  const onChangeInputDriver = (e) => {
    const VALUE = e.target.value
    const NAME = e.target.name
    if (NAME === 'driver2') {
      dispatch({ type: 'FILTER_SEARCH_LIST', payload: VALUE })
    } else {
      dispatch({
        type: 'FILTER_SMART_LIST',
        payload: VALUE,
        filterType: NAME,
      })
    }
    setDrivers({
      ...drivers,
      [NAME]: { ...[NAME], union: VALUE },
    })
  }
  const saveNewDate = (val, index) => {
    const test = lightTestEarlierLater(val, index, dates, 'not earlier')
    if (test) {
      setNotCorrectDatesMsg(index, null, false)
      return
    }
    const result = dates.map((obj, idx) => {
      if (idx === index) {
        return { ...obj, date1: val }
      } else {
        return obj
      }
    })
    setDates(result)
  }

  const onChangeTextArea = (e) => {
    setMessage(e.target.value)
  }

  const onChangeCrossingBorderTown = (where, obj, id) =>{

    setBorderCrossingTown((state) => ({
      ...state,
      [id]: obj,
    }))

    const result = dates.map((item)=>{
      if(item?.id === id){
        return {
          ...item,
          header: obj?.title,
          requiredId:obj?.id,
        }

      }
      return item
    });

    setDates(result)

  }

  const onSubmitNewDate = () => {

    const borderData = Object.values(borderCrossingTown)[0];
    const getBorderValue = () => {
      if(!borderData?.id){
        return null
      }
      const title = borderData?.title
      const distinct = borderData?.district
      const regionTitle = borderData?.region?.title

      return `${title}, ${distinct}, ${regionTitle}`
    }
    const result = {
      route: periodic,
      driver: drivers.driver.id || null,
      car: drivers.car.id || null,
      trailer: drivers.trailer.id || null,
      driver2: drivers.driver2.id || null,
      remark: message || null,
      bordercrossingat:  getBorderValue(),
      routepartsDates: dates
        .map((obj) => ({
          [obj.id]:
            obj.date1 === '0000-00-00'
              ? '0000-00-00'
              : moment(obj.date1).format('YYYY-MM-DD'),
        }))
        .reduce((result, item) => {
          const key = Object.keys(item)[0]
          result[key] = item[key]
          return result
        }, {}),
    }
   dispatch({ type: 'START_REQUEST', payload: `${periodic}bottom` })
   dispatch({ type: 'SAGA_OFFER_TRANSPORT', data: result })
  }

  const submitData = () => {
    validateSubmitOfferTrasportForm(
      {
        setErrors,
        executeScroll,
        setNotCorrectDatesMsg,
        lightTestEarlierLater,
        setIsDriverTwo,
        saveNewDriver,
      },
      drivers,
      errors,
      dates,
      onSubmitNewDate
    )
  }
  useEffect(() => {
    if (flagList.bottom) {
      fnClose(true)
    }
  }, [flagList]) //eslint-disable-line
  return (
    <ModalPortal>
      <Wrapper isIos={isIos}>
        <Icon
          name="close"
          onClick={() => fnClose(false)}
          style={{ marginLeft: 'auto', marginBottom: '1rem' }}
        />

        <Content>
          <Header>
            {dates.map(({ header, id }) => (
              <HeaderCity key={id}>{header}</HeaderCity>
            ))}
          </Header>
            <BaseBtn
              fullSize
              label={dic.msg_5}
              fnClick={submitData}
              style={{
                marginTop: '3.2rem',
                marginBottom: '2rem',
              }}
              localId={`${periodic}bottom`}
            />
          <span ref={refs.smart}>
            <SmartParkSelect
              dataList={searchList}
              data={drivers}
              fnFormValue={saveNewDriver}
              errors={errors}
              fnChange={onChangeInputDriver}
              style={{ height: '24rem', marginBottom: '3rem' }}
              typeUpdate={typeUpdate}
            />
          </span>
          {<Select onClick={commentToggler}>{`${dic.remark_add}...`}</Select>}
          {isComment && (
            <BaseTextarea
              fnChange={onChangeTextArea}
              value={message}
              label=""
              style={{ marginBottom: '2rem' }}
            />
          )}
          <Select
            onClick={driverTwoToggler}
            style={{ marginBottom: isDriverTwo ? '2rem' : '1rem' }}
          >
            {`${dic.add_driver_2}...`}
          </Select>
          <span style={{ minHeight: '4.6rem' }}>
            {isDriverTwo && (
              <span ref={refs.driver2}>
                <SelectInput
                  label={`${dic.driver} 2`}
                  value={drivers.driver2.union}
                  name="driver2"
                  fnFormValue={saveNewDriver}
                  fnChange={onChangeInputDriver}
                  list={searchList}
                  style={{ marginBottom: '5rem' }}
                  nothingToDisplay={dic.info_1}
                  error={errors.driver2 ? errors.driver2.bool : null}
                  message={errors.driver2 ? errors.driver2.msg : null}
                />
              </span>
            )}
          </span>
          <div style={{ width: '100%', marginTop: '1rem' }}>
            {dates &&
              dates.length > 0 &&
              dates.map((routepart, index) => {
                if(routepart.workaction === 4){
                  const value =  routepart.header || "";

                  return  routepart?.isRequired ? (
                        <SelectWrp
                            key={routepart.id}
                            ref={refs[routepart.id]}
                            style={{marginTop: '2.6rem'}}
                        >
                          <SelectInput
                              name="border"
                              id={routepart.id}
                              value={value}
                              label={`${routepart.title}*`}
                              error={datesError[index]}
                              message={dic.form_err_required}
                              action={{
                                type: 'SAGA_GET_BORDERS_TO_SELECT',
                                loader: 'selectList',
                              }}
                              list={selectList}
                              fnFormValue={onChangeCrossingBorderTown}
                          />
                        </SelectWrp>
                        ) : null
                }
              return (
                    <SelectWrp key={routepart.id} ref={refs[routepart.id]}>
                      <SelectDateBtn
                          disabledPeriod="nothing"
                          width="100%"
                          label={
                            routepart?.isRequired
                                ? `${routepart.title}*`
                                : routepart.title
                          }
                          extraString={routepart.address}
                          error={datesError[index]}
                          message={datesMsg}
                          value={
                            routepart.date1 === '0000-00-00'
                                ? ''
                                : new Date(routepart.date1)
                          }
                          index={index}
                          fnClick={saveNewDate}
                      />
                    </SelectWrp>
               )}
              )}

          </div>
        </Content>
      </Wrapper>
    </ModalPortal>
  )
}
