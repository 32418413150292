export const strToUpperCase = (str) => {
  if (str) {
    return str[0].toUpperCase() + str.slice(1)
  }
}

export const replaceChartSymbols = (str) =>
  str.replace(/(&#(\d+);)/g, (match, capture, charCode) =>
    String.fromCharCode(charCode)
  )
