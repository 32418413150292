import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Icon from '../../../Icon'
import BaseLink from '../../../links/BaseLink'
import TabMenuWithIcon from '../../../../containers/menus/TabMenuWithIcons'
import TenderCardChartList from '../TenderCardChartList'
import TenderCardInfo from './TenderCardInfo2'
import RouteCardChat from '../../forRoutes/RouteCardChat'
import RouteCardDocs from '../../forRoutes/RouteCardDocs'
import RouteCardChronology from '../../forRoutes/RouteCardChronology'
import { Info } from '../../../smallPartsForInterface/SmallPartsCard'
import InfoMsgBubble from '../../../support/InfoMsgBubble'
import { strToUpperCase } from '../../../../lib/helpers/forStyling'
import RoutesLisFromTender from '../RoutesListFromTender'

const Wrapper = styled.div`
  width: 100%;
  margin-top: 6rem;
  padding: 2.6rem 1.6rem;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mrgBottom {
    margin-bottom: 3rem;
  }
`
const Header = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`
const HeaderCity = styled.p`
  font-size: ${(p) => p.theme.h1};
  color: ${(p) => p.theme.regular_txt};
  font-weight: 600;
  :after {
    content: '→';
    margin: 0 0.5rem;
  }
  :last-child {
    :after {
      display: none;
    }
  }
`

export default ({
  obj,
  list = [],
  onChangeTab = () => {},
  currentTab = '',
  isWinnerStatus = 1,
  isFinish = false,
}) => {
  //TODO prepare context
  const lang = useSelector((s) => s.defaultLang.serverDictionary)
  const { routeparts, periodic } = obj

  const element = (isFinish, isWinnerStatus) => {
    return {
      11: (
        <TenderCardInfo
          data={obj}
          isFinish={isFinish}
          isWinnerStatus={isWinnerStatus}
        />
      ),
      22: <RouteCardDocs periodic={periodic} />,
      33: <RouteCardChat periodic={periodic} />,
      44: <RouteCardChronology periodic={periodic} />,
      55: <TenderCardChartList periodic={periodic} />,
    }
  }
  return (
    <Wrapper>
      <Header>
        {routeparts.map(({ checkpoint, id }) => (
          <HeaderCity key={id}>{checkpoint.town.title_ru}</HeaderCity>
        ))}
      </Header>

      <div
        className="flex"
        style={{ marginBottom: obj.blindtender ? '1rem' : '3rem' }}
      >
        <div className="flex">
          <Icon
            name="hammer"
            width="1.2rem"
            height="1.2rem"
            style={{ marginRight: '1rem' }}
          />
          <Info>{`${lang.tender} ${periodic}`}</Info>
        </div>
        {isFinish ? (
          <Info isTrigger={isWinnerStatus === 2}>
            {isWinnerStatus === 2 ? strToUpperCase(lang.msg_12) : ''}
          </Info>
        ) : (
          <Info isWarn={false}>{`${lang.to}  ${moment(
            obj.tenderavailableunillmoment
          ).format('HH:mm DD.MM')}`}</Info>
        )}
      </div>
      {obj.blindtender && ( // blind tender
        <div
          className="flex"
          style={{ justifyContent: 'flex-start', cursor: 'pointer' }}
        >
          <Info>{lang.blind}</Info>
          <Icon
            name="crossedEye"
            type="additionalInfo"
            width="1.6rem"
            height="1.6rem"
            style={{ marginTop: '0.5rem', marginLeft: '0.5rem' }}
          />
          <InfoMsgBubble msg={lang.info_2} />
        </div>
      )}

      {(isWinnerStatus === 1 || isWinnerStatus === 3) && (
        <Info
          isTrigger={isWinnerStatus === 1}
          isWarn={isWinnerStatus === 3}
          style={{ marginBottom: '2rem' }}
        >
          {isWinnerStatus === 3
            ? `${strToUpperCase(lang.msg_14)}:(`
            : isWinnerStatus === 1 && obj.routestatus < 0
            ? `${strToUpperCase(lang.msg_13)}`
            : ''}
        </Info>
      )}
      {isWinnerStatus === 2 && (
        <RoutesLisFromTender
            data={obj}
        />
      )}
      <TabMenuWithIcon
        onChangeTab={onChangeTab}
        list={
          obj.newMessages > 0
            ? list.map((item) => {
                if (item.typeIcon === 'chat') {
                  return { ...item, message: obj.newMessages }
                } else {
                  return item
                }
              })
            : list
        }
        currentRout={currentTab}
        style={{ paddingRight: 0, paddingLeft: 0 }}
      />
      {element(isFinish, isWinnerStatus)[currentTab]}
    </Wrapper>
  )
}
