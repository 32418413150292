import React, { useContext, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'
import Icon from '../../components/Icon'
import BaseBtn from '../../components/buttons/BaseBtn'
import useOutsideClick from '../../hooks/useOutsideClick'

const Wrapper = styled.div`
  position: relative;
  padding: 1rem;
  font-size: ${(p) => p.theme.h4};
  color: ${(p) => p.theme.icons};
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Label = styled.p`
  margin-right: 1rem;
  :first-letter {
    text-transform: uppercase;
  }
`
const ArrowWrp = styled.span`
  margin-top: 0.3rem;
  margin-left: ${(p) => (p.isopen ? `0.4rem` : `0`)};
  transform: ${(p) => (p.isopen ? `rotate(-90deg)` : `rotate(90deg)`)};
`
const List = styled.div`
  z-index: 5;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  min-width: 12rem;
  background-color: ${(p) => p.theme.opposite};
`
export default () => {
  const dispatch = useDispatch()
  const myref = useRef()
  const currentLang = useSelector((s) => s.defaultLang.currentLang)
  const currentTheme = useContext(ThemeContext)
  const list = useSelector((s) => s.defaultLang.list)
  const isOpen = useSelector((s) => s.defaultLang.isOpen)

  const closeList = () => {
    dispatch({ type: 'TOGGLE_LANG_SELECT', payload: 0 })
  }
  const toggler = () => {
    dispatch({ type: 'TOGGLE_LANG_SELECT', payload: isOpen ? 0 : 1 })
  }
  const onSelectLang = (id) => {
    dispatch({ type: 'SAGA_CHANGE_LANG', lang: id })
  }
  useOutsideClick(myref, closeList)
  useEffect(() => {
    dispatch({ type: 'SAGA_CHANGE_LANG', lang: currentLang })
  }, []) //eslint-disable-line
  return (
    <Wrapper ref={myref}>
      <Label onClick={toggler}>
        {list.find(({ id }) => id === currentLang).title}
      </Label>
      <ArrowWrp isopen={isOpen} onClick={toggler}>
        <Icon name="arrowShevron" width="0.9rem" />
      </ArrowWrp>
      {isOpen > 0 && (
        <List>
          {list.map(({ title, id }) => (
            <BaseBtn
              key={id}
              label={title}
              inContext="popup"
              flexLeft
              forLoadFlow
              localId={id}
              fnClick={() => onSelectLang(id)}
              style={{
                width: '100%',
                paddingLeft: '0.5rem',
                borderBottom: `0.1rem solid ${currentTheme.lines}`,
              }}
            />
          ))}
        </List>
      )}
    </Wrapper>
  )
}
