import Type from '../types'

const initialState = {
  bubbleMsg: '',
  bubbleMsgType: 2,
  isShowModal: false,
  modalChildren: '',
  isAuth: false,
  theme: 'base',
  appLoader: true,
  startPath: null,
  isIos: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.LOGOUT:
      return {
        ...state,
        isAuth: false,
        startPath: null,
      }
    case Type.SET_THEME:
      return {
        ...state,
        theme: action.payload,
      }
    case Type.SET_BUBBLE_MESSAGE:
      return {
        ...state,
        bubbleMsg: action.payload,
        bubbleMsgType: action.msgType,
      }
    case Type.CLOSE_BUBBLE_MESSAGE:
      return {
        ...state,
        bubbleMsg: '',
        bubbleMsgType: 2,
      }
    case Type.OPEN_MODAL:
      return {
        ...state,
        isShowModal: true,
        modalChildren: action.payload,
      }
    case Type.CLOSE_MODAL:
      return {
        ...state,
        isShowModal: false,
        modalChildren: '',
      }
    case Type.SET_AUTH:
      return {
        ...state,
        isAuth: action.payload,
      }
    case Type.SET_START_PATH:
      return {
        ...state,
        startPath: action.payload,
      }
    case Type.START_APP_LOADER:
      return {
        ...state,
        appLoader: true,
      }
    case Type.FINISH_APP_LOADER:
      return {
        ...state,
        appLoader: false,
      }

    case Type.DETECT_IOS:
      return {
        ...state,
        isIos: action.payload,
      }
    default:
      return state
  }
}
