import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import BaseLink from '../../components/links/BaseLink'
import BaseBtn from '../../components/buttons/BaseBtn'
import BaseInput from '../../components/inputs/BaseInput'

const Wrapper = styled.div`
  width: 100%;
  max-width: 80rem;
  height: 30rem;
  margin-top: ${(p) => p.marginTop};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const Title = styled.p`
  margin-bottom: 2rem;
  font-size: ${(p) => p.theme.h2};
`
const MessageWrap = styled.div`
  width: 100%;
  height: 80%;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
const Text = styled.p`
  margin-bottom: 2rem;
   :last-child {
    margin-bottom: 0;
  }
`

export default ({ marginTop = 0 }) => {
  const dispatch = useDispatch()
  const dic = useSelector((s) => s.defaultLang.serverDictionary)
  const [isRepeat, setIsRepeat] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const [telValue, setTelValue] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [telError, setTelError] = useState(false)
  const [emailMessage, setEmailMessage] = useState(dic.form_err_required)
  const [telMessage, setTelMessage] = useState(dic.form_err_required)
  const lang = useSelector((s) => s.defaultLang.langObj.renewal)
  const isRenewalProcess = useSelector((s) => s.user.isRenewalProcess)

  const onChangeInput = (e) => {
    if (e.target.name === 'email') {
      setEmailValue(e.target.value)
    } else {
      setTelValue(e.target.value)
    }
  }
  const onSubmitForm = () => {
    if (emailValue.length === 0) {
      setEmailError(true)
      setTimeout(
        () => {
          setEmailError(false)
        },
        3000,
        true
      )
      return
    }

    if (telValue.length === 0) {
      setTelError(true)
      setTimeout(
        () => {
          setTelError(false)
        },
        3000,
        true
      )
      return
    }

    dispatch({ type: 'START_REQUEST', payload: 'renewalBtn' })

    dispatch({
      type: 'POST_RENEWAL',
      email: emailValue,
      cellnum: telValue,
    })
  }
  return (
    <Wrapper marginTop={marginTop}>
      <Title>{lang.title}</Title>
      {isRenewalProcess ? (
        <MessageWrap>
          {isRepeat ? (
            <Text>
              {`${lang.msgBlock_one.first} `}
              <span style={{ fontWeight: '600' }}>{emailValue}</span>{' '}
            </Text>
          ) : (
            <>
              <Text>
                {`${lang.msgBlock_two.first} `}
                <span style={{ fontWeight: '600' }}>{emailValue}</span>{' '}
                {lang.msgBlock_two.second}
              </Text>
              <Text>{lang.msgBlock_three.first}</Text>
              <Text>{lang.msgBlock_three.second}</Text>
              <span style={{ color: 'blue' }} onClick={() => setIsRepeat(true)}>
                {lang.link_resend}
              </span>
            </>
          )}
        </MessageWrap>
      ) : (
        <>
          <BaseInput
            name="email"
            label={lang.input_email}
            error={emailError}
            message={emailMessage}
            value={emailValue}
            fnChange={onChangeInput}
            style={{ marginBottom: '1rem' }}
          />
          <BaseInput
            name="tel"
            label={lang.input_tel}
            error={telError}
            message={telMessage}
            value={telValue}
            fnChange={onChangeInput}
            style={{ marginBottom: '2rem' }}
          />
          <BaseBtn
            label={lang.button_confirm}
            inContext="confirm"
            toUpperCase
            fullSize
            fnClick={onSubmitForm}
            localId="renewalBtn"
          />

          <BaseLink
              nav
              arrowback={1}
              label={lang.link_back}
              rout="/authorization"
              style={{ margin: '5rem auto' }}
          />
        </>
      )}
    </Wrapper>
  )
}
