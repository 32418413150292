export const modalChildren = {
  check: 'checklist',
  date: 'calendar',
  time: 'setterTime',
  filter: 'filter',
  transportForm: 'transportForm',
}
export const registrationLink = 'https://sovtes.ua/#form'
export const bitrixCallBackLink =
  'https://bitrix24public.com/sovtes.bitrix24.ua/form/12_u_vas_poyavilis_voprosy_my_gotovy_na_nikh_otvetit/dym9mm/'
export const currencyIcons = {
  uah: '₴',
  usd: '$',
  eur: '€',
}

export const exampleSimbols = {
  uah: '₴',
  usd: '$',
  eur: '€',
  customMinus: '—',
  boldPoint: '•',
  doobleSpace: '\u00A0 \u00A0',
}
export const colors = {
  error: 'error',
  focused: 'focus',
  approver: 'approver',
  regular: 'basic',
  trigger: 'trigger',
  regularText: 'regular_txt',
  links: 'links',
  additionalInfo: 'additional',
  icons: 'elements',
  opposite: 'opposite',
}
export const iconsName = [
  'arrow',
  'share',
  'arrowShevron',
  'close',
  'cancelBtn',
  'avatarMulti',
  'avatarSingle',
  'user',
  'bill',
  'api',
  'calendar',
  'chat',
  'mail',
  'chronology',
  'clock',
  'timer',
  'desctop',
  'documents',
  'customs',
  'border',
  'arrowDown',
  'arrowUp',
  'atencion',
  'prompt',
  'star',
  'sad',
  'done',
  'driver',
  'basket',
  'pencil',
  'magic',
  'eye',
  'crossedEye',
  'theme',
  'filter',
  'hammer',
  'winner',
  'winnerNegative',
  'hook',
  'hookBig',
  'info',
  'location',
  'logout',
  'trailer',
  'mack',
  'map',
  'number',
  'search',
  'settings',
  'coupling',
  'tractor',
  'truck',
  'burger',
  'scrollToTop',
  'support',
]
export const delays = {
  forNotificationQuantity: 15000,
  forTenderBids: 10000,
  forCancelRequest: 30000,
}
export const newRoutePart_TEMPLATE = {
  workaction: 1,
  id: 'w1',
  date: { from: null, to: null, mode: 1, exactly: null },
  time: { from: null, to: null, mode: 1, exactly: null },
  group: { union: '' },
  cargo: { union: '' },
  weight: { union: '' },
  volume: { union: '' },
  indicatesСarrier: false,
}
export const newRoutePart_REQUIRED_FIELD = {
  date: { bool: 0, msg: '', id: 'date' },
  country: { bool: 0, msg: '', id: 'country' },
  town: { bool: 0, msg: '', id: 'town' },
  company: { bool: 0, msg: '', id: 'company' },
  address: { bool: 0, msg: '', id: 'address' },
  checkpointcontactdata: { bool: 0, msg: '', id: 'checkpointcontactdata' },
  cargo: { bool: 0, msg: '', id: 'cargo' },
  weight: { bool: 0, msg: '', id: 'weight' },
}

export const patterns = {
  int: /^-?[0-9]\d*(.\d+)?$/, // проверка на цифру
  float: /^[\d.,]*$/, // проверка на цифру c точкой или запятой
  mainCellNumber: /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/, // перевірка телефонного номера
}

export const FilesTypesApprovedDefault = [
  'jpg',
  'jpeg',
  'png',
  'bmp',
  'gif',
  'tif',
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
]
export const forwardTo = {
  telegram: 'https://t.me/share/url?url=',
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  viber: 'viber://forward?text=',
  gmail: 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=',
}

export const routeFilters = {
  loadTowns: "loadTowns",
  unloadTowns:"unloadTowns",
  shippers:"shippers",
  typeAuto:"typeAuto",
}
